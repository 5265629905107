import { LibraryCategory } from 'store';

export const getSelectedGeneSets = (
  geneSetOptions: LibraryCategory[],
  key: 'label' | 'id' = 'label'
): Set<string> => {
  const geneSets: string[] = [];
  for (let i = 0; i < geneSetOptions.length; i++) {
    for (let j = 0; j < geneSetOptions[i].children.length; j++) {
      geneSets.push(
        ...geneSetOptions[i].children[j].children
          .filter((geneSet) => geneSet.isChecked)
          .map((geneSet) => geneSet[key])
      );
    }
  }
  return new Set(geneSets);
};
