import { useState, useEffect } from 'react';
import { getLastSeenTime } from './utils';

export const useLastSeenTime = (lastStatusUpdated: string) => {
  const [time, setTime] = useState(getLastSeenTime(lastStatusUpdated));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const lastSeenTime = getLastSeenTime(lastStatusUpdated);
      setTime(lastSeenTime);
    }, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, [lastStatusUpdated]);

  return time;
};
