import { Storage } from 'aws-amplify';
import fileDownload from 'js-file-download';

export const downloadS3File = async (path: string) => {
  const url = await Storage.get(path);
  const res = await fetch(url);
  if (res.ok) {
    const data = await res.blob();
    const pathParts = path.split('/');
    fileDownload(data, pathParts[pathParts.length - 1]);
  } else {
    throw new Error(res.statusText);
  }
};
