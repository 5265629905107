import { Link } from '@chakra-ui/react';
import { Paths } from 'routes';
import { FC } from 'react';
import { generatePath, Link as BrowserLink } from 'react-router-dom';

interface LinkCellProps {
  quoteId: string;
}

export const LinkCell: FC<LinkCellProps> = ({ quoteId, children }) => {
  return (
    <Link
      as={BrowserLink}
      to={generatePath(Paths.QuoteDetails, { quoteId })}
      p="0px 12px"
      isTruncated
      w="100%"
      lineHeight="40px"
      _hover={{
        textDecoration: 'none'
      }}
    >
      {children}
    </Link>
  );
};
