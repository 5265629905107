import { ValidationError } from 'models';
import { UserType } from 'generated/types';
import { validatePassword } from './validatePassword';

export interface SignUpData {
  email?: string;
  password?: string;
  repeatPassword?: string;
  firstName?: string;
  lastName?: string;
  country?: { label: string; value: string };
  region?: { label: string; value: string };
  laboratory?: string;
  organization?: string;
  userType?: UserType;
  tosCheck?: boolean;
}

export const validateSignupForm = (
  userData: SignUpData
): ValidationError | undefined => {
  const error = { code: 'validationError' };

  if (!userData.firstName) {
    return {
      ...error,
      field: 'firstName',
      message: 'Please fill in your name'
    };
  }

  if (!userData.lastName) {
    return {
      ...error,
      field: 'lastName',
      message: 'Please fill in your last name'
    };
  }

  if (!userData.email) {
    return { ...error, field: 'email', message: 'Please fill in email' };
  }

  const { password = '', repeatPassword = '' } = userData;
  const passwordValidation = validatePassword(password, repeatPassword);
  if (passwordValidation) {
    return passwordValidation;
  }

  if (!userData.userType) {
    return {
      ...error,
      field: 'accountType',
      message: 'Please choose your account type'
    };
  }

  if (!(userData.country && userData.country.value)) {
    return {
      ...error,
      field: 'country',
      message: 'Please select your country'
    };
  }

  if (!(userData.region && userData.region.value)) {
    return {
      ...error,
      field: 'region',
      message: 'Please select your state/province'
    };
  }

  if (!userData.organization) {
    return {
      ...error,
      field: 'organization',
      message: 'Please fill in your organization'
    };
  }

  if (!userData.laboratory) {
    return {
      ...error,
      field: 'laboratory',
      message: 'Please fill in your laboratory'
    };
  }

  if (!userData.tosCheck) {
    return {
      ...error,
      field: 'tosCheck',
      message: 'You must agree with EULA'
    };
  }
};
