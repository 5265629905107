import { useState, useEffect } from 'react';
export const useFetchStaticFile = (path: string) => {
  const [data, setData] = useState('');
  const [error, setError] = useState<any>();

  useEffect(() => {
    let isIgnored = false;
    (async () => {
      try {
        if (path) {
          const res = await fetch(path);
          const content = await res.text();
          if (!isIgnored) {
            setData(content);
          }
        }
      } catch (e) {
        setError(e);
      }
    })();
    return () => {
      isIgnored = true;
    };
  }, [path]);

  return { data, error };
};
