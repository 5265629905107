import { HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface RowProps {
  label: string;
}

export const Row: FC<RowProps> = ({ label, children }) => {
  return (
    <HStack spacing="4px" align="start">
      <Text whiteSpace="nowrap" variant="bodyShort" color="content.additional">
        {label}
      </Text>
      <Text variant="bodyShort" color="content.tertiary">
        {children}
      </Text>
    </HStack>
  );
};
