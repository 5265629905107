import { Box, VStack, StackDivider } from '@chakra-ui/react';
import { BillingAddresses, ShippingAddresses } from './components';

export const AddressesSettings = () => {
  return (
    <Box w="50%" pl="12px">
      <VStack
        spacing="24px"
        divider={<StackDivider borderColor="border.divider" />}
        w="100%"
      >
        <ShippingAddresses />
        <BillingAddresses />
      </VStack>
    </Box>
  );
};
