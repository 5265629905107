import { Flex, Heading, Link, Text, VStack, Spinner } from '@chakra-ui/react';
import { FC } from 'react';
import { ApproveStatus, useApprovingStatus } from './useApprovingStatus';
import { CheckIcon, WarningIcon } from './assets';

const EMAIL = 'info@vizgen.com';

export const ApproveAccount: FC = () => {
  const status = useApprovingStatus();
  let title = '';
  let details = '';
  let icon = null;

  switch (status) {
    case ApproveStatus.Error: {
      title = 'The user hasn’t been approved';
      details = 'For more details contact';
      icon = <WarningIcon />;
      break;
    }
    case ApproveStatus.Success: {
      title = 'The user has been successfully approved';
      details = 'For any assistance contact';
      icon = <CheckIcon />;
      break;
    }
    case ApproveStatus.InProgress: {
      title = 'Approving user...';
      details = 'For any assistance contact';
      icon = (
        <Flex w="48px" h="48px" align="center" justify="center">
          <Spinner w="36px" h="36px" color="content.additional" />
        </Flex>
      );
    }
  }

  return (
    <Flex h="100vh" w="100vw" pt="220px" justify="center">
      <VStack spacing="8px">
        {icon}
        <VStack spacing="0">
          <Heading variant="subtitle">{title}</Heading>
          <Text>
            {details}{' '}
            <Link href={`mailto:${EMAIL}`} variant="primary">
              {EMAIL}
            </Link>
          </Text>
        </VStack>
      </VStack>
    </Flex>
  );
};
