import {
  Box,
  Flex,
  FlexProps,
  Heading,
  HStack,
  Text,
  VStack
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { TableInstance } from 'react-table';
import { TableColumnsLabels } from '../../../../types';
import { ManageTableColumns } from './components';

export interface TopBarProps<T extends {}> {
  hasSelectPlugin: boolean;
  hasColumnOrderPlugin: boolean;
  selectedAmount: number;
  text: [string?, string?];
  buttons: ReactNode;
  title: ReactNode | string;
  subtitle: ReactNode | string;
  tableInstance: TableInstance<T>;
  manageColumnsProps: {
    columnLabels: TableColumnsLabels;
    hiddenColumns: string[];
    disabledColumns: string[];
  };
  topBarProps?: FlexProps;
  placeholder?: ReactNode;
}

export const TopBar = <T extends {}>({
  hasSelectPlugin,
  hasColumnOrderPlugin,
  selectedAmount,
  text,
  title,
  subtitle,
  buttons,
  topBarProps = {},
  tableInstance,
  manageColumnsProps,
  placeholder
}: TopBarProps<T>) => {
  const isHidden =
    !hasSelectPlugin && !hasColumnOrderPlugin && !text.length && !title;

  if (isHidden) {
    return <Box pt="16px" />;
  }

  const amountText = selectedAmount > 1 && !!text[1] ? text[1] : text[0];

  return (
    <VStack spacing="0">
      <Flex
        justify={hasSelectPlugin ? 'space-between' : 'end'}
        alignItems="center"
        minH="48px"
        w="100%"
        {...topBarProps}
      >
        <HStack spacing="24px">
          {title && <Heading variant="subtitle">{title}</Heading>}
          {hasSelectPlugin && !!selectedAmount ? (
            <HStack spacing="4px">
              <Text as="span" variant="bodyShort" color="content.secondary">
                {selectedAmount}
              </Text>
              <Text as="span" variant="bodyShort" color="content.additional">
                {amountText}
              </Text>
            </HStack>
          ) : (
            placeholder
          )}
        </HStack>
        {hasSelectPlugin && buttons && !!selectedAmount && (
          <HStack spacing="8px" flex="1">
            {buttons}
          </HStack>
        )}
        {hasColumnOrderPlugin && (
          <ManageTableColumns
            tableInstance={tableInstance}
            labels={manageColumnsProps.columnLabels}
            hiddenColumns={manageColumnsProps.hiddenColumns}
            disabledColumns={manageColumnsProps.disabledColumns}
          />
        )}
      </Flex>
      {subtitle && subtitle}
    </VStack>
  );
};
