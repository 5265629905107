import {
  createPanelUserViewMutation,
  updatePanelUserViewMutation,
  getCurrentGeneSetLibraryQuery,
  getPanelUserViewQuery
} from 'api';
import {
  GeneSetSortMethod,
  GeneSortMethod,
  PanelUserView
} from 'generated/types';
import { checkS3File } from 'utils';

export async function fetchUserViewSettings(
  panelId: string,
  userId: string,
  transcriptomeId: string
): Promise<Partial<PanelUserView>> {
  const userViewSettings = await getPanelUserViewQuery({
    panelId,
    userId
  });
  if (!userViewSettings) {
    const [panelUserView, geneSetLibrary] = await Promise.all([
      createPanelUserViewMutation({
        input: {
          panelId,
          userId,
          geneSets: [],
          geneSortMethod: GeneSortMethod.alphabetic,
          geneSetSortMethod: GeneSetSortMethod.numMatchingDescending
        }
      }),
      getCurrentGeneSetLibraryQuery({
        transcriptomeId
      }) as Partial<PanelUserView>
    ]);
    return { ...panelUserView, ...geneSetLibrary };
  }
  const isS3FileExists = await checkS3File(
    userViewSettings?.geneSetS3Key || ''
  );
  if (!isS3FileExists) {
    const geneSetLibrary = (await getCurrentGeneSetLibraryQuery({
      transcriptomeId
    })) as unknown as Partial<PanelUserView>;
    const panelUserView = await updatePanelUserViewMutation({
      panelId,
      userId,
      geneSets: [],
      geneSortMethod: GeneSortMethod.alphabetic,
      geneSetSortMethod: GeneSetSortMethod.numMatchingDescending,
      ...geneSetLibrary
    });
    return { ...panelUserView };
  }
  return userViewSettings;
}
