import { GeneSetEntry, GeneSetsSource } from './types';

export function convertGeneSets(
  source: GeneSetsSource,
  filterByGeneSets?: Set<string>
): GeneSetEntry[] {
  const libraries = Object.values(source.categories).flatMap((category) =>
    Object.values(category.libraries)
  );
  const sets = libraries.flatMap((library) => Object.values(library.sets));

  const entries = sets.map(
    (set) =>
      [set.name, new Set(set.genes.map((g) => g.toUpperCase()))] as GeneSetEntry
  );

  return filterByGeneSets
    ? entries.filter(([geneSetName]) => filterByGeneSets.has(geneSetName))
    : entries;
}
