import { Link, Text, VStack } from '@chakra-ui/react';

export const SignUpMessage = () => {
  return (
    <VStack
      as="form"
      maxW="640px"
      width="100%"
      spacing="24px"
      align="stretch"
      m="0 auto"
      p="40px 36px 24px 36px"
    >
      <Text align="center" fontSize="20">
        Thank you for your interest in using the Vizgen Gene Panel Portal! Your
        request for access to the portal will be verified by an internal team
        member and an email will be sent once you are approved.
      </Text>
      <Text align="center" fontSize="20">
        This process may take up to 48 hours.
      </Text>
      <Text align="center" fontSize="20">
        During this time, please visit the{' '}
        <Link variant="primary" href="https://vizgen.com" size="lg" isExternal>
          Vizgen Official Website
        </Link>{' '}
        to read about the capabilities and new tools that will be available to
        you with MERSCOPE data.
      </Text>
    </VStack>
  );
};
