import { useState } from 'react';
import { Person } from './components';
import { useForm } from 'react-hook-form';
import { useUserStore } from 'store';
import { Box, Heading } from '@chakra-ui/react';
import { UserPropsInput } from 'generated/types';
import { ProfileTopbar } from '@vizgen/vizgen-ui';

interface FormData {
  firstName?: string;
  lastName?: string;
  country?: {
    label: string;
    value: string;
  };
  organization?: string;
  laboratory?: string;
}

export const ProfileDetails = () => {
  const [isProfileEditing, setIsProfileEditing] = useState(false);
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const [timerId, setTimerId] = useState<any>();
  const { handleSubmit, register, control, reset } = useForm();
  const updateAttributes = useUserStore((state) => state.updateAttributes);
  const isFetching = useUserStore((state) => state.isFetching);
  const error = useUserStore((state) => state.error);

  const onEdit = () => {
    setIsProfileEditing(true);
    setIsMessageVisible(false);
    clearTimeout(timerId);
  };

  const onCancel = () => {
    setIsProfileEditing(false);
    reset();
  };

  const saveChanges = async (formData: FormData) => {
    setIsMessageVisible(false);
    clearTimeout(timerId);
    const data: UserPropsInput = {
      name: formData.firstName,
      familyName: formData.lastName,
      laboratory: formData.laboratory,
      organization: formData.organization
    };
    const response = await updateAttributes(data);
    if (response) {
      setIsProfileEditing(false);
    }
    setIsMessageVisible(true);
    setTimerId(
      setTimeout(() => {
        setIsMessageVisible(false);
      }, 5000)
    );
  };

  return (
    <Box as="form" onSubmit={handleSubmit(saveChanges)}>
      <ProfileTopbar
        isFetching={isFetching}
        hasError={!!error}
        isEditing={isProfileEditing}
        isMessageVisible={isMessageVisible}
        containerProps={{
          alignItems: 'center',
          mb: '24px'
        }}
        onEdit={onEdit}
        onCancel={onCancel}
      >
        <Heading as="h1">Profile</Heading>
      </ProfileTopbar>
      <Person
        isProfileEditing={isProfileEditing}
        register={register}
        control={control}
      />
    </Box>
  );
};
