import { getPanelsWithGenesByOwner, getPromotedPanelsWithGenes } from 'api';
import { Auth } from 'aws-amplify';
import { Panel, PanelTranscriptType } from 'generated/types';
import { useQuery } from 'react-query';

export const PANELS_LIST_WITH_GENES_QUERY_KEY = 'panelsListWithGenes';

export const usePanelsListWithGenesQuery = () => {
  return useQuery(PANELS_LIST_WITH_GENES_QUERY_KEY, async () => {
    const { attributes } = await Auth.currentAuthenticatedUser();
    const owner = attributes.sub;
    if (!owner) return [] as Panel[];
    const genesFilter = { type: { eq: PanelTranscriptType.sequential } };
    const fetchCustomPanelsByOwner = async (owner: string) =>
      getPanelsWithGenesByOwner({
        owner,
        filter: { isCompleted: { eq: true } },
        genesFilter
      });

    const [oldCustomPanels = [], newCustomPanels = [], promotedPanels = []] =
      await Promise.all([
        // Search for owners written with v1 of the GraphQL transformer
        fetchCustomPanelsByOwner(owner),
        // // Search for owners written with v2 of the GraphQL transformer
        fetchCustomPanelsByOwner(owner + '::' + owner),
        getPromotedPanelsWithGenes({
          genesFilter
        })
      ]);
    return [
      ...oldCustomPanels,
      ...newCustomPanels,
      ...promotedPanels
    ] as Panel[];
  });
};
