import { FC } from 'react';
import { Scrollbar, ScrollbarVariants } from '../../../Scrollbar';
import { theme } from '../../../../theme';
import { Box } from '@chakra-ui/react';
const { colors } = theme;

interface TableWrapperProps {
  border: string | number;
  hasStickyColumns: boolean;
}

export const TableWrapper: FC<TableWrapperProps> = ({
  border,
  hasStickyColumns,
  children
}) => {
  const style = {
    border,
    borderColor: colors.border.normal,
    borderRadius: '8px'
  };

  return hasStickyColumns ? (
    <Scrollbar
      autoHeight
      height="100%"
      autoHeightMax="100%"
      variant={ScrollbarVariants.Table}
      style={style}
    >
      {children}
    </Scrollbar>
  ) : (
    <Box {...style} overflow="hidden">
      {children}
    </Box>
  );
};
