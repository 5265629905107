import { Box, Flex, Heading, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import {
  CreatePanelModel,
  duplicatedGeneNamesSelector,
  transcriptsByTypeAmountSelector,
  useCreatePanelStore
} from 'store';
import { PanelTranscriptType } from 'generated/types';
import { DownloadButton, Tooltip, theme } from '@vizgen/vizgen-ui';
import { downloadGenesList } from 'utils';
import { shallow } from 'zustand/shallow';
import { InfoIcon } from 'assets';
import { TooltipLabel } from './components';
const { colors } = theme;

export const Topbar: FC = () => {
  const [
    panel,
    transcripts,
    primaryTranscriptsAmount,
    duplicateGenes,
    duplicatedGeneNames
  ] = useCreatePanelStore(
    (state) => [
      state.panel || ({} as CreatePanelModel),
      state.transcripts,
      transcriptsByTypeAmountSelector(PanelTranscriptType.primary)(state),
      state.duplicateGenes,
      duplicatedGeneNamesSelector(state)
    ],
    shallow
  );
  const hasDuplicates = !!(
    duplicateGenes?.length || duplicatedGeneNames.length
  );

  const onGenesListDownload = () => {
    downloadGenesList(
      panel?.isCompleted,
      panel?.serialNumberTag,
      panel?.panelName,
      transcripts
    );
  };

  return (
    <Flex w="100%" pt="4px" pr="60px">
      <Flex justify="space-between" alignItems="center" w="100%" maxW="840px">
        <HStack h="40px">
          <Heading variant="subtitle">
            MERFISH Panel{' '}
            <Text color="content.additional" variant="span">
              ({primaryTranscriptsAmount})
            </Text>
          </Heading>
          {hasDuplicates && (
            <Tooltip
              maxW="615px"
              label={
                <TooltipLabel
                  duplicateGenes={duplicateGenes}
                  duplicatedGeneNames={duplicatedGeneNames}
                />
              }
            >
              <Box
                cursor="pointer"
                css={{
                  path: {
                    stroke: colors.state.infoDark
                  },
                  'path.icon-path_filled': {
                    stroke: 'transparent',
                    fill: colors.state.infoDark
                  },
                  '&:hover path': {
                    stroke: colors.content.link.normal
                  },
                  '&:hover path.icon-path_filled': {
                    stroke: 'transparent',
                    fill: colors.content.link.normal
                  }
                }}
              >
                <InfoIcon />
              </Box>
            </Tooltip>
          )}
        </HStack>
        <HStack spacing="8px">
          {transcripts.length && (
            <DownloadButton
              p="0 8px"
              minW="auto"
              onClick={onGenesListDownload}
              data-testid="downloadGeneListButton"
            >
              Download Gene List
            </DownloadButton>
          )}
        </HStack>
      </Flex>
    </Flex>
  );
};
