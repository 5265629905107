import { Flex, VStack, Center, Text } from '@chakra-ui/react';
import {
  AutocompleteField,
  AutocompleteSuggestion,
  AutocompleteFieldSize
} from '@vizgen/vizgen-ui';
import { FC } from 'react';
import { ButtonsBar } from './components';

interface GeneSetsAxisProps {
  isSearchDisabled?: boolean;
  suggestionItems: AutocompleteSuggestion[];
  searchValue: string;
  setSearchValue: (value: string) => void;
}

export const GeneSetsAxis: FC<GeneSetsAxisProps> = ({
  isSearchDisabled,
  suggestionItems,
  searchValue,
  setSearchValue
}) => {
  return (
    <VStack spacing="20px" w="100%">
      <Flex w="100%" justify="space-between">
        <AutocompleteField
          isLeftElementShown={false}
          size={AutocompleteFieldSize.Sm}
          suggestions={suggestionItems}
          value={suggestionItems.find((s) => s.label === searchValue)}
          isDisabled={isSearchDisabled}
          name="genesSearch"
          placeholder="Search gene/gene set"
          onChange={(e) => setSearchValue(e?.label || '')}
        />
        <ButtonsBar />
      </Flex>
      <Center>
        <Text variant="captionShort" color="content.additional">
          GENE SETS
        </Text>
      </Center>
    </VStack>
  );
};
