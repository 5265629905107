import { FC, Fragment } from 'react';
import { Text, Flex } from '@chakra-ui/layout';
import { ReactComponent as CheckIcon } from 'assets/checkIcon.svg';
import { Box } from '@chakra-ui/react';
import { theme } from '@vizgen/vizgen-ui';
const { colors } = theme;

export interface StepperProps {
  steps: string[];
  currentStep: number;
}

export const Stepper: FC<StepperProps> = ({ steps, currentStep }) => {
  const showSeparator = (index: number): boolean => {
    return index !== steps.length - 1;
  };

  const dotColor = (index: number): string => {
    return currentStep >= index
      ? colors.state.success
      : colors.background.primary;
  };

  const borderColor = (index: number): string => {
    return currentStep >= index ? colors.state.success : colors.border.selected;
  };

  const separatorColor = (index: number): string => {
    return currentStep > index ? colors.state.success : colors.border.normal;
  };

  return (
    <Flex direction="row" justify="space-between">
      {steps.map((step, index) => (
        <Fragment key={step}>
          <Flex direction="column" align="center" w="16px">
            <Flex
              align="center"
              justify="center"
              w="16px"
              h="16px"
              border="2px solid"
              borderColor={borderColor(index)}
              bg={dotColor(index)}
              borderRadius="8px"
              mb="6px"
            >
              {currentStep === index && (
                <Box>
                  <CheckIcon />
                </Box>
              )}
            </Flex>
            <Text
              variant="bodyShort"
              color={
                currentStep === index
                  ? colors.content.secondary
                  : colors.content.additional
              }
              whiteSpace="nowrap"
            >
              {step}
            </Text>
          </Flex>
          {showSeparator(index) && (
            <Flex
              flex="1"
              mt="6.5px"
              borderTop="1px solid"
              borderColor={separatorColor(index)}
            ></Flex>
          )}
        </Fragment>
      ))}
    </Flex>
  );
};
