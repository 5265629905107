import { useEffect, useState } from 'react';

export const useIsOverflow = (ref: any) => {
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const trigger = () => {
      const { scrollHeight, clientHeight } = ref;
      setIsOverflow(scrollHeight > clientHeight);
    };

    if (ref) {
      trigger();
      if ('ResizeObserver' in window) {
        const observer = new ResizeObserver(trigger);
        observer.observe(ref);
        return () => {
          observer.unobserve(ref);
        };
      }
    }
  }, [ref]);

  return isOverflow;
};

export const useScrollPosition = (ref: any, isOverflow: boolean) => {
  const [position, setPosition] = useState({
    isBottomReached: true,
    isTopReached: true
  });

  useEffect(() => {
    if (isOverflow) {
      const trigger = () => {
        const { scrollTop, scrollHeight, clientHeight } = ref;
        setPosition({
          isBottomReached: scrollTop + clientHeight === scrollHeight,
          isTopReached: scrollTop === 0
        });
      };

      if (ref) {
        trigger();
        ref.addEventListener('scroll', trigger);
        return () => {
          ref.removeEventListener('scroll', trigger);
        };
      }
    } else {
      setPosition({
        isBottomReached: true,
        isTopReached: true
      });
    }
  }, [ref, isOverflow]);

  return position;
};
