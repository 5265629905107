import {
  Circle,
  Menu,
  MenuButton,
  MenuList,
  MenuProps,
  Portal,
  useDisclosure
} from '@chakra-ui/react';
import { GeneStatusIcon, ConfirmGeneDeleteModal } from 'components';
import { PanelTranscriptStatus, PanelTranscriptType } from 'generated/types';
import { FC } from 'react';
import { PanelTranscriptStatusExtended } from 'types';
import {
  IncludeItem,
  MoveToItem,
  DeleteGene,
  GeneText,
  PickSynonym
} from './components';

interface GeneMenuProps extends Omit<MenuProps, 'children'> {
  view: PanelTranscriptStatus | PanelTranscriptStatusExtended;
  transcriptId: string;
  type: PanelTranscriptType;
  isIncluded: boolean | null;
  isPrimary: boolean;
}

export const GeneMenu: FC<GeneMenuProps> = ({
  children,
  view,
  transcriptId,
  type,
  isIncluded,
  isPrimary,
  ...rest
}) => {
  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose
  } = useDisclosure();
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose
  } = useDisclosure();

  return (
    <>
      <Menu {...rest} offset={[0, 0]} onOpen={onMenuOpen} onClose={onMenuClose}>
        <MenuButton
          d="flex"
          alignItems="center"
          w="40px"
          h="100%"
          justifyContent="center"
          _active={{
            bg: 'button.ghostOnPrimary.pressed',
            '& .three-dots-icon': {
              fill: 'content.secondary'
            }
          }}
          css={{
            '& svg': {
              margin: 'auto'
            }
          }}
          data-testid={`geneMenuButton_${transcriptId}`}
        >
          {children ||
            (isIncluded ? (
              <Circle size="12px" bg="state.warning" m="auto" />
            ) : (
              <GeneStatusIcon type={view} />
            ))}
        </MenuButton>
        {isMenuOpen && (
          <Portal>
            <MenuList maxW="265px">
              <GeneText view={view} transcriptId={transcriptId} />
              <IncludeItem
                isVisible={view === PanelTranscriptStatus.insufficient_tr}
                transcriptId={transcriptId}
              />
              <MoveToItem
                isVisible={view !== PanelTranscriptStatus.not_found}
                type={type}
                transcriptId={transcriptId}
              />
              <PickSynonym
                isVisible={view === PanelTranscriptStatus.not_found}
                transcriptId={transcriptId}
                onClick={onMenuClose}
              />
              <DeleteGene onClick={onModalOpen} />
            </MenuList>
          </Portal>
        )}
      </Menu>
      <ConfirmGeneDeleteModal
        isVisible={isModalOpen}
        isPrimary={isPrimary}
        transcriptIds={[transcriptId]}
        onClose={onModalClose}
      />
    </>
  );
};
