import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { InfoIcon } from 'assets';
import { Label } from './components';
import { theme, Tooltip } from '@vizgen/vizgen-ui';
const { colors } = theme;

interface AbundanceTooltipProps {
  color: string;
  maxW: string;
}

export const AbundanceTooltip: FC<AbundanceTooltipProps> = ({
  color,
  maxW
}) => {
  return (
    <Tooltip label={<Label />} maxW={maxW}>
      <Box
        as="span"
        w="24px"
        mr="4px"
        css={{
          path: {
            stroke: color
          },
          'path.icon-path_filled': {
            stroke: 'transparent',
            fill: color
          },
          '&:hover path': {
            stroke: colors.content.link.hover
          },
          '&:hover path.icon-path_filled': {
            stroke: 'transparent',
            fill: colors.content.link.hover
          }
        }}
      >
        <InfoIcon />
      </Box>
    </Tooltip>
  );
};
