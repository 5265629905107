import { fontStyles } from '../fonts';

export const Textarea = {
  variants: {
    default: {
      fontSize: fontStyles.fontSizes[16],
      lineHeight: fontStyles.lineHeights[24],
      borderRadius: '4px',
      p: '12px',
      color: 'content.secondary',
      border: '1px solid',
      bg: 'background.primary',
      borderColor: 'border.normal',
      _invalid: {
        borderColor: 'state.error',
        boxShadow: 'none'
      },
      _focus: {
        borderColor: 'border.selected',
        boxShadow: 'none'
      },
      _placeholder: {
        color: 'content.additional'
      },
      _disabled: {
        opacity: 0.5
      }
    }
  },
  defaultProps: {
    size: 'md',
    variant: 'default'
  }
};
