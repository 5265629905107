import { Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface TablePlaceholderProps {
  isLoading?: boolean;
}

export const TablePlaceholder: FC<TablePlaceholderProps> = ({
  isLoading,
  children
}) => {
  return (
    <Flex w="100%" justify="center">
      <Text color="content.additional" variant="bodyShort" mt="16px">
        {isLoading ? 'Loading...' : children}
      </Text>
    </Flex>
  );
};
