export enum Paths {
  Home = '/',
  Quotes = '/quote-requests',
  Profile = '/profile',
  ContactUs = '/contact-us',
  TermsOfUse = '/terms-of-use',
  Instruments = '/instruments',
  CreatePanel = '/create-panel',
  CreatePanel_Configuration = '/create-panel/:panelId',
  CreatePanel_AddGenes = '/create-panel/add-genes/:panelId',
  CreatePanel_Summary = '/create-panel/summary/:panelId',
  Panels = '/panels',
  PanelDetails = '/panel-details/:panelId',
  PromotedPanelDetails = '/promoted-panel-details/:panelId',
  QuoteDetails = '/quote-details/:quoteId',
  NewQuote = '/new-quote-request',
  NewQuoteForPanel = '/new-quote-request/:panelId',
  PrintablePanel = '/printable-panel/:panelType/:panelId',
  Resources = '/resources',
  ResourcesByType = '/resources/:resourceType',
  ApproveAccount = '/approve-account/:requestId',
  GeneSetLibraries = '/gene-set-libraries'
}

export enum ResourceType {
  Software = 'software',
  UserGuides = 'user-guides',
  ApplicationAndTechnicalNotes = 'application-and-technical-notes',
  SafetyDataSheets = 'safety-data-sheets',
  ProductNotifications = 'product-notifications'
}
