import { FC, useState, CSSProperties } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text
} from '@chakra-ui/react';
import { useResizeDetector } from 'react-resize-detector';
import {
  PopoverCloseIcon,
  PopoverInfoIcon,
  PopoverInfoTriggeredIcon
} from 'assets';
import { addPromotedPanelToPanel, addFinalizedPanelToPanel } from 'api';
import { CreatePanelModel, TranscriptModel, useCreatePanelStore } from 'store';
import { PanelSummary } from './components/PanelSummary';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Paths } from 'routes';
import { Panel, PanelOwnershipType } from 'generated/types';

interface PanelItemProps {
  panel: Panel;
  style: CSSProperties;
}

export const PanelItem: FC<PanelItemProps> = ({ panel, style }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const { ref } = useResizeDetector();
  const draftPanel = useCreatePanelStore(
    (state) => state.panel || ({} as CreatePanelModel)
  );
  const addTranscripts = useCreatePanelStore((state) => state.addTranscripts);
  const isProprietary = panel.ownershipType === PanelOwnershipType.proprietary;

  const addGenes = async () => {
    setIsSaving(true);
    let transcripts;
    const targetPanelId = draftPanel.panelId;
    const promotedPanelId = panel.panelId;
    if (isProprietary) {
      transcripts = await addFinalizedPanelToPanel(
        targetPanelId,
        promotedPanelId
      );
    } else {
      transcripts = await addPromotedPanelToPanel(
        targetPanelId,
        promotedPanelId
      );
    }
    addTranscripts(transcripts as TranscriptModel[]);
    setIsSaving(false);
  };

  return (
    <Popover placement="bottom-start" isOpen={isOpen} onClose={close}>
      <PopoverTrigger>
        <ListItem
          style={style}
          h="64px"
          d="flex"
          justifyContent="space-between"
          borderBottom="1px solid"
          borderColor="border.divider"
          p="0 8px 3px 12px"
          css={{ '&:last-child': { borderBottom: 0 } }}
          ref={ref}
        >
          <Stack spacing="0" mt="8px">
            <HStack mb="4px" alignItems="center" spacing="4px">
              <Text variant="accentBodyShort">{panel.panelName}</Text>
              <Box as="span" onClick={open}>
                {isOpen ? (
                  <PopoverInfoTriggeredIcon />
                ) : (
                  <PopoverInfoIcon cursor="pointer" />
                )}
              </Box>
            </HStack>
            <Text>
              {panel?.genesSummary?.totalNumber || 0}{' '}
              <Text as="span" color="content.additional">
                genes
              </Text>
            </Text>
          </Stack>
          <Button
            mt="4px"
            size="tiny"
            variant="tinyGhostPrimary"
            onClick={addGenes}
            disabled={isSaving}
          >
            Add
          </Button>
        </ListItem>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          pos="relative"
          mt="-8px"
          w={(ref.current as any)?.getBoundingClientRect().width}
          border="1px solid"
          borderColor="border.normal"
          boxShadow="0px 4px 8px rgba(215, 217, 220, 0.4)"
        >
          <PopoverHeader
            p="8px 12px"
            fontWeight="heavy"
            border="0"
            d="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex alignItems="center">
              <Text color="state.infoDark" variant="accentBodyShort" ml="4px">
                {panel.panelName} Panel
              </Text>
            </Flex>
            <IconButton
              aria-label="Close"
              variant="primaryGhost"
              size="auto"
              icon={<PopoverCloseIcon />}
              onClick={close}
            />
          </PopoverHeader>
          <PopoverBody p="8px 12px">
            <PanelSummary panel={panel} />
          </PopoverBody>
          <PopoverFooter borderTop="0" p="0 4px 12px">
            <Flex justify="flex-end">
              <Button
                as={RouterLink}
                to={generatePath(
                  isProprietary
                    ? Paths.PanelDetails
                    : Paths.PromotedPanelDetails,
                  {
                    panelId: panel.panelId
                  }
                )}
                target="_blank"
                variant="tinyGhostPrimary"
                size="tiny"
              >
                View full specifications
              </Button>
            </Flex>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
