import { Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { TranscriptModel } from 'store';

interface GenesHeadingProps {
  transcripts: TranscriptModel[];
}

export const GenesHeading: FC<GenesHeadingProps> = ({ transcripts }) => {
  return (
    <VStack spacing="16px" w="100%" align="start">
      <Heading as="h2" variant="subtitle" w="100%">
        <HStack as="span" spacing="8px">
          <Heading as="span" variant="subtitle">
            Genes
          </Heading>
          <Heading as="span" variant="subtitle" color="content.additional">
            ({transcripts.length})
          </Heading>
        </HStack>
      </Heading>
      {!transcripts.length && (
        <Text variant="accentBase" color="content.additional">
          This panel has no added genes yet
        </Text>
      )}
    </VStack>
  );
};
