import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { LibraryCategory } from 'store';
import pluralize from 'pluralize';
import { calculateSelectedSets } from './utils';

interface FooterProps {
  isLoading: boolean;
  libraryCategories: LibraryCategory[];
  onClose: () => void;
  onSubmit: () => void;
}

export const Footer: FC<FooterProps> = ({
  isLoading,
  libraryCategories,
  onClose,
  onSubmit
}) => {
  const { sets, libraries } = calculateSelectedSets(libraryCategories);
  const librariesText = (
    <>
      in <b>{libraries}</b> {pluralize('library', libraries)}
    </>
  );

  return (
    <VStack w="100%" align="center" spacing="8px">
      <Text data-testid="footerSummary">
        <b>{sets}</b> {pluralize('set', sets)} {sets ? librariesText : ''}{' '}
        selected
      </Text>
      <HStack w="100%" justify="center" spacing="14px">
        <Button
          variant="primaryGhost"
          bg="background.primary"
          data-testid="cancelButton"
          onClick={onClose}
          maxW="80px"
          minW="80px"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="secondarySolid"
          data-testid="applyButton"
          onClick={onSubmit}
          disabled={isLoading}
          isLoading={isLoading}
        >
          Apply
        </Button>
      </HStack>
    </VStack>
  );
};
