import { FC, ReactNode, useState, CSSProperties, useEffect } from 'react';
import { Accordion, AccordionItem, Box } from '@chakra-ui/react';
import { AccordionBody } from './components';
import { theme } from '../../theme';
const { colors } = theme;

export interface SingleAccordionProps {
  title: string | ReactNode;
  hideButton?: boolean;
  variant?: 'card' | 'open';
  cardStyles?: CSSProperties;
  borderStyles?: CSSProperties;
  isDefaultExpanded?: boolean;
}

const cardBorderStyles = {
  border: '1px solid',
  borderColor: colors.border.normal,
  borderRadius: '8px',
  boxShadow: `0px 4px 8px 0px rgba(215, 217, 220, 0.4)`
};

const openBorderStyles = {
  border: 'none'
};

const cardContainerStyles = {
  background: colors.background.secondary,
  border: 'none',
  borderColor: 'transparent',
  borderRadius: '8px',
  padding: '16px'
};

const openContainerStyles = {
  border: 'none',
  borderColor: 'transparent'
};

export const SingleAccordion: FC<SingleAccordionProps> = ({
  title,
  children,
  variant = 'card',
  isDefaultExpanded = true,
  hideButton = false,
  cardStyles = {},
  borderStyles = {}
}) => {
  // Initially we reduce motion to prevent blinking
  const [reduceMotion, setReduceMotion] = useState<boolean>(true);

  useEffect(() => {
    if (reduceMotion) setReduceMotion(false);
  }, [reduceMotion]);

  const boxBorderStyles = {
    card: { ...cardBorderStyles, ...borderStyles },
    open: openBorderStyles
  }[variant];
  const containerStyles = {
    card: {
      ...cardContainerStyles,
      ...cardStyles
    },
    open: openContainerStyles
  }[variant];

  return (
    <Box style={boxBorderStyles} w="100%">
      <Accordion
        allowToggle
        allowMultiple
        style={containerStyles}
        reduceMotion={reduceMotion}
        defaultIndex={isDefaultExpanded ? 0 : undefined}
      >
        <AccordionItem
          css={{
            '.chakra-collapse': {
              overflow: 'visible !important'
            }
          }}
        >
          <AccordionBody hideButton={hideButton} title={title}>
            {children}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
