import { Box, Text } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { SequenceActions } from 'components';

interface SequenceProps {
  sequence: string;
}

export const Sequence: FC<SequenceProps> = ({ sequence }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Box pos="relative">
      <SequenceActions
        value={sequence}
        isCollapsed={isCollapsed}
        onCollapseButtonClick={() => setIsCollapsed((prev) => !prev)}
        containerProps={{ top: '-32px' }}
      />
      <Box p="12px" bg="background.secondary" borderRadius="4px">
        <Text
          variant="bodyShort"
          isTruncated={isCollapsed}
          {...(isCollapsed ? {} : { maxH: '200px', overflow: 'scroll' })}
        >
          {sequence}
        </Text>
      </Box>
    </Box>
  );
};
