import { Flex, Text, ListItem, forwardRef } from '@chakra-ui/react';
import { Panel } from 'generated/types';

interface ItemProps {
  item: Panel;
  isSelected: boolean;
  onItemSelect: (value: Panel) => void;
}

export const Item = forwardRef<ItemProps, 'ul'>(
  ({ isSelected, item, onItemSelect }, ref) => {
    const backgroundColor = isSelected
      ? 'background.secondary'
      : 'background.primary';

    return (
      <ListItem
        bg={backgroundColor}
        cursor="pointer"
        h="40px"
        p="8px 12px"
        transition="all 0.2s ease-in-out"
        _hover={{
          bg: 'background.highlight'
        }}
        onClick={() => onItemSelect(item)}
      >
        <Flex justify="space-between" alignItems="center" h="100%">
          <Flex h="100%" direction="column" justify="center">
            <Text>{item.panelName}</Text>
          </Flex>
        </Flex>
      </ListItem>
    );
  }
);
