import { Tr } from '@chakra-ui/react';
import { CSSProperties, PropsWithChildren } from 'react';
import { Row } from 'react-table';
import { theme } from '../../theme';
const { colors } = theme;

interface TrTemplateProps<T extends {} = {}> {
  row: Row<T>;
  style: CSSProperties;
}
export const TrTemplate = <T extends {}>({
  row,
  children,
  style
}: PropsWithChildren<TrTemplateProps<T>>): JSX.Element => {
  return (
    <Tr
      as="div"
      className="tr"
      {...row.getRowProps()}
      d="flex"
      alignItems="center"
      style={{
        background: row.isSelected ? colors.background.highlight : undefined,
        ...style
      }}
    >
      {children}
    </Tr>
  );
};
