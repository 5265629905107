import { GeneSet, GeneSetLibrary, LibraryCategory } from 'store';

export const matchGeneSet = (geneSetName: string, searchValue: string) =>
  geneSetName.toLowerCase().includes(searchValue.toLowerCase());

export const findMatchingGeneSetsAmount = (
  geneSets: GeneSet[],
  searchValue: string
) =>
  geneSets.filter((geneSet) => matchGeneSet(geneSet.label, searchValue)).length;

export const findMatchingGeneSetLibrariesAmount = (
  geneSetLibraries: GeneSetLibrary[],
  searchValue: string
) =>
  geneSetLibraries.reduce(
    (accum, geneSetLibrary) =>
      accum + findMatchingGeneSetsAmount(geneSetLibrary.children, searchValue),
    0
  );

export const findMatchingGeneSetCategoriesAmount = (
  categories: LibraryCategory[],
  searchValue: string
) =>
  categories.reduce(
    (accum, category) =>
      accum +
      findMatchingGeneSetLibrariesAmount(category.children, searchValue),
    0
  );
