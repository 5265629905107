import { useIncrementalListRendering } from 'hooks';
import { FC } from 'react';
import { GeneSet as GeneSetItem } from 'store';
import { GeneSet } from './components';

interface GeneSetListProps {
  searchedGene: string;
  geneSets: GeneSetItem[];
  expandedGeneSetName: string;
  onGeneSetExpand: (name: string) => void;
}

export const GeneSetList: FC<GeneSetListProps> = ({
  searchedGene,
  geneSets,
  expandedGeneSetName,
  onGeneSetExpand
}) => {
  const renderCount = useIncrementalListRendering(geneSets);

  return (
    <>
      {geneSets.slice(0, renderCount).map((geneSet) => {
        const isGeneSetExpanded = geneSet.label === expandedGeneSetName;

        return (
          <GeneSet
            key={geneSet.label}
            searchedGene={searchedGene}
            label={geneSet.label}
            isGeneSetExpanded={isGeneSetExpanded}
            genes={geneSet.genes}
            onGeneSetExpand={onGeneSetExpand}
          />
        );
      })}
    </>
  );
};
