import { FC, useEffect, useMemo } from 'react';
import { HStack, Text, Flex, Box } from '@chakra-ui/react';
import { Dropdown, ValidationMessage } from '@vizgen/vizgen-ui';
import { ReactComponent as LockedIcon } from 'assets/locked.svg';
import { Control, FieldValues, useController } from 'react-hook-form';
import { TissueModel, useGlobalListsStore } from 'store';

interface TissueFieldProps {
  control: Control<FieldValues>;
  referenceValue: string | undefined;
  isDisabled: boolean;
  tissueId: string | null;
}

export const TissueField: FC<TissueFieldProps> = ({
  control,
  referenceValue,
  isDisabled,
  tissueId
}) => {
  const tissuesData = useGlobalListsStore((state) => state.tissues);
  const tissues = useMemo(
    () => tissuesData.filter((tis) => tis.transcriptomeId === referenceValue),
    [tissuesData, referenceValue]
  );
  const defaultValue = tissueId
    ? tissues.find((data) => data.tissueId === tissueId)
    : referenceValue && tissues.length
    ? tissues[0]
    : null;
  const {
    field: { onChange, name, value },
    fieldState: { invalid }
  } = useController({
    name: 'tissue',
    control,
    rules: { required: true },
    defaultValue
  });

  const onChangeHandler = (
    e: {
      transcriptomeId: string;
      tissueId: string;
      name: string;
    } | null
  ) => {
    onChange(e);
  };

  useEffect(() => {
    onChange(defaultValue);
  }, [defaultValue]); // eslint-disable-line

  return (
    <HStack spacing="24px" alignItems="flex-start">
      <Flex direction="column" maxW={264} w="40%" pt="12px">
        <Flex direction="row">
          <Text mr="8px">Tissue</Text>
          {(!tissues.length || isDisabled) && <LockedIcon />}
        </Flex>
        <Text color="content.additional" variant="captionShort">
          If the tissue is not the exact match, select the closest to the one
          you require and let us know about it
        </Text>
      </Flex>
      <Box maxW={360} w="60%" pos="relative">
        <Dropdown<TissueModel>
          data-testid="select-tissue"
          items={tissues}
          defaultValue={value}
          value={value}
          onChange={onChangeHandler}
          valueKey="tissueId"
          labelKey="name"
          name={name}
          isDisabled={!tissues.length || isDisabled}
          placeholder="--Select--"
          isInvalid={invalid}
        />
        {invalid && (
          <ValidationMessage>Fill the required field</ValidationMessage>
        )}
      </Box>
    </HStack>
  );
};
