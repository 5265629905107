import { ChangeEvent, CSSProperties } from 'react';
import { Row, IdType } from 'react-table';
import { IndeterminateCheckbox } from '../../../IndeterminateCheckbox';

interface CheckboxCellProps<T extends {}> {
  row: Row<T>;
  rows: Row<T>[];
  prevRowId?: string;
  setPrevRowId: (rowId: string) => void;
  toggleRow: (rowId: IdType<T>, state?: boolean) => void;
  rowCheckboxStyles?: CSSProperties;
  isDisabled?: boolean;
}

export const CheckboxCell = <T extends {}>({
  row,
  rows,
  prevRowId,
  setPrevRowId,
  toggleRow,
  isDisabled = false,
  rowCheckboxStyles
}: CheckboxCellProps<T>) => {
  const { onChange, ...rest } = row.getToggleRowSelectedProps();

  const onClick = (e: ChangeEvent<Element>) => {
    const rowId = row.id;
    const event = e.nativeEvent as MouseEvent;
    if (!event.shiftKey) {
      setPrevRowId(rowId);
      return;
    }
    if (prevRowId) {
      let startIndex = rows.findIndex((row) => row.id === prevRowId);
      let endIndex = rows.findIndex((row) => row.id === rowId);
      if (startIndex > endIndex) {
        [startIndex, endIndex] = [endIndex, startIndex];
      }
      for (let i = startIndex; i < endIndex; i++) {
        toggleRow(rows[i].id, true);
      }
    }
  };

  return (
    <IndeterminateCheckbox
      onChange={(e) => {
        onClick(e);
        onChange && onChange(e);
      }}
      {...rest}
      isDisabled={isDisabled}
      style={{
        ...rowCheckboxStyles
      }}
    />
  );
};
