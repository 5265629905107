import { MAX_MERFISH_GENES_AMOUNT, MID_MERFISH_GENES_AMOUNT } from 'consts';
import { PanelOwnershipType, PanelTranscriptStatus } from 'generated/types';
import { useFeatureFlagsStore } from 'store';
import {
  CreatePanelData,
  GeneInfo,
  PanelFormData,
  TranscriptModel,
  UpdatePanelData
} from './models';

export const includeUpdatedTranscripts = (
  stateTranscripts: TranscriptModel[],
  updatedTranscripts: TranscriptModel[]
) => {
  return stateTranscripts.map((transcript) => {
    const newTranscript = updatedTranscripts.find(
      (updatedTranscript) =>
        updatedTranscript.transcriptId === transcript.transcriptId
    );
    if (newTranscript) {
      return newTranscript;
    }
    return transcript;
  });
};

export const isIssue = (
  status: PanelTranscriptStatus,
  isIncluded: boolean | null
) => {
  const { insufficient_tr, untargetable, not_found } = PanelTranscriptStatus;
  const isInsufficientIssue = status === insufficient_tr && !isIncluded;
  const isOtherIssue = [untargetable, not_found].includes(status);
  return isInsufficientIssue || isOtherIssue;
};

export const getTotalAbundance = (transcripts: TranscriptModel[]) => {
  return transcripts.reduce((accumulator, transcript) => {
    return accumulator + (transcript.abundance || 0);
  }, 0);
};

export const getNewFormData = (form: PanelFormData): CreatePanelData => {
  return {
    ...getUpdatedFormData(form),
    // Temporarily set as true and proprietary for all new panels VCD-352
    isProprietary: true,
    ownershipType: PanelOwnershipType.proprietary
  };
};

export const getUpdatedFormData = (form: PanelFormData): UpdatePanelData => {
  return {
    tissueId: form.tissue?.tissueId || '',
    panelName: form.panelName || '',
    transcriptomeId: form.reference?.transcriptomeId || '',
    comment: form.comment || ''
  };
};

export const getMaxMerfishGenesAmount = () => {
  const { vcd560 } = useFeatureFlagsStore.getState().featureFlags;
  return vcd560 ? MAX_MERFISH_GENES_AMOUNT : MID_MERFISH_GENES_AMOUNT;
};

export const getDuplicates = (response: any[]) => {
  const duplicates = response.filter((res) => res.duplicated);

  const duplicateGenes = duplicates?.map((duplicateGene) => {
    const obj: GeneInfo = {
      geneInput: duplicateGene.userSpecifiedName,
      name: duplicateGene.geneName
        ? duplicateGene.geneName
        : duplicateGene.userSpecifiedName
    };
    return obj;
  });

  return duplicateGenes;
};
