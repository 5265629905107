import { DEFAULT_PRODUCTS_OPTION, PRODUCT_LABELS } from 'consts';
import { Resource } from 'models';
import { useState, useMemo } from 'react';
import { formatShortDate } from 'utils';

export const useFilteredResources = (
  resources: Resource[],
  searchValue: string,
  dropdownValue: string
) => {
  const [isFiltered, setIsFiltered] = useState(false);

  const filteredResources = useMemo(() => {
    const sanitizedSearchValue = searchValue.toLowerCase();
    const defaultDropdownValue = DEFAULT_PRODUCTS_OPTION.value;
    let data: Resource[] = [];
    setIsFiltered(
      !!(sanitizedSearchValue || dropdownValue !== defaultDropdownValue)
    );
    if (dropdownValue === defaultDropdownValue) {
      data = [...resources];
    } else {
      data = resources.filter((resource) => resource.product === dropdownValue);
    }
    data = data.filter(({ description, product, version, resourceDate }) => {
      const props = [
        description,
        PRODUCT_LABELS[product] || '',
        version,
        formatShortDate(resourceDate)
      ];
      return props.some((prop) =>
        prop.toLowerCase().includes(sanitizedSearchValue)
      );
    });
    return data;
  }, [resources, searchValue, dropdownValue]);

  return { filteredResources, isFiltered };
};
