import { Box, Button } from '@chakra-ui/react';
import { Panel } from 'generated/types';
import { FC, useEffect, useMemo, useState } from 'react';
import { sortPanelsByName } from 'utils';
import { List, PanelsTypeSwitcher } from './components';

export const PANEL_TYPES = ['My Panels', 'MERSCOPE Panels'];

interface PanelsDropdownProps {
  searchValue: string;
  value: Panel;
  promotedPanels: Panel[];
  finalizedPanels: Panel[];
  setIsListVisible: (value: boolean) => void;
  onChange: (value: Panel) => void;
}

const getFilteredPanels = (panels: Panel[], searchValue: string) =>
  sortPanelsByName(
    panels.filter((panel) =>
      panel.panelName.toLowerCase().includes(searchValue.toLowerCase())
    )
  );

export const PanelsDropdown: FC<PanelsDropdownProps> = ({
  searchValue,
  promotedPanels,
  finalizedPanels,
  setIsListVisible,
  value,
  onChange
}) => {
  const [filteredFinalizedPanels, filteredPromotedPanels] = useMemo(
    () => [
      getFilteredPanels(finalizedPanels, searchValue),
      getFilteredPanels(promotedPanels, searchValue)
    ],
    [finalizedPanels, promotedPanels, searchValue]
  );
  const finalizedPanelsAmount = filteredFinalizedPanels.length;
  const promotedPanelsAmount = filteredPromotedPanels.length;

  const [panelType, setPanelType] = useState(PANEL_TYPES[0]);

  const onItemSelect = (value: Panel) => {
    onChange(value);
    setIsListVisible(false);
  };

  const onFromScratchClick = () => {
    onItemSelect({
      panelName: 'From scratch',
      panelId: 'custom',
      tissueId: '',
      transcriptomeId: ''
    } as Panel);
  };

  useEffect(() => {
    if (finalizedPanelsAmount && !promotedPanelsAmount) {
      setPanelType(PANEL_TYPES[0]);
    } else if (!finalizedPanelsAmount && promotedPanelsAmount) {
      setPanelType(PANEL_TYPES[1]);
    }
  }, [finalizedPanelsAmount, promotedPanelsAmount]);

  return (
    <Box
      overflow="auto"
      pos="absolute"
      top="48px"
      w="100%"
      maxH="302px"
      m="0"
      bg="background.primary"
      borderBottomRadius="4px"
      border="1px solid"
      borderTop="none"
      borderColor="border.selected"
    >
      <PanelsTypeSwitcher
        setPanelType={setPanelType}
        currentType={panelType}
        promotedPanelsAmount={promotedPanelsAmount}
        myPanelsAmount={finalizedPanelsAmount}
      />
      <List
        onItemSelect={onItemSelect}
        value={value}
        data={
          panelType === PANEL_TYPES[0]
            ? filteredFinalizedPanels
            : filteredPromotedPanels
        }
      />
      <Box borderTop="1px solid" borderColor="border.divider">
        <Button
          variant="primaryGhost"
          w="100%"
          h="48px"
          p="8px 12px"
          justifyContent="flex-start"
          onClick={onFromScratchClick}
        >
          From scratch
        </Button>
      </Box>
    </Box>
  );
};
