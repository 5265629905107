import { PanelComposition } from 'components';
import { FC, memo } from 'react';
import { TranscriptModel } from 'store';

interface PanelCompositionTabProps {
  transcripts: TranscriptModel[];
  transcriptomeId: string;
}

const Component: FC<PanelCompositionTabProps> = ({
  transcripts,
  transcriptomeId
}) => {
  return (
    <PanelComposition
      transcriptomeId={transcriptomeId}
      clustergrammerStyles={{
        width: '100%',
        height: '900px'
      }}
      transcripts={transcripts}
    />
  );
};

export const PanelCompositionTab = memo(Component);
