import { PanelTranscriptStatus } from 'generated/types';
import { FC } from 'react';
import { Cell } from 'react-table';
import {
  duplicatedGeneNamesSelector,
  TranscriptModel,
  useCreatePanelStore
} from 'store';
import { shallow } from 'zustand/shallow';
import { Calculating } from './components';
import { GeneMenu } from 'components';
import { PanelTranscriptStatusExtended } from 'types';

interface StatusCellProps {
  cell: Cell<TranscriptModel, PanelTranscriptStatus>;
  isPrimary: boolean;
}

export const StatusCell: FC<StatusCellProps> = ({ cell, isPrimary }) => {
  const { transcriptId, type, isIncluded } = cell.row.original;
  const [duplicatedGeneNames] = useCreatePanelStore(
    (state) => [duplicatedGeneNamesSelector(state)],
    shallow
  );
  const isDuplicateGeneName = duplicatedGeneNames.some(
    (t) => t.transcriptId === transcriptId
  );

  if (!isDuplicateGeneName && cell.value === PanelTranscriptStatus.targetable) {
    return <div css={{ maxWidth: '40px', width: '40px' }} />;
  }

  if (cell.value === PanelTranscriptStatus.calculating) {
    return <Calculating />;
  }

  return (
    <GeneMenu
      isPrimary={isPrimary}
      view={
        isDuplicateGeneName
          ? PanelTranscriptStatusExtended.duplicated
          : cell.value
      }
      transcriptId={transcriptId}
      type={type}
      isIncluded={isIncluded}
    />
  );
};
