import Select, { OnChangeValue } from 'react-select';
import { customStyles } from './styles';
import { Option, DropdownIndicator } from './components';
import { DropdownSize } from './models';
import { useState } from 'react';

export type DefaultItemType = {
  value: string;
  label: string;
};

type ItemType =
  | {
      [key: string]: any;
    }
  | DefaultItemType;

export type DropdownProps<T extends ItemType = DefaultItemType> = {
  items: T[];
  name: string;
  labelKey?: string;
  valueKey?: string;
  onChange: (e: T | null) => void | Promise<void>;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  defaultValue?: T;
  value?: T | null;
  size?: DropdownSize;
  isInvalid?: boolean;
  hasWarning?: boolean;
  noOptionsMessage?: string;
  isOptionDisabled?: (option: any) => boolean;
  usePortal?: boolean;
};

export function Dropdown<T extends ItemType>({
  items,
  onChange,
  defaultValue,
  placeholder = '',
  isSearchable = false,
  isDisabled = false,
  isInvalid = false,
  isLoading = false,
  hasWarning = false,
  name,
  value,
  valueKey,
  labelKey,
  isOptionDisabled,
  size = DropdownSize.Md,
  noOptionsMessage,
  usePortal = false
}: DropdownProps<T>) {
  const [placement, setPlacement] = useState('bottom');

  const onSelectChange = (val: OnChangeValue<any, boolean>) => {
    const selectedValue = val as T | null;
    onChange(selectedValue);
  };

  const getLabel = !!labelKey ? (option: any) => option[labelKey] : undefined;
  const getValue = !!valueKey ? (option: any) => option[valueKey] : undefined;

  const styles = customStyles(size, isInvalid, placement, hasWarning, (value) =>
    setPlacement(value)
  );

  return (
    <Select
      className={`dropdownInput_${name}`}
      options={items}
      value={value}
      onChange={onSelectChange}
      styles={styles}
      defaultValue={defaultValue}
      placeholder={placeholder}
      components={{
        Option,
        DropdownIndicator: (props) => (
          <DropdownIndicator {...props} isLoading={isLoading} />
        )
      }}
      noOptionsMessage={() => noOptionsMessage || 'No results found'}
      maxMenuHeight={245}
      isSearchable={isSearchable}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      isDisabled={isDisabled}
      name={name}
      isOptionDisabled={isOptionDisabled}
      {...(usePortal
        ? {
            menuPortalTarget: document.body,
            menuPosition: 'fixed',
            menuShouldBlockScroll: true
          }
        : {})}
    />
  );
}
