import { useState, FC } from 'react';
import { ExogenousSequenceForm } from 'components';
import { SuccessMessage, SavedExogenousSequences } from './components';
import { RadioGroup, HStack, Radio } from '@chakra-ui/react';
import { useCreatePanelStore } from 'store';
import { useCalculatingTranscriptsPolling } from './useCalculatingTranscriptsPolling';
import { SingleAccordion } from '@vizgen/vizgen-ui';
import { useParams } from 'react-router';
import { CreateExogenousSequenceInput } from 'generated/types';
import { shallow } from 'zustand/shallow';

export const ExogenousSequences: FC = () => {
  const { panelId } = useParams();
  const [
    isSequenceUpdating,
    addedExogenousSequence,
    resetAddedExogenousSequence,
    createExogenousSequence,
    addExogenousSequenceToPanel
  ] = useCreatePanelStore(
    (state) => [
      state.isSequenceUpdating,
      state.addedExogenousSequence,
      state.resetAddedExogenousSequence,
      state.createExogenousSequence,
      state.addExogenousSequenceToPanel
    ],
    shallow
  );
  const [type, setType] = useState('new');
  const isNew = type === 'new';

  useCalculatingTranscriptsPolling();

  const onSubmit = async (data: CreateExogenousSequenceInput) => {
    const res = await createExogenousSequence(data);
    if (res) {
      await addExogenousSequenceToPanel(res.id, panelId!);
    }
    setTimeout(() => {
      resetAddedExogenousSequence();
    }, 10000);
  };

  return (
    <SingleAccordion title="Exogenous Sequences">
      {!addedExogenousSequence && (
        <RadioGroup
          defaultValue={type}
          onChange={(value: string) => setType(value)}
          mb="20px"
          ml="8px"
        >
          <HStack spacing="24px">
            <Radio value="new" name="new" disabled={isSequenceUpdating}>
              New one
            </Radio>
            <Radio value="saved" name="saved" disabled={isSequenceUpdating}>
              Add from saved
            </Radio>
          </HStack>
        </RadioGroup>
      )}
      {isNew ? (
        addedExogenousSequence ? (
          <SuccessMessage />
        ) : (
          <ExogenousSequenceForm onSubmit={onSubmit} />
        )
      ) : (
        <SavedExogenousSequences />
      )}
    </SingleAccordion>
  );
};
