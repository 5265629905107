import dayjs from 'dayjs';
import { Row } from 'react-table';

export const sortByDate = <T extends object>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string
) => {
  const timestampA = dayjs((rowA.original as any)[columnId]).valueOf();
  const timestampB = dayjs((rowB.original as any)[columnId]).valueOf();

  return timestampA === timestampB ? 0 : timestampA < timestampB ? 1 : -1;
};
