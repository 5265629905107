import { FC } from 'react';
import { ErrorFilledSmallIcon } from 'assets';
import { Label } from './components';
import { Box } from '@chakra-ui/react';
import { theme, Tooltip } from '@vizgen/vizgen-ui';
const { colors } = theme;

interface CellTooltipProps {
  value: number;
}

export const CellTooltip: FC<CellTooltipProps> = ({ value }) => {
  return (
    <Tooltip label={<Label value={value} />} maxW="256px">
      <Box
        css={{
          '&:hover path.background': {
            fill: colors.content.link.hover
          }
        }}
      >
        <ErrorFilledSmallIcon />
      </Box>
    </Tooltip>
  );
};
