import { Scrollbar, ScrollbarProps, ScrollbarVariants } from '../Scrollbar';
import { Ref, forwardRef } from 'react';

export const ScrollbarVirtual = forwardRef(
  (
    { onScroll, style, children }: Partial<ScrollbarProps>,
    ref: Ref<ScrollbarProps>
  ) => {
    return (
      <Scrollbar
        ref={ref}
        variant={ScrollbarVariants.Table}
        style={{ ...style, height: style?.height || 0, overflow: 'hidden' }}
        onScroll={onScroll}
      >
        {children}
      </Scrollbar>
    );
  }
);
