import { FC } from 'react';
import { DotsMenu } from '@vizgen/vizgen-ui';
import { Paths } from 'routes';
import { MenuItem, useDisclosure } from '@chakra-ui/react';
import {
  DownloadIcon,
  PaperIcon,
  AddIcon,
  DuplicateIcon,
  DeleteIcon
} from './assets';
import { ConfirmDeletePanelModal } from 'components';
import { getListPanelTranscripts } from 'api';
import { downloadGenesList } from 'utils';
import { generatePath, Link as BrowserLink } from 'react-router-dom';
import { PanelOwnershipType } from 'generated/types';

interface MenuCellProps {
  isCompleted: boolean;
  panelId: string;
  panelName: string;
  serialNumberTag: string;
  hasGenes: boolean;
  ownershipType: PanelOwnershipType;
}

export const MenuCell: FC<MenuCellProps> = ({
  isCompleted,
  panelId,
  panelName,
  serialNumberTag,
  hasGenes,
  ownershipType
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onGenesListDownload = async () => {
    const transcripts = await getListPanelTranscripts(panelId);
    downloadGenesList(isCompleted, serialNumberTag, panelName, transcripts);
  };

  return (
    <>
      <DotsMenu>
        {hasGenes && (
          <>
            <MenuItem
              iconSpacing="4px"
              icon={<DownloadIcon />}
              onClick={onGenesListDownload}
              data-testid="downloadGeneListButton"
            >
              Download Gene List
            </MenuItem>
            <MenuItem
              iconSpacing="4px"
              icon={<PaperIcon />}
              as={BrowserLink}
              target="_blank"
              to={generatePath(Paths.PrintablePanel, {
                panelType: ownershipType,
                panelId
              })}
              data-testid="openAsPrintableFileLink"
            >
              Open as Printable File
            </MenuItem>
          </>
        )}
        {isCompleted ? (
          <>
            <MenuItem
              iconSpacing="4px"
              icon={<AddIcon />}
              as={BrowserLink}
              to={generatePath(Paths.NewQuoteForPanel, { panelId })}
              data-testid="addToQuoteLink"
            >
              Add to Quote
            </MenuItem>
            <MenuItem
              iconSpacing="4px"
              icon={<DuplicateIcon />}
              as={BrowserLink}
              target="_blank"
              to={`${Paths.CreatePanel}?panelTemplate=${panelId}${
                ownershipType === PanelOwnershipType.promoted
                  ? '&isPromoted=true'
                  : ''
              }`}
              data-testid="duplicateAndEditLink"
            >
              Duplicate and Edit
            </MenuItem>
          </>
        ) : (
          <MenuItem
            iconSpacing="4px"
            icon={<DeleteIcon />}
            onClick={onOpen}
            data-testid="deleteDraftPanelButton"
          >
            Delete Draft Panel
          </MenuItem>
        )}
      </DotsMenu>
      <ConfirmDeletePanelModal
        panelId={panelId}
        isVisible={isOpen}
        onClose={onClose}
      />
    </>
  );
};
