import { Center, Text } from '@chakra-ui/react';
import { FC } from 'react';

export const GenesAxis: FC = () => {
  return (
    <Center>
      <Text
        variant="captionShort"
        color="content.additional"
        css={{
          writingMode: 'vertical-rl',
          transform: 'scale(-1)'
        }}
      >
        GENES
      </Text>
    </Center>
  );
};
