import { useEffect } from 'react';
import { useCreatePanelStore, useNotificationsStore } from 'store';

export const useClearState = () => {
  const clearCreatePanelState = useCreatePanelStore(
    (state) => state.clearState
  );
  const clearNotificationsState = useNotificationsStore(
    (state) => state.clearState
  );

  useEffect(
    () => {
      return () => {
        clearCreatePanelState();
        clearNotificationsState();
      };
    },
    [] // eslint-disable-line
  );
};
