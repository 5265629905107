import { FC } from 'react';
import { NotificationType } from '../../types';
import CheckIcon from './assets/check.svg';
import ErrorIcon from './assets/error.svg';

interface StatusIconProps {
  type: NotificationType;
}

export const StatusIcon: FC<StatusIconProps> = ({ type }) => {
  switch (type) {
    case NotificationType.Success:
      return <CheckIcon />;
    case NotificationType.Error:
      return <ErrorIcon />;
    default:
      return null;
  }
};
