import { Box, Stack, HStack, Text, Link } from '@chakra-ui/react';
import { GeneStatusIcon } from 'components';
import { PanelTranscriptStatus } from 'generated/types';
import { FC } from 'react';
import { PanelTranscriptStatusExtended } from 'types';

interface GeneTextProps {
  view: PanelTranscriptStatus | PanelTranscriptStatusExtended | null;
  transcriptId: string;
}

const title = (
  view: PanelTranscriptStatus | PanelTranscriptStatusExtended | null
) => {
  switch (view) {
    case PanelTranscriptStatus.insufficient_tr: {
      return 'Gene has insufficient target regions and may have compromised detection efficiency';
    }
    case PanelTranscriptStatus.untargetable: {
      return 'Gene is untargetable';
    }
    case PanelTranscriptStatus.not_found: {
      return 'Gene was not found';
    }
    default: {
      return null;
    }
  }
};

const TopText: FC = ({ children }) => (
  <Text color="content.additional">{children}</Text>
);

export const GeneText: FC<GeneTextProps> = ({ view, transcriptId }) => {
  return (
    <Box p="12px" borderBottom="1px solid" borderColor="border.normal">
      {(view === PanelTranscriptStatus.targetable ||
        view === PanelTranscriptStatusExtended.duplicated) && (
        <Stack spacing="0">
          <TopText>Selected transcript:</TopText>
          <Link
            href={`https://www.ensembl.org/Multi/Search/Results?q=${transcriptId}`}
            target="_blank"
            variant="underline"
          >
            {transcriptId}
          </Link>
        </Stack>
      )}
      {view !== PanelTranscriptStatusExtended.duplicated && (
        <HStack alignItems="flex-start" spacing="4px">
          <Box>
            <GeneStatusIcon type={view as PanelTranscriptStatus} />
          </Box>
          <TopText>{title(view)}</TopText>
        </HStack>
      )}
    </Box>
  );
};
