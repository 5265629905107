import { useEffect, FC, useState, useRef } from 'react';
import { Flex, Heading, Container } from '@chakra-ui/react';
import { QuotesTable } from 'components';
import { QuotesFilters } from './components';
import { Paths } from 'routes';
import { Quote } from 'api/types';
import { TableInstance } from 'react-table';
import { useQuotesListQuery } from 'api/hooks';
import { SecondaryButton, SecondaryButtonIcon } from '@vizgen/vizgen-ui';
import { Link as RouterLink } from 'react-router-dom';

export const Quotes: FC = () => {
  const tableInstance = useRef<TableInstance<Quote>>(null);

  const [filter, setFilter] = useState('');

  const { isLoading, data: quotesList = [] } = useQuotesListQuery();

  useEffect(() => {
    tableInstance.current?.setGlobalFilter(filter || undefined);
  }, [filter]);

  return (
    <Container
      d="flex"
      flexDir="column"
      p="40px 60px"
      maxW="1248px"
      w="100%"
      m="auto"
      h="100%"
      overflowY="hidden"
    >
      <Flex justify="space-between" mb="32px">
        <Heading as="h1">Quote Requests</Heading>
        <SecondaryButton
          icon={SecondaryButtonIcon.Add}
          as={RouterLink}
          to={Paths.NewQuote}
          data-testid="createNewQuoteLink"
        >
          Request a Quote
        </SecondaryButton>
      </Flex>
      <QuotesFilters filter={filter} setFilter={setFilter} />
      <Flex
        h="100%"
        overflowY="hidden"
        direction="column"
        alignItems="center"
        w="100%"
      >
        <QuotesTable
          quotesList={quotesList}
          tableRef={tableInstance}
          hasGlobalFilterPlugin
          hasFilterPlugin
          isLoading={isLoading}
        />
      </Flex>
    </Container>
  );
};
