import { InstrumentNotifications } from 'consts';

export const useSelectedNotifications = (
  data: string[],
  isFetched: boolean
) => {
  let notifications = '';
  const notificationsAmount = data.length;
  const isAllNotificationsEnabled =
    notificationsAmount === Object.values(InstrumentNotifications).length;

  if (isFetched) {
    if (!notificationsAmount) {
      notifications = 'Disabled';
    } else if (isAllNotificationsEnabled) {
      notifications = 'All';
    } else {
      notifications = Object.entries(InstrumentNotifications)
        .filter(([key, _]) => data.includes(key))
        .map(([_, value]) => value)
        .join(', ');
    }
  }

  return notifications;
};
