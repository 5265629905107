import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Box, Flex, Button, Container } from '@chakra-ui/react';
import { Paths } from 'routes';
import { useNewOrderStore, useUserStore } from 'store';
import { shallow } from 'zustand/shallow';
import { useNavigate, useNavigationType } from 'react-router-dom';

interface NavigationProps {
  isLastStep: boolean;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

export const Navigation: FC<NavigationProps> = ({
  isLastStep,
  currentStep,
  setCurrentStep
}) => {
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const user = useUserStore((state) => state.user);
  const [requestNewQuote, setId, isFetching] = useNewOrderStore(
    (state) => [state.requestNewQuote, state.setId, state.isFetching],
    shallow
  );
  const [isOrderValid, setIsOrderValid] = useState(false);
  const state = useNewOrderStore(
    (state) => ({
      selectedAddress: state.selectedAddress,
      comment: state.comment,
      panels: state.panels,
      additionalExtras: state.additionalExtras
    }),
    shallow
  );

  const showSummary = () => {
    setId();
    setCurrentStep(currentStep + 1);
  };

  const showQuoteSetup = () => {
    setCurrentStep(currentStep - 1);
  };

  const onBackClick = () => {
    if (isLastStep) {
      showQuoteSetup();
    } else if (navigationType === 'POP') {
      navigate(Paths.Home);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    setIsOrderValid(
      !!state.selectedAddress && !state.panels.some((p) => p === null)
    );
  }, [state]);

  const requestQuote = async () => {
    if (user) {
      await requestNewQuote(user.getUsername());
    }
    navigate(Paths.Quotes);
  };

  return (
    <Box
      bg="background.secondary"
      borderTop="1px solid"
      borderColor="border.divider"
      w="100%"
    >
      <Container maxW="1440px" p="15px 60px">
        <Flex justify="space-between">
          <Button
            onClick={onBackClick}
            variant="ghost"
            data-testid="backLink"
            disabled={isFetching}
          >
            Back
          </Button>
          {isLastStep ? (
            <Button
              onClick={requestQuote}
              variant="secondarySolid"
              data-testid="requestQuoteButton"
            >
              Request Quote
            </Button>
          ) : (
            <Button
              onClick={showSummary}
              data-testid="nextButton"
              variant="secondarySolid"
              disabled={!isOrderValid || isFetching}
            >
              Next
            </Button>
          )}
        </Flex>
      </Container>
    </Box>
  );
};
