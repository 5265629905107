import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
// import reportWebVitals from 'reportWebVitals';
import * as serviceWorker from 'serviceWorker';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const { REACT_APP_LD_CLIENT_KEY = '' } = process.env;

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: REACT_APP_LD_CLIENT_KEY,
    options: {
      bootstrap: 'localStorage'
    }
  });
  ReactDOM.render(
    <StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </StrictMode>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
