import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';

interface MenuButtonProps extends ButtonProps {
  isActive?: boolean;
}

const underlineStyles = {
  content: "''",
  pos: 'absolute',
  left: '8px',
  bottom: '0',
  w: 'calc(100% - 16px)',
  p: '0 8px',
  borderBottom: '4px solid'
};

export const MenuButton = forwardRef<MenuButtonProps, 'input'>(
  ({ children, isActive = false, ...rest }, ref) => {
    return (
      <Button
        ref={ref}
        variant="secondaryGhost"
        size="auto"
        h="100%"
        textTransform="unset"
        color={isActive ? 'content.link.normal' : 'content.secondary'}
        _before={isActive ? underlineStyles : undefined}
        _hover={{
          color: 'content.link.normal'
        }}
        _active={{
          color: 'content.link.normal'
        }}
        {...rest}
      >
        {children}
      </Button>
    );
  }
);
