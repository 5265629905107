import { FC, useCallback, useState } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { Topbar, Issues } from './components';
import { issuesAmountSelector, useCreatePanelStore } from 'store';

export const GenesStatuses: FC = () => {
  const issuesAmount = useCreatePanelStore(issuesAmountSelector);
  const toggleIssuesPanelExpanded = useCreatePanelStore(
    (state) => state.toggleIssuesPanelExpanded
  );
  const isIssuesPanelExpanded = useCreatePanelStore(
    (state) => state.isIssuesPanelExpanded
  );
  const [height, setHeight] = useState(0);

  const elementRef = useCallback((node) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver((e) => {
      setHeight(e[0].target.clientHeight || 0);
    });
    resizeObserver.observe(node);
  }, []);

  if (!issuesAmount) {
    return null;
  }

  return (
    <Box pos="relative" mt="16px">
      <Box pt={`${height}px`} />
      <Flex
        direction="row"
        justify="space-between"
        bgColor="background.primary"
        borderRadius="8px"
        border="1px solid"
        borderColor="border.normal"
        pos="absolute"
        top="0"
        w="100%"
        overflow="hidden"
        left="0"
        alignItems="center"
        zIndex="dockedGenesStatuses"
      >
        <Flex direction="column" w="100%">
          <Box ref={elementRef}>
            <Topbar
              isOpen={isIssuesPanelExpanded}
              onToggle={toggleIssuesPanelExpanded}
            />
          </Box>
          <Issues isOpen={isIssuesPanelExpanded} />
        </Flex>
      </Flex>
    </Box>
  );
};
