import { useMemo } from 'react';
import { usePanelCompositionStore } from 'store';
import { getSelectedGeneSets } from 'utils/geneset/getSelectedGeneSets';

export const useSelectedGeneSets = () => {
  const geneSetOptions = usePanelCompositionStore(
    (state) => state.geneSetOptions
  );

  return useMemo(() => getSelectedGeneSets(geneSetOptions), [geneSetOptions]);
};
