import { Scrollbar, ScrollbarVariants } from '@vizgen/vizgen-ui';
import { FC } from 'react';

export const Content: FC = ({ children }) => {
  return (
    <Scrollbar
      className="content-scrollbar"
      variant={ScrollbarVariants.Content}
    >
      {children}
    </Scrollbar>
  );
};
