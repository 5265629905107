import { Button } from '@chakra-ui/react';
import { FC } from 'react';

interface ToggleButtonProps {
  onClick: () => void;
}

export const ToggleButton: FC<ToggleButtonProps> = ({ children, onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="tinyGhostPrimary"
      size="tiny"
      mt="8px"
      ml="auto"
      color="content.secondary"
    >
      {children}
    </Button>
  );
};
