import { FC, useEffect } from 'react';
import { HStack, Text, Flex, Box } from '@chakra-ui/react';
import { ReactComponent as LockedIcon } from 'assets/locked.svg';
import { ReferenceModel, useGlobalListsStore } from 'store';
import { Control, FieldValues, useController } from 'react-hook-form';
import { Dropdown, ValidationMessage } from '@vizgen/vizgen-ui';

interface ReferenceFieldProps {
  referencesData: ReferenceModel[];
  control: Control<FieldValues>;
  isDisabled: boolean;
  transcript: ReferenceModel | null;
}

const Field: FC<ReferenceFieldProps> = ({
  referencesData,
  control,
  isDisabled,
  transcript
}) => {
  const {
    field: { onChange, value, name },
    fieldState: { invalid }
  } = useController({
    name: 'reference',
    control,
    rules: { required: true },
    defaultValue: transcript
  });

  const onChangeHandler = (
    e: {
      transcriptomeId: string;
      scientificName: string;
    } | null
  ) => {
    onChange(e);
  };

  useEffect(() => {
    if (transcript) {
      onChange(transcript);
    }
  }, [transcript]); // eslint-disable-line

  return (
    <HStack spacing="24px" alignItems="flex-start">
      <Flex direction="row" maxW={264} w="40%" pt="12px">
        <Text mr="8px">Reference</Text>
        {isDisabled && <LockedIcon />}
      </Flex>
      <Box pos="relative" maxW={360} w="60%">
        <Dropdown<ReferenceModel>
          data-testid="select-reference"
          items={referencesData}
          defaultValue={value}
          value={value}
          onChange={onChangeHandler}
          valueKey="transcriptomeId"
          labelKey="scientificName"
          name={name}
          isDisabled={!referencesData.length || isDisabled}
          placeholder="--Select--"
          isInvalid={invalid}
        />
        {invalid && (
          <ValidationMessage>Fill the required field</ValidationMessage>
        )}
      </Box>
    </HStack>
  );
};

export const ReferenceField: FC<Omit<ReferenceFieldProps, 'referencesData'>> = (
  props
) => {
  const references = useGlobalListsStore((state) => state.transcriptomes);
  return references.length ? (
    <Field {...props} referencesData={references} />
  ) : null;
};
