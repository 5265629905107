import { Category, Product } from 'models';

export const CATEGORY_LABELS: Record<Category, string> = {
  Software: 'Software',
  UserGuides: 'User Guides',
  ApplicationAndTechnicalNotes: 'Application & Technical Notes',
  SafetyDataSheets: 'Safety Data Sheets',
  ProductNotifications: 'Product Notifications'
};

export const PRODUCT_LABELS: Record<Product, string> = {
  Vizualizer: 'MERSCOPE Vizualizer',
  Platform: 'MERSCOPE Platform',
  PreparationKit: 'MERSCOPE Sample Preparation Kit',
  SampleVerificationKit: 'MERSCOPE Sample Verification Kit',
  GenePanelPortal: 'Gene Panel Portal',
  CellBoundaryKit: 'MERSCOPE Cell Boundary Kit',
  ImagingKit: 'MERSCOPE Imaging Kit',
  GenePanel: 'MERSCOPE Gene Panel',
  TrainingKit: 'MERSCOPE Training Kit',
  VerificationKit: 'MERSCOPE Verification Kit'
};

export const DEFAULT_PRODUCTS_OPTION = {
  value: 'All Products',
  label: 'All Products'
};

export const IMAGING_KIT = 'Imaging Kit';
