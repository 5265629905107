import { Global } from '@emotion/react';

export const FontsConfig = () => {
  return (
    <Global
      styles={`
        /* Heavy */
        @font-face {
          font-family: 'Avenir';
          font-style: normal;
          font-weight: 800;
          font-display: swap;
          src: url(/fonts/AvenirHeavy.woff2) format('woff2');
        }
        /* Medium */
        @font-face {
          font-family: 'Avenir';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url(/fonts/AvenirMedium.woff2) format('woff2');
        }
        `}
    />
  );
};
