export const Menu = {
  baseStyle: {
    list: {
      boxShadow: '0px 8px 24px rgba(215, 217, 220, 0.4)',
      border: '1px solid',
      borderColor: 'border.normal',
      background: 'background.primary',
      borderRadius: '8px',
      p: '0',
      overflow: 'hidden',
      zIndex: 'tooltip'
    },
    divider: {
      borderColor: 'border.divider'
    },
    item: {
      color: 'content.secondary',
      padding: '12px',
      borderBottom: '1px solid',
      borderColor: 'border.divider',
      _active: {
        bg: 'button.ghostOnPrimary.hover'
      },
      _focus: {
        bg: 'button.ghostOnPrimary.hover'
      },
      '&:last-child': {
        borderBottom: '0'
      },
      _disabled: {
        opacity: 1,
        color: 'content.additional',
        svg: {
          opacity: 0.4
        }
      }
    }
  }
};
