import { Button, HStack } from '@chakra-ui/react';
import { FC } from 'react';

interface FooterProps {
  onCancel: () => void;
}

export const Footer: FC<FooterProps> = ({ onCancel }) => {
  return (
    <HStack spacing="12px" justify="end" w="100%">
      <Button
        variant="tinyGhostSecondary"
        minW="auto"
        data-testid="cancelButton"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        variant="secondaryFilled"
        minW="auto"
        w="168px"
        data-testid="addUserButton"
      >
        Add User
      </Button>
    </HStack>
  );
};
