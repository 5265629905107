import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { ChevronDown } from 'assets';
import pluralize from 'pluralize';

interface ExpandButtonProps {
  label: string;
  geneSetsAmount: number;
  isExpanded: boolean;
  onExpand: (id: string) => void;
}

export const ExpandButton: FC<ExpandButtonProps> = ({
  label,
  geneSetsAmount,
  isExpanded,
  onExpand
}) => {
  return (
    <Button
      borderRadius="0"
      w="100%"
      ml="14px"
      variant="ghost"
      h="32px"
      p="4px 0"
      _hover={{
        bg: 'background.additional2'
      }}
      _active={{
        bg: 'background.additional2'
      }}
      onClick={() => onExpand(isExpanded ? '' : label)}
      data-testid={label}
    >
      <HStack spacing="4px" w="100%" align="center">
        <Box
          as="span"
          transition="all 0.2s ease"
          transform={isExpanded ? 'rotate(180deg)' : undefined}
        >
          <ChevronDown />
        </Box>
        <Text>
          {label} ({geneSetsAmount} gene {pluralize('set', geneSetsAmount)})
        </Text>
      </HStack>
    </Button>
  );
};
