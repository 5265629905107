import { ConfirmModal, useFailureNotification } from '@vizgen/vizgen-ui';
import { FC } from 'react';
import { useCreatePanelStore } from 'store';
import { formatErrorMessages } from 'utils';

interface ConfirmFinalizationModalProps {
  kit: number;
  isVisible: boolean;
  onClose: () => void;
}

const ModalComponent: FC<Omit<ConfirmFinalizationModalProps, 'isVisible'>> = ({
  kit,
  onClose
}) => {
  const finalizePanel = useCreatePanelStore((state) => state.finalizePanel);
  const { showFailureNotification } = useFailureNotification();

  const submit = async () => {
    onClose();
    try {
      await finalizePanel();
    } catch (e) {
      showFailureNotification(formatErrorMessages(e));
    }
  };

  return (
    <ConfirmModal
      title="Finalize panel"
      text={`This panel will work with ${kit} gene imaging kits. After finalization you won’t be able to make any edits.`}
      submitText="Finalize"
      onClose={onClose}
      onSubmit={submit}
    />
  );
};

export const ConfirmFinalizationModal: FC<ConfirmFinalizationModalProps> = ({
  isVisible,
  ...rest
}) => {
  if (!isVisible) {
    return null;
  }

  return <ModalComponent {...rest} />;
};
