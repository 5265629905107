import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { Content } from 'components';
import { Header, List } from './components';

interface TermsContentProps {
  termsOfUse: string;
}

export const TermsContent: FC<TermsContentProps> = ({ termsOfUse }) => {
  return (
    <Box overflowY="hidden" w="100%" h="100%">
      <Content>
        <Box mb="40px" pr="60px" pt="40px">
          <Header />
          <List termsOfUse={termsOfUse} />
        </Box>
      </Content>
    </Box>
  );
};
