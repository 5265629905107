import { FC } from 'react';
import { Button, Flex, Text, HStack, Box } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ExogenousSequenceField, SequenceNameField } from './components';
import { getValidationMessage } from './utils';
import { InputField } from '@vizgen/vizgen-ui';
import { CreateExogenousSequenceInput } from 'generated/types';

interface FormData {
  abundance?: string;
  sequence?: string;
  name?: string;
}

interface ExogenousSequenceFormProps {
  onSubmit: (data: CreateExogenousSequenceInput) => Promise<void>;
}

export const ExogenousSequenceForm: FC<ExogenousSequenceFormProps> = ({
  onSubmit
}) => {
  const { handleSubmit, register, formState, control } = useForm();
  const abundanceValidation = getValidationMessage(
    'abundance',
    formState.errors
  );
  const isSubmitting = formState.isSubmitting;

  const submitForm = async (formData: FormData) => {
    const data = {
      name: formData.name || '',
      sequence: formData.sequence || '',
      fpkm: Number(formData.abundance || 0)
    };
    await onSubmit(data);
  };

  return (
    <Box as="form" onSubmit={handleSubmit(submitForm)} w="100%">
      <HStack spacing="16px" mb="8px" alignItems="start">
        <SequenceNameField
          control={control}
          label="Sequence Name"
          formState={formState}
          isDisabled={isSubmitting}
        />
        <InputField
          w="100%"
          {...register('abundance', { required: true })}
          label="Abundance, FPKM"
          type="number"
          controlProps={{
            isInvalid: !!abundanceValidation,
            d: 'flex',
            flexDir: 'column'
          }}
          errorProps={{ pos: 'relative' }}
          error={abundanceValidation}
          disabled={isSubmitting}
          step="any"
        />
      </HStack>
      <Text ml="12px" mb="16px" color="content.additional" variant="bodyShort">
        Maximum 20 alphanumeric characters. Sequence names may not match gene
        names in reference genome.
      </Text>
      <ExogenousSequenceField
        control={control}
        label="Enter Exogenous Sequence"
        formState={formState}
        isDisabled={isSubmitting}
      />
      <Flex justify="flex-end" mt="16px">
        <Button
          type="submit"
          variant="secondaryFilled"
          minW="auto"
          w="168px"
          isLoading={formState.isSubmitting}
          data-testid="addSequenceButton"
          disabled={isSubmitting}
        >
          Add Sequence
        </Button>
      </Flex>
    </Box>
  );
};
