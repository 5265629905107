import { FC } from 'react';
import { VStack, Heading, HStack, Box, Text } from '@chakra-ui/react';
import { ReactComponent as ExtrasIcon } from './assets/extras.svg';
import { ExtraPosition } from 'api/types';
import { countQuoteExtrasQuantity } from 'utils';
import { SingleAccordion, ExtraSummary } from '@vizgen/vizgen-ui';

export interface QuoteExtrasProps {
  extraPositions: Array<ExtraPosition>;
}

export const QuoteExtras: FC<QuoteExtrasProps> = ({ extraPositions }) => {
  return (
    <SingleAccordion
      title={
        <Heading as="h2" variant="subtitle">
          <HStack spacing="4px">
            <HStack spacing="8px">
              <ExtrasIcon />
              <Box>Extras</Box>
            </HStack>
            <Box color="content.additional">
              ({countQuoteExtrasQuantity(extraPositions)})
            </Box>
          </HStack>
        </Heading>
      }
      cardStyles={{
        padding: '0',
        background: 'none'
      }}
      borderStyles={{
        border: 'none',
        boxShadow: 'none'
      }}
    >
      {extraPositions.length > 0 && (
        <Text color="content.additional" variant="captionHigh">
          FFPE samples require double the volume of MERSCOPE™ Gene Panel Mix for
          sample processing
        </Text>
      )}
      <VStack spacing="16px" alignItems="start" w="100%" mt="8px">
        {extraPositions.map((position: ExtraPosition) => (
          <ExtraSummary
            key={position.extraId}
            description={position.extra?.description || ''}
            name={position.extra?.extraName || ''}
            quantity={position.quantity}
          />
        ))}
      </VStack>
    </SingleAccordion>
  );
};
