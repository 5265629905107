import { PanelConfiguration } from 'components';
import { FC } from 'react';
import { PanelFormData, useGlobalListsStore } from 'store';

interface PanelSummaryProps {
  data: PanelFormData;
}

export const PanelSummary: FC<PanelSummaryProps> = ({ data }) => {
  const references = useGlobalListsStore((state) => state.transcriptomes);
  const reference = references.find(
    (ref) => ref.transcriptomeId === data.reference?.transcriptomeId
  )?.scientificName;

  return (
    <PanelConfiguration
      items={[data.panelName || '', reference || '', data.tissue?.name || '']}
    />
  );
};
