import { HStack } from '@chakra-ui/layout';
import { Box, Heading } from '@chakra-ui/react';
import { SingleAccordion } from '@vizgen/vizgen-ui';
import { InfoIcon } from './assets';
import { Instruction } from './components/Instruction';

const Title = () => {
  return (
    <HStack spacing="8px">
      <Box>
        <InfoIcon />
      </Box>
      <Heading variant="subtitle">How it works</Heading>
    </HStack>
  );
};

export const HowItWorks = () => {
  return (
    <SingleAccordion title={<Title />}>
      <HStack justify="space-between" p="16px 54px" spacing="24px">
        <Instruction step={1} />
        <Instruction step={2} />
        <Instruction step={3} />
        <Instruction step={4} />
      </HStack>
    </SingleAccordion>
  );
};
