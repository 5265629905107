import {
  Box,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  Text,
  VStack
} from '@chakra-ui/react';
import { CheckboxInput, SearchField } from '@vizgen/vizgen-ui';
import { FC, Dispatch, SetStateAction } from 'react';
import { LibraryCategory, usePanelCompositionStore } from 'store';
import { CloseIcon, ExternalLinkIcon } from './assets';
import { produce } from 'immer';
import { getFoundItems, getTotalItems } from './utils';
import { Link as BrowserLink, useParams } from 'react-router-dom';
import { Paths } from 'routes';
import pluralize from 'pluralize';

interface HeaderProps {
  searchValue: string;
  libraryCategories: LibraryCategory[];
  isLoading: boolean;
  onClose: () => void;
  onSelect: Dispatch<SetStateAction<LibraryCategory[]>>;
  onSearchChange: (value: string) => void;
}

export const Header: FC<HeaderProps> = ({
  searchValue,
  libraryCategories,
  isLoading,
  onClose,
  onSelect,
  onSearchChange
}) => {
  const { panelId } = useParams();
  const transcriptomeId = usePanelCompositionStore(
    (state) => state.transcriptomeId
  );
  const isAllChecked = libraryCategories.every((libraryCategory) =>
    libraryCategory.children.every((geneSetLibrary) =>
      geneSetLibrary.children.every((geneSet) => geneSet.isChecked)
    )
  );
  const isIndeterminate =
    libraryCategories.some((libraryCategory) =>
      libraryCategory.children.some((geneSetLibrary) =>
        geneSetLibrary.children.some((geneSet) => geneSet.isChecked)
      )
    ) && !isAllChecked;
  const { totalGeneSetsAmount, totalLibrariesCategoriesAmount } =
    getTotalItems(libraryCategories);
  const { foundGeneSetsAmount, foundLibrariesCategoriesAmount } = getFoundItems(
    libraryCategories,
    searchValue
  );

  const librariesText = (
    <>
      in <b>{foundLibrariesCategoriesAmount}</b>{' '}
      {pluralize('library', foundLibrariesCategoriesAmount)}
    </>
  );

  const onSelectChange = (isChecked: boolean) => {
    onSelect((prev) =>
      produce(prev, (draft) => {
        for (let i = 0; i < draft.length; i++) {
          for (let j = 0; j < draft[i].children.length; j++) {
            for (let k = 0; k < draft[i].children[j].children.length; k++) {
              draft[i].children[j].children[k].isChecked = isChecked;
            }
          }
        }
        return draft;
      })
    );
  };

  return (
    <VStack w="100%" spacing="16px" align="start">
      <Flex w="100%" justify="space-between">
        <Heading variant="subtitle">Select gene sets / libraries</Heading>
        <IconButton
          onClick={onClose}
          variant="primaryGhost"
          size="icon"
          aria-label="Close modal"
          data-testid="closeModalButton"
          icon={<CloseIcon />}
        />
      </Flex>
      <HStack spacing="16px">
        <SearchField
          value={searchValue}
          name="searchGeneSets"
          placeholder="Search gene sets"
          controlProps={{
            w: '264px'
          }}
          onChange={(e) => onSearchChange(e.target.value || '')}
          isRightElementShown={!!searchValue}
          rightElementProps={{
            onClick: () => onSearchChange('')
          }}
        />
        {searchValue && (
          <Text data-testid="searchResult">
            <b>{foundGeneSetsAmount}</b> {pluralize('set', foundGeneSetsAmount)}{' '}
            {foundGeneSetsAmount ? librariesText : ''} found
          </Text>
        )}
      </HStack>
      <Flex w="100%" justify="space-between">
        <CheckboxInput
          data-testid="allLibraries"
          isDisabled={isLoading}
          isChecked={isAllChecked}
          isIndeterminate={isIndeterminate}
          onChange={(e) => onSelectChange(e.target.checked)}
        >
          <Text data-testid="headerSummary">
            All ({totalGeneSetsAmount} gene{' '}
            {pluralize('set', totalGeneSetsAmount)} in{' '}
            {totalLibrariesCategoriesAmount} gene set{' '}
            {pluralize('library', totalLibrariesCategoriesAmount)})
          </Text>
        </CheckboxInput>
        <Link
          as={BrowserLink}
          target="_blank"
          to={`${Paths.GeneSetLibraries}?panelId=${panelId}&transcriptomeId=${transcriptomeId}`}
          variant="primary"
          textDecoration="underline"
          fontWeight="normal"
        >
          <HStack spacing="10px">
            <Box>Libraries info</Box>
            <ExternalLinkIcon />
          </HStack>
        </Link>
      </Flex>
    </VStack>
  );
};
