import { Divider, HStack, Stack, Text, Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNewOrderStore } from 'store';
import { Panel } from './components';
import { usePanelsListWithGenesQuery } from 'api/hooks';
import { PanelModelWithGenes } from 'api';
import { useParams } from 'react-router';

export const Panels = () => {
  const { panelId } = useParams<{ panelId: string }>();
  const panels = useNewOrderStore((state) => state.panels);
  const selectPanel = useNewOrderStore((state) => state.selectPanel);
  const { data: panelList, isLoading } = usePanelsListWithGenesQuery();

  useEffect(() => {
    if (panelList?.length && panelId) {
      const panel = panelList?.find((panel) => panel.panelId === panelId);
      if (panel) {
        selectPanel(panel as PanelModelWithGenes, 0);
      }
    }
  }, [panelId, panelList, selectPanel]);

  if (isLoading) {
    return (
      <HStack mb="24px" spacing="4px" justify="center">
        <Spinner color="content.additional" />
        <Text color="content.additional" as="span">
          Loading panels...
        </Text>
      </HStack>
    );
  }

  if (!panels.length || !panelList) {
    return null;
  }

  return (
    <Stack spacing="24px" mb="24px" divider={<Divider />}>
      {panels.map((panel, index) => (
        <Panel
          panel={panel}
          key={panel ? panel.panelId : index}
          index={index}
          panelList={panelList as PanelModelWithGenes[]}
        />
      ))}
    </Stack>
  );
};
