import { FC } from 'react';
import { ErrorFilledSmallIcon } from 'assets';
import { Box } from '@chakra-ui/react';
import { theme, Tooltip } from '@vizgen/vizgen-ui';
const { colors } = theme;

interface ErrorTooltipProps {
  label: string;
}

export const ErrorTooltip: FC<ErrorTooltipProps> = ({ label }) => {
  if (!label) {
    return null;
  }

  return (
    <Tooltip label={label} maxW="256px">
      <Box
        css={{
          '&:hover path.background': {
            fill: colors.content.link.hover
          }
        }}
      >
        <ErrorFilledSmallIcon />
      </Box>
    </Tooltip>
  );
};
