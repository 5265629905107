import {
  AccordionButton,
  Flex,
  Heading,
  Text,
  Box,
  AccordionPanel,
  useAccordionItemState
} from '@chakra-ui/react';
import ChevronDown from './assets/chevronSmallDown.svg';
import { FC, ReactNode } from 'react';
import { theme } from '../../../../theme';
const { colors } = theme;

interface AccordionBodyProps {
  title: string | ReactNode;
  hideButton?: boolean;
}

const buttonBackground = {
  background: colors.background.secondary
};

export const AccordionBody: FC<AccordionBodyProps> = ({
  title,
  children,
  hideButton = false
}) => {
  const { isOpen } = useAccordionItemState();

  return (
    <>
      <Heading d="flex" alignItems="center" as="h2" variant="subtitle" h="30px">
        <Flex justify="space-between" alignItems="center" w="100%">
          {title}
          <Flex d={hideButton ? 'none' : 'flex'} alignItems="center">
            <AccordionButton
              d="flex"
              justifyContent="flex-end"
              w="75px"
              borderRadius="4px"
              p="4px 8px"
              _hover={buttonBackground}
              _active={buttonBackground}
            >
              <Text mr="5px" color="content.secondary">
                {isOpen ? 'Hide' : 'Show'}
              </Text>
              <Box
                transition="all .2s ease"
                transform={isOpen ? 'rotate(180deg)' : ''}
              >
                <ChevronDown />
              </Box>
            </AccordionButton>
          </Flex>
        </Flex>
      </Heading>
      <AccordionPanel p="20px 0 0 0">{children}</AccordionPanel>
    </>
  );
};
