import { Box, Button } from '@chakra-ui/react';
import { FC } from 'react';
import { ChevronDown } from 'assets';
import { theme } from '@vizgen/vizgen-ui';
const { colors } = theme;

interface CollapseButtonProps {
  isExpanded: boolean;
  onClick: () => void;
}

export const CollapseButton: FC<CollapseButtonProps> = ({
  isExpanded,
  onClick
}) => {
  return (
    <Button
      autoFocus
      onClick={onClick}
      variant="tinyGhostPrimary"
      h="24px"
      minW="auto"
      data-testid={isExpanded ? 'collapseButton' : 'seeAllButton'}
    >
      {isExpanded ? 'Collapse ' : 'See all '}
      <Box
        as="span"
        transition="all 0.2s ease"
        transform={isExpanded ? 'rotate(180deg)' : undefined}
        css={{
          path: {
            stroke: colors.content.link.normal
          }
        }}
        data-testid="chevronIcon"
      >
        <ChevronDown />
      </Box>
    </Button>
  );
};
