import { TranscriptModel } from 'store';
import { GeneSetEntry } from './types';

export const getTranscriptsIntersection = (
  geneSets: GeneSetEntry[],
  panelTranscripts: TranscriptModel[],
  library: string
): string[] => {
  const geneSet = geneSets.find((set) => set[0] === library);
  const length = panelTranscripts.length;
  const genes = [];
  if (geneSet) {
    for (let i = 0; i < length; i++) {
      const geneName = panelTranscripts[i].geneName?.toUpperCase();
      if (geneSet[1].has(geneName)) {
        genes.push(geneName);
      }
    }
  }
  genes.sort();
  return genes;
};
