import { VStack, Text, HStack, Heading } from '@chakra-ui/react';

export const Header = () => {
  return (
    <VStack w="100%" align="start" spacing="4px">
      <Heading variant="smallSubtitle" fontWeight="normal">
        Genes list
      </Heading>
      <HStack spacing="8px" w="100%">
        <Text>Genes list</Text>
        <Text>
          <Text as="span" fontWeight="heavy">
            С
          </Text>{' '}
          - Custom Input
        </Text>
        <Text>
          <Text as="span" fontWeight="heavy">
            M
          </Text>{' '}
          - MERSCOPE Panel
        </Text>
        <Text>
          <Text as="span" fontWeight="heavy">
            E
          </Text>{' '}
          - Exogenous Sequence
        </Text>
      </HStack>
    </VStack>
  );
};
