import { getPromotedPanel } from 'api';
import { Panel } from 'generated/types';
import { useQuery } from 'react-query';

const PROMOTED_PANEL_QUERY_KEY = 'promotedPanel';

export const usePromotedPanelQuery = (panelId: string) => {
  return useQuery(
    [PROMOTED_PANEL_QUERY_KEY, panelId],
    async () => {
      if (!panelId) {
        return {} as Panel;
      }
      return getPromotedPanel(panelId);
    },
    { refetchOnWindowFocus: false }
  );
};
