import { useQuery } from 'react-query';
import { getQuote } from 'api';

const GET_QUOTE_QUERY_KEY = 'getQuote';

export const useQuoteQuery = (quoteId: string) => {
  return useQuery([GET_QUOTE_QUERY_KEY, quoteId], async () => {
    return await getQuote(quoteId);
  });
};
