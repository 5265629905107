import { Spinner, VStack } from '@chakra-ui/react';
import { Summary, Topbar } from './components';
import { useParams } from 'react-router';
import { ContainerLayout } from 'components';
import { QuotePanels, QuoteExtras } from 'components';
import { useQuoteQuery } from 'api/hooks';
import { countQuoteExtrasQuantity } from 'utils';

export const QuoteDetails = () => {
  const { quoteId } = useParams();
  const { data: quote, isFetching } = useQuoteQuery(quoteId!);

  const panels =
    quote?.quotepositions?.items
      .filter((pos) => !!pos && pos.panelId)
      .map((panelPos) => panelPos.panel) || [];
  const extraPositions =
    quote?.quotepositions?.items.filter((pos) => pos.extra) || [];
  const totalAmount = countQuoteExtrasQuantity(extraPositions) + panels.length;

  if (!quote || isFetching) {
    return (
      <ContainerLayout>
        <VStack align="center" w="100%">
          <Spinner />
        </VStack>
      </ContainerLayout>
    );
  }

  return (
    <ContainerLayout>
      <Topbar quote={quote} totalAmount={totalAmount} />
      <Summary
        deliveryAddress={quote.deliveryAddress}
        comment={quote.comment}
      />
      <VStack spacing="32px" maxW="840px">
        {panels && <QuotePanels panels={panels} />}
        {extraPositions && <QuoteExtras extraPositions={extraPositions} />}
      </VStack>
    </ContainerLayout>
  );
};
