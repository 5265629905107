import { FC } from 'react';
import { Text } from '@chakra-ui/react';

interface TipProps {
  message: string;
  isValid: boolean;
}

export const Tip: FC<TipProps> = ({ message, isValid }) => {
  return (
    <Text>
      <Text
        as="span"
        color={isValid ? 'content.secondary' : 'content.additional'}
      >
        ✓
      </Text>{' '}
      {message}
    </Text>
  );
};
