import { FC } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { MAX_MERFISH_GENE_ABUNDANCE } from 'consts';
import { CellTooltip } from './components';

interface AbundanceProps {
  value: number;
  isCalculating: boolean;
}

export const Abundance: FC<AbundanceProps> = ({ value, isCalculating }) => {
  const isInvalid = value > MAX_MERFISH_GENE_ABUNDANCE;
  const abundance = value ? +value.toFixed(5) : 0;

  return (
    <HStack
      justify="space-between"
      w="100%"
      h="100%"
      p="8px 12px 8px 8px"
      bg={isInvalid ? 'state.error10Percent' : ''}
    >
      <Box cursor="pointer">
        {isInvalid && <CellTooltip value={abundance} />}
      </Box>
      <Box opacity={isCalculating ? 0.5 : 1}>{abundance}</Box>
    </HStack>
  );
};
