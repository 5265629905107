import {
  useDisclosure,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Box,
  Flex,
  Spacer,
  Text
} from '@chakra-ui/react';
import { useFailureNotification } from '@vizgen/vizgen-ui';
import { FC } from 'react';
import { transcriptByIdSelector, useCreatePanelStore } from 'store';
import { shallow } from 'zustand/shallow';
import { RightArrow, Substitute } from './assets';
import { formatErrorMessages } from 'utils';

interface PickSynonymProps {
  isVisible: boolean;
  transcriptId: string;
  onClick: () => void;
}

const getColor = (isDisabled: boolean) =>
  isDisabled ? 'content.additional' : '';

const Component: FC<Omit<PickSynonymProps, 'isVisible'>> = ({
  transcriptId,
  onClick
}) => {
  const { showFailureNotification, closeFailureNotifications } =
    useFailureNotification();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [transcript, transcripts, includeSynonym] = useCreatePanelStore(
    (state) => [
      transcriptByIdSelector(transcriptId)(state),
      state.transcripts,
      state.includeSynonym
    ],
    shallow
  );
  const synonyms = transcript?.synonyms;
  const isDisabled = !synonyms;

  const onClickHandler = async (geneIdentifier: string) => {
    onClick();
    closeFailureNotifications();
    try {
      await includeSynonym(geneIdentifier, transcript?.transcriptId || '');
    } catch (e) {
      showFailureNotification(formatErrorMessages(e));
    }
  };

  const renderList = () => {
    return synonyms?.map(({ geneSymbol }) => {
      const isIncluded = transcripts.some(
        ({ geneName, geneId }) =>
          geneName === geneSymbol || geneId === geneSymbol
      );

      return (
        <MenuItem
          data-testid={`synonym_${geneSymbol}`}
          isDisabled={isIncluded}
          _disabled={{
            opacity: 1,
            cursor: 'not-allowed'
          }}
          key={geneSymbol}
          onClick={() => onClickHandler(geneSymbol)}
        >
          <Text>
            <Text as="span" color={getColor(isIncluded)}>
              {geneSymbol}
            </Text>
            {isIncluded && (
              <>
                {' '}
                <Text as="span">(duplicated)</Text>
              </>
            )}
          </Text>
        </MenuItem>
      );
    });
  };

  return (
    <Menu offset={[222, -49]} onOpen={onOpen} onClose={onClose}>
      <MenuButton
        p="12px"
        w="100%"
        h="100%"
        _active={{
          bg: 'button.ghostOnPrimary.pressed'
        }}
        _hover={{
          bg: 'button.ghostOnPrimary.hover'
        }}
        _disabled={{
          cursor: 'not-allowed'
        }}
        data-testid={`pickSynonymButton_${transcriptId}`}
        borderBottom="1px solid"
        borderColor="border.divider"
        disabled={isDisabled}
      >
        <Flex>
          <Box>
            <Substitute />
          </Box>
          <Text as="span" color={getColor(isDisabled)} ml="4px">
            Pick a synonym {`(${synonyms?.length || 0})`}
          </Text>
          <Spacer />
          {isDisabled || (
            <Box alignSelf="right">
              <RightArrow />
            </Box>
          )}
        </Flex>
      </MenuButton>
      {isOpen && <MenuList>{renderList()}</MenuList>}
    </Menu>
  );
};

export const PickSynonym: FC<PickSynonymProps> = ({ isVisible, ...rest }) => {
  if (!isVisible) {
    return null;
  }

  return <Component {...rest} />;
};
