import { FC, useEffect } from 'react';
import { VStack, Text, Button, HStack, Box } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { AuthFlow, useUserStore } from 'store';
import { Divider } from 'components';
import { shallow } from 'zustand/shallow';
import { InputField, InputPassword, ErrorMessage } from '@vizgen/vizgen-ui';

export const SignInForm: FC = () => {
  const setAuthFlow = useUserStore((state) => state.setAuthFlow);
  const signIn = useUserStore((state) => state.signIn);
  const error = useUserStore((state) => state.error);
  const [isFetching, resetErrors] = useUserStore(
    (state) => [state.isFetching, state.resetErrors],
    shallow
  );

  const { handleSubmit, register, formState } = useForm();

  useEffect(() => {
    return () => {
      resetErrors();
    };
  }, []); // eslint-disable-line

  return (
    <VStack
      as="form"
      maxW="552px"
      width="100%"
      spacing="0"
      align="stretch"
      m="0 auto"
      onSubmit={handleSubmit(signIn)}
    >
      <Box p="40px 36px 24px 36px">
        <InputField
          {...register('email')}
          w="100%"
          mb="24px"
          label="Email"
          type="text"
          placeholder="Enter your email"
        />
        <InputPassword
          {...register('password')}
          id="password"
          label="Password"
          placeholder="Enter your password"
        />
        <Button
          disabled={isFetching}
          type="submit"
          textTransform="none"
          isLoading={formState.isSubmitting}
          w="100%"
          size="md"
          fontSize="16px"
          m="28px 0 8px 0"
          h="40px"
          data-testid="signInButton"
        >
          Sign In
        </Button>
        <HStack display="flex" w="100%" p="0px" m="0">
          <Button
            minW={0}
            variant="tinyGhostPrimary"
            p="4px 8px"
            m="0 auto"
            size="sm"
            h="32px"
            onClick={() => setAuthFlow(AuthFlow.ResetPassword)}
            data-testid="forgotPasswordButton"
          >
            Forgot my password
          </Button>
        </HStack>
        <ErrorMessage error={error?.message} mt="8px" />
        <Divider />
        <HStack justify="center" spacing="4px">
          <Text>Don't have an account yet?</Text>
          <Button
            minW={0}
            variant="tinyGhostPrimary"
            size="sm"
            h="32px"
            padding="4px 8px"
            onClick={() => setAuthFlow(AuthFlow.SignUp)}
            data-testid="signUpButton"
          >
            Sign Up
          </Button>
        </HStack>
      </Box>
    </VStack>
  );
};
