import { Text, Tr, Td, Th } from '@chakra-ui/react';
import { FC } from 'react';

interface DetailsRowProps {
  field: string;
  value?: string | number | null;
  headerWidth?: string;
  cellWidth?: string;
}

export const DetailsRow: FC<DetailsRowProps> = ({
  field,
  value,
  headerWidth = '30%',
  cellWidth = '70%'
}) => {
  return (
    <Tr
      minH="25px"
      borderBottom="1px dashed"
      borderColor="printable.additional"
      d="flex"
    >
      <Th
        d="flex"
        w={headerWidth}
        textTransform="unset"
        fontSize="16px"
        letterSpacing="unset"
        border="none"
        p="5px 0"
      >
        <Text as="span" px="8px" color="printable.secondary">
          {field}
        </Text>
      </Th>
      <Td w={cellWidth} minW="280px" p="5px 0" border="none" d="flex">
        <Text as="span" px="8px">
          {value}
        </Text>
      </Td>
    </Tr>
  );
};
