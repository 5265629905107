import { listInstrumentsQuery } from 'api';
import { useQuery } from 'react-query';

export const LIST_INSTRUMENTS_QUERY_KEY = 'listInstruments';
const REFETCH_INTERVAL_MSECS = 240_000;

export const useListInstrumentsQuery = () => {
  return useQuery(
    [LIST_INSTRUMENTS_QUERY_KEY],
    async () => {
      return await listInstrumentsQuery();
    },
    {
      refetchInterval: REFETCH_INTERVAL_MSECS
    }
  );
};
