import {
  Box,
  Button,
  Divider,
  HStack,
  VStack,
  Heading
} from '@chakra-ui/react';
import { useNewOrderStore } from 'store';
import { PlusIcon } from './assets';
import { Deliveries, CommentsField, Panels, Extras } from './components';
import { Notifications } from 'components';
import { useIncompatibilityNotifications } from './hooks';

export const OrderPanel = () => {
  const addPanel = useNewOrderStore((state) => state.addPanel);
  useIncompatibilityNotifications();

  return (
    <Box p="0 96px">
      <VStack spacing="32px" mb="32px" align="start" maxW="552px">
        <Heading as="h1" variant="headline">
          New Quote Request
        </Heading>
        <Notifications isCloseAllowed={false} />
      </VStack>
      <VStack
        bg="background.primary"
        direction="column"
        maxW="552px"
        spacing="48px"
      >
        <Box w="100%">
          <Panels />
          <Divider />
          <Box p="24px 0">
            <Button
              w="100%"
              onClick={addPanel}
              variant="secondaryFilled"
              data-testid="addMorePanelButton"
            >
              <HStack spacing="4px">
                <PlusIcon />
                <Box as="span">Add One More Panel</Box>
              </HStack>
            </Button>
          </Box>
          <Divider />
          <Extras />
          <Divider />
          <Deliveries />
          <CommentsField />
        </Box>
      </VStack>
    </Box>
  );
};
