import { Box, BoxProps, Container, forwardRef } from '@chakra-ui/react';
import { Content } from '../Content';

export const ContainerLayout = forwardRef<BoxProps, 'div'>(
  ({ pt, pb, bg, children }, ref) => {
    return (
      <Content>
        <Box ref={ref} pt={pt || '40px'} pb={pb || '40px'} bg={bg}>
          <Container maxW="1440px" p="0 60px">
            {children}
          </Container>
        </Box>
      </Content>
    );
  }
);
