import { FileResource, PanelOwnershipType } from 'generated/types';

export enum Reference {
  Human = 'Human',
  Mouse = 'Mouse'
}

export interface PersonalGenePanelModel {
  panelId: string;
  panelName: string;
  genesSummary?: { totalNumber: number };
  ref: Reference;
  updatedAt: number;
  comment: string;
  isCompleted?: boolean;
  hasCodebook?: boolean;
  serialNumberTag?: string;
  ownershipType: PanelOwnershipType;
}

export enum Category {
  Software = 'Software',
  UserGuides = 'UserGuides',
  ApplicationAndTechnicalNotes = 'ApplicationAndTechnicalNotes',
  SafetyDataSheets = 'SafetyDataSheets',
  ProductNotifications = 'ProductNotifications'
}

export enum Product {
  Vizualizer = 'Vizualizer',
  Platform = 'Platform',
  PreparationKit = 'PreparationKit',
  SampleVerificationKit = 'SampleVerificationKit',
  GenePanelPortal = 'GenePanelPortal',
  CellBoundaryKit = 'CellBoundaryKit',
  ImagingKit = 'ImagingKit',
  GenePanel = 'GenePanel',
  TrainingKit = 'TrainingKit',
  VerificationKit = 'VerificationKit'
}

export interface Path {
  winInstaller?: string;
  macOsInstaller?: string;
  document?: string;
}

export interface Resource
  extends Omit<FileResource, 'category' | 'path' | 'product'> {
  category: Category;
  path: Path;
  product: Product;
}

export interface ErrorType {
  message: string;
  code: string;
}

export interface ValidationError extends ErrorType {
  field: string;
}

export enum InstrumentNotification {
  RunStarted = 'RunStarted',
  AcquisitionCompleted = 'AcquisitionCompleted',
  AcquisitionFailed = 'AcquisitionFailed',
  ImageProcessingComplete = 'ImageProcessingComplete',
  DataTransferCompleted = 'DataTransferCompleted'
}
