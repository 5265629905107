import { create } from 'zustand';
import { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { ErrorType, ValidationError } from 'models';
import {
  validateSignupForm,
  SignUpData,
  validateConfirmationCode,
  validatePassword
} from 'utils';
import {
  getUserPropsQuery,
  updateUserPropsMutation,
  UserProperties
} from 'api';
import { UserPropsInput } from 'generated/types';

type AuthChallengeName =
  | 'NEW_PASSWORD_REQUIRED'
  | 'SMS_MFA'
  | 'SOFTWARE_TOKEN_MFA'
  | 'MFA_SETUP';

export enum AuthFlow {
  SignIn = 'Sign In',
  SignUp = 'Sign Up',
  ResetPassword = 'Reset Password',
  ConfirmRegistration = 'Confirm Registration',
  EULA = 'EULA',
  TemporaryPassword = 'Create a new password'
}

interface User extends CognitoUser {
  challengeName: AuthChallengeName;
}

type UserStore = {
  user: User | null;
  tempUser: User | null;
  error: ErrorType | null;
  errorValidation: ValidationError | null;
  registrationEmail: string;
  isFetching: boolean;
  isEmailVerified: boolean;
  isChangingPassword: boolean;
  authFlow: AuthFlow;
  setAuthFlow: (authFlow: AuthFlow) => void;
  signIn: ({
    email,
    password
  }: {
    email?: string;
    password?: string;
    rememberMe?: boolean;
  }) => void;
  checkAuth: () => void;
  signUp: (formData: SignUpData) => void;
  updateAttributes: (attributes: UserPropsInput) => Promise<boolean>;
  attributes: UserProperties | null;
  fetchAttributes: () => void;
  signOut: () => void;
  confirmRegistration: (code: string) => void;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  changeTemporaryPassword: ({
    password,
    repeatPassword
  }: {
    password?: string;
    repeatPassword?: string;
  }) => Promise<void>;
  resetErrors: () => void;
  clearState: () => void;
};

const defaultState = {
  user: null,
  tempUser: null,
  error: null,
  errorValidation: null,
  registrationEmail: '',
  isFetching: false,
  isChangingPassword: false,
  isEmailVerified: false,
  authFlow: AuthFlow.SignIn,
  attributes: null
};

export const useUserStore = create<UserStore>()((set, get) => ({
  ...defaultState,

  setAuthFlow: (authFlow) => {
    set({ authFlow });
  },

  signIn: async ({ email, password }) => {
    const { setAuthFlow } = get();
    set({ user: null, error: null, isFetching: true });
    try {
      const user: User = await Auth.signIn(email || '', password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setAuthFlow(AuthFlow.TemporaryPassword);
        set({ tempUser: user, isFetching: false });
      } else {
        set({ user, isFetching: false });
      }
    } catch (error: any) {
      set({ error, isFetching: false });
    }
  },

  checkAuth: async () => {
    set({ isFetching: true });
    try {
      const user = await Auth.currentAuthenticatedUser();
      set({ user });
    } catch (e) {
      set({ user: null });
    }
    set({ isFetching: false });
  },

  changePassword: async (oldPassword, newPassword) => {
    const { user } = get();
    try {
      await Auth.changePassword(user, oldPassword, newPassword);
      const newUser = await Auth.currentAuthenticatedUser();
      set({ user: newUser });
    } catch (error) {
      throw error;
    }
  },

  changeTemporaryPassword: async ({ password, repeatPassword }) => {
    set({ error: null, errorValidation: null, isFetching: true });
    const { tempUser } = get();
    const validationError = validatePassword(
      password || '',
      repeatPassword || ''
    );

    if (validationError) {
      set({ errorValidation: validationError, isFetching: false });
      return;
    }

    try {
      const user: User = await Auth.completeNewPassword(
        tempUser,
        password || ''
      );
      set({
        tempUser: null,
        user,
        isFetching: false,
        authFlow: AuthFlow.SignIn
      });
    } catch (error: any) {
      set({ error, isFetching: false });
    }
  },

  signUp: async (formData) => {
    set({ error: null, isFetching: true });
    const validationError = validateSignupForm(formData);

    if (validationError) {
      set({ errorValidation: validationError, isFetching: false });
      return;
    }

    const userData = {
      username: formData.email || '',
      password: formData.password || '',
      attributes: {
        email: formData.email || '',
        name: formData.firstName || '',
        family_name: formData.lastName || ''
      }
    };

    try {
      const { userSub } = await Auth.signUp(userData);

      const userProps: UserPropsInput = {
        userId: userSub,
        userType: formData.userType,
        name: formData.firstName,
        familyName: formData.lastName,
        organization: formData.organization,
        laboratory: formData.laboratory,
        countryId: formData.country?.value || null,
        regionId: formData.region?.value || null
      };

      await updateUserPropsMutation(userProps);

      set({
        authFlow: AuthFlow.ConfirmRegistration,
        isFetching: false,
        registrationEmail: userData.username || ''
      });
    } catch (error: any) {
      set({ error, isFetching: false });
    }
  },

  updateAttributes: async (data) => {
    set({ isFetching: true, error: null });
    try {
      const attributes = await updateUserPropsMutation(data);
      set({ isFetching: false, attributes });
      return true;
    } catch (error: any) {
      console.error(error);
      set({ error, isFetching: false });
      return false;
    }
  },

  fetchAttributes: async () => {
    try {
      const attributes = await getUserPropsQuery();
      set({ attributes });
    } catch (error) {
      console.log(error);
    }
  },

  signOut: async () => {
    const { clearState } = get();
    set({ isFetching: true, error: null });
    try {
      await Auth.signOut();
      clearState();
    } catch (error: any) {
      set({ isFetching: false, error });
    }
  },

  confirmRegistration: async (code: string) => {
    set({ error: null, isFetching: true });
    const validationError = validateConfirmationCode(code);

    if (validationError) {
      set({ errorValidation: validationError, isFetching: false });
      return;
    }

    const { registrationEmail } = get();
    try {
      await Auth.confirmSignUp(registrationEmail, code);
      set({
        isFetching: false,
        isEmailVerified: true,
        authFlow: AuthFlow.SignUp
      });
    } catch (error: any) {
      set({ isFetching: false, error });
    }
  },

  resetErrors: () => {
    set({ errorValidation: null, error: null });
  },

  clearState: () => {
    set({ ...defaultState });
  }
}));
