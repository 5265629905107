import { LibraryCategory } from 'store';
import { sortStringsByKey } from 'utils';
import { GeneSetsSource } from './types';

export const convertGeneSetOptions = (
  geneSetsSource: GeneSetsSource,
  selectedGeneSets?: Set<string>
): LibraryCategory[] => {
  return Object.entries(geneSetsSource.categories)
    .map(([categoryId, category]) => ({
      title: category.name,
      children: Object.entries(category.libraries)
        .map(([libraryId, library]) => ({
          label: library.name,
          children: Object.entries(library.sets)
            .map(([setId, set]) => {
              const id = [categoryId, libraryId, setId].join('.');
              return {
                id,
                label: set.name,
                isChecked: selectedGeneSets?.has(id) || false,
                genes: new Set(set.genes.sort())
              };
            })
            .sort(sortStringsByKey('label'))
        }))
        .sort(sortStringsByKey('label'))
    }))
    .sort(sortStringsByKey('title'));
};
