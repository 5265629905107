import { Flex, Text } from '@chakra-ui/react';
import {
  MiniModal,
  useDebounce,
  useFailureNotification
} from '@vizgen/vizgen-ui';
import { FC, useMemo, useState } from 'react';
import { LibraryCategory, usePanelCompositionStore } from 'store';
import { Header, Footer, LibraryCategoryItem } from './components';
import { findMatchingGeneSetCategoriesAmount } from './utils';

interface GeneSetLibrariesModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const Component: FC<Omit<GeneSetLibrariesModalProps, 'isVisible'>> = ({
  onClose
}) => {
  const geneSetOptions = usePanelCompositionStore(
    (state) => state.geneSetOptions
  );
  const setSelectedGeneSets = usePanelCompositionStore(
    (state) => state.setSelectedGeneSets
  );
  const { showFailureNotification } = useFailureNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [expandedId, setExpandedId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const { value: debouncedValue } = useDebounce(searchValue, 200);
  const [libraries, setLibraries] = useState(geneSetOptions);

  const isLibrariesVisible = useMemo(
    () => findMatchingGeneSetCategoriesAmount(libraries, searchValue) > 0,
    [libraries, searchValue]
  );

  const submit = async (data: LibraryCategory[]) => {
    try {
      setIsLoading(true);
      await setSelectedGeneSets(data);
    } catch {
      showFailureNotification('Something went wrong, please try again');
    } finally {
      onClose();
    }
  };

  return (
    <MiniModal
      isInProgress={isLoading}
      onClose={onClose}
      contentProps={{
        maxW: '610px',
        maxH: '840px',
        h: '100%'
      }}
      bodyProps={{
        p: '0 0 16px 0'
      }}
      renderHeader={() => (
        <Header
          isLoading={isLoading}
          searchValue={searchValue}
          libraryCategories={libraries}
          onClose={onClose}
          onSelect={setLibraries}
          onSearchChange={setSearchValue}
        />
      )}
      renderFooter={() => (
        <Footer
          isLoading={isLoading}
          libraryCategories={libraries}
          onSubmit={async () => {
            await submit(libraries);
          }}
          onClose={onClose}
        />
      )}
    >
      {isLibrariesVisible ? (
        <Flex direction="column">
          {libraries.map((libraryCategory, i) => (
            <LibraryCategoryItem
              key={libraryCategory.title}
              isLoading={isLoading}
              searchValue={debouncedValue}
              expandedId={expandedId}
              libraryCategory={libraryCategory}
              libraryCategoryIndex={i}
              onExpand={setExpandedId}
              onSelect={setLibraries}
            />
          ))}
        </Flex>
      ) : (
        <Text ml="24px" mt="8px" color="content.primary">
          No Gene Sets match your request
        </Text>
      )}
    </MiniModal>
  );
};

export const GeneSetLibrariesModal: FC<GeneSetLibrariesModalProps> = ({
  isVisible,
  ...rest
}) => {
  if (!isVisible) {
    return null;
  }

  return <Component {...rest} />;
};
