import { HStack, Button, Stack, Text, Flex } from '@chakra-ui/react';
import {
  BaseField,
  Dropdown,
  InputField,
  ValidationMessage
} from '@vizgen/vizgen-ui';
import { useCountryItems, useCountryRegionsItems } from 'hooks';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormModel } from './models';

interface AddAddressFormProps {
  hide: () => void;
  updateAddress: (newAddress: string) => void;
  address?: string;
}

export const AddAddressForm: FC<AddAddressFormProps> = ({
  hide,
  updateAddress,
  address = ''
}) => {
  const { handleSubmit, formState, control, watch, setValue } = useForm();
  const formData: FormModel = watch();
  const [street, city, region, zip, country] = address.split(', ', 5); // eslint-disable-line

  const countryItems = useCountryItems();
  const { items: countryRegionsItems, isLoading } = useCountryRegionsItems(
    formData.country?.value
  );
  const isCanada = formData.country?.value === 'CA';
  const isUSA = formData.country?.value === 'US';

  useEffect(() => {
    setValue('region', null);
  }, [formData.country?.value]); // eslint-disable-line

  const submitForm = (values: FormModel) => {
    const newAddress = `${values.streetAddress}, ${values.city}, ${values.region?.label}, ${values.zip}, ${values.country?.label}`;
    updateAddress(newAddress);
    hide();
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Flex
        direction="column"
        bg="background.secondary"
        p="12px 8px 8px"
        boxShadow="0px 4px 8px rgba(215, 217, 220, 0.4)"
        border="1px solid"
        borderColor="border.normal"
        borderRadius="8px"
      >
        <Text pl="12px" variant="accentBase">
          {address ? 'Edit Address' : 'Add New Address'}
        </Text>
        <Stack spacing="24px" mt="20px">
          <BaseField id="streetAddress" label="Street Address">
            <Controller
              control={control}
              name="streetAddress"
              defaultValue={street || ''}
              render={({ field: { onChange, name, value, ref } }) => {
                return (
                  <InputField
                    ref={ref}
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder="Enter your street address"
                    controlProps={{
                      isInvalid: !!formState?.errors?.streetAddress?.message
                    }}
                    error={formState?.errors?.streetAddress?.message}
                  />
                );
              }}
              rules={{ required: 'This field cannot be empty' }}
            />
          </BaseField>
          <HStack spacing="24px">
            <BaseField
              id="country"
              label="Country"
              controlProps={{
                w: '50%'
              }}
            >
              <Controller
                control={control}
                name="country"
                defaultValue={null}
                render={({
                  field: { onChange, name, value },
                  fieldState: { invalid }
                }) => {
                  return (
                    <>
                      <Dropdown
                        data-testid="select"
                        items={countryItems}
                        onChange={onChange}
                        value={value}
                        name={name}
                        defaultValue={{}}
                        isSearchable
                        placeholder="Select your country"
                        isInvalid={invalid}
                      />
                      {invalid && (
                        <ValidationMessage>
                          This field cannot be empty
                        </ValidationMessage>
                      )}
                    </>
                  );
                }}
                rules={{ required: true }}
              />
            </BaseField>
            <BaseField
              id="region"
              label="State/Province"
              controlProps={{
                w: '50%'
              }}
            >
              <Controller
                control={control}
                name="region"
                defaultValue={region || ''}
                render={({
                  field: { onChange, name, value },
                  fieldState: { invalid }
                }) => {
                  return (
                    <>
                      <Dropdown
                        items={countryRegionsItems}
                        onChange={onChange}
                        value={value}
                        name={name}
                        defaultValue={{}}
                        isSearchable
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        placeholder={
                          isCanada
                            ? 'Select your province'
                            : 'Select your state'
                        }
                        isInvalid={invalid}
                      />
                      {invalid && (
                        <ValidationMessage>
                          This field cannot be empty
                        </ValidationMessage>
                      )}
                    </>
                  );
                }}
                rules={{ required: true }}
              />
            </BaseField>
          </HStack>
          <HStack spacing="24px" alignItems="baseline">
            <BaseField
              id="city"
              label="City"
              controlProps={{
                w: '50%'
              }}
            >
              <Controller
                control={control}
                name="city"
                defaultValue={city || ''}
                render={({ field: { onChange, name, value, ref } }) => {
                  return (
                    <InputField
                      ref={ref}
                      name={name}
                      value={value}
                      onChange={onChange}
                      placeholder="Enter your city"
                      controlProps={{
                        isInvalid: !!formState?.errors?.city?.message
                      }}
                      errorProps={{ pos: 'relative' }}
                      error={formState?.errors?.city?.message}
                    />
                  );
                }}
                rules={{ required: 'This field cannot be empty' }}
              />
            </BaseField>
            <BaseField
              id="zip"
              label="Postal code"
              controlProps={{
                w: '50%'
              }}
            >
              <Controller
                control={control}
                name="zip"
                defaultValue={zip || ''}
                render={({ field: { onChange, name, value, ref } }) => {
                  return (
                    <InputField
                      ref={ref}
                      name={name}
                      value={value}
                      onChange={onChange}
                      placeholder="Enter postal code"
                      controlProps={{
                        isInvalid: !!formState?.errors?.zip?.message,
                        d: 'flex',
                        flexDir: 'column'
                      }}
                      errorProps={{ pos: 'relative' }}
                      error={formState?.errors?.zip?.message}
                    />
                  );
                }}
                rules={{
                  required: 'This field cannot be empty',
                  pattern: {
                    value: isCanada
                      ? /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/
                      : isUSA
                      ? /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4})$/
                      : /^[a-zA-Z0-9- ]+$/,
                    message: isCanada
                      ? 'Postal code should be in A1A 1A1 format'
                      : isUSA
                      ? 'Postal code should be in 11111-1111 or 11111 format'
                      : 'Please enter a valid postal code'
                  }
                }}
              />
            </BaseField>
          </HStack>
          <HStack justify="flex-end" alignItems="center" spacing="24px">
            <Button
              variant="tinyGhostSecondary"
              maxW="65px"
              minW="65px"
              onClick={hide}
              data-testid="cancelButton"
            >
              Cancel
            </Button>
            <Button
              variant="secondaryFilled"
              type="submit"
              isLoading={formState.isSubmitting}
              minW="132px"
              data-testid="submitButton"
            >
              {address ? 'Save' : 'Add'}
            </Button>
          </HStack>
        </Stack>
      </Flex>
    </form>
  );
};
