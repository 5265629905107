import { ConfirmModal, useSuccessNotification } from '@vizgen/vizgen-ui';
import { useAddInstrumentUserMutation } from 'api/hooks';
import { InstrumentUserRole, UserProps } from 'generated/types';
import { FC } from 'react';
import { useUserStore } from 'store';
import { ChangeRoleWarning } from './components';

interface ConfirmAddUserModalProps {
  email: string;
  instrumentUniqueId: string;
  users: UserProps[];
  isVisible: boolean;
  isAdmin: boolean;
  onClose: () => void;
  onFailure: () => void;
  onSuccess: () => void;
}

const ModalComponent: FC<Omit<ConfirmAddUserModalProps, 'isVisible'>> = ({
  users,
  instrumentUniqueId,
  isAdmin,
  email,
  onClose,
  onSuccess,
  onFailure
}) => {
  const showSuccessNotification = useSuccessNotification();
  const { mutateAsync: addInstrumentUser, isLoading } =
    useAddInstrumentUserMutation();
  const currentUserEmail = useUserStore(
    (state) => state.attributes?.email || ''
  );

  const isChangingOwnRole = currentUserEmail === email;
  const hasAssignment = users.some((user) => user.email === email);
  const currentRole = isAdmin ? 'viewer' : 'administrator';
  const newRole = isAdmin ? 'administrator' : 'viewer';
  const title = `Add instrument ${newRole}`;
  let text;
  let submitText;

  if (isChangingOwnRole) {
    text = <ChangeRoleWarning />;
    submitText = 'I understand, change MY role anyway';
  } else if (hasAssignment) {
    text = `You are about to add user as the instrument ${newRole}. This user currently has an ${currentRole} role. Change the role for this user?`;
    submitText = `Yes, add as a ${newRole}`;
  } else {
    text = `You are about to add user as the instrument ${newRole}.`;
    submitText = `Add as a ${newRole}`;
  }

  const onSubmit = async () => {
    const res = await addInstrumentUser({
      instrumentUniqueId,
      userEmail: email,
      role: isAdmin ? InstrumentUserRole.Admin : InstrumentUserRole.Viewer
    });
    if (res) {
      onSuccess();
      showSuccessNotification('The user has been added');
    } else {
      onFailure();
    }
  };

  return (
    <ConfirmModal
      title={title}
      text={text}
      submitText={submitText}
      isInProgress={isLoading}
      contentProps={{ maxW: isAdmin || isChangingOwnRole ? '452px' : '375px' }}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export const ConfirmAddUserModal: FC<ConfirmAddUserModalProps> = ({
  isVisible,
  ...rest
}) => {
  if (!isVisible) {
    return null;
  }

  return <ModalComponent {...rest} />;
};
