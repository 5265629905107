/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_appsync_graphqlEndpoint:
    'https://xtvqrqknfbajddw6662g3ytv5i.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-y6ekzu34svba5ojdgvt5iqwnby',
  aws_cognito_identity_pool_id:
    'us-east-1:117d9e1e-1216-42c6-9af2-e88982b44df7',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_Jes4DfZqf',
  aws_user_pools_web_client_id: 'fd1vffo7sbg47krcv0mtcvgea',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 9,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS'
    ]
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket:
    'genepanelapi2330ac85120b4fcd9ec8b2bc20e2afac114621-dorp',
  aws_user_files_s3_bucket_region: 'us-east-1'
};

export default awsmobile;
