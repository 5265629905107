import { Text, useDisclosure, VStack } from '@chakra-ui/react';
import { SecondaryButton, SecondaryButtonIcon } from '@vizgen/vizgen-ui';
import { FC } from 'react';
import { GeneSetLibrariesModal } from 'components';

export const AddGeneSets: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <VStack
      spacing="12px"
      mt="24px"
      bg="background.secondary"
      align="center"
      justify="center"
      w="100%"
      py="40px"
    >
      <Text variant="accentBase">
        Add Gene Sets to view the Panel Composition details
      </Text>
      <SecondaryButton
        icon={SecondaryButtonIcon.Add}
        h="32px"
        fontSize="16px"
        fontWeight="normal"
        p="0 16px 0 8px"
        w="auto"
        onClick={onOpen}
      >
        Add Gene Sets
      </SecondaryButton>
      <GeneSetLibrariesModal isVisible={isOpen} onClose={onClose} />
    </VStack>
  );
};
