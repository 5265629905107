import { matchGeneSet } from 'components/GeneSetLibrariesModal/utils';
import { useIncrementalListRendering } from 'hooks';
import { Dispatch, FC, SetStateAction } from 'react';
import { LibraryCategory, GeneSet } from 'store';
import { Checkbox } from './components';

interface GeneSetListProps {
  searchValue: string;
  geneSets: GeneSet[];
  geneSetLibraryIndex: number;
  libraryCategoryIndex: number;
  isLoading: boolean;
  onSelect: Dispatch<SetStateAction<LibraryCategory[]>>;
}

export const GeneSetList: FC<GeneSetListProps> = ({
  searchValue,
  geneSets,
  geneSetLibraryIndex,
  libraryCategoryIndex,
  isLoading,
  onSelect
}) => {
  const renderCount = useIncrementalListRendering(geneSets);

  return (
    <>
      {geneSets
        .slice(0, renderCount)
        .map(
          ({ label, isChecked }, geneSetIndex) =>
            matchGeneSet(label, searchValue) && (
              <Checkbox
                key={label}
                label={label}
                isDisabled={isLoading}
                isChecked={isChecked}
                geneSetIndex={geneSetIndex}
                geneSetLibraryIndex={geneSetLibraryIndex}
                libraryCategoryIndex={libraryCategoryIndex}
                onSelect={onSelect}
              />
            )
        )}
    </>
  );
};
