import { FC } from 'react';
import { Box, Heading, Stack, VStack } from '@chakra-ui/react';
import {
  CustomInput,
  AddPanels,
  VerticalDivider,
  ExogenousSequences
} from './components';
import { Scrollbar, ScrollbarVariants } from '@vizgen/vizgen-ui';

export const Sidebar: FC = () => {
  return (
    <Stack w="455px" pos="relative" spacing="0">
      <Heading as="h1" mb="16px">
        Add genes
      </Heading>
      <Scrollbar
        variant={ScrollbarVariants.Sidebar}
        style={{
          position: 'relative'
        }}
      >
        <VerticalDivider />
        <VStack pr="23px" pb="16px" spacing="16px" pos="relative">
          <VerticalDivider />
          <AddPanels />
          <CustomInput />
          <ExogenousSequences />
        </VStack>
      </Scrollbar>
      <Box
        p="16px 0"
        pos="absolute"
        bottom="0"
        w="100%"
        left="0"
        bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%)"
      />
    </Stack>
  );
};
