import {
  Textarea,
  FormControlProps,
  TextareaProps,
  FormErrorMessageProps,
  forwardRef
} from '@chakra-ui/react';
import { BaseField } from '../BaseField';

export interface TextareaFieldProps extends TextareaProps {
  name: string;
  label?: string | JSX.Element;
  error?: string;
  controlProps?: FormControlProps;
  errorProps?: FormErrorMessageProps;
}

export const TextareaField = forwardRef<TextareaFieldProps, 'input'>(
  (props, ref) => {
    const { error, label, controlProps, errorProps, ...rest } = props;
    return (
      <BaseField
        controlProps={controlProps}
        errorProps={errorProps}
        id={rest.name}
        label={label}
        error={error}
      >
        <Textarea {...rest} ref={ref} />
      </BaseField>
    );
  }
);
