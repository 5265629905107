import { MenuItem, Box } from '@chakra-ui/react';
import { FC } from 'react';
import { CanIcon } from './assets';

interface DeleteGeneProps {
  onClick: () => void;
}

export const DeleteGene: FC<DeleteGeneProps> = ({ onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      <Box>
        <CanIcon />
      </Box>
      <Box ml="4px">Delete gene</Box>
    </MenuItem>
  );
};
