import { Heading, Flex } from '@chakra-ui/react';

export const Header = () => {
  return (
    <Flex mb="40px" justify="center" maxW="744px">
      <Heading variant="headline" textAlign="center">
        TERMS OF USE
        <br />
        FOR
        <br />
        MERSCOPE GENE PANEL DESIGN PORTAL
      </Heading>
    </Flex>
  );
};
