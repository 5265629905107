import { Auth } from 'aws-amplify';
import { S3 } from 'aws-sdk';
import awsconfig from 'aws-exports';

export async function fetchS3File(key: string, versionId?: string) {
  const credentials = await Auth.currentCredentials();
  const s3 = new S3({
    region: awsconfig.aws_appsync_region,
    credentials
  });
  const params = {
    Bucket: awsconfig.aws_user_files_s3_bucket,
    Key: key,
    VersionId: versionId
  };
  const s3Object: S3.GetObjectOutput = await new Promise((resolve, reject) => {
    s3.getObject(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
  return s3Object.Body?.toString();
}

export async function checkS3File(key: string, versionId?: string) {
  if (!key) {
    return false;
  }
  const credentials = await Auth.currentCredentials();
  const s3 = new S3({
    region: awsconfig.aws_appsync_region,
    credentials
  });
  const params = {
    Bucket: awsconfig.aws_user_files_s3_bucket,
    Key: key,
    VersionId: versionId
  };
  try {
    await s3.headObject(params).promise();
    return true;
  } catch (err: any) {
    if (err.name === 'NotFound') {
      return false;
    } else {
      throw err;
    }
  }
}
