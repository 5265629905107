import { FC, useState } from 'react';
import { Flex, Button, Box } from '@chakra-ui/react';
import { GeneStatusIcon, GeneLink } from 'components';
import { ToggleButton, InfoText, Action } from './components';
import { PanelTranscriptStatus } from 'generated/types';
import { TranscriptModel, useCreatePanelStore } from 'store';

interface RowProps {
  genes: TranscriptModel[];
  status: PanelTranscriptStatus;
}

const genesAmountToCut = 8;

export const Row: FC<RowProps> = ({ genes, status }) => {
  const [isAllVisible, setIsAllVisible] = useState<boolean>(false);

  const deleteTranscripts = useCreatePanelStore(
    (state) => state.deleteTranscripts
  );

  if (!genes.length) {
    return null;
  }

  const genesIds = genes.map((gene) => gene.transcriptId);

  const deleteGenes = () => {
    deleteTranscripts(genesIds);
  };

  const cutGenes = genes.slice(0, genesAmountToCut + 1);

  const showAllGenes = () => setIsAllVisible(true);
  const hideAllGenes = () => setIsAllVisible(false);

  const genesLinks = () => {
    return genes.map(({ userSpecifiedName, transcriptId }) => (
      <GeneLink
        transcriptId={transcriptId}
        gene={userSpecifiedName}
        key={userSpecifiedName + transcriptId}
      />
    ));
  };

  const genesLinksCut = () => {
    return cutGenes.map(({ userSpecifiedName, transcriptId }) => (
      <GeneLink
        transcriptId={transcriptId}
        gene={userSpecifiedName}
        key={userSpecifiedName + transcriptId}
      />
    ));
  };

  return (
    <Flex
      p="8px 0 0px 16px"
      alignItems="flex-start"
      justify="start"
      css={{
        '&:first-of-type .issues-icon, &:first-of-type .issues-text': {
          paddingTop: '4px'
        },
        '&:last-of-type .issues-main-content': {
          borderBottom: 0
        }
      }}
    >
      <Flex overflow="hidden" minW="24px" pt="6px" className="issues-icon">
        <GeneStatusIcon type={status} />
      </Flex>
      <Box
        w="calc(100% - 24px)"
        className="issues-main-content"
        borderBottom="1px solid"
        borderColor="border.divider"
        pb="12px"
        pr="12px"
        ml="10px"
      >
        <Flex>
          <Flex
            overflow="hidden"
            direction="column"
            pt="6px"
            className="issues-text"
          >
            <InfoText type={status} genesAmount={genes.length} />
          </Flex>
          <Flex minW="250px" ml="auto" justify="flex-end">
            <Button
              variant="tinyGhostPrimary"
              size="tiny"
              onClick={deleteGenes}
              data-testid={`deleteGenes_${status}`}
            >
              Delete genes
            </Button>
            <Action type={status} ids={genesIds} />
          </Flex>
        </Flex>
        <Flex wrap="wrap">
          <Flex wrap="wrap" w="100%">
            {isAllVisible || genes.length < genesAmountToCut ? (
              genesLinks()
            ) : (
              <>{genesLinksCut()}...</>
            )}
          </Flex>
          {genes.length > genesAmountToCut && (
            <Flex w="100%">
              {isAllVisible ? (
                <ToggleButton onClick={hideAllGenes}>Show less</ToggleButton>
              ) : (
                <ToggleButton onClick={showAllGenes}>Show more</ToggleButton>
              )}
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};
