import { getPanelsByOwner } from 'api';
import { Auth } from 'aws-amplify';
import { GetPanelsByOwnerQueryVariables, Panel } from 'generated/types';
import { useQuery } from 'react-query';

export const CUSTOM_PANELS_LIST_QUERY_KEY = 'customPanelsList';

export const useCustomPanelsListQuery = (
  input?: Omit<GetPanelsByOwnerQueryVariables, 'owner'>
) => {
  return useQuery(CUSTOM_PANELS_LIST_QUERY_KEY, async () => {
    const { attributes } = await Auth.currentAuthenticatedUser();
    const owner = attributes.sub;
    if (!owner) return [] as Panel[];
    const [oldItems = [], newItems = []] = await Promise.all([
      // Search for owners written with v1 of the GraphQL transformer
      getPanelsByOwner({
        ...input,
        owner
      }),
      // Search for owners written with v2 of the GraphQL transformer
      getPanelsByOwner({
        ...input,
        owner: owner + '::' + owner
      })
    ]);

    return [...oldItems, ...newItems] as Panel[];
  });
};
