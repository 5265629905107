import { FC, useRef } from 'react';
import { UnorderedList, Text } from '@chakra-ui/react';
import { Item } from './components';
import { Scrollbar } from '@vizgen/vizgen-ui';
import { Panel } from 'generated/types';

interface ListProps {
  value?: Panel;
  data?: Panel[];
  onItemSelect: (value: Panel) => void;
}

export const List: FC<ListProps> = ({ value, data, onItemSelect }) => {
  const ref = useRef<HTMLUListElement>(null);

  return (
    <Scrollbar style={{ height: '196px' }}>
      <UnorderedList
        ref={ref}
        listStyleType="none"
        m="0"
        overflowY="auto"
        __css={{
          'li:not(:last-child)': {
            borderBottom: '1px solid',
            borderColor: 'border.divider'
          }
        }}
      >
        {data?.length ? (
          data.map((item) => (
            <Item
              key={item.panelId}
              item={item}
              onItemSelect={onItemSelect}
              isSelected={value?.panelId === item.panelId}
            />
          ))
        ) : (
          <Text color="content.additional" textAlign="center" mt="16px">
            No panels found
          </Text>
        )}
      </UnorderedList>
    </Scrollbar>
  );
};
