const switchTrackSize = {
  '--switch-track-width': '30px',
  '--switch-track-height': '14px'
};

export const Switch = {
  sizes: {
    md: {
      container: {
        width: '32px',
        height: '16px',
        ...switchTrackSize
      },
      track: {
        border: '2px solid',
        borderColor: 'background.tertiary',
        bg: 'background.tertiary',
        padding: '0',
        _checked: {
          borderColor: 'state.success',
          bg: 'state.success'
        },
        ...switchTrackSize
      },
      thumb: {
        ...switchTrackSize
      }
    }
  }
};
