export const styles = {
  global: {
    '.add-genes-main-content': {
      '.ps__rail-y': {
        borderBottomRightRadius: '0',
        borderBottomLeftRadius: '0'
      }
    },
    '@media print': {
      body: {
        overflowY: 'visible',
        '.app': {
          overflowY: 'visible'
        },
        '.chakra-container': {
          margin: '0'
        },
        '.printable-panel': {
          zoom: '150%',
          overflowY: 'visible'
        }
      }
    },
    '.terms-of-use': {
      ol: {
        listStyleType: 'none',
        'li:not(:last-child)': {
          mb: '40px'
        },
        li: {
          'li:not(:last-child)': {
            mb: '20px'
          }
        }
      },
      ul: {
        listStyleType: '"- "',
        pl: '14px',
        li: {
          color: 'content.additionalTablet',
          fontSize: '20',
          lineHeight: '24',
          fontWeight: 'normal',
          mb: '20px',
          a: {
            color: 'content.link.normal',
            _hover: {
              color: 'content.link.hover',
              textDecoration: 'underline'
            }
          }
        }
      },
      p: {
        color: 'content.additionalTablet',
        fontSize: '20',
        lineHeight: '24',
        fontWeight: 'normal',
        mb: '20px',
        strong: {
          color: 'content.secondary'
        }
      },
      'p:last-child': {
        mb: '0'
      },
      span: {
        color: 'content.additionalTablet'
      },
      h3: {
        color: 'content.secondary',
        mb: '20px',
        fontSize: '24',
        lineHeight: '32',
        fontWeight: 'heavy',
        pt: '20px',
        mt: '-20px'
      },
      h4: {
        fontWeight: 'heavy',
        color: 'content.secondary',
        fontSize: '20',
        lineHeight: '24',
        mb: '20px'
      }
    },
    '#chakra-toast-manager-top': {
      pt: '86px'
    }
  }
};
