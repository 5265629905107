import { useState, useRef, useEffect } from 'react';
import { ContainerLayout } from 'components';
import { OrderPanel, QuoteSummary, Navigation, Header } from './components';
import { useClearState } from './hooks';

const steps = [<OrderPanel />, <QuoteSummary />];

export const NewQuote = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useClearState();

  useEffect(() => {
    containerRef?.current?.scrollIntoView();
  }, [currentStep]);

  const isLastStep = steps.length - 1 === currentStep;

  return (
    <>
      <Header />
      <ContainerLayout ref={containerRef}>{steps[currentStep]}</ContainerLayout>
      <Navigation
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        isLastStep={isLastStep}
      />
    </>
  );
};
