export const Radio = {
  baseStyle: {
    control: {
      cursor: 'pointer',
      bg: 'background.primary',
      border: '1px solid',
      borderColor: 'border.secondary',
      _disabled: {
        bg: 'background.primary',
        border: '1px solid border.secondary',
        borderColor: 'border.secondary',
        opacity: 0.5
      },
      _checked: {
        bg: 'background.primary',
        border: '1px solid',
        borderTopColor: 'border.secondary',
        borderBottomColor: 'border.secondary',
        borderLeftColor: 'border.secondary',
        borderRightColor: 'border.secondary',
        color: 'background.primary',
        '&::before': {
          borderColor: 'border.secondary',
          content: '""',
          display: 'inline-block',
          position: 'relative',
          width: '50%',
          height: '50%',
          borderRadius: '50%',
          _disabled: {
            opacity: 0.5
          }
        },
        _disabled: {
          bg: 'background.primary',
          border: '1px solid',
          borderTopColor: 'border.secondary',
          borderBottomColor: 'border.secondary',
          borderLeftColor: 'border.secondary',
          borderRightColor: 'border.secondary',
          color: 'background.primary',
          opacity: 0.5
        },
        '&:hover': {
          bg: 'background.primary',
          borderTopColor: 'border.secondary',
          borderBottomColor: 'border.secondary',
          borderLeftColor: 'border.secondary',
          borderRightColor: 'border.secondary'
        }
      }
    },
    label: {
      color: 'content.secondary',
      cursor: 'pointer',
      _disabled: {
        color: 'content.secondary',
        opacity: 0.5
      }
    }
  },
  sizes: {
    md: {
      control: {
        h: '24px',
        w: '24px',
        _checked: {
          '&::before': {
            borderWidth: '8px'
          }
        }
      }
    }
  },
  variants: {
    small: {
      control: {
        h: '20px',
        w: '20px',
        _checked: {
          '&::before': {
            borderWidth: '7px'
          }
        }
      }
    }
  }
};
