import { create } from 'zustand';

interface Notification {
  message: string;
  type: string;
  isVisible: boolean;
}

export enum NotificationLevel {
  ERROR = 'ERROR',
  WARN = 'WARN',
  INFO = 'INFO'
}

type NotificationsState = {
  notifications: Notification[];
  setNotifications: (notifications: Notification[]) => void;
  getErrors: () => string[];
  getWarnings: () => string[];
  getInfo: () => string[];
  clearNotifications: (level: NotificationLevel) => void;
  clearState: () => void;
};

export const useNotificationsStore = create<NotificationsState>()(
  (set, get) => ({
    notifications: [],

    setNotifications: (newNotifications) => {
      const { notifications } = get();

      const currentNotifications = [...notifications];
      newNotifications.forEach((newNotification) => {
        const index = currentNotifications.findIndex(
          (message) => message.type === newNotification.type
        );
        if (index === -1) {
          currentNotifications.push(newNotification);
        } else {
          currentNotifications[index] = newNotification;
        }
      });

      set({
        notifications: currentNotifications.filter(
          (notification) => notification.isVisible
        )
      });
    },

    getErrors: () => {
      const { notifications } = get();
      return getNotificationsByType(notifications, NotificationLevel.ERROR);
    },

    getWarnings: () => {
      const { notifications } = get();
      return getNotificationsByType(notifications, NotificationLevel.WARN);
    },

    getInfo: () => {
      const { notifications } = get();
      return getNotificationsByType(notifications, NotificationLevel.INFO);
    },

    clearNotifications: (level) => {
      const { notifications } = get();
      set({
        notifications: notifications.filter(
          (notification) => !notification.type.startsWith(level)
        )
      });
    },

    clearState: () => {
      set({ notifications: [] });
    }
  })
);

const getNotificationsByType = (
  notifications: Notification[],
  level: NotificationLevel
) => {
  return notifications
    .filter((notification) => notification.type.startsWith(level))
    .map((notification) => notification.message);
};
