import { FC } from 'react';
import { Flex, Container, Heading } from '@chakra-ui/react';
import { Paths } from 'routes';
import { Link as RouterLink } from 'react-router-dom';
import { SecondaryButton, SecondaryButtonIcon } from '@vizgen/vizgen-ui';
import { GenePanels } from './components';

export const Panels: FC = () => {
  return (
    <Container
      d="flex"
      flexDir="column"
      p="40px 0"
      maxW="1248px"
      w="100%"
      m="auto"
      h="100%"
      overflowY="hidden"
    >
      <Flex justify="space-between" px="60px">
        <Heading as="h1">Panels</Heading>
        <SecondaryButton
          icon={SecondaryButtonIcon.Add}
          as={RouterLink}
          to={Paths.CreatePanel}
          data-testid="createNewPanelLink"
        >
          Create New Panel
        </SecondaryButton>
      </Flex>
      <Flex
        h="100%"
        overflowY="hidden"
        direction="column"
        alignItems="center"
        w="100%"
      >
        <GenePanels />
      </Flex>
    </Container>
  );
};
