import { Heading, VStack, Spinner, HStack } from '@chakra-ui/react';
import { Summary } from './components';
import { QuotePanels, QuoteExtras } from 'components';
import { useNewOrderStore } from 'store';
import { shallow } from 'zustand/shallow';
import { countQuoteExtrasQuantity } from 'utils';
import { ErrorMessage } from '@vizgen/vizgen-ui';

export const QuoteSummary = () => {
  const [
    id,
    isFetching,
    panels,
    comment,
    selectedAddress,
    additionalExtras,
    error
  ] = useNewOrderStore(
    (state) => [
      state.id,
      state.isFetching,
      state.panels,
      state.comment,
      state.selectedAddress,
      state.additionalExtras,
      state.error
    ],
    shallow
  );

  return (
    <VStack p="0 96px" spacing="16px" alignItems="start">
      <HStack spacing="4px">
        <Heading as="h1" variant="headline">
          Summary
        </Heading>
        {isFetching && <Spinner />}
      </HStack>
      <VStack direction="column" maxW="840px" spacing="32px" alignItems="start">
        <Summary
          quoteId={id}
          deliveryAddress={selectedAddress}
          comment={comment}
          panelsAmount={panels.length}
          extrasAmount={countQuoteExtrasQuantity(additionalExtras)}
        />
        <QuotePanels panels={panels} />
        <QuoteExtras extraPositions={additionalExtras} />
        <ErrorMessage error={error?.message} />
      </VStack>
    </VStack>
  );
};
