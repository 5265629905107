import { FC } from 'react';
import { components, OptionProps } from 'react-select';
import { Flex, Text } from '@chakra-ui/react';
import CheckIcon from './assets/check.svg';

export const Option: FC<OptionProps> = (props) => {
  const innerProps = { ...props.innerProps } as any;
  innerProps['data-testid'] = `dropdownOption_${(props as any).value}`;
  return (
    <components.Option {...props} innerProps={innerProps}>
      <Flex justify="space-between" alignItems="center">
        <Text
          color={
            props.isDisabled
              ? 'colors.content.additional'
              : 'colors.content.secondary'
          }
        >
          {props.label}
        </Text>
        {props.isSelected && <CheckIcon />}
      </Flex>
    </components.Option>
  );
};
