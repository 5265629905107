import { FC } from 'react';
import { Box, Circle, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { FirstStep, SecondStep, ThirdStep, FourthStep } from './assets';

interface InstructionProps {
  step: number;
}

export const Instruction: FC<InstructionProps> = ({ step }) => {
  let blockText = '';
  let imageSrc = null;
  switch (step) {
    case 1: {
      blockText = 'Configure panel';
      imageSrc = <FirstStep />;
      break;
    }
    case 2: {
      blockText = 'Specify genes';
      imageSrc = <SecondStep />;
      break;
    }
    case 3: {
      blockText = 'Refine selection';
      imageSrc = <ThirdStep />;
      break;
    }
    case 4: {
      blockText = 'Request quote';
      imageSrc = <FourthStep />;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <Stack
      spacing="20px"
      bg="background.primary"
      p="16px"
      borderRadius="8px"
      flex="1 1 auto"
    >
      <Flex justify="center">{imageSrc}</Flex>
      <Stack spacing="4px">
        <Heading
          as="span"
          variant="subtitleSuffix"
          fontWeight="800"
          color="content.secondary"
          pos="relative"
        >
          <Box as="span" zIndex="1" pos="relative" left="4px">
            {step}.
          </Box>
          <Circle
            bg="state.infoLight"
            size="32px"
            pos="absolute"
            top="-4px"
            zIndex="0"
          />
        </Heading>
        <Text color="content.primary">{blockText}</Text>
      </Stack>
    </Stack>
  );
};
