import { listUserInstrumentNotificationsQuery } from 'api';
import { useQuery } from 'react-query';

export const USER_INSTRUMENT_NOTIFICATIONS_QUERY_KEY =
  'listUserInstrumentNotifications';

export const useUserInstrumentNotificationsQuery = (
  instrumentUniqueId: string
) => {
  return useQuery(
    [USER_INSTRUMENT_NOTIFICATIONS_QUERY_KEY, instrumentUniqueId],
    async () => {
      return await listUserInstrumentNotificationsQuery(instrumentUniqueId);
    }
  );
};
