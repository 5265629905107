import { Text } from '@chakra-ui/react';
import { FC } from 'react';

export const Experiments: FC = () => {
  return (
    <Text
      pt="20px"
      w="100%"
      textAlign="center"
      variant="accentBodyShort"
      color="content.additional"
      fontWeight="normal"
    >
      No experiment records found for this instrument
    </Text>
  );
};
