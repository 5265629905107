import {
  Text,
  Tr,
  Td,
  Th,
  TextProps,
  TableCellProps,
  TableColumnHeaderProps,
  Tooltip,
  Box,
  HStack
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import InfoIcon from './assets/infoIcon.svg';
import { theme } from '../../theme';
const { colors } = theme;

interface SummaryRowProps {
  field: string | ReactNode;
  value?: ReactNode | string | number | undefined;
  headerWidth?: string;
  cellWidth?: string;
  isTruncated?: boolean;
  valueProps?: TextProps;
  fieldProps?: TextProps;
  cellProps?: TableCellProps;
  headerProps?: TableColumnHeaderProps;
  tooltipText?: string;
}

export const SummaryRow: FC<SummaryRowProps> = ({
  field,
  value,
  headerWidth = '40%',
  cellWidth = '60%',
  isTruncated = true,
  valueProps,
  fieldProps,
  cellProps,
  headerProps,
  tooltipText,
  children
}) => {
  const isTextField = typeof field === 'string';

  return (
    <Tr>
      <Th
        h="100%"
        p="14px 0 14px 12px"
        verticalAlign="top"
        w={headerWidth}
        textTransform="unset"
        letterSpacing="unset"
        borderBottom="1px solid"
        borderColor="border.normal"
        {...headerProps}
      >
        <HStack spacing="12px" alignItems="center">
          {isTextField ? (
            <Text
              as="span"
              variant="accentBodyShort"
              color="content.primary"
              {...fieldProps}
            >
              {field}
            </Text>
          ) : (
            field
          )}
          {tooltipText && (
            <Tooltip maxW="615px" label={tooltipText}>
              <Box
                cursor="pointer"
                css={{
                  '&:hover path': {
                    stroke: colors.content.link.normal
                  },
                  '&:hover path.icon-path_filled': {
                    stroke: 'transparent',
                    fill: colors.content.link.normal
                  }
                }}
              >
                <InfoIcon />
              </Box>
            </Tooltip>
          )}
        </HStack>
      </Th>
      <Td
        h="100%"
        w={cellWidth}
        verticalAlign="top"
        maxW="248px"
        p="14px 8px 14px 12px"
        borderBottom="1px solid"
        borderColor="border.normal"
        data-testid={`${isTextField ? field.toLowerCase() : 'field'}Value`}
        {...cellProps}
      >
        {children || (
          <Text
            as="span"
            variant="bodyShort"
            {...(isTruncated
              ? {
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }
              : {})}
            {...valueProps}
          >
            {value}
          </Text>
        )}
      </Td>
    </Tr>
  );
};
