import { FC } from 'react';
import { IconButton, useAccordionItemState } from '@chakra-ui/react';
import { ReactComponent as SearchIcon } from 'assets/searchIconDark.svg';

interface SearchButtonProps {
  onOpenSearch: () => void;
}

export const SearchButton: FC<SearchButtonProps> = ({ onOpenSearch }) => {
  const { isOpen } = useAccordionItemState();
  return isOpen ? (
    <IconButton
      onClick={onOpenSearch}
      size="icon"
      variant="ghost"
      aria-label="Search gene"
      borderRadius="0"
      opacity={0.5}
      _hover={{
        opacity: 1
      }}
      icon={<SearchIcon />}
    />
  ) : null;
};
