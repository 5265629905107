import { FC } from 'react';
import { Table, Tbody, Heading, VStack } from '@chakra-ui/react';
import { DetailsRow } from './components';
import { usePanelSpecifications } from 'hooks';
import { Panel } from 'generated/types';

interface PanelDetailsProps {
  panel: Panel;
}

export const PanelDetails: FC<PanelDetailsProps> = ({ panel }) => {
  const { genesSummary, comment, panelId, serialNumberTag } = panel;
  const { tissue, reference } = usePanelSpecifications(panel);

  return (
    <VStack w="100%" align="start">
      <Heading variant="smallSubtitle" fontWeight="normal">
        Details
      </Heading>
      <Table mb="33px" display="flex" flexDir="column">
        <Tbody>
          <DetailsRow field="Reference" value={reference} />
          <DetailsRow field="Tissue" value={tissue} />
          <DetailsRow field="Total Genes" value={genesSummary?.totalNumber} />
          <DetailsRow field="MERFISH" value={genesSummary?.primary} />
          <DetailsRow field="Sequential" value={genesSummary?.sequential} />
          <DetailsRow field="Description" value={comment || '-'} />
          <DetailsRow field="Panel ID" value={panelId} />
          <DetailsRow
            field="Serial Number"
            value={serialNumberTag || 'Not assigned until panel is finalized'}
          />
        </Tbody>
      </Table>
    </VStack>
  );
};
