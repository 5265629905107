import { useState } from 'react';
import {
  IconButton,
  forwardRef,
  FormControlProps,
  InputProps
} from '@chakra-ui/react';
import { InputField } from '../InputField';
import EyeIcon from './assets/eye.svg';
import EyeCrossedIcon from './assets/eyeCrossed.svg';

export interface InputPasswordProps extends InputProps {
  id: string;
  label: string;
  placeholder?: string;
  error?: string;
  controlProps?: FormControlProps;
}

export const InputPassword = forwardRef<InputPasswordProps, 'input'>(
  ({ id, label, placeholder, error, controlProps, ...rest }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    return (
      <InputField
        w="100%"
        ref={ref}
        id={id}
        name={id}
        label={label}
        type={isPasswordVisible ? 'text' : 'password'}
        placeholder={placeholder}
        rightElement={
          <IconButton
            onClick={() => setIsPasswordVisible((prev) => !prev)}
            minW="0"
            w="32px"
            h="32px"
            variant="ghost"
            p="0px"
            aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
            isDisabled={rest?.disabled}
            icon={isPasswordVisible ? <EyeIcon /> : <EyeCrossedIcon />}
            css={{
              '& path': {
                stroke: isPasswordVisible ? '#37404E' : '#9B9FA7'
              },
              '&:hover': {
                background: '#F4F4F4'
              },
              '&:active': {
                background: '#E6E6E6'
              }
            }}
          />
        }
        error={error}
        controlProps={controlProps}
        {...rest}
      />
    );
  }
);
