import { Paths } from './routes';
import {
  Home,
  CreatePanel,
  Panels,
  Quotes,
  CustomPanel,
  PromotedPanel,
  ContactUs,
  QuoteDetails,
  Profile,
  NewQuote,
  PrintablePanel,
  Resources,
  ApproveAccount,
  Instruments,
  GeneSetLibraries
} from 'pages';
import { Route, Routes } from 'react-router-dom';
import { MainLayout, TermsOfUse } from 'components';
import {
  AddGenesStep,
  ConfigurationStep,
  SummaryStep
} from 'pages/CreatePanel/components';
import { FC } from 'react';

export const RoutesList: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path={Paths.CreatePanel} element={<CreatePanel />}>
          <Route index element={<ConfigurationStep />} />
          <Route
            path={Paths.CreatePanel_Configuration}
            element={<ConfigurationStep />}
          />
          <Route path={Paths.CreatePanel_AddGenes} element={<AddGenesStep />} />
          <Route path={Paths.CreatePanel_Summary} element={<SummaryStep />} />
        </Route>
        <Route path={Paths.Panels}>
          <Route index element={<Panels />} />
          <Route path=":panelType" element={<Panels />} />
        </Route>
        <Route path={Paths.Quotes} element={<Quotes />} />
        <Route path={Paths.PanelDetails} element={<CustomPanel />} />
        <Route path={Paths.PromotedPanelDetails} element={<PromotedPanel />} />
        <Route path={Paths.ContactUs} element={<ContactUs />} />
        <Route path={Paths.Profile} element={<Profile />} />
        <Route path={Paths.QuoteDetails} element={<QuoteDetails />} />
        <Route path={Paths.NewQuote} element={<NewQuote />} />
        <Route path={Paths.NewQuoteForPanel} element={<NewQuote />} />
        <Route path={Paths.PrintablePanel} element={<PrintablePanel />} />
        <Route path={Paths.TermsOfUse} element={<TermsOfUse />} />
        <Route path={Paths.Instruments} element={<Instruments />} />
        <Route path={Paths.Resources} element={<Resources />} />
        <Route path={Paths.ResourcesByType} element={<Resources />} />
        <Route path={Paths.ApproveAccount} element={<ApproveAccount />} />
        <Route path={Paths.GeneSetLibraries} element={<GeneSetLibraries />} />
      </Route>
    </Routes>
  );
};
