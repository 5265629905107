import { VStack } from '@chakra-ui/react';
import {
  MiniModal,
  Dropdown,
  BaseField,
  useFailureNotification
} from '@vizgen/vizgen-ui';
import { FC, useEffect, useState } from 'react';
import {
  GENES_SORT_DROPDOWN_ITEMS,
  GENE_SETS_SORT_DROPDOWN_ITEMS,
  usePanelCompositionStore
} from 'store';
import { shallow } from 'zustand/shallow';

interface SortingModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const ModalComponent: FC<Omit<SortingModalProps, 'isVisible'>> = ({
  onClose
}) => {
  const { showFailureNotification } = useFailureNotification();
  const [isSorting, setIsSorting] = useState(false);
  const [userViewSettings, updateUserViewSettings] = usePanelCompositionStore(
    (state) => [state.userViewSettings, state.updateUserViewSettings],
    shallow
  );
  const [sorting, setSorting] = useState({
    genesSortMode:
      GENES_SORT_DROPDOWN_ITEMS.find(
        (i) => i.value === userViewSettings?.geneSortMethod
      ) || GENES_SORT_DROPDOWN_ITEMS[0],
    geneSetSortMode:
      GENE_SETS_SORT_DROPDOWN_ITEMS.find(
        (i) => i.value === userViewSettings?.geneSetSortMethod
      ) || GENE_SETS_SORT_DROPDOWN_ITEMS[0]
  });

  useEffect(() => {
    if (isSorting) {
      (async () => {
        try {
          await updateUserViewSettings({
            geneSetSortMethod: sorting.geneSetSortMode.value,
            geneSortMethod: sorting.genesSortMode.value
          });
        } catch {
          showFailureNotification('Something went wrong, please try again');
        } finally {
          onClose();
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSorting]);

  return (
    <MiniModal
      isInProgress={isSorting}
      contentProps={{ maxW: '408px' }}
      title="Sorting/clustering"
      submitText="Apply"
      onClose={onClose}
      onSubmit={() => setIsSorting(true)}
    >
      <VStack w="100%" spacing="32px">
        <BaseField id="genesSortBy" label="Sort/Cluster genes (rows) by">
          <Dropdown
            usePortal
            isDisabled={isSorting}
            name="genesSortBy"
            value={sorting.genesSortMode}
            items={GENES_SORT_DROPDOWN_ITEMS}
            onChange={(genesSortMode: any) =>
              setSorting((prev) => ({ ...prev, genesSortMode }))
            }
          />
        </BaseField>
        <BaseField
          id="geneSetsSortBy"
          label="Sort/Cluster gene sets (columns) by"
        >
          <Dropdown
            usePortal
            isDisabled={isSorting}
            name="geneSetsSortBy"
            value={sorting.geneSetSortMode}
            items={GENE_SETS_SORT_DROPDOWN_ITEMS}
            onChange={(geneSetSortMode: any) =>
              setSorting((prev) => ({ ...prev, geneSetSortMode }))
            }
          />
        </BaseField>
      </VStack>
    </MiniModal>
  );
};

export const SortingModal: FC<SortingModalProps> = ({ isVisible, ...rest }) => {
  if (!isVisible) {
    return null;
  }

  return <ModalComponent {...rest} />;
};
