import { Center, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import NotFoundIcon from './assets/notFound.svg';

export const NotFound: FC = () => {
  return (
    <Center w="100%" pt="16px">
      <VStack spacing="8px">
        <NotFoundIcon />
        <Text color="content.additionalTablet">No results found</Text>
      </VStack>
    </Center>
  );
};
