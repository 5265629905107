import { VStack, HStack, Heading, Text } from '@chakra-ui/react';
import { Instrument } from 'generated/types';
import { FC } from 'react';
import { InstrumentTabs } from './components';
import { theme } from '@vizgen/vizgen-ui';
const { colors } = theme;

interface InstrumentBlockProps {
  instrument: Instrument;
}

export const InstrumentBlock: FC<InstrumentBlockProps> = ({ instrument }) => {
  return (
    <HStack
      spacing="24px"
      align="self-start"
      w="100%"
      pt="16px"
      mb="16px"
      css={{
        '&:last-child': {
          marginBottom: 0,
          paddingBottom: '40px',
          borderBottom: '1px solid',
          borderBottomColor: colors.border.normal
        }
      }}
    >
      <VStack w="100%" spacing="0" align="self-start" flex="1">
        <Heading variant="subtitle">{instrument.serialNumber || 'N/A'}</Heading>
        <HStack spacing="4px">
          <Text>
            <b color="content.primary">Instrument ID: </b>
            {instrument.uniqueId}
          </Text>
        </HStack>
      </VStack>
      <InstrumentTabs instrument={instrument} />
    </HStack>
  );
};
