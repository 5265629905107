import {
  Box,
  Button,
  HStack,
  useDisclosure,
  ButtonProps
} from '@chakra-ui/react';
import { GeneSetLibrariesModal } from 'components';
import { FC } from 'react';
import { SortIcon, SettingsIcon } from './assets';
import { SortingModal } from './components';

const buttonProps: ButtonProps = {
  variant: 'secondarySolid',
  h: '32px',
  fontSize: '16px',
  fontWeight: 'normal',
  p: '0 16px 0 8px',
  minW: 'auto'
};

export const ButtonsBar: FC = () => {
  const {
    isOpen: isSortModalOpen,
    onOpen: onSortModalOpen,
    onClose: onSortModalClose
  } = useDisclosure();
  const {
    isOpen: isGeneSetsModalOpen,
    onOpen: onGeneSetsModalOpen,
    onClose: onGeneSetsModalClose
  } = useDisclosure();

  return (
    <>
      <HStack spacing="16px">
        <Button {...buttonProps} onClick={onGeneSetsModalOpen}>
          <HStack spacing="4px">
            <SettingsIcon />
            <Box>Select gene sets</Box>
          </HStack>
        </Button>
        <Button {...buttonProps} onClick={onSortModalOpen}>
          <HStack spacing="4px">
            <SortIcon />
            <Box>Sort/Cluster</Box>
          </HStack>
        </Button>
      </HStack>
      <SortingModal isVisible={isSortModalOpen} onClose={onSortModalClose} />
      <GeneSetLibrariesModal
        isVisible={isGeneSetsModalOpen}
        onClose={onGeneSetsModalClose}
      />
    </>
  );
};
