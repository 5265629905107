import { unparse } from 'papaparse';
import fileDownload from 'js-file-download';
import { TranscriptModel } from 'store';
import { PanelTranscriptType } from 'generated/types';

const createGeneListName = (
  isCompleted: boolean,
  serialNumber: string,
  panelName: string
) =>
  isCompleted
    ? `FinalGeneList_${panelName}_${serialNumber}.csv`
    : `PreliminaryGeneList_${panelName}.csv`;

export const downloadGenesList = (
  isCompleted: boolean,
  serialNumber: string,
  panelName: string,
  transcripts: TranscriptModel[]
) => {
  const data = transcripts.map((transcript) => ({
    'Gene Input': transcript.userSpecifiedName || '',
    'Vizgen Gene': transcript.geneName || '',
    'Ensemble ID': transcript.geneId || '',
    Transcript: transcript.transcriptId || '',
    Notes: transcript.comment || '',
    Abundance: transcript.abundance ?? '',
    'Target Regions': transcript.targetRegionQty ?? '',
    Type:
      transcript.type === PanelTranscriptType.primary ? 'MERFISH' : 'sequential'
  }));

  const csv = unparse(data);

  fileDownload(
    csv,
    createGeneListName(isCompleted, serialNumber, panelName),
    'text/csv'
  );
};
