import { FC } from 'react';
import { TranscriptModel } from 'store';
import { Td, Tr, Text, HStack } from '@chakra-ui/react';
import { PanelTranscriptSource } from 'generated/types';

const cellBaseStyles = {
  p: '5px 8px',
  borderBottom: '0.5px solid',
  borderRight: '0.5px solid',
  borderColor: 'printable.additional'
};

interface RowProps {
  transcript: TranscriptModel;
}

export const Row: FC<RowProps> = ({ transcript }) => {
  const source =
    transcript.source === PanelTranscriptSource.P ? 'M' : transcript.source;

  return (
    <>
      <Tr>
        <Td
          w="34px"
          maxW="34px"
          rowSpan={transcript.comment ? 2 : 1}
          textAlign="center"
          {...cellBaseStyles}
          p="0"
        >
          <Text>{source}</Text>
        </Td>
        <Td w="230px" maxW="230px" {...cellBaseStyles}>
          <Text>{transcript.userSpecifiedName}</Text>
        </Td>
        <Td w="150px" maxW="150px" {...cellBaseStyles}>
          <Text>{transcript.geneName}</Text>
        </Td>
        <Td w="110px" maxW="110px" {...cellBaseStyles}>
          <Text textAlign="right">
            {Number(transcript.abundance).toFixed(5)}
          </Text>
        </Td>
        <Td w="110px" maxW="110px" {...cellBaseStyles} borderRight="none">
          <Text textAlign="right">{transcript.targetRegionQty}</Text>
        </Td>
      </Tr>
      {transcript.comment && (
        <Tr>
          <Td colSpan={4} {...cellBaseStyles} borderRight="none">
            <Text>
              <HStack as="span" spacing="8px" align="baseline">
                <Text as="span" color="printable.secondary" fontStyle="italic">
                  Notes
                </Text>
                <Text as="span" color="printable.secondary">
                  {transcript.comment}
                </Text>
              </HStack>
            </Text>
          </Td>
        </Tr>
      )}
    </>
  );
};
