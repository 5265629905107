import { create } from 'zustand';

type FeatureFlagsState = {
  featureFlags: FeatureFlags;
  setFeatureFlags: (flags: FeatureFlags) => void;
  clearState: () => void;
};

export type FeatureFlags = {
  vcd560?: boolean;
  vcd478?: boolean;
};

const initialState = {
  featureFlags: {
    vcd560: false,
    vcd478: false
  }
};

export const useFeatureFlagsStore = create<FeatureFlagsState>()((set, get) => ({
  ...initialState,

  setFeatureFlags: (featureFlags) => {
    set({ featureFlags });
  },

  clearState: () => {
    set({ ...initialState });
  }
}));
