import { getValidationMessage } from '../../utils';
import { FC, ChangeEvent } from 'react';
import {
  Control,
  useController,
  FieldValues,
  FormState
} from 'react-hook-form';
import { InputField } from '@vizgen/vizgen-ui';

interface SequenceNameFieldProps {
  control: Control<FieldValues>;
  label: string;
  formState: FormState<FieldValues>;
  isDisabled: boolean;
}

export const SequenceNameField: FC<SequenceNameFieldProps> = ({
  control,
  label,
  formState,
  isDisabled
}) => {
  const {
    field: { ref, onChange, name, value }
  } = useController({
    name: 'name',
    control,
    defaultValue: '',
    rules: { required: true }
  });
  const validation = getValidationMessage('name', formState.errors);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
  };

  return (
    <InputField
      ref={ref}
      name={name}
      label={label}
      value={value}
      onChange={onChangeHandler}
      maxLength={20}
      w="100%"
      controlProps={{
        isInvalid: !!validation,
        d: 'flex',
        flexDir: 'column'
      }}
      errorProps={{ pos: 'relative' }}
      error={validation}
      disabled={isDisabled}
    />
  );
};
