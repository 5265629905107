import { NOTIFICATIONS } from './consts';
import { parse } from 'papaparse';

export const parseIncompatibilitiesFile = async () => {
  const res = await fetch('files/incompatibilities.csv');
  const content = await res.text();
  return parse(content, {
    delimiter: ','
  }).data as string[][];
};

export interface SelectedKit {
  extraId: string;
  extraName: string;
}

type Incompatibilities = {
  [key in string]: string[];
};

export const matchAntibodyKitsCompatibility = (
  selectedKits: SelectedKit[],
  incompatibilityRules: string[][]
) => {
  const incompatibilities: Incompatibilities = {};
  const notificationTypes = new Set<string>();

  selectedKits.forEach(({ extraId, extraName }) => {
    incompatibilityRules.forEach((rules) => {
      if (rules[0] === extraName) {
        for (let i = 1; i < rules.length; i++) {
          const kit = incompatibilityRules[i - 1][0];
          const isKitSelected = selectedKits.find(
            (selectedKit) => selectedKit.extraName === kit
          );
          if (isKitSelected && rules[i]) {
            notificationTypes.add(rules[i]);
            if (incompatibilities[extraId]) {
              incompatibilities[extraId].push(kit);
            } else {
              incompatibilities[extraId] = [kit];
            }
          }
        }
      }
    });
  });

  return {
    notificationTypes: Array.from(notificationTypes),
    incompatibilities
  };
};

export const getAntibodyIncompatibilityNotifications = (
  selectedKits: SelectedKit[],
  data: {
    notificationTypes: string[];
    incompatibilities: Incompatibilities;
  }
) => {
  const { incompatibilities, notificationTypes } = data;
  const cellBoundaryStainKitId = selectedKits.find(
    (kit) => kit.extraName === 'MERSCOPE Cell Boundary Staining Kit'
  )?.extraId;

  return {
    antibodyKitsNotifications: NOTIFICATIONS.map(
      ({ message, incompatibilityType, notificationType }) => ({
        message,
        type: notificationType,
        isVisible: notificationTypes.includes(incompatibilityType)
      })
    ),
    antibodyKitsExtras: Object.keys(incompatibilities).map((extraId) => {
      const kits = incompatibilities[extraId];
      const kitType =
        cellBoundaryStainKitId === extraId
          ? 'cell boundary staining'
          : 'protein stain';
      return {
        extraId,
        message: `This ${kitType} kit is not compatible with ${kits.join(', ')}`
      };
    })
  };
};

export const getVerb = (amount: number) => {
  return amount > 1 ? 'are' : 'is';
};
