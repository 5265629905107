import {
  getListPanelTranscripts,
  getPanel,
  getPromotedPanel,
  getListPromotedPanelTranscripts
} from 'api';
import { Panel, PanelOwnershipType } from 'generated/types';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TranscriptModel } from 'store';

interface PrintablePanelData {
  panel: Panel;
  transcripts: TranscriptModel[];
  isLoading: boolean;
  hasError: boolean;
  error: any;
}

export const usePrintablePanelData = () => {
  const { panelId = '', panelType = '' } = useParams();
  const [data, setData] = useState<PrintablePanelData>({
    panel: {} as Panel,
    transcripts: [],
    isLoading: true,
    hasError: false,
    error: undefined
  });

  useEffect(() => {
    (async () => {
      try {
        if (panelType === PanelOwnershipType.proprietary) {
          const res = await Promise.all([
            getPanel(panelId),
            getListPanelTranscripts(panelId)
          ]);
          setData((prev) => ({
            ...prev,
            panel: res[0] as Panel,
            transcripts: res[1],
            isLoading: false,
            error: undefined
          }));
        } else {
          const res = await Promise.all([
            getPromotedPanel(panelId),
            getListPromotedPanelTranscripts({ promotedPanelId: panelId })
          ]);
          setData((prev) => ({
            ...prev,
            panel: res[0] as Panel,
            transcripts: res[1] as TranscriptModel[],
            isLoading: false,
            error: undefined
          }));
        }
      } catch (e) {
        setData((prev) => ({
          ...prev,
          isLoading: false,
          hasError: true,
          error: e
        }));
      }
    })();
  }, [panelId, panelType]);

  return data;
};
