import { Category, Resource } from 'models';
import { Paths, ResourceType } from 'routes';
import { generatePath } from 'react-router';

export const getUrl = (resourceType: ResourceType): string =>
  generatePath(Paths.ResourcesByType, { resourceType });

interface ResourcesVisibility {
  isSoftwareVisible: boolean;
  isGuidesVisible: boolean;
  isNotesVisible: boolean;
  isSafetyVisible: boolean;
  isNotificationsVisible: boolean;
}

export const getTabs = (visibility: ResourcesVisibility) => {
  const {
    isSafetyVisible,
    isNotesVisible,
    isGuidesVisible,
    isSoftwareVisible,
    isNotificationsVisible
  } = visibility;

  return [
    ...(isSoftwareVisible ? [ResourceType.Software] : []),
    ...(isGuidesVisible ? [ResourceType.UserGuides] : []),
    ...(isNotesVisible ? [ResourceType.ApplicationAndTechnicalNotes] : []),
    ...(isSafetyVisible ? [ResourceType.SafetyDataSheets] : []),
    ...(isNotificationsVisible ? [ResourceType.ProductNotifications] : [])
  ];
};

const filterByCategory = (resources: Resource[], category: Category) =>
  resources.filter((resource) => resource.category === category);

export const selectResources = (resources: Resource[]) => {
  return {
    software: filterByCategory(resources, Category.Software),
    guides: filterByCategory(resources, Category.UserGuides),
    notes: filterByCategory(resources, Category.ApplicationAndTechnicalNotes),
    safety: filterByCategory(resources, Category.SafetyDataSheets),
    notifications: filterByCategory(resources, Category.ProductNotifications)
  };
};
