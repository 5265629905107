import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  HStack,
  Link,
  Box
} from '@chakra-ui/react';
import { useFailureNotification } from '@vizgen/vizgen-ui';
import { Resource } from 'models';
import { FC } from 'react';
import { downloadS3File, formatErrorMessages, formatShortDate } from 'utils';

interface SoftwareTableProps {
  resource: Resource;
}

export const SoftwareTable: FC<SoftwareTableProps> = ({ resource }) => {
  const {
    resourceDate,
    version,
    path: { macOsInstaller, winInstaller }
  } = resource;
  const { showFailureNotification, closeFailureNotifications } =
    useFailureNotification();

  const downloadFile = async (path: string) => {
    closeFailureNotifications();
    try {
      await downloadS3File(path);
    } catch (e: any) {
      showFailureNotification(formatErrorMessages(e));
    }
  };

  return (
    <Box minW="583px">
      <Table size="sm" variant="unstyled" w="100%" mr="auto">
        <Thead>
          <Tr color="content.additional">
            <Th p="0 80px 8px 0">Version</Th>
            <Th p="0 108px 8px 0">Released</Th>
            <Th p="0 0 8px 0">Download</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td p="0">{version}</Td>
            <Td p="0">{formatShortDate(resourceDate)}</Td>
            <Td p="0">
              <HStack spacing="49px">
                {winInstaller && (
                  <Link
                    as="button"
                    variant="primary"
                    lineHeight="20px"
                    onClick={() => downloadFile(winInstaller)}
                  >
                    Windows
                  </Link>
                )}
                {macOsInstaller && (
                  <Link
                    as="button"
                    variant="primary"
                    lineHeight="20px"
                    onClick={() => downloadFile(macOsInstaller)}
                  >
                    macOS
                  </Link>
                )}
              </HStack>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};
