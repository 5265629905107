import { colors } from '../colors';

export const Tooltip = {
  baseStyle: {
    '--tooltip-bg': colors.background.additional,
    bg: 'background.additional',
    borderRadius: '8px',
    '--popper-arrow-bg': colors.background.additional,
    filter: 'drop-shadow(0px 8px 24px rgba(215, 217, 220, 0.4))',
    color: 'content.tertiary',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    boxShadow: 'none',
    padding: '12px'
  },
  sizes: {
    lg: {
      maxW: '360px'
    }
  },
  defaultProps: {
    size: 'lg'
  }
};
