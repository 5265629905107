import { Box, Button, ButtonProps, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { ChevronBigDown } from 'assets';

interface GeneSetLibraryExpandButtonProps {
  title: string;
  isExpanded: boolean;
  isDark?: boolean;
  buttonProps?: ButtonProps;
  subtitle?: string;
  onExpand: (id: string) => void;
}

export const GeneSetLibraryExpandButton: FC<
  GeneSetLibraryExpandButtonProps
> = ({ title, isExpanded, isDark, buttonProps, subtitle, onExpand }) => {
  const props: ButtonProps = isDark
    ? {
        bg: 'background.additional2',
        _hover: {
          bg: 'background.tertiary'
        },
        _active: {
          bg: 'background.tertiary'
        }
      }
    : {
        bg: 'background.primary',
        _hover: {
          bg: 'background.highlight'
        },
        _active: {
          bg: 'background.highlight'
        },
        p: '0px 16px 0px 24px'
      };

  return (
    <Button
      borderRadius="0"
      w="100%"
      variant="ghost"
      minH="48px"
      _focus={{
        boxShadow: 'none'
      }}
      onClick={() => onExpand(isExpanded ? '' : title)}
      data-testid={title}
      {...props}
      {...buttonProps}
    >
      <HStack spacing="16px" w="100%" align="center">
        <Box
          as="span"
          transition="all 0.2s ease"
          transform={isExpanded ? 'rotate(180deg)' : undefined}
        >
          <ChevronBigDown />
        </Box>
        <Text textAlign="left" whiteSpace="break-spaces">
          <b>{title}</b>
          {subtitle ? ` (${subtitle})` : ''}
        </Text>
      </HStack>
    </Button>
  );
};
