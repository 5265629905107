import { LibraryCategory } from 'store';

export const calculateSelectedSets = (libraryCategories: LibraryCategory[]) => {
  let sets = 0;
  let libraries = 0;
  for (let i = 0; i < libraryCategories.length; i++) {
    for (let j = 0; j < libraryCategories[i].children.length; j++) {
      let selectedGeneSets = 0;
      const length = libraryCategories[i].children[j].children.length;
      for (let k = 0; k < length; k++) {
        if (libraryCategories[i].children[j].children[k].isChecked) {
          selectedGeneSets++;
        }
      }
      if (selectedGeneSets) {
        libraries++;
        sets += selectedGeneSets;
      }
    }
  }
  return { sets, libraries };
};
