import { Panel } from 'generated/types';
import { updatePanel } from 'api';
import { useMutation, useQueryClient } from 'react-query';
import { CUSTOM_PANELS_LIST_QUERY_KEY } from './useCustomPanelsListQuery';

export const useUpdatePanelMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(updatePanel, {
    onSuccess: (_, variables) => {
      const current =
        queryClient.getQueryData<Panel[]>(CUSTOM_PANELS_LIST_QUERY_KEY) || [];
      const updated = current.filter(
        (panel) => panel.panelId !== variables.panelId
      );
      queryClient.setQueryData(CUSTOM_PANELS_LIST_QUERY_KEY, updated);
    }
  });
};
