import { MotionStyle } from 'framer-motion';
import { CSSProperties, FC, useEffect, useState } from 'react';
import { ClustergrammerData } from 'utils/geneset/types';
import { Tooltips } from './components';

type ClustergrammerProps = {
  data: ClustergrammerData;
  clustergrammerStyles: CSSProperties;
  annotationPanelStyles?: MotionStyle;
  selectedGene?: string;
};

const CONTAINER_ID = 'clust_container';

export const Clustergrammer: FC<ClustergrammerProps> = ({
  data,
  clustergrammerStyles,
  annotationPanelStyles,
  selectedGene
}) => {
  const [clust, setClust] = useState<any>();
  const [clustRect, setClustRect] = useState<DOMRect>();

  useEffect(() => {
    import('clustergrammer-lite').then(({ default: Clust }) => {
      // See all params here:
      // https://clustergrammer.readthedocs.io/clustergrammer_js.html#clustergrammer-js-api
      const cgm = new Clust({
        root: `#${CONTAINER_ID}`,
        network_data: data,
        tile_colors: ['#2F80ED', '#2F80ED']
      });
      if (selectedGene) {
        // Doesn't highlight without timeout
        window.setTimeout(() => {
          cgm.search_row(selectedGene);
        }, 1000);
      }

      setClust(cgm);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!clust) return;
    const onResize = () => {
      setTimeout(() => {
        clust.resize_viz();
        setClustRect(
          document.getElementById(CONTAINER_ID)?.getBoundingClientRect()
        );
      }, 50);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [clust]);

  return (
    <>
      <div id={CONTAINER_ID} style={clustergrammerStyles} />
      <Tooltips
        clustRect={clustRect}
        annotationPanelStyles={annotationPanelStyles}
      />
    </>
  );
};
