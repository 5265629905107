import { FC } from 'react';
import { Box, Container, Heading, Flex, VStack, Text } from '@chakra-ui/react';
import { ContactInfo } from './components';

export const ContactUs: FC = () => {
  return (
    <Box h="100%" overflow="hidden">
      <Flex p="40px 60px 104px 60px" h="100%" pos="relative">
        <Container maxW="1128px" w="100%" m="auto" h="100%" p="0">
          <VStack justify="space-between" h="100%" w="100%" alignItems="start">
            <VStack spacing="32px" alignItems="start" w="100%">
              <Heading variant="headline">Contact Us</Heading>
              <ContactInfo />
            </VStack>
          </VStack>
        </Container>
        <Text
          color="content.primary"
          pos="absolute"
          bottom="80px"
          left="50%"
          transform="translateX(-50%)"
        >
          Release version: {(window as any).version}
        </Text>
      </Flex>
    </Box>
  );
};
