import { PanelComposition } from 'components';
import { Box, Center, Text } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { identifiedTranscriptsSelector, useCreatePanelStore } from 'store';
import { Scrollbar } from '@vizgen/vizgen-ui';

const Component: FC = () => {
  const transcriptsAmount = useCreatePanelStore(
    identifiedTranscriptsSelector
  ).length;
  const panel = useCreatePanelStore((state) => state.panel);
  const transcripts = useCreatePanelStore((state) => state.transcripts);

  return (
    <Scrollbar style={{ paddingRight: '60px' }}>
      <Box maxW="840px" mr="60px" pb="40px" pt="16px" pos="relative">
        {transcriptsAmount ? (
          <PanelComposition
            transcriptomeId={panel?.transcriptomeId || ''}
            clustergrammerStyles={{
              width: '100%',
              height: '600px'
            }}
            annotationPanelStyles={{
              bottom: '71px'
            }}
            transcripts={transcripts}
          />
        ) : (
          <Center mt="24px" bg="background.secondary">
            <Text my="40px" variant="accentBase">
              Please add genes to your panel to get started with Panel
              Composition View
            </Text>
          </Center>
        )}
      </Box>
    </Scrollbar>
  );
};

export const PanelCompositionTab = memo(Component);
