import { FC } from 'react';
import { usePanelCompositionStore } from 'store';
import {
  VStack,
  IconButton,
  Slide,
  Flex,
  Text,
  HStack,
  StackDivider,
  Box,
  useDisclosure,
  Collapse
} from '@chakra-ui/react';
import { CloseIcon, InfoIcon } from './assets';
import { CollapseButton, GeneInfo, GeneList, GeneSetInfo } from './components';
import { MotionStyle } from 'framer-motion';

interface AnnotationPanelProps {
  clustRect?: DOMRect;
  annotationPanelStyles?: MotionStyle;
}

const LEGEND_WIDTH = 16;

export const AnnotationPanel: FC<AnnotationPanelProps> = ({
  clustRect,
  annotationPanelStyles
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const selectedItem = usePanelCompositionStore((state) => state.selectedItem);
  const setSelectedItem = usePanelCompositionStore(
    (state) => state.setSelectedItem
  );

  const clustWidth = clustRect?.width || 0;
  const hasGene = !!selectedItem?.gene;
  const title = `${hasGene ? 'Gene / ' : ''}Gene Set information`;

  return (
    <Slide
      data-testid="annotationPanelSlider"
      direction="bottom"
      in={!!selectedItem}
      style={{
        ...(selectedItem ? annotationPanelStyles : {}),
        left: (clustRect?.left || 0) - LEGEND_WIDTH,
        zIndex: 10
      }}
    >
      <Box w={clustWidth + LEGEND_WIDTH}>
        <Flex
          direction="column"
          p="16px 24px 24px 24px"
          bg="background.secondary"
          maxW="1128px"
          w="100%"
          borderTopLeftRadius="4px"
          borderTopRightRadius="4px"
        >
          <Flex w="100%" justify="space-between">
            <HStack spacing="8px">
              <InfoIcon />
              <Text fontWeight="heavy">{title}</Text>
            </HStack>
            <IconButton
              onClick={() => {
                setSelectedItem();
                onClose();
              }}
              variant="primaryGhost"
              size="icon"
              aria-label="Close modal"
              data-testid="closePanelButton"
              icon={<CloseIcon />}
            />
          </Flex>
          <VStack
            mt="16px"
            w="100%"
            align="start"
            spacing="16px"
            divider={<StackDivider borderColor="border.divider" />}
          >
            {hasGene && <GeneInfo />}
            <Flex w="100%" align="end">
              <GeneSetInfo />
              <Box ml="14px" minW="104px">
                {isOpen || (
                  <CollapseButton onClick={onOpen} isExpanded={isOpen} />
                )}
              </Box>
            </Flex>
          </VStack>
          <Collapse in={isOpen} animateOpacity unmountOnExit>
            <Box maxH="336px" overflowY="auto" overflowX="hidden" mt="22px">
              <GeneList />
            </Box>
            <Flex p="16px 3px 3px 0" justify="end">
              <CollapseButton onClick={onClose} isExpanded={isOpen} />
            </Flex>
          </Collapse>
        </Flex>
      </Box>
    </Slide>
  );
};
