import { FC, useState, useEffect, useMemo } from 'react';
import {
  Tabs,
  TabList,
  HStack,
  TabPanels,
  Box,
  Flex,
  TabPanel,
  TabPanelProps
} from '@chakra-ui/react';
import { ResourceType } from 'routes';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { ResourcesList } from './components';
import { Resource, Category } from 'models';
import { useFilteredResources } from './useFilteredResources';
import { CATEGORY_LABELS } from 'consts';
import { CustomTab, Tab } from '@vizgen/vizgen-ui';
import { getTabs, getUrl, selectResources } from './utils';

const tabPanelStyles: TabPanelProps = {
  p: '0',
  h: '100%',
  d: 'flex',
  flexDirection: 'column',
  overflowY: 'hidden',
  w: '100%'
};

interface ResourcesTabsProps {
  resources: Resource[];
  searchValue: string;
  dropdownValue: string;
}

export const ResourcesTabs: FC<ResourcesTabsProps> = ({
  resources,
  searchValue,
  dropdownValue
}) => {
  const navigate = useNavigate();
  const { resourceType } = useParams<{ resourceType: ResourceType }>();
  const [tabIndex, setTabIndex] = useState(0);
  const { filteredResources, isFiltered } = useFilteredResources(
    resources,
    searchValue,
    dropdownValue
  );
  const { software, notes, guides, safety, notifications } =
    selectResources(resources);
  const filtered = selectResources(filteredResources);
  const isSafetyVisible = !!software.length;
  const isNotesVisible = !!notes.length;
  const isGuidesVisible = !!guides.length;
  const isSoftwareVisible = !!safety.length;
  const isNotificationsVisible = !!notifications.length;
  const tabs = useMemo(
    () =>
      getTabs({
        isSafetyVisible,
        isNotesVisible,
        isGuidesVisible,
        isSoftwareVisible,
        isNotificationsVisible
      }),
    [
      isGuidesVisible,
      isNotesVisible,
      isNotificationsVisible,
      isSafetyVisible,
      isSoftwareVisible
    ]
  );

  useEffect(() => {
    if (tabs.length) {
      const foundIndex = tabs.indexOf(resourceType!);
      const tabIndex = foundIndex === -1 ? 0 : foundIndex;
      setTabIndex(tabIndex);
      navigate(getUrl(tabs[tabIndex]));
    }
  }, [tabs, resourceType, navigate]);

  return (
    <Box h="100%">
      <Tabs
        isLazy
        pt="32px"
        variant="unstyled"
        w="100%"
        h="100%"
        bg="background.primary"
        d="flex"
        flexDirection="column"
        pos="relative"
        alignItems="center"
        index={tabIndex}
        onChange={setTabIndex}
      >
        <Flex w="100%" justify="center">
          <TabList pos="relative" maxW="1148px" w="100%" h="48px">
            <HStack spacing="24px" padding="3px" overflow="auto" h="54px">
              {isSoftwareVisible && (
                <CustomTab
                  as={RouterLink}
                  to={getUrl(ResourceType.Software)}
                  data-testid="softwareLink"
                  renderButtonContent={(isActive) => (
                    <Tab
                      count={filtered.software.length}
                      isActive={isActive || isFiltered}
                    >
                      {CATEGORY_LABELS[Category.Software]}
                    </Tab>
                  )}
                />
              )}
              {isGuidesVisible && (
                <CustomTab
                  as={RouterLink}
                  to={getUrl(ResourceType.UserGuides)}
                  data-testid="userGuidesLink"
                  renderButtonContent={(isActive) => (
                    <Tab
                      count={filtered.guides.length}
                      isActive={isActive || isFiltered}
                    >
                      {CATEGORY_LABELS[Category.UserGuides]}
                    </Tab>
                  )}
                />
              )}
              {isNotesVisible && (
                <CustomTab
                  as={RouterLink}
                  to={getUrl(ResourceType.ApplicationAndTechnicalNotes)}
                  data-testid="applicationAndTechnicalNotesLink"
                  renderButtonContent={(isActive) => (
                    <Tab
                      count={filtered.notes.length}
                      isActive={isActive || isFiltered}
                    >
                      {CATEGORY_LABELS[Category.ApplicationAndTechnicalNotes]}
                    </Tab>
                  )}
                />
              )}
              {isSafetyVisible && (
                <CustomTab
                  as={RouterLink}
                  to={getUrl(ResourceType.SafetyDataSheets)}
                  data-testid="safetyDataSheetsLink"
                  renderButtonContent={(isActive) => (
                    <Tab
                      count={filtered.safety.length}
                      isActive={isActive || isFiltered}
                    >
                      {CATEGORY_LABELS[Category.SafetyDataSheets]}
                    </Tab>
                  )}
                />
              )}
              {isNotificationsVisible && (
                <CustomTab
                  as={RouterLink}
                  to={getUrl(ResourceType.ProductNotifications)}
                  data-testid="productNotificationsLink"
                  renderButtonContent={(isActive) => (
                    <Tab
                      count={filtered.notifications.length}
                      isActive={isActive || isFiltered}
                    >
                      {CATEGORY_LABELS[Category.ProductNotifications]}
                    </Tab>
                  )}
                />
              )}
            </HStack>
          </TabList>
        </Flex>
        <TabPanels
          pt="44px"
          px="0"
          h="100%"
          d="flex"
          flexDirection="column"
          overflowY="hidden"
          alignItems="center"
        >
          {isSoftwareVisible && (
            <TabPanel {...tabPanelStyles}>
              <ResourcesList
                resources={filtered.software}
                category={Category.Software}
              />
            </TabPanel>
          )}
          {isGuidesVisible && (
            <TabPanel {...tabPanelStyles}>
              <ResourcesList
                resources={filtered.guides}
                category={Category.UserGuides}
              />
            </TabPanel>
          )}
          {isNotesVisible && (
            <TabPanel {...tabPanelStyles}>
              <ResourcesList
                resources={filtered.notes}
                category={Category.ApplicationAndTechnicalNotes}
              />
            </TabPanel>
          )}
          {isSafetyVisible && (
            <TabPanel {...tabPanelStyles}>
              <ResourcesList
                resources={filtered.safety}
                category={Category.SafetyDataSheets}
              />
            </TabPanel>
          )}
          {isNotificationsVisible && (
            <TabPanel {...tabPanelStyles}>
              <ResourcesList
                resources={filtered.notifications}
                category={Category.ProductNotifications}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
};
