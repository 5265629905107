import { FC, useEffect, useState } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { AnchorLink } from './components';

const titles = [
  'Background',
  'License and Restrictions',
  'Confidentiality',
  'Customer Representations and Warranties',
  'Disclaimer of Vizgen Representations and Warranties',
  'Limits of Liability',
  'General'
];

interface NavigationProps {
  termsOfUse: string;
}

export const Navigation: FC<NavigationProps> = ({ termsOfUse }) => {
  const [activeAnchor, setActiveAnchor] = useState(
    window.location.hash || '#1'
  );

  useEffect(() => {
    const { hash } = window.location;
    if (hash && termsOfUse) {
      const id = hash.split('#')[1];
      scrollTo(id);
    }
  }, [termsOfUse]);

  useEffect(() => {
    return () => {
      window.history.replaceState('', document.title, window.location.pathname);
    };
  }, []);

  const onAnchorClick = (anchorId: string) => {
    window.history.pushState('', document.title, anchorId);
    const id = anchorId.split('#')[1];
    setActiveAnchor(anchorId);
    scrollTo(id);
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box w="100%" maxW="320px" mt="16px" pl="60px" pt="40px">
      <VStack spacing="24px" align="end">
        {titles.map((title, index) => (
          <AnchorLink
            key={title}
            id={`#${index + 1}`}
            text={title}
            onClick={onAnchorClick}
            isActive={activeAnchor === `#${index + 1}`}
          />
        ))}
      </VStack>
    </Box>
  );
};
