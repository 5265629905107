import { ReactNode } from 'react';
import { forwardRef, useTab, TabProps, chakra, Box } from '@chakra-ui/react';
import { MenuButton } from '../MenuButton';

const StyledTab = chakra('button');

interface CustomTabProps extends TabProps {
  renderButtonContent?: (isActive: boolean) => ReactNode;
}

export const CustomTab = forwardRef<CustomTabProps, 'div'>(
  ({ renderButtonContent, children, ...rest }, ref) => {
    const tabProps = useTab(rest);
    const isActive = !!tabProps['aria-selected'];

    return (
      <StyledTab
        {...tabProps}
        __css={{
          _focus: {
            boxShadow: 'outline',
            outline: 'none',
            borderRadius: '4px',
            transition: 'all 250ms ease'
          }
        }}
      >
        <MenuButton ref={ref} as={Box} p="12px 16px" isActive={isActive}>
          {renderButtonContent ? renderButtonContent(isActive) : children}
        </MenuButton>
      </StyledTab>
    );
  }
);
