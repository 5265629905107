import { Button, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { useUserInstrumentNotificationsQuery } from 'api/hooks';
import { FC } from 'react';
import { NotificationsSettingsModal } from './components';
import { useSelectedNotifications } from './useSelectedNotifications';

interface NotificationsProps {
  instrumentUniqueId: string;
}

export const Notifications: FC<NotificationsProps> = ({
  instrumentUniqueId
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data = [], isFetched } =
    useUserInstrumentNotificationsQuery(instrumentUniqueId);
  const notifications = useSelectedNotifications(data, isFetched);

  return (
    <>
      <HStack spacing="8px" w="100%">
        <Text w="100%" variant="bodyShort">
          {notifications}
        </Text>
        <Button
          variant="primaryGhost"
          h="28px"
          px="8px"
          minW="auto"
          onClick={onOpen}
          data-testid="setupNotificationsButton"
          isDisabled={!isFetched}
        >
          Set Up
        </Button>
      </HStack>
      <NotificationsSettingsModal
        instrumentUniqueId={instrumentUniqueId}
        notifications={data}
        isVisible={isOpen}
        onClose={onClose}
      />
    </>
  );
};
