export const NOTIFICATIONS = [
  {
    incompatibilityType: 'A',
    message:
      'The quote request contains protein stain kits and verification kits that do not match',
    notificationType: 'INFO_ProteinStainVerificationStainMismatch'
  },
  {
    incompatibilityType: 'B',
    message:
      'The same species of antibodies cannot be used in the same experiment',
    notificationType: 'INFO_SameAntibodies'
  },
  {
    incompatibilityType: 'C',
    message:
      'Anti-Rabbit secondary stain kits are not compatible with cell boundary staining kit',
    notificationType: 'INFO_SecondaryStainCellBoundaryStainMismatch'
  },
  {
    incompatibilityType: 'E',
    message:
      'Only one ROI (Region of Interest) protein stain can be used per experiment',
    notificationType: 'INFO_ROIAmountExceeded'
  }
];
