import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthFlow, useUserStore } from 'store';

export function useInitialFlow() {
  const location = useLocation();
  const setAuthFlow = useUserStore((state) => state.setAuthFlow);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('signUp')) {
      setAuthFlow(AuthFlow.SignUp);
    } else if (params.get('forgotPassword')) {
      setAuthFlow(AuthFlow.ResetPassword);
    }
  }, [location.search, setAuthFlow]);
}
