import { Flex } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface LoaderProps {
  text: string | ReactNode;
}

export const Loader: FC<LoaderProps> = ({ text }) => {
  return (
    <Flex
      minH="80px"
      background="background.primary"
      h="100%"
      w="100%"
      justify="center"
      align="center"
    >
      {text || 'Loading...'}
    </Flex>
  );
};
