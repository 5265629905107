import { Tooltip } from '@vizgen/vizgen-ui';
import { FC } from 'react';
import { usePanelCompositionStore } from 'store';
import { AnnotationPanel, Label } from './components';
import { useEventListeners } from './hooks';
import { shallow } from 'zustand/shallow';
import { MotionStyle } from 'framer-motion';

interface TooltipsProps {
  clustRect?: DOMRect;
  annotationPanelStyles?: MotionStyle;
}

export const Tooltips: FC<TooltipsProps> = ({
  clustRect,
  annotationPanelStyles
}) => {
  const [column, row, tile] = usePanelCompositionStore(
    (state) => [state.hoveredColumn, state.hoveredRow, state.hoveredTile],
    shallow
  );
  useEventListeners();

  return (
    <>
      {row?.name && (
        <Tooltip label={row?.name} placement="right" hasArrow isOpen>
          <div
            style={{
              position: 'fixed',
              top: row?.top,
              left: row?.left
            }}
          />
        </Tooltip>
      )}
      {column?.name && (
        <Tooltip label={column?.name} placement="bottom" hasArrow isOpen>
          <div
            style={{
              position: 'fixed',
              top: column?.top,
              left: column?.left
            }}
          />
        </Tooltip>
      )}
      {tile?.gene && (
        <Tooltip
          maxW="330px"
          label={<Label />}
          placement="bottom"
          hasArrow
          isOpen
        >
          <div
            style={{
              visibility: 'hidden',
              position: 'fixed',
              top: tile?.top,
              left: tile?.left,
              width: tile?.width,
              height: tile?.height
            }}
          />
        </Tooltip>
      )}
      <AnnotationPanel
        clustRect={clustRect}
        annotationPanelStyles={annotationPanelStyles}
      />
    </>
  );
};
