import {
  Input,
  InputProps,
  InputGroup,
  InputRightElement,
  FormControlProps,
  FormErrorMessageProps,
  forwardRef,
  InputElementProps
} from '@chakra-ui/react';
import { BaseField } from '../BaseField';

export interface InputFieldProps extends InputProps {
  name: string;
  placeholder?: string;
  label?: string | JSX.Element;
  error?: string;
  controlProps?: FormControlProps;
  errorProps?: FormErrorMessageProps;
  rightElement?: JSX.Element;
  rightElementProps?: InputElementProps;
}

export const InputField = forwardRef<InputFieldProps, 'input'>((props, ref) => {
  const {
    controlProps,
    errorProps,
    label,
    error,
    rightElement,
    rightElementProps = {},
    ...rest
  } = props;

  return (
    <BaseField
      controlProps={controlProps}
      errorProps={errorProps}
      id={rest.name}
      label={label}
      error={error}
    >
      <InputGroup>
        <Input ref={ref} autoComplete="off" {...rest} />
        {rightElement && (
          <InputRightElement {...rightElementProps}>
            {rightElement}
          </InputRightElement>
        )}
      </InputGroup>
    </BaseField>
  );
});
