import { HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { NotFoundIcon } from './assets';

export const NotFoundMessage: FC = ({ children }) => {
  return (
    <HStack spacing="4px" w="100%" justify="center">
      <NotFoundIcon />
      <Text
        variant="accentBodyShort"
        color="content.additional"
        fontWeight="normal"
      >
        {children}
      </Text>
    </HStack>
  );
};
