import { FC, useRef } from 'react';
import { Flex, Heading, HStack, Text } from '@chakra-ui/react';
import { TableInstance, useAsyncDebounce } from 'react-table';
import { GenesTable, Transcript } from '../GenesTable';
import { SearchField } from '../Fields/SearchField';

interface PanelGenesProps {
  isHeadingHidden?: boolean;
  primaryTranscripts: Transcript[];
  sequentialTranscripts: Transcript[];
}

export const PanelGenes: FC<PanelGenesProps> = ({
  isHeadingHidden,
  primaryTranscripts,
  sequentialTranscripts
}) => {
  const primaryTable = useRef<TableInstance<Transcript>>(null);
  const sequentialTable = useRef<TableInstance<Transcript>>(null);
  const primaryTranscriptsAmount = primaryTranscripts.length;
  const sequentialTranscriptsAmount = sequentialTranscripts.length;

  const onSearchChange = useAsyncDebounce((value) => {
    primaryTable.current?.setGlobalFilter(value || undefined);
    sequentialTable.current?.setGlobalFilter(value || undefined);
  }, 200);

  if (!sequentialTranscriptsAmount && !primaryTranscriptsAmount) {
    return null;
  }

  return (
    <Flex w="100%" h="100%" direction="column" alignItems="start">
      <Flex
        justify={isHeadingHidden ? 'start' : 'space-between'}
        alignItems="center"
        w="100%"
        maxW="1188px"
      >
        {isHeadingHidden || (
          <Heading as="h2" variant="subtitle" w="100%">
            <HStack as="span" spacing="8px">
              <Heading as="span" variant="subtitle">
                Genes
              </Heading>
              <Heading as="span" variant="subtitle" color="content.additional">
                ({primaryTranscriptsAmount + sequentialTranscriptsAmount})
              </Heading>
            </HStack>
          </Heading>
        )}
        <SearchField
          name="geneSearch"
          isLeftElementShown={false}
          placeholder="Search gene"
          onChange={(e) => {
            const { value } = e.target;
            onSearchChange(value);
          }}
          controlProps={{
            maxW: '264px'
          }}
        />
      </Flex>
      <GenesTable
        tableRef={primaryTable}
        transcripts={primaryTranscripts}
        isPrimary
        title={
          <Text as="span" variant="captionHigh">
            {`MERFISH Panel (${primaryTranscriptsAmount})`}
          </Text>
        }
      />
      <GenesTable
        tableRef={sequentialTable}
        transcripts={sequentialTranscripts}
        title={
          <Text as="span" variant="captionHigh">
            {`Sequential Panel (${sequentialTranscriptsAmount})`}
          </Text>
        }
      />
    </Flex>
  );
};
