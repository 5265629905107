import { FC } from 'react';
import { Flex, HStack, Link, Text, VStack } from '@chakra-ui/react';
import MultiplicationIcon from './assets/multiplication.svg';
import { Tooltip } from '../Tooltip';
import { fontStyles } from '../../theme/fonts';

interface ExtraSummaryProps {
  description: string;
  name: string;
  quantity: number;
}

export const ExtraSummary: FC<ExtraSummaryProps> = ({
  description,
  name,
  quantity
}) => {
  return (
    <Flex
      justify="space-between"
      minH="56px"
      w="100%"
      p="8px 16px 8px 12px"
      border="1px solid"
      borderRadius="4px"
      borderColor="border.normal"
      data-testid="extraSummary"
    >
      <HStack justify="space-between" w="100%">
        <VStack spacing="4px" alignItems="start">
          <Text variant="accentCaptionHigh">{name}</Text>
          {description && (
            <Tooltip label={description} maxW="180px">
              <Link variant="underline" size="sm">
                Product Details
              </Link>
            </Tooltip>
          )}
        </VStack>
        <HStack spacing="0" alignItems="center">
          <MultiplicationIcon />
          <Text
            color="content.additional"
            fontSize={fontStyles.fontSizes[24]}
            lineHeight={fontStyles.lineHeights[32]}
          >
            {quantity}
          </Text>
        </HStack>
      </HStack>
    </Flex>
  );
};
