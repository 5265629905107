import { Reference } from 'models';
import { useGlobalListsStore } from 'store';
import { Panel } from 'generated/types';

export const usePanelSpecifications = (panel?: Panel) => {
  const tissues = useGlobalListsStore((state) => state.tissues);
  const references = useGlobalListsStore((state) => state.transcriptomes);
  const tissue =
    tissues.find((tissue) => tissue.tissueId === panel?.tissueId)?.name || '';
  const reference =
    (references.find(
      (reference) => reference.transcriptomeId === panel?.transcriptomeId
    )?.name as Reference) || '';

  return { tissue, reference };
};
