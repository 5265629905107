import {
  Container,
  Flex,
  VStack,
  Box,
  Heading,
  Center
} from '@chakra-ui/react';
import { Logo } from './assets';
import { Header, PanelDetails, GenesList } from './components';
import { PanelLoader } from 'components';
import { FC } from 'react';
import { usePrintablePanelData } from './usePrintablePanelData';

export const PrintablePanel: FC = () => {
  const { isLoading, hasError, panel, transcripts } = usePrintablePanelData();

  if (isLoading) {
    return <PanelLoader />;
  }

  if (hasError || !panel) {
    return (
      <Center h="100%" w="100%">
        <Heading color="content.additional">Something went wrong</Heading>
      </Center>
    );
  }

  return (
    <Box className="printable-panel" h="100%" overflowY="auto">
      <Container maxW="700px" w="100%" m="auto" p="16px 32px">
        <VStack spacing="8px" w="100%">
          <Flex justify="flex-end" w="100%">
            <Logo style={{ height: '42px', width: '108px' }} />
          </Flex>
          <Header panel={panel} />
          <PanelDetails panel={panel} />
          <GenesList transcripts={transcripts} />
        </VStack>
      </Container>
    </Box>
  );
};
