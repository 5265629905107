import { useMemo } from 'react';
import { usePanelCompositionStore } from 'store';
import { getSelectedGeneSetsGenes, splitGenes, SplittedGenes } from '../utils';

export const useSplittedTranscripts = (
  selectedGeneSets: Set<string>
): SplittedGenes => {
  const geneSets = usePanelCompositionStore((state) => state.geneSets);
  const transcripts = usePanelCompositionStore((state) => state.transcripts);

  const geneSetsGenes = useMemo(
    () => getSelectedGeneSetsGenes(geneSets, selectedGeneSets),
    [geneSets, selectedGeneSets]
  );

  return useMemo(
    () => splitGenes(transcripts, geneSetsGenes),
    [transcripts, geneSetsGenes]
  );
};
