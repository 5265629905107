import { Text } from '@chakra-ui/react';

export const TooltipLabel = () => {
  return (
    <>
      <Text color="content.ternary" pb="12px" variant="bodyShort">
        Use comas to add notes and abundance to genes:{' '}
        <Text color="inherit" fontWeight="heavy" as="span">
          Gene, Note, Abundance
        </Text>
      </Text>
      <Text variant="captionHigh" color="content.additional" pb="4px">
        For example:
      </Text>
      <Text color="content.ternary" variant="bodyShort">
        A2MHGHH, Outer membrane protein, 100 A2MHGHHJP, Mitochondrial rho GTP,
        125
      </Text>
    </>
  );
};
