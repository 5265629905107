import { FC } from 'react';
import { Link } from '@chakra-ui/react';

interface GeneLinkProps {
  gene: string;
  transcriptId: string;
}

export const GeneLink: FC<GeneLinkProps> = ({ gene, transcriptId }) => {
  return (
    <Link
      href={`https://www.ensembl.org/Multi/Search/Results?q=${transcriptId}`}
      target="_blank"
      variant="underline"
      css={{
        '&:not(:last-of-type)::after': {
          content: '", "',
          marginRight: '4px'
        }
      }}
    >
      {gene}
    </Link>
  );
};
