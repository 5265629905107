import { FC, useEffect } from 'react';
import { ConfirmModal, useFailureNotification } from '@vizgen/vizgen-ui';
import { useUpdatePanelMutation } from 'api/hooks';
import { formatErrorMessages } from 'utils';

interface ConfirmDeletePanelModalProps {
  panelId: string;
  isVisible: boolean;
  onSubmit?: () => void;
  onClose: () => void;
}

const ModalComponent: FC<Omit<ConfirmDeletePanelModalProps, 'isVisible'>> = ({
  panelId,
  onSubmit,
  onClose
}) => {
  const { mutateAsync, isLoading } = useUpdatePanelMutation();
  const { showFailureNotification, closeFailureNotifications } =
    useFailureNotification();

  useEffect(() => {
    closeFailureNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = async () => {
    try {
      await mutateAsync({ panelId, deleted: true });
      onSubmit?.();
    } catch (e) {
      showFailureNotification(formatErrorMessages(e));
    } finally {
      onClose();
    }
  };

  return (
    <ConfirmModal
      isInProgress={isLoading}
      title="Delete panel"
      text="You are about to delete draft panel"
      submitText="Delete panel"
      onClose={onClose}
      onSubmit={onDelete}
    />
  );
};
export const ConfirmDeletePanelModal: FC<ConfirmDeletePanelModalProps> = ({
  isVisible,
  ...rest
}) => {
  if (!isVisible) {
    return null;
  }

  return <ModalComponent {...rest} />;
};
