import { listPanelTranscriptsBySourceQuery } from 'api';
import { PanelTranscriptStatus } from 'generated/types';
import { useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import { useCreatePanelStore } from 'store';

export const useCalculatingTranscriptsPolling = () => {
  const timerId = useRef<any>();
  const transcripts = useCreatePanelStore((state) => state.transcripts);
  const fetchGenes = useCreatePanelStore((state) => state.fetchGenes);
  const { panelId } = useParams();

  useEffect(() => {
    clearInterval(timerId.current);
    const isCalculating = transcripts.some(
      (transcript) => transcript.status === PanelTranscriptStatus.calculating
    );
    if (isCalculating) {
      timerId.current = setInterval(async () => {
        const exogenousTranscripts =
          (
            await listPanelTranscriptsBySourceQuery({
              panelId: panelId!,
              source: { eq: 'E' }
            })
          ).listPanelTranscriptsBySource?.items || [];
        const isCalculatingDone = !exogenousTranscripts.find(
          (transcript) =>
            transcript?.status === PanelTranscriptStatus.calculating
        );
        if (isCalculatingDone) {
          await fetchGenes(panelId!, false);
          clearInterval(timerId.current);
        } else {
          const isSomeoneCalculated = exogenousTranscripts.some(
            (exogenousTranscript) =>
              transcripts.some(
                (transcript) =>
                  transcript.transcriptId ===
                    exogenousTranscript?.transcriptId &&
                  transcript.status !== exogenousTranscript?.status
              )
          );
          if (isSomeoneCalculated) {
            await fetchGenes(panelId!, false);
          }
        }
      }, 10000);
    }

    return () => {
      clearInterval(timerId.current);
    };
  }, [transcripts, panelId, fetchGenes]);
};
