import { Button, Flex, HStack, Stack, Text, Icon } from '@chakra-ui/react';
import { ErrorType } from 'models';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUserStore } from 'store';
import { ErrorIcon } from 'assets';
import { InputPassword } from '@vizgen/vizgen-ui';

interface FormModel {
  oldPassword?: string;
  newPassword?: string;
  repeatPassword?: string;
}

const REQUIRED_FILED = 'This field is required';

interface ChangePasswordFormProps {
  onClose: () => void;
}

export const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
  onClose
}) => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
    setError,
    clearErrors
  } = useForm();
  const [otherError, setOtherError] = useState<ErrorType>();
  const changePassword = useUserStore((state) => state.changePassword);

  const onChange = (fieldName: string) => {
    clearErrors(fieldName);
    setOtherError(undefined);
  };

  const onSubmit = async ({
    oldPassword = '',
    newPassword,
    repeatPassword
  }: FormModel) => {
    if (!oldPassword) {
      setError('oldPassword', {
        message: REQUIRED_FILED
      });
    }
    if (!newPassword) {
      setError('newPassword', {
        message: REQUIRED_FILED
      });
    }
    if (!repeatPassword) {
      setError('repeatPassword', {
        message: REQUIRED_FILED
      });
      return;
    }
    if (newPassword !== repeatPassword) {
      setError('repeatPassword', {
        message: 'Passwords are not the same'
      });
      return;
    }
    if (oldPassword === newPassword && oldPassword === repeatPassword) {
      setError('newPassword', {
        message: 'New password can not be the same as your old password'
      });
      return;
    }
    try {
      await changePassword(oldPassword, newPassword);
      onClose();
    } catch (error: any) {
      if (error.code === 'NotAuthorizedException') {
        setError('oldPassword', {
          message: 'Password is invalid'
        });
      } else {
        setOtherError(error);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100%', maxWidth: '552px' }}
    >
      <Flex
        direction="column"
        bg="background.secondary"
        p="12px 8px 8px"
        boxShadow="0px 4px 8px rgba(215, 217, 220, 0.4)"
        border="1px solid"
        borderColor="border.normal"
        borderRadius="8px"
      >
        <Text pl="12px" variant="accentBase">
          Change Password
        </Text>
        <Stack spacing="24px" mt="20px">
          <InputPassword
            id="oldPassword"
            {...register('oldPassword')}
            onChange={() => onChange('oldPassword')}
            label="Old Password"
            isDisabled={isSubmitting}
            controlProps={{
              isInvalid: errors.oldPassword
            }}
            error={errors.oldPassword?.message}
          />
          <InputPassword
            id="newPassword"
            {...register('newPassword')}
            onChange={() => onChange('newPassword')}
            label="New Password"
            data-testid="newPassword"
            isDisabled={isSubmitting}
            controlProps={{
              isInvalid: errors.newPassword
            }}
            error={errors.newPassword?.message}
          />
          <InputPassword
            id="repeatPassword"
            {...register('repeatPassword')}
            onChange={() => onChange('repeatPassword')}
            label="Repeat new password"
            isDisabled={isSubmitting}
            controlProps={{
              isInvalid: errors.repeatPassword
            }}
            error={errors.repeatPassword?.message}
          />
          {otherError && (
            <HStack
              alignContent="center"
              padding="8px 16px"
              spacing="8px"
              bg="#FDE0DF"
              borderRadius="24px"
              w="100%"
              flex="1"
            >
              <Icon w="32px" h="32px" as={ErrorIcon} />
              <Text p="0" color="state.error">
                {otherError.message}
              </Text>
            </HStack>
          )}
          <HStack justify="flex-end" alignItems="center" spacing="24px">
            <Button
              variant="tinyGhostSecondary"
              maxW="65px"
              minW="65px"
              isDisabled={isSubmitting}
              onClick={onClose}
              data-testid="cancelButton"
            >
              Cancel
            </Button>
            <Button
              variant="secondaryFilled"
              type="submit"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              minW="132px"
              data-testid="submitButton"
            >
              Save
            </Button>
          </HStack>
        </Stack>
      </Flex>
    </form>
  );
};
