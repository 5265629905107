import {
  Box,
  BoxProps,
  FlexProps,
  Table,
  TableCellProps,
  TableColumnHeaderProps,
  Tbody
} from '@chakra-ui/react';
import {
  PropsWithChildren,
  Ref,
  useEffect,
  useImperativeHandle,
  useCallback,
  CSSProperties,
  useRef,
  forwardRef,
  ReactNode,
  ReactElement,
  Fragment,
  useMemo
} from 'react';
import {
  Column,
  Hooks,
  useRowSelect,
  useSortBy,
  useTable,
  useFilters,
  useGlobalFilter,
  useColumnOrder,
  useResizeColumns,
  TableInstance,
  useFlexLayout,
  Row as RowData,
  HeaderProps,
  CellProps,
  PluginHook,
  TableState,
  HeaderGroup,
  Renderer
} from 'react-table';
import {
  TopBar,
  Loader,
  Head,
  Row,
  CheckboxCell,
  TableWrapper
} from './components';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ScrollbarVirtual } from '../ScrollbarVirtual';
import { IndeterminateCheckbox } from '../IndeterminateCheckbox';
import { useFilterTypes } from './useFilterTypes';
import { TableColumnsLabels } from '../../types';
import { getTableState, saveTableState } from './utils';
import { theme } from '../../theme';
const { colors } = theme;

export type CustomColumn<T extends object = {}> = Column<T> & {
  /*
  If the column is not sortable, sorting arrows won't appear,
  hasSortPlugin is required for a table
  */
  isSortable?: boolean;
  /*
  If the column is not resizable, resize cursor won't appear,
  hasResizePlugin is required for a table.
  Won't work with columns with columnMaxW property!
  */
  isResizable?: boolean;
  cellProps?: TableCellProps & { style?: CSSProperties };
  headerCellProps?: TableColumnHeaderProps & { style?: CSSProperties };
  /* 
  Use columnMaxW: N and width: 100% for responsive columns and width: N for fixed columns,
  where N is the width in pixels. Don't mix these approaches in a single table!
  */
  columnMaxW?: number;
  Menu?: Renderer<{}>;
  Icon?: Renderer<{}>;
};

interface TableTemplateProps<T extends {}> {
  columns: CustomColumn<T>[];
  state: T[];
  initialState?: Partial<TableState<T>>;
  // Just for testing
  tableName: string;
  hasColumnOrderPlugin?: boolean;
  hasSortPlugin?: boolean;
  hasSelectPlugin?: boolean;
  hasFilterPlugin?: boolean;
  hasGlobalFilterPlugin?: boolean;
  hasResizePlugin?: boolean;
  /*
  Be careful!
  This is an experimental feature and works for tables without
  vertical scroll
  */
  hasStickyColumns?: boolean;
  hasHeader?: boolean;
  hasSelectAll?: boolean;
  maxHeight?: number;
  // Text about [single, multiple] selected rows and optional buttons with actions
  selectedRowsInfo?: {
    placeholder?: ReactNode;
    buttons?: ReactNode;
    text: [string?, string?];
  };
  // Props to show the loader for table
  loadingProps?: {
    isVisible: boolean;
    text: string | ReactNode;
  };
  title?: ReactNode | string;
  subtitle?: ReactNode | string;
  // The custom row template, if common solutions cannot cover all cases
  renderRow?: (
    row: RowData<T>,
    style: CSSProperties,
    hasSelectPlugin: boolean
  ) => ReactElement;
  renderHead?: (props: {
    headerGroups: HeaderGroup<T>[];
    hasSortPlugin: boolean;
    hasResizePlugin: boolean;
    resetToggledRow: () => void;
  }) => ReactElement;
  wrapperProps?: BoxProps;
  topBarProps?: FlexProps;
  // Content that is showed instead of table if there are no loader or rows
  placeholder?: string | ReactNode;
  // If you need to work with selected rows on higher level, it can be useful(hasSelectPlugin required)
  selectedRowsCallback?: (rows: T[]) => void;
  filteredRowsCallback?: (rows: T[]) => void;
  isTopbarHidden?: boolean;
  isRadioSelect?: boolean;
  manageColumnsProps?: {
    columnLabels?: TableColumnsLabels;
    /*
    Allows to hide columns only at the beginning of the table 
    or the end, hiding in the middle doesn't make sense
    */
    hiddenColumns?: string[];
    /*
    Allows to disable changing columns visibility
    */
    disabledColumns?: string[];
  };
  getIsRowCheckboxDisabled?: (cell: CellProps<T>) => boolean;
  getRowCheckboxStyles?: (cell: CellProps<T>) => CSSProperties;
  tableStateKey?: string;
}

export const TableTemplate = forwardRef(
  <T extends {}>(
    {
      columns,
      state,
      initialState,
      hasHeader = true,
      tableName,
      placeholder,
      hasColumnOrderPlugin = false,
      hasSortPlugin = false,
      hasSelectPlugin = false,
      hasFilterPlugin = false,
      hasGlobalFilterPlugin = false,
      hasResizePlugin = false,
      hasSelectAll = false,
      hasStickyColumns = false,
      maxHeight,
      title = null,
      subtitle = null,
      loadingProps = { isVisible: false, text: null },
      selectedRowsInfo = { text: [] },
      renderRow,
      renderHead,
      wrapperProps = {},
      topBarProps = {},
      selectedRowsCallback,
      filteredRowsCallback,
      isTopbarHidden,
      isRadioSelect,
      manageColumnsProps = {},
      getIsRowCheckboxDisabled = () => false,
      getRowCheckboxStyles = () => ({}),
      tableStateKey
    }: PropsWithChildren<TableTemplateProps<T>>,
    ref: Ref<TableInstance<T>>
  ) => {
    const prevRowIdRef = useRef<string>();
    const filterTypes = useFilterTypes();
    const selectionStyles = hasStickyColumns
      ? { position: 'sticky', left: 0, zIndex: 11 }
      : {};
    const isResizable = useMemo(
      () => columns.some((column) => column.columnMaxW),
      [columns]
    );

    const getPluginHooks = () => {
      // Flex layout is required for react-window. Otherwise, the whole table will broke
      const hooks: PluginHook<T>[] = [useFlexLayout];
      // The order should be straight. The rules of plugin have such order.
      if (hasColumnOrderPlugin) {
        hooks.push(useColumnOrder);
      }
      if (hasResizePlugin) {
        hooks.push(useResizeColumns);
      }
      if (hasGlobalFilterPlugin) {
        hooks.push(useGlobalFilter);
      }
      if (hasFilterPlugin) {
        hooks.push(useFilters);
      }
      if (hasSortPlugin) {
        hooks.push(useSortBy);
      }
      if (hasSelectPlugin) {
        hooks.push(useRowSelect);
      }
      return hooks;
    };

    const toggleRow = (rowId?: string) => {
      prevRowIdRef.current = rowId;
    };

    const instance = useTable<T>(
      {
        columns,
        initialState: {
          ...initialState,
          ...(tableStateKey ? getTableState(tableStateKey) : {})
        },
        data: state,
        filterTypes,
        autoResetSortBy: false,
        autoResetFilters: false,
        autoResetResize: false,
        autoResetGlobalFilter: false,
        autoResetRowState: false,
        autoResetHiddenColumns: false
      },
      ...getPluginHooks(),
      (hooks: Hooks<T>) => {
        hooks.allColumns.push((columns) => [
          ...(hasSelectPlugin
            ? [
                {
                  id: 'selection',
                  Header: hasSelectAll
                    ? ({ getToggleAllRowsSelectedProps }: HeaderProps<T>) => (
                        <IndeterminateCheckbox
                          {...getToggleAllRowsSelectedProps()}
                        />
                      )
                    : '',
                  width: 32,
                  cellProps: { p: 0, style: selectionStyles },
                  headerCellProps: { p: 0, style: selectionStyles },
                  Cell: (cell: CellProps<T>) => (
                    <CheckboxCell
                      row={cell.row}
                      rows={rows}
                      prevRowId={prevRowIdRef.current}
                      setPrevRowId={toggleRow}
                      toggleRow={toggleRowSelected}
                      rowCheckboxStyles={getRowCheckboxStyles(cell)}
                      isDisabled={getIsRowCheckboxDisabled(cell)}
                    />
                  )
                },
                ...columns
              ]
            : columns)
        ]);
      }
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
      toggleAllRowsSelected,
      toggleRowSelected,
      filteredFlatRows,
      state: tableState
    } = instance;
    const headProps = {
      headerGroups,
      hasSortPlugin,
      hasResizePlugin,
      resetToggledRow: toggleRow
    };

    useEffect(() => {
      tableStateKey && saveTableState(tableStateKey, tableState);
    }, [tableStateKey, tableState]);

    const RenderRow = useCallback(
      ({ index, style }: ListChildComponentProps) => {
        const row = rows[index];
        if (row) {
          prepareRow(row);
          if (renderRow) {
            return renderRow(row, style, hasSelectPlugin);
          }
          return (
            <Row<T>
              row={row}
              style={style}
              hasSelectPlugin={hasSelectPlugin}
              toggleAllRowsSelected={
                isRadioSelect ? toggleAllRowsSelected : undefined
              }
            />
          );
        }
        return null;
      },
      // eslint-disable-next-line
      [
        prepareRow,
        renderRow,
        rows,
        hasSelectPlugin,
        tableState.columnResizing,
        tableState.selectedRowIds
      ]
    );

    useEffect(() => {
      if (selectedRowsCallback && hasSelectPlugin) {
        const selectedRows = selectedFlatRows.map((d) => d.original);
        selectedRowsCallback(selectedRows);
      }
      // eslint-disable-next-line
    }, [selectedFlatRows, hasSelectPlugin]);

    useEffect(() => {
      if (filteredRowsCallback) {
        const filteredRows = filteredFlatRows.map((d) => d.original);
        filteredRowsCallback(filteredRows);
      }
      // eslint-disable-next-line
    }, [filteredFlatRows]);

    useImperativeHandle(ref, () => instance);

    const containerHeight =
      maxHeight && rows.length > 9 ? maxHeight : rows.length * 40;

    return (
      <Box
        {...wrapperProps}
        css={
          wrapperProps.css || {
            '& .tr:hover': {
              '.td': {
                background: colors.background.highlight
              }
            }
          }
        }
        height="100%"
      >
        {isTopbarHidden || (
          <TopBar<T>
            title={title}
            subtitle={subtitle}
            hasSelectPlugin={hasSelectPlugin}
            hasColumnOrderPlugin={hasColumnOrderPlugin}
            selectedAmount={selectedFlatRows?.length}
            text={selectedRowsInfo.text}
            buttons={selectedRowsInfo.buttons}
            placeholder={selectedRowsInfo.placeholder}
            topBarProps={topBarProps}
            tableInstance={instance}
            manageColumnsProps={{
              columnLabels: manageColumnsProps.columnLabels || {},
              hiddenColumns: manageColumnsProps.hiddenColumns || [],
              disabledColumns: manageColumnsProps.disabledColumns || []
            }}
          />
        )}
        <TableWrapper
          hasStickyColumns={hasStickyColumns}
          border={!hasHeader && !rows.length ? 0 : '1px solid'}
        >
          {loadingProps.isVisible ? (
            <Loader text={loadingProps.text} />
          ) : (
            <Table
              {...getTableProps()}
              data-testid={tableName}
              as="div"
              variant="gridFixed"
              size="sm"
              d="flex"
              flexDirection="column"
              height="100%"
              w={isResizable ? '100%' : 'fit-content'}
            >
              <Box
                {...(hasStickyColumns
                  ? { pos: 'sticky', zIndex: '12', top: '0' }
                  : {})}
              >
                {hasHeader &&
                  (renderHead ? (
                    renderHead(headProps)
                  ) : (
                    <Head<T> {...headProps} />
                  ))}
              </Box>
              <Tbody
                {...getTableBodyProps()}
                as="div"
                height={containerHeight + 'px'}
                maxHeight={maxHeight ? maxHeight + 'px' : '100%'}
              >
                {hasStickyColumns ? (
                  rows.map((row, index) => (
                    <Fragment key={row.id}>
                      {RenderRow({
                        index,
                        style: { height: '40px' }
                      } as ListChildComponentProps)}
                    </Fragment>
                  ))
                ) : (
                  <AutoSizer disableWidth>
                    {({ height = 0 }) => (
                      <FixedSizeList
                        height={height}
                        outerElementType={ScrollbarVirtual}
                        width="100%"
                        itemCount={rows.length}
                        itemSize={40}
                      >
                        {RenderRow}
                      </FixedSizeList>
                    )}
                  </AutoSizer>
                )}
              </Tbody>
            </Table>
          )}
        </TableWrapper>
        {placeholder && !rows.length && !loadingProps.isVisible
          ? placeholder
          : null}
      </Box>
    );
  }
) as <T extends {}>(
  props: PropsWithChildren<TableTemplateProps<T>> & {
    ref?: Ref<TableInstance<T>>;
  }
) => JSX.Element;
