import { GeneSortMethod, GeneSetSortMethod } from 'generated/types';

export const GENE_SETS_SORT_DROPDOWN_ITEMS = [
  {
    value: GeneSetSortMethod.numMatchingDescending,
    label: 'Number of matching genes - Descending'
  },
  {
    value: GeneSetSortMethod.numMatchingAscending,
    label: 'Number of matching genes - Ascending'
  },
  {
    value: GeneSetSortMethod.proportionOfRepresentationDescending,
    label: 'Proportion of gene representation - Descending'
  },
  {
    value: GeneSetSortMethod.proportionOfRepresentationAscending,
    label: 'Proportion of gene representation - Ascending'
  },
  {
    value: GeneSetSortMethod.jaccardSimilarity,
    label: 'Jaccard similarity clustering'
  }
];

export const GENES_SORT_DROPDOWN_ITEMS = [
  {
    value: GeneSortMethod.alphabetic,
    label: 'Alphabetical'
  },
  {
    value: GeneSortMethod.geneAbundanceDescending,
    label: 'Gene abundance - Descending'
  },
  {
    value: GeneSortMethod.geneAbundanceAscending,
    label: 'Gene abundance - Ascending'
  },
  {
    value: GeneSortMethod.jaccardSimilarity,
    label: 'Jaccard similarity clustering'
  }
];
