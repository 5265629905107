import { Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Tooltip } from '../Tooltip';

export enum GeneSource {
  C = 'C',
  P = 'P',
  E = 'E'
}

interface GeneTypeProps {
  type: GeneSource;
}

export const GeneType: FC<GeneTypeProps> = ({ type }) => {
  const renderText = (text: GeneSource | string) => (
    <Text
      cursor="pointer"
      variant="accentBodyShort"
      d="flex"
      alignItems="center"
      justifyContent="center"
      h="100%"
      as="span"
      maxW="40px"
      w="40px"
      transition="all 0.2s ease-in-out"
      _hover={{
        color: 'content.link.normal',
        background: 'button.ghostOnPrimary.pressed'
      }}
    >
      {text}
    </Text>
  );

  switch (type) {
    case GeneSource.P: {
      return (
        <Tooltip label="Gene added from MERSCOPE panel" maxW="168px">
          {renderText('M')}
        </Tooltip>
      );
    }
    case GeneSource.C: {
      return (
        <Tooltip label="Gene added as Custom Input" maxW="168px">
          {renderText(type)}
        </Tooltip>
      );
    }
    case GeneSource.E: {
      return (
        <Tooltip label="Gene added as Exogenous" maxW="168">
          {renderText(type)}
        </Tooltip>
      );
    }
    default: {
      return null;
    }
  }
};
