import { Thead, Text, Th, Tr, VStack, Box, HStack } from '@chakra-ui/react';
import { HeaderGroup, TableSortByToggleProps } from 'react-table';
import { CustomColumn } from '../../TableTemplate';
import { SortIcon } from './components';

interface HeadProps<T extends {}> {
  headerGroups: HeaderGroup<T>[];
  hasSortPlugin: boolean;
  hasResizePlugin: boolean;
  resetToggledRow: () => void;
}

type Column = HeaderGroup<any> & CustomColumn<any>;

export const Head = <T extends {}>({
  headerGroups,
  hasSortPlugin,
  hasResizePlugin,
  resetToggledRow
}: HeadProps<T>): JSX.Element => {
  return (
    <Thead as="div">
      {headerGroups.map((headerGroup) => {
        const hasSomeColumnFilter = headerGroup.headers.some(
          (column) => column.filter && column.Filter
        );

        return (
          <Tr as="div" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: Column) => {
              const content = column.render('Header');
              const hasFilter = !!column.filter && !!column.Filter;
              const isSortable = !!column.isSortable && hasSortPlugin;
              const isResizable = !!column.isResizable && hasResizePlugin;
              const { onClick, ...restToggleProps } =
                column.getSortByToggleProps({ title: '' });
              const sortingProps = isSortable
                ? ({
                    onClick: (e) => {
                      onClick?.(e);
                      resetToggledRow();
                    },
                    ...restToggleProps
                  } as Partial<TableSortByToggleProps>)
                : {};
              const { style, ...rest } = column.getHeaderProps();

              return (
                <Th
                  as="div"
                  className="th"
                  alignItems="center"
                  d="flex"
                  borderTop="0"
                  w="100%"
                  pos="relative"
                  {...rest}
                  {...(column?.headerCellProps ? column.headerCellProps : {})}
                  {...(column?.columnMaxW ? { maxW: column.columnMaxW } : {})}
                  style={{
                    ...style,
                    ...column?.headerCellProps?.style
                  }}
                >
                  <VStack spacing="8px" w="100%" h="100%">
                    <HStack
                      spacing="6px"
                      w="100%"
                      h="100%"
                      align="center"
                      justify="center"
                      mb={hasSomeColumnFilter && !hasFilter ? '44px' : 0}
                      {...sortingProps}
                    >
                      {content && (
                        <Text
                          color="content.primary"
                          variant="accentBodyShort"
                          as="span"
                          w="100%"
                        >
                          {content}
                        </Text>
                      )}
                      {isSortable && (
                        <SortIcon
                          isSorted={column.isSorted}
                          isSortedDesc={column.isSortedDesc}
                        />
                      )}
                    </HStack>
                    {hasFilter && column.render('Filter')}
                  </VStack>
                  {isResizable && (
                    <Box
                      {...column.getResizerProps()}
                      w="5px"
                      h="100%"
                      pos="absolute"
                      right="0"
                      top="0"
                    />
                  )}
                </Th>
              );
            })}
          </Tr>
        );
      })}
    </Thead>
  );
};
