import { Flex, HStack, Text } from '@chakra-ui/react';
import { ReactComponent as ErrorIcon } from './assets/error.svg';

export const Error = () => {
  return (
    <Flex
      justify="center"
      alignItems="center"
      h="96px"
      border="1px solid"
      borderRadius="8px"
      borderColor="state.error"
      bg="background.primary"
    >
      <HStack spacing="8px">
        <ErrorIcon /> <Text color="state.error">Error reading file</Text>
      </HStack>
    </Flex>
  );
};
