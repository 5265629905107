import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { theme } from '@vizgen/vizgen-ui';
const { colors } = theme;

export const SecondaryButton = forwardRef<ButtonProps & LinkProps, 'input'>(
  ({ children, ...rest }, ref) => {
    return (
      <Button
        ref={ref}
        as={RouterLink}
        size="auto"
        p="12px"
        bg="button.ghostOnPrimary.normal"
        lineHeight="24"
        fontWeight="normal"
        color="content.additional"
        textTransform="unset"
        _hover={{
          bg: 'button.ghostOnPrimary.hover',
          color: 'content.secondary'
        }}
        _active={{
          bg: 'button.ghostOnPrimary.pressed'
        }}
        css={{
          '&:hover path': {
            transition: 'all 250ms ease-in-out',
            stroke: colors.content.secondary
          }
        }}
        {...rest}
      >
        {children}
      </Button>
    );
  }
);
