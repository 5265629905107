import { FC } from 'react';
import { Table, Tbody } from '@chakra-ui/react';
import { SmallSummaryRow } from '@vizgen/vizgen-ui';
import { Panel } from 'generated/types';

interface PanelSummaryProps {
  panel: Panel;
}

export const PanelSummary: FC<PanelSummaryProps> = ({ panel }) => {
  const { totalNumber, sequential, primary } = panel.genesSummary || {};
  const { comment, panelId } = panel;

  return (
    <Table>
      <Tbody>
        <SmallSummaryRow field="Total Genes" value={totalNumber} />
        <SmallSummaryRow field="MERFISH" value={primary} />
        <SmallSummaryRow field="Sequential" value={sequential} />
        <SmallSummaryRow
          field="Description"
          value={comment || '-'}
          cellTextCss={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'initial'
          }}
        />
        <SmallSummaryRow field="Panel ID" value={panelId} />
      </Tbody>
    </Table>
  );
};
