import { CSSProperties } from 'react';
import { Row as RowData } from 'react-table';
import { TableRadioButton } from '../../../TableRadioButton';
import { TdTemplate } from '../../../TdTemplate';
import { TrTemplate } from '../../../TrTemplate';

interface RowProps<T extends {} = {}> {
  row: RowData<T>;
  hasSelectPlugin: boolean;
  style: CSSProperties;
  toggleAllRowsSelected?: (value?: boolean) => void;
}

export const Row = <T extends {}>({
  row,
  hasSelectPlugin,
  style,
  toggleAllRowsSelected
}: RowProps<T>): JSX.Element => {
  return (
    <TrTemplate row={row} style={style}>
      {row.cells.map((cell) => (
        <TdTemplate
          row={row}
          cell={cell}
          cellProps={(cell.column as any)?.cellProps || {}}
          key={`${cell.row.id}-${cell.value}-${cell.column.id}`}
        >
          {cell.column.id === 'selection' &&
          hasSelectPlugin &&
          toggleAllRowsSelected ? (
            <TableRadioButton
              {...row.getToggleRowSelectedProps({
                onChange: () => {
                  toggleAllRowsSelected(false);
                  row.toggleRowSelected(true);
                }
              })}
            />
          ) : (
            cell.render('Cell')
          )}
        </TdTemplate>
      ))}
    </TrTemplate>
  );
};
