import { Flex, HStack, Text, Box } from '@chakra-ui/react';
import { PanelTranscriptType } from 'generated/types';
import { FC } from 'react';
import {
  isAuxProbeMissingSelector,
  transcriptsByTypeSelector,
  useCreatePanelStore
} from 'store';
import { shallow } from 'zustand/shallow';
import { InfoIcon } from './assets';

const KITS: { [key in string]: string } = {
  Aux4: 'Anti-Mouse',
  Aux5: 'Anti-Rabbit',
  Aux6: 'Anti-Goat',
  Aux7: 'Anti-Rat',
  Aux8: 'Anti-Human',
  Aux9: 'Anti-Chicken'
};

const getTermsEnumeration = (terms: string[]) => {
  return terms.reduce(
    (accum, aux, index, arr) =>
      accum
        ? `${accum}${arr.length - 1 === index ? ` and ` : `, `}${aux}`
        : `${aux}`,
    ''
  );
};

export const SequentialNotification: FC = () => {
  const isProbeMissing = useCreatePanelStore(isAuxProbeMissingSelector);
  const auxProbes = useCreatePanelStore(
    transcriptsByTypeSelector(PanelTranscriptType.sequential),
    shallow
  )
    .filter((transcript) => transcript.auxProbes)
    .map((transcript) => transcript.auxProbes)
    .sort() as string[];
  const auxProbesAmount = auxProbes.length;
  const kitText = getTermsEnumeration(
    auxProbes.map((auxProbe) => KITS[auxProbe])
  );
  const auxText = getTermsEnumeration(auxProbes);

  if (!auxProbesAmount || isProbeMissing) {
    return null;
  }

  return (
    <Flex w="100%" pb="12px">
      <Flex
        w="100%"
        borderRadius="24px"
        background="state.infoLight10Percent"
        padding="8px 16px"
        alignItems="center"
      >
        <HStack spacing="16px" pr="16px" w="100%">
          <Box w="32px" h="32px">
            <InfoIcon />
          </Box>
          <Text as="span" color="content.primary">
            {kitText} protein kit{auxProbesAmount > 1 ? 's' : ''} cannot be used
            with this panel due to the inclusion of {auxText}. Select{' '}
            {auxProbesAmount > 1
              ? 'different probes to use these protein kits'
              : 'a different probe to use this protein kit'}
            .
          </Text>
        </HStack>
      </Flex>
    </Flex>
  );
};
