import { Box, Flex, Text } from '@chakra-ui/react';
import { ColumnInstance } from 'react-table';
import { theme } from '../../../../../../../../../../../../theme';
import { TableColumnsLabels } from '../../../../../../../../../../../../types';
import { CheckboxInput } from '../../../../../../../../../../../CheckboxInput';
import { ColumnFilter } from '../../../../../../ManageTableColumns';
import DotsIcon from './assets/dots.svg';
import HideIcon from './assets/hide.svg';
const { colors } = theme;

interface ColumnSelectProps<T extends {}> {
  column: ColumnInstance<T>;
  isDragging: boolean;
  labels: TableColumnsLabels;
  disabledColumns: string[];
  setFilter: ColumnFilter;
}

export const ColumnSelect = <T extends {}>({
  column: { getToggleHiddenProps, id, Header },
  isDragging,
  labels,
  disabledColumns,
  setFilter
}: ColumnSelectProps<T>) => {
  const { checked, onChange } = getToggleHiddenProps();
  const customLabel = labels[id];
  const header = typeof Header === 'string' ? Header : null;
  const label =
    customLabel?.render?.(checked) ?? customLabel?.value ?? header ?? id;
  const isDisabled = disabledColumns.includes(id);

  const getFill = (isDragging: boolean) => ({
    '& path.dots_svg__dots': {
      fill: isDragging ? colors.content.secondary : colors.content.additional
    }
  });

  return (
    <Flex
      bg="background.primary"
      transition="all 200ms ease-in-out"
      _hover={{
        bg: 'background.highlight',
        ...getFill(false)
      }}
      boxShadow={
        isDragging ? '0px 8px 24px 0px rgba(215, 217, 220, 0.4)' : undefined
      }
      css={getFill(isDragging)}
    >
      <Box w="24px" pt="9px" mr="2px">
        <DotsIcon />
      </Box>
      <CheckboxInput
        isChecked={isDisabled || checked}
        isDisabled={isDisabled}
        onChange={(e) => {
          onChange(e);
          if (!e.target.checked) {
            setFilter(id, undefined);
          }
        }}
        size="sm"
        w="100%"
        p="11px 0 9px 0"
        cursor="pointer !important"
      >
        <Text
          as="span"
          ml="2px"
          mr="24px"
          lineHeight="20"
          color={
            !checked || isDisabled ? 'content.additional' : 'content.secondary'
          }
        >
          {label}
        </Text>
      </CheckboxInput>
      {checked || (
        <Box w="24px" pt="9px" ml="8px" mr="16px">
          <HideIcon />
        </Box>
      )}
    </Flex>
  );
};
