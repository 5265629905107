import { useRadio, Box, UseRadioProps, Flex, BoxProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface RadioBarButtonProps extends UseRadioProps {
  children: ReactNode;
  labelProps?: BoxProps;
}

export const RadioBarButton: FC<RadioBarButtonProps> = ({
  children,
  labelProps,
  ...rest
}) => {
  const { getInputProps, getCheckboxProps } = useRadio(rest);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" h="100%" style={{ marginInlineStart: 0 }} {...labelProps}>
      <input {...input} />
      <Flex
        {...checkbox}
        cursor="pointer"
        color="content.additional"
        bg="button.ghostOnPrimary.normal"
        fontSize="16px"
        lineHeight="24px"
        h="100%"
        alignItems="center"
        justifyContent="center"
        fontWeight="bold"
        _hover={{
          bg: 'button.ghostOnPrimary.hover'
        }}
        _checked={{
          bg: 'background.primary',
          cursor: 'default',
          color: 'content.secondary',
          _hover: {
            bg: 'background.primary'
          },
          _focus: {
            bg: 'background.primary'
          }
        }}
        _focus={{
          bg: 'button.ghostOnPrimary.pressed'
        }}
      >
        {children}
      </Flex>
    </Box>
  );
};
