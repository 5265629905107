import { useEffect, useState } from 'react';
import { useCreatePanelStore } from 'store';
import { useGetReference } from './useGetReference';
import { useGetTissue } from './useGetTissue';

export const useGetPanelConfiguration = () => {
  const [panelConfiguration, setPanelConfiguration] = useState<string[]>([]);
  const tissue = useGetTissue();
  const reference = useGetReference();
  const panelName = useCreatePanelStore((state) => state.panel)?.panelName;

  useEffect(() => {
    setPanelConfiguration([`${panelName || 'New Panel'}`, reference, tissue]);
  }, [panelName, reference, tissue]);

  return panelConfiguration;
};
