import React, { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { PanelTranscriptStatus } from 'generated/types';
import { useCreatePanelStore } from 'store';

interface ActionProps {
  type: PanelTranscriptStatus;
  ids: string[];
}

const ActionButton: FC<{
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}> = ({ children, onClick }) => {
  return (
    <Button variant="tinyGhostPrimary" size="tiny" ml="8px" onClick={onClick}>
      {children}
    </Button>
  );
};

export const Action: FC<ActionProps> = ({ type, ids }) => {
  const includeAllInsufficient = useCreatePanelStore(
    (state) => state.includeAllInsufficient
  );
  const include = () => includeAllInsufficient(ids);
  switch (type) {
    case PanelTranscriptStatus.insufficient_tr: {
      return <ActionButton onClick={include}>Include anyway</ActionButton>;
    }
    // case PanelTranscriptStatus.not_found: {
    //   return <ActionButton>Edit Genes</ActionButton>;
    // }
    case PanelTranscriptStatus.untargetable:
    default: {
      return null;
    }
  }
};
