import { FC } from 'react';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { ErrorFilledSmallIcon } from 'assets';
import { MAX_MERFISH_GENE_ABUNDANCE } from 'consts';

interface LabelProps {
  value: number;
}

export const Label: FC<LabelProps> = ({ value }) => {
  return (
    <VStack spacing="6px" w="100%" alignItems="flex-start">
      <Text variant="bodyShort" color="content.tertiary">
        Gene abundance:
      </Text>
      <HStack spacing="4px">
        <ErrorFilledSmallIcon />
        <Text variant="accentBodyShort" color="content.tertiary">
          <Text as="span" variant="accentBodyShort" color="state.error">
            {value}
          </Text>
          <Text as="span" variant="bodyShort" color="content.tertiary">
            /
          </Text>
          {MAX_MERFISH_GENE_ABUNDANCE}
        </Text>
      </HStack>
      <Text variant="captionHigh" color="content.tertiary">
        The gene exceeds single gene abundance threshold (
        {MAX_MERFISH_GENE_ABUNDANCE} FPKM for MERFISH Panel)
        <br />
        <br />
        Delete it or move to Sequential Panel
      </Text>
    </VStack>
  );
};
