import { Box, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { HugeWarningIcon } from 'assets';

export const ChangeRoleWarning: FC = () => {
  return (
    <HStack as="span" w="100%" spacing="16px">
      <Box as="span">
        <HugeWarningIcon />
      </Box>
      <Text as="span">
        You are going to <b>remove yourself</b> from the instrument
        administrators list and assign yourself a viewer role. Only Vizgen
        support will be able to restore your permissions to manage this
        instrument
      </Text>
    </HStack>
  );
};
