import { Flex, VStack } from '@chakra-ui/react';
import { CheckboxInput } from '@vizgen/vizgen-ui';
import { LibraryCategory } from 'store';
import { FC, Fragment, Dispatch, SetStateAction, useMemo } from 'react';
import { produce } from 'immer';
import { ExpandButton, LibraryCategoryTitle, GeneSetList } from './components';
import {
  findMatchingGeneSetsAmount,
  findMatchingGeneSetLibrariesAmount
} from 'components/GeneSetLibrariesModal/utils';

interface LibraryCategoryItemProps {
  searchValue: string;
  libraryCategory: LibraryCategory;
  expandedId: string;
  libraryCategoryIndex: number;
  isLoading: boolean;
  onExpand: (id: string) => void;
  onSelect: Dispatch<SetStateAction<LibraryCategory[]>>;
}

export const LibraryCategoryItem: FC<LibraryCategoryItemProps> = ({
  searchValue,
  libraryCategory,
  expandedId,
  libraryCategoryIndex,
  isLoading,
  onExpand,
  onSelect
}) => {
  const isCategoryVisible = useMemo(
    () =>
      findMatchingGeneSetLibrariesAmount(
        libraryCategory.children,
        searchValue
      ) > 0,
    [libraryCategory.children, searchValue]
  );

  const onGeneSetLibrarySelect = (
    isChecked: boolean,
    geneSetLibraryIndex: number
  ) => {
    onSelect((prev) =>
      produce(prev, (draft) => {
        const geneSets =
          draft[libraryCategoryIndex].children[geneSetLibraryIndex].children;
        for (let i = 0; i < geneSets.length; i++) {
          geneSets[i].isChecked = isChecked;
        }
        return draft;
      })
    );
  };

  if (!isCategoryVisible) {
    return null;
  }

  return (
    <>
      <LibraryCategoryTitle title={libraryCategory.title} />
      {libraryCategory.children.map(
        (geneSetLibrary, geneSetLibraryIndex, arr) => {
          const isExpanded = expandedId === geneSetLibrary.label;
          const isAllSelected = geneSetLibrary.children.every(
            (geneSet) => geneSet.isChecked
          );
          const isIndeterminate =
            geneSetLibrary.children.some((geneSet) => geneSet.isChecked) &&
            !isAllSelected;
          const isGeneSetLibraryVisible =
            findMatchingGeneSetsAmount(geneSetLibrary.children, searchValue) >
            0;

          return (
            isGeneSetLibraryVisible && (
              <Fragment key={geneSetLibrary.label}>
                <Flex
                  zIndex="1"
                  px="24px"
                  pt="16px"
                  mb={
                    arr.length - 1 === geneSetLibraryIndex && !isExpanded
                      ? '11px'
                      : '0'
                  }
                  pos="sticky"
                  top="42px"
                  bg="background.secondary"
                >
                  <Flex
                    pb="4px"
                    w="100%"
                    borderBottom="1px solid"
                    borderColor={
                      isExpanded ? 'border.divider' : 'background.secondary'
                    }
                  >
                    <CheckboxInput
                      isDisabled={isLoading}
                      isChecked={isAllSelected}
                      isIndeterminate={isIndeterminate}
                      onChange={(e) =>
                        onGeneSetLibrarySelect(
                          e.target.checked,
                          geneSetLibraryIndex
                        )
                      }
                    />
                    <ExpandButton
                      label={geneSetLibrary.label}
                      geneSetsAmount={geneSetLibrary.children.length}
                      isExpanded={isExpanded}
                      onExpand={onExpand}
                    />
                  </Flex>
                </Flex>
                {isExpanded && (
                  <VStack
                    spacing="20px"
                    pl="62px"
                    w="100%"
                    align="start"
                    py="16px"
                  >
                    <GeneSetList
                      isLoading={isLoading}
                      searchValue={searchValue}
                      geneSets={geneSetLibrary.children}
                      geneSetLibraryIndex={geneSetLibraryIndex}
                      libraryCategoryIndex={libraryCategoryIndex}
                      onSelect={onSelect}
                    />
                  </VStack>
                )}
              </Fragment>
            )
          );
        }
      )}
    </>
  );
};
