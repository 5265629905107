import { Box, Flex, forwardRef, VStack } from '@chakra-ui/react';
import { FC, SVGProps } from 'react';
import { theme } from '../../../../../../theme';
const { colors } = theme;

interface IconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

interface SortIconProps {
  isSorted: boolean;
  isSortedDesc?: boolean;
}

const SortTriangleIcon = forwardRef<IconProps, 'svg'>(
  ({ color = colors.state.infoDark, ...rest }, ref) => {
    return (
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
        ref={ref}
      >
        <path d="M1 1L5 5L9 1" fill={color} />
        <path
          d="M1 1L5 5L9 1H1Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export const SortIcon: FC<SortIconProps> = ({ isSorted, isSortedDesc }) => {
  const ActiveSortedIcon = (): JSX.Element => (
    <Flex align="center" justify="center" as="span" w="20px" h="20px">
      <Flex as="span" transform={isSortedDesc ? 'rotate(180deg)' : undefined}>
        <SortTriangleIcon color={colors.content.secondary} />
      </Flex>
    </Flex>
  );

  return isSorted ? (
    <ActiveSortedIcon />
  ) : (
    <VStack
      spacing="6px"
      as="span"
      w="20px"
      h="20px"
      align="center"
      justify="center"
    >
      <Box as="span" transform="rotate(180deg)">
        <SortTriangleIcon color={colors.content.additional} />
      </Box>
      <Box as="span">
        <SortTriangleIcon color={colors.content.additional} />
      </Box>
    </VStack>
  );
};
