import { Reference } from 'models';
import { useMemo } from 'react';
import { useCreatePanelStore, useGlobalListsStore } from 'store';

export const useGetReference = () => {
  const panel = useCreatePanelStore((state) => state.panel);
  const references = useGlobalListsStore((state) => state.transcriptomes);

  return useMemo(
    () =>
      (references.find(
        (reference) => reference.transcriptomeId === panel?.transcriptomeId
      )?.scientificName as Reference) || '',
    [references, panel]
  );
};
