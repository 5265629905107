import { Text } from '@chakra-ui/react';
import { CheckboxInput } from '@vizgen/vizgen-ui';
import produce from 'immer';
import { Dispatch, FC, memo, SetStateAction } from 'react';
import { LibraryCategory } from 'store';

interface ComponentProps {
  label: string;
  isChecked: boolean;
  geneSetIndex: number;
  geneSetLibraryIndex: number;
  libraryCategoryIndex: number;
  isDisabled?: boolean;
  onSelect: Dispatch<SetStateAction<LibraryCategory[]>>;
}

const Component: FC<ComponentProps> = ({
  label,
  isChecked,
  geneSetIndex,
  geneSetLibraryIndex,
  libraryCategoryIndex,
  isDisabled,
  onSelect
}) => {
  const onGeneSetSelect = (isChecked: boolean) => {
    onSelect((prev) =>
      produce(prev, (draft) => {
        draft[libraryCategoryIndex].children[geneSetLibraryIndex].children[
          geneSetIndex
        ].isChecked = isChecked;
        return draft;
      })
    );
  };

  return (
    <CheckboxInput
      isDisabled={isDisabled}
      isChecked={isChecked}
      onChange={(e) => onGeneSetSelect(e.target.checked)}
    >
      <Text ml="2px" mr="24px">
        {label}
      </Text>
    </CheckboxInput>
  );
};

export const Checkbox = memo(Component);
