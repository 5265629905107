import { PanelTranscriptStatus } from 'generated/types';
import { useCreatePanelStore, transcriptsByStatusSelector } from 'store';
import { shallow } from 'zustand/shallow';
import { Row } from '..';

export const NonTrackableRow = () => {
  const issues = useCreatePanelStore(
    transcriptsByStatusSelector(PanelTranscriptStatus.untargetable),
    shallow
  );
  return <Row genes={issues} status={PanelTranscriptStatus.untargetable} />;
};
