import { transparentize } from '@chakra-ui/theme-tools';
import { fontStyles } from '../fonts';

export const Button = {
  baseStyle: {
    fontStyle: 'normal',
    fontWeight: 'heavy',
    fontSize: '16',
    lineHeight: '20',
    color: 'content.tertiary',
    borderRadius: '4px'
  },
  variants: {
    ghost: {
      color: 'content.secondary',
      _hover: {
        bg: 'transparent'
      },
      _active: {
        bg: 'transparent'
      }
    },
    primary: {
      lineHeight: '24',
      textTransform: 'uppercase',
      bg: 'button.primary.normal',
      _hover: {
        bg: 'button.primary.hover',
        _disabled: {
          bg: 'button.primary.hover'
        }
      },
      _active: {
        bg: 'button.primary.pressed'
      }
    },
    primaryHigh: {
      lineHeight: '24',
      height: '48px',
      bg: 'button.primary.normal',
      _hover: {
        bg: 'button.primary.hover',
        _disabled: {
          bg: 'button.primary.hover'
        }
      },
      _active: {
        bg: 'button.primary.pressed'
      }
    },
    primarySmall: {
      h: '28px',
      bg: 'button.primary.normal',
      _hover: {
        bg: 'button.primary.hover',
        _disabled: {
          bg: 'button.primary.hover'
        }
      },
      _active: {
        bg: 'button.primary.pressed'
      }
    },
    secondarySolid: {
      bg: 'button.secondarySolid.normal',
      _hover: {
        bg: 'button.secondarySolid.hover',
        _disabled: {
          bg: 'button.secondarySolid.hover'
        }
      },
      _active: {
        bg: 'button.secondarySolid.pressed'
      }
    },
    secondaryOutline: {
      bg: 'button.secondaryOutline.normal',
      color: 'content.secondary',
      border: '1px solid',
      borderColor: 'content.secondary',
      _hover: {
        bg: transparentize('button.secondaryOutline.hover', 0.1),
        _disabled: {
          bg: transparentize('button.secondaryOutline.hover', 0.1)
        }
      },
      _active: {
        bg: transparentize('button.secondaryOutline.pressed', 0.2)
      }
    },
    primaryGhost: {
      bg: 'transparent',
      color: 'content.link.normal',
      _hover: {
        bg: 'button.ghostOnPrimary.hover',
        _disabled: {
          bg: 'button.ghostOnPrimary.hover'
        }
      },
      _active: {
        bg: 'button.ghostOnPrimary.pressed'
      }
    },
    secondaryGhost: {
      bg: 'transparent',
      color: 'content.secondary',
      _hover: {
        bg: 'button.ghostOnSecondary.hover',
        _disabled: {
          bg: 'button.primary.hover'
        }
      },
      _active: {
        bg: 'button.ghostOnSecondary.pressed'
      }
    },
    tinyGhostPrimary: {
      bg: 'transparent',
      color: 'content.link.normal',
      fontWeight: 'normal',
      lineHeight: '24',
      _hover: {
        bg: 'button.tinyGhostPrimary.hover',
        _disabled: {
          bg: 'button.tinyGhostPrimary.hover'
        }
      },
      _active: {
        bg: 'button.tinyGhostPrimary.pressed'
      }
    },
    tinyGhostSecondary: {
      bg: 'transparent',
      color: 'content.link.normal',
      fontWeight: 'normal',
      lineHeight: '24',
      _hover: {
        bg: 'button.tinyGhostSecondary.hover',
        _disabled: {
          bg: 'button.tinyGhostSecondary.hover'
        }
      },
      _active: {
        bg: 'button.tinyGhostSecondary.pressed'
      }
    },
    secondaryFilled: {
      bg: 'button.secondaryFilled.normal',
      lineHeight: '24',
      _hover: {
        bg: 'button.secondaryFilled.hover',
        _disabled: {
          bg: 'button.secondaryFilled.hover'
        }
      },
      _active: {
        bg: 'button.secondaryFilled.pressed'
      }
    }
  },
  sizes: {
    md: {
      minW: '168px'
    },
    sm: {
      minW: '67px',
      h: '28px',
      fontSize: fontStyles.fontSizes[16],
      lineHeight: fontStyles.lineHeights[20]
    },
    icon: {
      w: '32px',
      h: '32px',
      p: 0
    },
    tiny: {
      p: '4px 8px',
      h: '32px'
    },
    auto: {
      maxW: 'auto',
      minW: 'auto',
      h: 'auto',
      w: 'auto'
    }
  },
  defaultProps: {
    size: 'md',
    variant: 'primary'
  }
};
