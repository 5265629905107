import { ValidationError } from 'models';

export const validateConfirmationCode = (
  confirmationCode: string
): ValidationError | undefined => {
  if (!confirmationCode) {
    return {
      code: 'validationError',
      field: 'code',
      message: 'Please fill in confirmation code'
    };
  }
};
