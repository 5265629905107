import { FC, useEffect } from 'react';
import { Box, Container, Flex, Button, useMediaQuery } from '@chakra-ui/react';
import { Sidebar, MainContent } from './components';
import { PanelConfiguration, Navigation } from 'components';
import { generatePath, useParams } from 'react-router';
import { useCreatePanelStore } from 'store';
import { useGetPanelConfiguration, useGetPanel } from 'hooks';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Paths } from 'routes';
import { Header } from '../Header';

export const AddGenesStep: FC = () => {
  const navigate = useNavigate();
  const { panelId } = useParams();
  const fetchGenes = useCreatePanelStore((state) => state.fetchGenes);
  const panelConfiguration = useGetPanelConfiguration();
  const validatePanel = useCreatePanelStore((state) => state.validatePanel);
  const [isLargerThan1440] = useMediaQuery('(min-width: 1440px)');

  useGetPanel();

  useEffect(() => {
    if (panelId) fetchGenes(panelId);
  }, []); // eslint-disable-line

  const onNext = () => {
    const isValid = validatePanel();
    if (isValid) {
      navigate(generatePath(Paths.CreatePanel_Summary, { panelId }));
    }
  };

  return (
    <>
      <Header currentStep={1} />
      <Box h="100%" overflow="hidden">
        <Container
          p="0 0 0 60px"
          h="100%"
          maxW="calc(100% - calc((100vw - 1440px) / 2))"
          ml={isLargerThan1440 ? 'calc((100vw - 1440px) / 2)' : 0}
        >
          <Flex direction="column" h="100%" pt="40px" overflowY="hidden">
            <PanelConfiguration items={panelConfiguration} />
            <Flex h="100%" overflowY="hidden">
              <Sidebar />
              <MainContent />
            </Flex>
          </Flex>
        </Container>
      </Box>
      <Navigation
        leftButton={
          <Button
            as={RouterLink}
            to={generatePath(Paths.CreatePanel_Configuration, { panelId })}
            variant="ghost"
            px="5"
            data-testid="backLink"
          >
            Back
          </Button>
        }
        rightButton={
          <Button
            onClick={onNext}
            px="8"
            variant="secondarySolid"
            data-testid="nextButton"
          >
            Next
          </Button>
        }
      />
    </>
  );
};
