import { Box } from '@chakra-ui/react';
import { getValidationMessage } from '../../utils';
import { FC, useState, ChangeEvent } from 'react';
import {
  Control,
  useController,
  FieldValues,
  FormState
} from 'react-hook-form';
import { SequenceActions } from 'components';
import { InputField, TextareaField } from '@vizgen/vizgen-ui';

interface ExogenousSequenceFieldProps {
  control: Control<FieldValues>;
  isExtendable?: boolean;
  label: string;
  formState: FormState<FieldValues>;
  isDisabled: boolean;
}

export const ExogenousSequenceField: FC<ExogenousSequenceFieldProps> = ({
  control,
  label,
  formState,
  isDisabled
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const {
    field: { ref, onChange, name, value }
  } = useController({
    name: 'sequence',
    control,
    defaultValue: '',
    rules: {
      required: true,
      maxLength: 400000,
      pattern: /^[TACG]*$/
    }
  });
  const validation = getValidationMessage('sequence', formState.errors);
  const placeholder = 'Use only T, A, C, G characters';

  const onSequenceChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(e.target.value.replace(/\s/g, ''));
  };

  return (
    <Box pos="relative">
      <SequenceActions
        value={value}
        isCollapsed={isCollapsed}
        onCollapseButtonClick={() => setIsCollapsed((prev) => !prev)}
      />
      {isCollapsed ? (
        <InputField
          ref={ref}
          name={name}
          label={label}
          value={value}
          onChange={onSequenceChange}
          placeholder={placeholder}
          controlProps={{
            isInvalid: !!validation,
            d: 'flex',
            flexDir: 'column'
          }}
          errorProps={{ pos: 'relative' }}
          error={validation}
          disabled={isDisabled}
        />
      ) : (
        <TextareaField
          ref={ref}
          name={name}
          label={label}
          value={value}
          onChange={onSequenceChange}
          placeholder={placeholder}
          controlProps={{
            isInvalid: !!validation,
            d: 'flex',
            flexDir: 'column'
          }}
          errorProps={{ pos: 'relative' }}
          error={validation}
          disabled={isDisabled}
          rows={17}
          resize="none"
          overflowX="hidden"
          lineHeight="23px"
        />
      )}
    </Box>
  );
};
