import { DeepMap, FieldError, FieldValues } from 'react-hook-form';

export const getValidationMessage = (
  field: string,
  errors: DeepMap<FieldValues, FieldError>
) => {
  if (errors[field]) {
    switch (errors[field].type) {
      case 'required': {
        return 'Fill the required field';
      }
      case 'pattern': {
        return 'Only T, A, C, G characters are allowed';
      }
      case 'maxLength': {
        return 'Maximum sequence length exceeded';
      }
    }
  }
  return '';
};
