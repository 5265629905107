import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Center, Container, Link } from '@chakra-ui/react';
import { Paths } from 'routes';
import { SmallLogo } from 'assets';
import { Stepper } from './components';
import { CloseButton } from '@vizgen/vizgen-ui';

const titles = ['Configure Panel', 'Add Genes', 'Summary'];

interface HeaderProps {
  currentStep: number;
}

export const Header: FC<HeaderProps> = ({ currentStep }) => {
  return (
    <>
      <Box bg="background.primary">
        <Container maxW="1440px" h="80px" p="0 60px">
          <Center pos="relative" h="100%">
            <Link
              as={RouterLink}
              to={Paths.Home}
              pos="absolute"
              left="0"
              top="50%"
              transform="translateY(-50%)"
            >
              <SmallLogo />
            </Link>
            <Box w="100%" maxW="500" alignSelf="flex-end" mb="5px">
              <Stepper steps={titles} currentStep={currentStep} />
            </Box>
            <CloseButton
              as={RouterLink}
              to={Paths.Home}
              pos="absolute"
              right="0"
              minW="132px"
            >
              Close
            </CloseButton>
          </Center>
        </Container>
      </Box>
    </>
  );
};
