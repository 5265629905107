export const fontStyles = {
  fonts: {
    body: 'Avenir, sans-serif',
    heading: 'Avenir, sans-serif'
  },
  fontWeights: {
    normal: '500',
    heavy: '800'
  },
  fontSizes: {
    12: '12px',
    14: '14px',
    16: '16px',
    20: '20px',
    24: '24px',
    32: '32px'
  },
  lineHeights: {
    16: '16px',
    20: '20px',
    24: '24px',
    32: '32px',
    48: '48px'
  }
};
