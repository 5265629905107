import { HStack, Text, Textarea } from '@chakra-ui/react';
import { useGetQuery } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { Control, useController, FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';

interface CommentFieldProps {
  control: Control<FieldValues>;
  isDisabled?: boolean;
  comment: string;
}

export const CommentField: FC<CommentFieldProps> = ({
  control,
  isDisabled = false,
  comment
}) => {
  const { panelId } = useParams<{ panelId: string }>();
  const {
    field: { ref, onChange, name, value }
  } = useController({
    name: 'comment',
    control,
    defaultValue: comment || ''
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const query = useGetQuery();

  useEffect(() => {
    const templateId = query.get('panelTemplate');
    if (panelId || templateId) {
      if (!isLoaded && comment) {
        onChange(comment || '');
        setIsLoaded(true);
      }
    } else {
      setIsLoaded(true);
    }
  }, [isLoaded, comment, onChange, setIsLoaded, panelId, query]);

  return (
    <HStack spacing="24px" alignItems="flex-start">
      <Text maxW={264} w="40%" pt="12px">
        Description{' '}
        <Text as="span" color="content.additional">
          (optional)
        </Text>
      </Text>
      <Textarea
        isDisabled={isDisabled}
        onChange={onChange}
        value={value || ''}
        maxW={360}
        h="96px"
        w="60%"
        name={name}
        ref={ref}
        placeholder="Add panel description"
      />
    </HStack>
  );
};
