import { useMemo } from 'react';
import { useCreatePanelStore, useGlobalListsStore } from 'store';

export const useGetTissue = () => {
  const panel = useCreatePanelStore((state) => state.panel);
  const tissues = useGlobalListsStore((state) => state.tissues);

  return useMemo(
    () =>
      tissues.find((tissue) => tissue.tissueId === panel?.tissueId)?.name || '',
    [tissues, panel]
  );
};
