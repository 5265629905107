import { CheckboxInput } from '../../CheckboxInput';
import { FC } from 'react';
import {
  Control,
  FieldValues,
  useController,
  ValidationRule
} from 'react-hook-form';
import { VStack } from '@chakra-ui/react';
import { ValidationMessage } from '../../ValidationMessage';

export interface CheckboxFormFieldProps {
  defaultValue?: boolean;
  control: Control<FieldValues>;
  fieldName: string;
  isDisabled?: boolean;
  label: string;
  size?: string;
  isInvalid?: boolean;
  rules?: {
    required?: string | ValidationRule<boolean>;
  };
}

export const CheckboxFormField: FC<CheckboxFormFieldProps> = ({
  defaultValue,
  control,
  fieldName,
  label,
  isDisabled,
  rules,
  ...rest
}) => {
  const {
    field: { ref, onChange, name, value },
    fieldState: { invalid, error }
  } = useController({
    name: fieldName,
    control,
    rules,
    defaultValue
  });

  return (
    <VStack w="100%" spacing="4px" alignItems="flex-start">
      <CheckboxInput
        ref={ref}
        name={name}
        defaultChecked={value}
        onChange={onChange}
        disabled={isDisabled}
        isInvalid={invalid}
        data-testid={name}
        {...rest}
      >
        {label}
      </CheckboxInput>
      {error?.message && (
        <ValidationMessage pos="relative" pl="28px">
          {error.message}
        </ValidationMessage>
      )}
    </VStack>
  );
};
