import { ExtraBlock } from './components';
import { FC } from 'react';
import { useNewOrderStore } from 'store';
import { additionalExtraQuantitySelector } from 'store';
import { ExtraModel } from 'api/types';

interface ExtraProps {
  extra: ExtraModel;
}

export const Extra: FC<ExtraProps> = ({ extra }) => {
  const editExtras = useNewOrderStore((state) => state.editAdditionalExtras);
  const extraAmount = useNewOrderStore((state) =>
    additionalExtraQuantitySelector(extra.extraId, state)
  );

  const onChangeValue = (valueAsNumber: number) => {
    editExtras(extra, valueAsNumber);
  };

  return (
    <ExtraBlock
      name={extra.extraName}
      extraId={extra.extraId}
      details={extra.description}
      onChangeAmount={onChangeValue}
      extraAmount={extraAmount}
    />
  );
};
