import { FC } from 'react';
import { HStack, Text, Button, VStack, Box } from '@chakra-ui/react';
import { AuthFlow, useResetPasswordStore, useUserStore } from 'store';
import { ErrorMessage } from '@vizgen/vizgen-ui';
import { shallow } from 'zustand/shallow';
import { SendCode, NewPassword } from './components';

export const ResetPasswordForm: FC = () => {
  const [error, errorValidation, isCodeSent] = useResetPasswordStore(
    (state) => [state.error, state.errorValidation, state.isCodeSent],
    shallow
  );
  const setAuthFlow = useUserStore((state) => state.setAuthFlow);

  return (
    <Box maxW="552px" m="0 auto">
      <VStack w="100%" spacing="24px" align="stretch" p="40px 36px 24px 36px">
        {isCodeSent ? <NewPassword /> : <SendCode />}
        <ErrorMessage error={error?.message || errorValidation?.message} />
        <HStack justify="center" spacing="4px">
          <Text>Back to</Text>
          <Button
            minW={0}
            variant="tinyGhostPrimary"
            size="sm"
            h="32px"
            p="4px 8px"
            onClick={() => setAuthFlow(AuthFlow.SignIn)}
            data-testid="signInButton"
          >
            Sign In
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};
