import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useUserStore } from 'store';
import { shallow } from 'zustand/shallow';
import { Button, Box, Text, VStack } from '@chakra-ui/react';
import { InputField, ErrorMessage } from '@vizgen/vizgen-ui';

interface ConfirmRegistrationFormData {
  code?: string;
}

export const ConfirmRegistrationForm: FC = () => {
  const { handleSubmit, register, formState } = useForm();
  const [
    registrationEmail,
    confirmRegistration,
    isFetching,
    errorValidation,
    error
  ] = useUserStore(
    (state) => [
      state.registrationEmail,
      state.confirmRegistration,
      state.isFetching,
      state.errorValidation,
      state.error
    ],
    shallow
  );

  const submitCode = (formData: ConfirmRegistrationFormData) => {
    confirmRegistration(formData?.code || '');
  };

  return (
    <Box maxW="552px" m="0 auto">
      <VStack
        as="form"
        w="100%"
        spacing="24px"
        align="stretch"
        p="40px 36px 24px 36px"
        onSubmit={handleSubmit(submitCode)}
      >
        <Text
          alignSelf="center"
          fontWeight="heavy"
          lineHeight="28px"
          fontSize="24px"
        >
          Do Not Leave This Page
        </Text>
        <Text fontSize="20px" lineHeight="24px">
          A confirmation code was sent to {registrationEmail}. You will be
          unable to return to this page. Please enter the code and confirm your
          registration.
        </Text>
        <InputField
          disabled={isFetching}
          w="100%"
          {...register('code')}
          label="Confirmation code"
          inputMode="numeric"
          type="text"
          placeholder="______"
          variant="code"
          maxLength={6}
          autoComplete="one-time-code"
          controlProps={{
            isInvalid: !!errorValidation && errorValidation.field === 'code'
          }}
          error={
            errorValidation && errorValidation.field === 'code'
              ? errorValidation.message
              : undefined
          }
        />
        <Button
          disabled={isFetching}
          type="submit"
          textTransform="none"
          isLoading={formState.isSubmitting}
          w="100%"
          size="md"
          fontSize="16px"
          h="40px"
          data-testid="confirmButton"
        >
          Confirm
        </Button>
        <ErrorMessage error={error?.message || errorValidation?.message} />
      </VStack>
    </Box>
  );
};
