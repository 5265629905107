import {
  CreatePanelModel,
  CreatePanelState,
  TranscriptModel,
  PanelFormData,
  MessageType
} from './models';
import { create } from 'zustand';
import {
  getListPanelTranscripts,
  changeTranscriptPanel,
  updatePanelTranscript,
  deletePanelTranscript,
  createPanelTranscriptsFromGenes,
  updatePanel,
  finalizePanel as finalize,
  includeInsufficientTranscript,
  createPanel,
  createExogenousSequenceMutation,
  addExogenousSequenceToPanelMutation,
  getPanel,
  deletePanelTranscripts,
  addPromotedPanelToPanel,
  addFinalizedPanelToPanel
} from 'api';
import {
  MAX_MERFISH_GENE_ABUNDANCE,
  SEQUENTIAL_TRANSCRIPTS_MAX_AMOUNT
} from 'consts';
import {
  GeneInput,
  PanelTranscriptType,
  ExogenousSequence,
  PanelTranscriptStatus,
  PanelTranscriptSequentialAuxProbes,
  PanelOwnershipType
} from 'generated/types';
import { parse } from 'papaparse';
import {
  includeUpdatedTranscripts,
  getDuplicates,
  getTotalAbundance,
  getNewFormData,
  getUpdatedFormData,
  getMaxMerfishGenesAmount
} from './utils';
import { useNotificationsStore } from '../notifications';
import {
  isAuxProbeMissingSelector,
  issuesAmountSelector,
  maxTotalAbundanceSelector
} from './selectors';

const defaultState = {
  searchValue: undefined,
  panel: undefined,
  isUploadingGenes: false,
  isUpdatingGenes: false,
  isFetchingGenes: false,
  isFinalizingPanel: false,
  transcripts: [],
  duplicateGenes: undefined,
  isPanelUpdating: false,
  sourcePanel: null,
  addedExogenousSequence: '',
  isSequenceUpdating: false,
  isSubmitted: false,
  isIssuesPanelExpanded: false
};

export const useCreatePanelStore = create<CreatePanelState>()((set, get) => ({
  ...defaultState,

  toggleIssuesPanelExpanded: () => {
    set(({ isIssuesPanelExpanded }) => ({
      isIssuesPanelExpanded: !isIssuesPanelExpanded
    }));
  },

  setSearchValue: (searchValue) => {
    set({ searchValue });
  },

  createExogenousSequence: async (data) => {
    set({ isSequenceUpdating: true });
    const res = await createExogenousSequenceMutation(data);
    set({
      addedExogenousSequence: res?.name || '',
      isSequenceUpdating: false
    });
    return res as ExogenousSequence;
  },

  addExogenousSequenceToPanel: async (exogenousSequenceId, panelId) => {
    const { transcripts } = get();
    set({ isSequenceUpdating: true });
    const res = await addExogenousSequenceToPanelMutation({
      panelId,
      exogenousSequenceId
    });
    const transcript = res?.addExogenousSequenceToPanel;
    if (transcript) {
      set({
        transcripts: [...transcripts, ...[transcript as TranscriptModel]]
      });
    }
    set({ isSequenceUpdating: false });
  },

  resetAddedExogenousSequence: () => {
    set({ addedExogenousSequence: '' });
  },

  setIsUpdating: () => {
    const { isPanelUpdating } = get();
    if (!isPanelUpdating) {
      set({ isPanelUpdating: true });
    }
  },

  updatePanel: async (data: PanelFormData) => {
    const actualData = getUpdatedFormData(data);
    const { panel, setIsUpdating } = get();
    setIsUpdating();
    const newPanelData = (await updatePanel({
      panelId: panel?.panelId || '',
      ...actualData
    })) as CreatePanelModel;
    set({ isPanelUpdating: false, panel: newPanelData });
  },

  createPanel: async (data: PanelFormData) => {
    const actualData = getNewFormData(data);
    const { isPanelUpdating } = get();
    if (!isPanelUpdating) {
      set({ isPanelUpdating: true });
    }
    const newPanelData = (await createPanel(actualData)) as CreatePanelModel;
    set({ isPanelUpdating: false, panel: newPanelData });
    return newPanelData.panelId;
  },

  setPanel: (panel) => {
    set({
      panel
    });
  },

  setTranscripts: (transcripts) => {
    set({
      transcripts
    });
  },

  addTranscripts: (newTranscripts) => {
    const { transcripts } = get();
    set({
      transcripts: [...transcripts, ...newTranscripts]
    });
  },

  fetchGenes: async (panelId, handleStatus = true) => {
    handleStatus &&
      set({
        isFetchingGenes: true
      });
    try {
      const response = await getListPanelTranscripts(panelId);
      set({
        transcripts: response,
        isFetchingGenes: false
      });
    } catch (e) {
      set({
        transcripts: []
      });
      handleStatus &&
        set({
          isFetchingGenes: false
        });
      console.error('Error during the fetching genes', e);
    }
  },

  setNotFoundErrorMessage: (transcripts) => {
    const { setNotifications } = useNotificationsStore.getState();
    const genesJoined = transcripts
      .slice(0, 3)
      .map((transcript) => transcript.userSpecifiedName)
      .join(', ');
    const amount = transcripts.length;
    const isMultiple = amount > 1;
    let message = `Gene ${genesJoined} was not found and can not be moved to Sequential Panel`;
    if (amount > 3) {
      message = `Genes ${genesJoined} + ${
        amount - 3
      } others were not found and can not be moved to Sequential Panel`;
    } else if (isMultiple) {
      message = `Genes ${genesJoined} were not found and can not be moved to Sequential Panel`;
    }
    setNotifications([
      {
        message,
        type: MessageType.GenesNotFound,
        isVisible: true
      }
    ]);
  },

  resetNotFoundErrorMessage: () => {
    const { setNotifications } = useNotificationsStore.getState();
    setNotifications([
      {
        message: '',
        type: MessageType.GenesNotFound,
        isVisible: false
      }
    ]);
  },

  toggleTranscriptPanel: async (panelTranscripts) => {
    const { panel, transcripts } = get();

    const usedAuxProbes = transcripts
      .filter(
        (transcript) =>
          transcript.type === PanelTranscriptType.sequential &&
          transcript.auxProbes
      )
      .map((transcript) => transcript.auxProbes);
    const availableAuxProbes = Object.values(
      PanelTranscriptSequentialAuxProbes
    ).filter((auxProbe) => !usedAuxProbes.includes(auxProbe));

    const updatedTranscripts = await Promise.all(
      panelTranscripts.map((transcript) =>
        changeTranscriptPanel(
          panel?.panelId || '',
          transcript,
          availableAuxProbes
        )
      )
    );

    set((state) => ({
      transcripts: includeUpdatedTranscripts(
        state.transcripts,
        updatedTranscripts
      )
    }));
  },

  changeNote: async (transcriptId, comment) => {
    const { panel } = get();
    const data = {
      transcriptId,
      comment
    } as TranscriptModel;
    const updatedTranscript = await updatePanelTranscript(
      panel?.panelId || '',
      data
    );
    set((state) => ({
      transcripts: state.transcripts.map((transcript) => {
        if (updatedTranscript.transcriptId === transcript.transcriptId) {
          return updatedTranscript;
        }
        return transcript;
      })
    }));
  },

  updateAuxProbe: async (transcriptId, auxProbes) => {
    const { panel, transcripts } = get();
    const { panelId = '' } = panel || {};
    const selectedTranscript = transcripts.find(
      (transcript) => transcript.auxProbes === auxProbes
    );

    const updateAuxProbe = (
      transcriptId: string,
      auxProbes: PanelTranscriptSequentialAuxProbes | null
    ) =>
      updatePanelTranscript(panelId, {
        transcriptId,
        auxProbes
      } as TranscriptModel);

    const promises = [updateAuxProbe(transcriptId, auxProbes)];
    if (selectedTranscript) {
      promises.push(updateAuxProbe(selectedTranscript.transcriptId, null));
    }
    const [updatedTranscript, replacedTranscript] = await Promise.all(promises);

    set((state) => ({
      transcripts: state.transcripts.map((transcript) => {
        if (updatedTranscript.transcriptId === transcript.transcriptId) {
          return updatedTranscript;
        }
        if (replacedTranscript?.transcriptId === transcript.transcriptId) {
          return replacedTranscript;
        }
        return transcript;
      })
    }));
  },

  deleteTranscripts: async (transcriptIds) => {
    const { panel } = get();
    const deletedTranscripts = await deletePanelTranscripts(
      panel?.panelId || '',
      transcriptIds
    );

    set((state) => ({
      transcripts: state.transcripts.filter(
        (transcript) =>
          !deletedTranscripts.find(
            (deletedTranscript) =>
              deletedTranscript.transcriptId === transcript.transcriptId
          )
      )
    }));
  },

  uploadTranscripts: async (value) => {
    if (!value) {
      return false;
    }
    const { setNotifications } = useNotificationsStore.getState();
    const { panel } = get();
    set({
      isUploadingGenes: true
    });
    const data = parse(value, {
      delimiter: ','
    }).data as string[][];
    const genes = data.filter((data) => !!data[0].trim());
    const maxMerfishGenesAmount = getMaxMerfishGenesAmount();
    const isGenesAmountExceeded = genes.length > maxMerfishGenesAmount;
    setNotifications([
      {
        message:
          `You are trying to upload more than ${maxMerfishGenesAmount} genes. ` +
          'Please reduce the number of genes and try again',
        type: MessageType.UploadGenesAmountExceeded,
        isVisible: isGenesAmountExceeded
      }
    ]);
    if (isGenesAmountExceeded) {
      set({
        isUploadingGenes: false
      });
      return false;
    }
    const geneInput = genes.map((data) => {
      const obj: GeneInput = {
        geneIdentifier: data[0].toUpperCase().trim()
      };
      if (data[1]) {
        obj['comment'] = data[1].trim();
      }
      if (data[2]?.trim()) {
        obj['fpkm'] = Number(data[2].trim()) || 0;
      }
      return obj;
    });
    const { panelId, tissueId, transcriptomeId } = panel as CreatePanelModel;
    const preparedData = {
      geneInput,
      panelId,
      tissueId,
      transcriptomeId
    };

    try {
      const response = await createPanelTranscriptsFromGenes(preparedData);
      const filteredResponse = response.filter((res) => !res.duplicated);

      set((state) => ({
        transcripts: [...state.transcripts, ...filteredResponse],
        duplicateGenes: getDuplicates(response)
      }));
    } catch (e) {
      throw e;
    } finally {
      set({
        isUploadingGenes: false
      });
    }

    return true;
  },

  addGenesFromSourcePanel: async (sourcePanel, targetPanelId) => {
    const { addTranscripts } = get();
    if (
      !sourcePanel ||
      !sourcePanel.panelId ||
      sourcePanel.panelId === 'custom' ||
      !targetPanelId
    ) {
      return;
    }
    set({ isPanelUpdating: true });
    let transcripts;
    const sourcePanelId = sourcePanel.panelId;
    if (sourcePanel.ownershipType === PanelOwnershipType.proprietary) {
      transcripts = await addFinalizedPanelToPanel(
        targetPanelId,
        sourcePanelId
      );
    } else {
      transcripts = await addPromotedPanelToPanel(targetPanelId, sourcePanelId);
    }
    addTranscripts(transcripts as TranscriptModel[]);
    set({ isPanelUpdating: false });
  },

  validatePanel: () => {
    set({ isSubmitted: true });
    const state = get();
    const { setNotifications } = useNotificationsStore.getState();
    const { transcripts } = state;
    const primaryTranscripts = transcripts.filter(
      (transcript) => transcript.type === PanelTranscriptType.primary
    );
    const sequentialTranscripts = transcripts.filter(
      (transcript) => transcript.type === PanelTranscriptType.sequential
    );
    const hasCalculatingTranscripts = !!transcripts.filter(
      (transcript) => transcript.status === PanelTranscriptStatus.calculating
    ).length;
    const totalAbundance = getTotalAbundance(primaryTranscripts);
    const merfishThresholdGenesAmount = primaryTranscripts.filter(
      (transcript) => transcript.abundance > MAX_MERFISH_GENE_ABUNDANCE
    ).length;
    const primaryTranscriptsLength = primaryTranscripts.length;
    const sequentialTranscriptsLength = sequentialTranscripts.length;
    const geneExceedsText =
      merfishThresholdGenesAmount > 1 ? 'genes exceed' : 'gene exceeds';
    const geneExceedPronoun = merfishThresholdGenesAmount > 1 ? 'them' : 'it';
    const isProbeMissing = isAuxProbeMissingSelector(state);
    const maxTotalAbundance = maxTotalAbundanceSelector(state);
    const maxMerfishGenesAmount = getMaxMerfishGenesAmount();

    const errorMessages = [
      {
        message: `MERFISH Panel. The draft panel contains ${primaryTranscriptsLength} genes. To proceed please delete genes so that the MERFISH panel has no more than ${maxMerfishGenesAmount} genes.`,
        type: MessageType.MerfishGenesAmountExceeded,
        isVisible: primaryTranscriptsLength > maxMerfishGenesAmount
      },
      {
        message:
          'MERFISH Panel. There are no genes in MERFISH Panel. Please add any to finalize the panel.',
        type: MessageType.NoMerfishGenes,
        isVisible: !primaryTranscriptsLength
      },
      {
        message: `MERFISH Panel. ${merfishThresholdGenesAmount} ${geneExceedsText} single gene abundance threshold. Delete ${geneExceedPronoun} or move ${geneExceedPronoun} to Sequential Panel and try to finalize panel again.`,
        type: MessageType.MerfishThresholdExceeded,
        isVisible: !!merfishThresholdGenesAmount
      },
      {
        message:
          'MERFISH Panel. The abundance of the selected genes is above the abundance threshold. Please delete some genes or move them to the Sequential Panel.',
        type: MessageType.TotalAbundanceExceeded,
        isVisible: totalAbundance > maxTotalAbundance
      },
      {
        message:
          'Sequential Panel. The total number of genes exceeds the available threshold. Delete redundant genes or move them to the MERFISH panel and try to finalize the panel again.',
        type: MessageType.SequentialGenesAmountExceeded,
        isVisible:
          sequentialTranscriptsLength > SEQUENTIAL_TRANSCRIPTS_MAX_AMOUNT
      },
      {
        message:
          'Sequential Panel. Some genes don’t have unique probes assigned.',
        type: MessageType.ProbeMissing,
        isVisible: isProbeMissing
      }
    ];
    setNotifications(errorMessages);

    const hasError = errorMessages.some((error) => error.isVisible);
    if (!hasError) {
      const hasIssue = !!issuesAmountSelector(state);
      setNotifications([
        {
          message:
            'There are still some issues pending. Please resolve them to finalize the panel.',
          type: MessageType.OtherIssues,
          isVisible: hasIssue
        }
      ]);
      setNotifications([
        {
          message:
            'Some exogenous sequences still need to be identified. Try to finalize a bit later.',
          type: MessageType.ExogenousSequencesIdentification,
          isVisible: hasCalculatingTranscripts
        }
      ]);
      if (hasCalculatingTranscripts || hasIssue) {
        return false;
      }

      return true;
    }

    return false;
  },

  finalizePanel: async () => {
    const { panel } = get();
    const { panelId = '' } = panel || {};
    set({
      isFinalizingPanel: true
    });

    try {
      await finalize(panelId);
      const updatedPanel = await getPanel(panelId);
      set({ panel: updatedPanel });
    } catch (e) {
      throw e;
    } finally {
      set({ isFinalizingPanel: false });
    }
  },

  includeInsufficient: async (id, excludeMode) => {
    const { panel } = get();
    const updatedTranscript = await includeInsufficientTranscript(
      panel?.panelId || '',
      id,
      excludeMode
    );
    set((state) => ({
      transcripts: state.transcripts.map((transcript) => {
        if (updatedTranscript.transcriptId === transcript.transcriptId) {
          return updatedTranscript;
        }
        return transcript;
      })
    }));
  },

  includeAllInsufficient: async (transcriptsIds) => {
    const { panel } = get();

    const updatedTranscripts = await Promise.all(
      transcriptsIds.map((id) =>
        includeInsufficientTranscript(panel?.panelId || '', id)
      )
    );

    set((state) => ({
      transcripts: includeUpdatedTranscripts(
        state.transcripts,
        updatedTranscripts
      )
    }));
  },

  includeSynonym: async (geneIdentifier, transcriptId) => {
    const { panel } = get();
    const { panelId = '', tissueId = '', transcriptomeId = '' } = panel || {};
    const geneInfo = {
      geneInput: [
        {
          geneIdentifier
        }
      ],
      panelId,
      tissueId,
      transcriptomeId
    };
    set({
      isUpdatingGenes: true
    });

    try {
      const transcripts = await createPanelTranscriptsFromGenes(geneInfo);
      if (transcripts[0]) {
        await deletePanelTranscript(panelId, transcriptId);
      } else {
        throw new Error();
      }
      set((state) => ({
        transcripts: state.transcripts.map((transcript) => {
          if (transcriptId === transcript.transcriptId) {
            return transcripts[0];
          }
          return transcript;
        })
      }));
    } catch (e) {
      throw e;
    } finally {
      set({ isUpdatingGenes: false });
    }
  },

  clearState: () => {
    set({ ...defaultState });
  }
}));
