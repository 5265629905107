import { HStack, VStack } from '@chakra-ui/react';
import { Clustergrammer } from 'components';
import { MotionStyle } from 'framer-motion';
import { GeneSetSortMethod, GeneSortMethod } from 'generated/types';
import { useClustergrammerData } from 'hooks';
import { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { TranscriptModel, usePanelCompositionStore } from 'store';
import { shallow } from 'zustand/shallow';
import {
  AddGeneSets,
  GenesAxis,
  GeneSetsAxis,
  NonIntersectingGeneList,
  InfoMessage
} from './components';
import {
  useSelectedGeneSets,
  useSetStoreData,
  useSplittedTranscripts
} from './hooks';

interface PanelCompositionProps {
  transcriptomeId: string;
  transcripts: TranscriptModel[];
  clustergrammerStyles: CSSProperties;
  annotationPanelStyles?: MotionStyle;
}

export const PanelComposition: FC<PanelCompositionProps> = ({
  transcriptomeId,
  transcripts,
  clustergrammerStyles,
  annotationPanelStyles
}) => {
  const { panelId = '' } = useParams();
  const [
    userViewSettings,
    geneSetsSource,
    isFetchingData,
    hasFetchingError,
    resetState,
    fetchUserView
  ] = usePanelCompositionStore(
    (state) => [
      state.userViewSettings,
      state.geneSetsSource,
      state.isFetchingData,
      state.hasFetchingError,
      state.resetState,
      state.fetchUserView
    ],
    shallow
  );

  useEffect(() => {
    fetchUserView(panelId, transcriptomeId);
  }, [fetchUserView, panelId, transcriptomeId]);

  const selectedGeneSets = useSelectedGeneSets();

  const { intersecting, nonIntersecting } =
    useSplittedTranscripts(selectedGeneSets);

  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    setSearchValue('');
  }, [selectedGeneSets]);
  const searchedItem = searchValue || undefined;

  const { clustergrammerData, searchedGene, geneSetEntries } =
    useClustergrammerData(
      geneSetsSource,
      intersecting,
      userViewSettings?.geneSortMethod as GeneSortMethod,
      userViewSettings?.geneSetSortMethod as GeneSetSortMethod,
      selectedGeneSets,
      searchedItem
    );

  useSetStoreData({
    geneSetEntries,
    transcripts
  });

  const suggestionItems = useMemo(
    () => [
      ...intersecting.map((i) => ({ value: i.geneName, label: i.geneName })),
      ...Array.from(selectedGeneSets)
        .sort()
        .map((gs) => ({ value: gs, label: gs }))
    ],
    [intersecting, selectedGeneSets]
  );

  useEffect(() => {
    return () => resetState();
  }, [resetState]);

  if (isFetchingData) {
    return <InfoMessage>Loading gene sets library...</InfoMessage>;
  }

  if (hasFetchingError) {
    return <InfoMessage>Error loading gene sets library</InfoMessage>;
  }

  if (!selectedGeneSets.size) {
    return <AddGeneSets />;
  }

  if (!intersecting.length) {
    return (
      <VStack w="100%" spacing="36px">
        <GeneSetsAxis
          suggestionItems={suggestionItems}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <InfoMessage>
          No genes from the panel found in added gene sets
        </InfoMessage>
        <NonIntersectingGeneList transcripts={nonIntersecting} />
      </VStack>
    );
  }

  if (
    !clustergrammerData.row_nodes.length ||
    !clustergrammerData.col_nodes.length
  ) {
    return <InfoMessage>Building the heatmap...</InfoMessage>;
  }

  return (
    <VStack w="100%" spacing="10px">
      <VStack w="100%" spacing="36px">
        <GeneSetsAxis
          suggestionItems={suggestionItems}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <HStack spacing="0" w="100%">
          <GenesAxis />
          <Clustergrammer
            data={clustergrammerData}
            clustergrammerStyles={clustergrammerStyles}
            annotationPanelStyles={annotationPanelStyles}
            selectedGene={searchedGene}
          />
        </HStack>
      </VStack>
      <NonIntersectingGeneList transcripts={nonIntersecting} />
    </VStack>
  );
};
