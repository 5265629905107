import { FC, useState, useMemo } from 'react';
import { Box, Container, Flex, Heading, VStack } from '@chakra-ui/react';
import { convertGeneSetOptions } from 'utils/geneset/convertGeneSetOptions';
import { GeneSetLibraryList } from './components';
import { AutocompleteField } from '@vizgen/vizgen-ui';
import { useAutocompleteData } from './useAutocompleteData';
import { sortStringsByKey } from 'utils';
import { useGeneSetsSource, useGetQuery } from 'hooks';
import { InfoMessage } from 'components/PanelComposition/components';
import { useGlobalListsStore, useUserStore } from 'store';

export const GeneSetLibraries: FC = () => {
  const query = useGetQuery();
  const panelId = query.get('panelId') || '';
  const transcriptomeId = query.get('transcriptomeId') || '';
  const hasParams = panelId && transcriptomeId;
  const [searchValue, setSearchValue] = useState('');
  const transcriptomes = useGlobalListsStore((state) => state.transcriptomes);
  const reference =
    transcriptomes.find((t) => t.transcriptomeId === transcriptomeId)?.name ||
    '';
  const userId = useUserStore((state) => state.attributes?.userId || '');

  const {
    data: geneSetsSource,
    error: geneSetsSourceError,
    isLoading: isGeneSetsSourceLoading
  } = useGeneSetsSource(panelId, transcriptomeId, userId);

  const library = useMemo(
    () => convertGeneSetOptions(geneSetsSource),
    [geneSetsSource]
  );

  const geneSetLibraries = useMemo(
    () =>
      library
        .map((category) => category.children)
        .flat()
        .sort(sortStringsByKey('label')),
    [library]
  );

  const { suggestions, geneSets, genes } =
    useAutocompleteData(geneSetLibraries);

  const searchedGene = genes.has(searchValue) ? searchValue : '';
  const searchedGeneSet = geneSets.has(searchValue) ? searchValue : '';

  return (
    <Box p="64px 0 0 0" h="100%" overflow="hidden">
      <Container maxW="1248px" p="0" h="100%">
        <VStack spacing="0" w="100%" h="100%">
          {Object.entries(geneSetsSource.categories).length && (
            <Flex justify="space-between" w="100%" align="center" p="0 60px">
              <Heading variant="subtitle" fontWeight="heavy">
                {reference[0].toUpperCase() + reference.slice(1)} gene set
                libraries
              </Heading>
              <AutocompleteField
                suggestions={suggestions}
                name="genesSearch"
                placeholder="Search gene/gene set"
                onChange={(e) => setSearchValue(e?.label || '')}
                suggestionsLimit={1000}
              />
            </Flex>
          )}
          {isGeneSetsSourceLoading && hasParams && (
            <InfoMessage>Loading gene sets library...</InfoMessage>
          )}
          {(!!geneSetsSourceError || !hasParams) && (
            <InfoMessage>Error loading gene sets library</InfoMessage>
          )}
          <Box overflow="hidden" w="100%" p="16px 60px 40px">
            <Box overflow="auto" w="100%" h="100%">
              <GeneSetLibraryList
                searchedGene={searchedGene}
                searchedGeneSet={searchedGeneSet}
                geneSetLibraries={geneSetLibraries}
              />
            </Box>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};
