import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { TranscriptModel } from 'store';

interface NonIntersectingGeneListProps {
  transcripts: TranscriptModel[];
}

export const NonIntersectingGeneList: FC<NonIntersectingGeneListProps> = ({
  transcripts
}) => {
  if (!transcripts.length) {
    return null;
  }

  return (
    <Box w="100%">
      <Text variant="accentBase">
        The following genes in this gene panel were not found in any of the gene
        sets added here:
      </Text>
      <Text>
        {transcripts.map((t) => t.geneName?.toUpperCase()).join(', ')}
      </Text>
    </Box>
  );
};
