import { Button, Center, Flex, Heading } from '@chakra-ui/react';
import { useQuotesListQuery } from 'api/hooks';
import { QuotesTable } from 'components';
import { Paths } from 'routes';
import { Link as RouterLink } from 'react-router-dom';
import { SecondaryButton, SecondaryButtonIcon } from '@vizgen/vizgen-ui';

export const RecentQuotes = () => {
  const { data: quotesList, isLoading } = useQuotesListQuery();

  const slicedQuotes = quotesList?.slice(0, 3) || [];

  return (
    <Flex
      direction="column"
      mt="24px"
      pb="40px"
      borderBottom="1px solid"
      borderColor="border.divider"
    >
      <Flex justify="space-between" alignItems="center" mb="16px">
        <Heading variant="headline">Recent Quote Requests</Heading>
        <SecondaryButton
          icon={SecondaryButtonIcon.Add}
          as={RouterLink}
          to={Paths.NewQuote}
          data-testid="createNewQuoteLink"
        >
          Request a Quote
        </SecondaryButton>
      </Flex>
      <QuotesTable quotesList={slicedQuotes} isLoading={isLoading} isShort />
      <Center>
        <Button
          variant="primaryGhost"
          w="216px"
          h="40px"
          mt="24px"
          as={RouterLink}
          to={Paths.Quotes}
          data-testid="allQuotesLink"
        >
          View All Quote Requests
        </Button>
      </Center>
    </Flex>
  );
};
