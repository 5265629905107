import {
  HStack,
  VStack,
  Text,
  Button,
  Box,
  useDisclosure,
  Collapse,
  Flex
} from '@chakra-ui/react';
import { UserProps } from 'generated/types';
import { FC, useState } from 'react';
import { RemoveUserModal, AddUser, AddButton } from './components';

interface UsersProps {
  users: UserProps[];
  otherUsers: UserProps[];
  isEditable: boolean;
  isAdmin?: boolean;
  instrumentUniqueId: string;
}

export const Users: FC<UsersProps> = ({
  isEditable,
  isAdmin = false,
  users,
  otherUsers,
  instrumentUniqueId,
  children
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedUserEmail, setSelectedUserEmail] = useState('');
  const sortedUsers = users.sort((a, b) => (a.name > b.name ? 1 : -1));

  if (!isEditable && !users.length) {
    return <>—</>;
  }

  return (
    <>
      <VStack spacing="16px" mb="14px">
        {users.length && (
          <VStack spacing="12px" w="100%" align="start">
            {sortedUsers.map(({ name, familyName, email }) => (
              <HStack
                key={email}
                justify="space-between"
                align="center"
                w="100%"
              >
                <Text variant="bodyShort">
                  {name} {familyName} ({email})
                </Text>
                {isEditable && (
                  <Button
                    variant="primaryGhost"
                    h="28px"
                    px="8px"
                    minW="auto"
                    data-testid="removeButton"
                    onClick={() => setSelectedUserEmail(email)}
                  >
                    Remove
                  </Button>
                )}
              </HStack>
            ))}
          </VStack>
        )}
        {isEditable && (
          <>
            <Box w="100%">
              <Collapse
                in={isOpen}
                style={{
                  borderRadius: '8px',
                  boxShadow: '0px 4px 8px 0px rgba(215, 217, 220, 0.4)'
                }}
                animateOpacity
                unmountOnExit
              >
                <AddUser
                  instrumentUniqueId={instrumentUniqueId}
                  isAdmin={isAdmin}
                  users={users}
                  otherUsers={otherUsers}
                  onCancel={onClose}
                />
              </Collapse>
              {isOpen || (
                <Flex justify="center">
                  <AddButton onClick={onOpen}>{children}</AddButton>
                </Flex>
              )}
            </Box>
          </>
        )}
      </VStack>
      <RemoveUserModal
        userEmail={selectedUserEmail}
        isAdmin={isAdmin}
        instrumentUniqueId={instrumentUniqueId}
        onClose={() => setSelectedUserEmail('')}
      />
    </>
  );
};
