import { ValidationError } from 'models';

export const validatePassword = (
  password: string,
  repeatPassword: string
): ValidationError | undefined => {
  const error = { code: 'validationError' };

  if (!password) {
    return {
      ...error,
      field: 'password',
      message: 'Please fill in password'
    };
  }

  const validator = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\W_])(?=.{9,})'
  );

  if (password && !validator.test(password)) {
    return {
      ...error,
      field: 'password',
      message: 'Password does not meet policy requirements'
    };
  }

  if (password && password !== repeatPassword) {
    return { ...error, field: 'password', message: "Passwords don't match" };
  }
};
