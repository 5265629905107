import { FC } from 'react';
import {
  TableColumnHeaderProps,
  TableCellProps,
  Td,
  Text,
  Th,
  Tr
} from '@chakra-ui/react';

interface SmallSummaryRowProps {
  field: string;
  value?: string | number | null;
  cellTextCss?: any;
  cellProps?: TableCellProps;
  headerProps?: TableColumnHeaderProps;
}

export const SmallSummaryRow: FC<SmallSummaryRowProps> = ({
  field,
  value,
  cellTextCss,
  headerProps,
  cellProps,
  children
}) => {
  return (
    <Tr
      h="40px"
      css={{ '&:last-of-type th, &:last-of-type td': { borderBottom: 0 } }}
    >
      <Th
        w="42%"
        p="10px 12px"
        borderBottom="1px solid"
        borderColor="border.normal"
        textTransform="unset"
        letterSpacing="0"
        verticalAlign="baseline"
        {...headerProps}
      >
        <Text as="span" variant="accentCaptionHigh" color="content.primary">
          {field}
        </Text>
      </Th>
      <Td
        w="58%"
        maxW="220px"
        p="10px 8px"
        borderBottom="1px solid"
        borderColor="border.normal"
        data-testid={`${field.toLowerCase()}Value`}
        {...cellProps}
      >
        {children || (
          <Text variant="captionHigh" isTruncated css={cellTextCss}>
            {value}
          </Text>
        )}
      </Td>
    </Tr>
  );
};
