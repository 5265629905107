import { VStack } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { GeneSetLibrary as GeneSetLibraryItem } from 'store';
import { GeneSetLibrary } from './components';

interface GeneSetLibraryListProps {
  searchedGene: string;
  searchedGeneSet: string;
  geneSetLibraries: GeneSetLibraryItem[];
}

export const GeneSetLibraryList: FC<GeneSetLibraryListProps> = ({
  searchedGene,
  searchedGeneSet,
  geneSetLibraries
}) => {
  const [expandedGeneSetLibraryName, setExpandedGeneSetLibraryName] =
    useState('');

  return (
    <VStack spacing="0" w="100%" h="100%" display="contents">
      {geneSetLibraries.map((geneSetLibrary) => (
        <GeneSetLibrary
          key={geneSetLibrary.label}
          searchedGene={searchedGene}
          searchedGeneSet={searchedGeneSet}
          expandedGeneSetLibraryName={expandedGeneSetLibraryName}
          onGeneSetLibraryExpand={setExpandedGeneSetLibraryName}
          geneSetLibrary={geneSetLibrary}
        />
      ))}
    </VStack>
  );
};
