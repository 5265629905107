import { fontStyles } from '../fonts';

export const FormLabel = {
  baseStyle: {
    fontSize: fontStyles.fontSizes[14],
    lineHeight: fontStyles.lineHeights[16],
    mb: '4px',
    color: 'content.secondary',
    pl: '12px'
  }
};
