import { FC } from 'react';
import { VStack, Heading, HStack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { Panel, PanelOwnershipType } from 'generated/types';

interface HeaderProps {
  panel: Panel;
}

export const Header: FC<HeaderProps> = ({ panel }) => {
  const { panelName, updatedAt, isCompleted } = panel;
  const { panelType = '' } = useParams();
  const isPromoted = panelType === PanelOwnershipType.promoted;

  return (
    <VStack spacing="4px" w="100%" align="flex-start">
      <Heading as="h1" variant="subtitle" fontWeight="normal">
        Gene Panel - {panelName}
      </Heading>
      <HStack
        spacing="24px"
        w="100%"
        pb="4px"
        borderBottom="1px solid"
        borderColor="printable.additional"
      >
        <HStack spacing="4px">
          <Text color="printable.secondary">Last Updated</Text>
          <Text>{dayjs(updatedAt).format('M/D/YYYY, h:mm A')}</Text>
        </HStack>
        <HStack spacing="4px">
          <Text color="printable.secondary">Status</Text>
          <Text>
            {isPromoted
              ? 'MERSCOPE panel'
              : isCompleted
              ? 'Completed'
              : 'Draft'}
          </Text>
        </HStack>
      </HStack>
    </VStack>
  );
};
