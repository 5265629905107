import { Flex, Text, Button } from '@chakra-ui/react';
import { FC } from 'react';
import { useCreatePanelStore } from 'store';

export const SuccessMessage: FC = () => {
  const sequenceName = useCreatePanelStore(
    (state) => state.addedExogenousSequence
  );
  const resetAddedExogenousSequence = useCreatePanelStore(
    (state) => state.resetAddedExogenousSequence
  );

  return (
    <Flex
      mt="24px"
      flexDir="column"
      justify="space-between"
      h="280px"
      p="16px 8px 0px 8px"
    >
      <Text>
        The sequence{' '}
        <b>
          <i>{sequenceName}</i>
        </b>{' '}
        was sent to the panel.
        <br />
        <br />
        Adding exogenous sequence to the panel might take some time. The process
        will be running in the background you may continue editing
      </Text>
      <Button
        variant="primaryGhost"
        onClick={resetAddedExogenousSequence}
        data-testid="okButton"
      >
        OK
      </Button>
    </Flex>
  );
};
