import { fontStyles } from '../fonts';

export const Heading = {
  baseStyle: {
    color: 'content.primary'
  },
  variants: {
    headline: {
      fontSize: fontStyles.fontSizes[32],
      fontWeight: fontStyles.fontWeights.heavy,
      lineHeight: fontStyles.lineHeights[48]
    },
    subtitle: {
      fontSize: fontStyles.fontSizes[24],
      lineHeight: fontStyles.lineHeights[32],
      fontWeight: fontStyles.fontWeights.normal
    },
    subtitleSuffix: {
      color: 'content.additional',
      fontSize: fontStyles.fontSizes[24],
      lineHeight: fontStyles.lineHeights[32],
      fontWeight: fontStyles.fontWeights.normal
    },
    smallSubtitle: {
      fontSize: fontStyles.fontSizes[20],
      lineHeight: fontStyles.lineHeights[24],
      fontWeight: fontStyles.fontWeights.heavy
    }
  },
  sizes: {
    xl: {
      lineHeight: fontStyles.lineHeights[48],
      fontSize: fontStyles.fontSizes[32]
    }
  }
};
