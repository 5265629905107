import { Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useCreatePanelStore, issuesAmountSelector } from 'store';

export const Title: FC = () => {
  const issuesAmount = useCreatePanelStore(issuesAmountSelector);

  return (
    <Heading
      variant="subtitle"
      d="flex"
      alignItems="center"
      whiteSpace="nowrap"
      flexWrap="nowrap"
    >
      Issues found
      <Text color="content.additional" variant="span" ml="4px">
        ({issuesAmount})
      </Text>
    </Heading>
  );
};
