export const colors = {
  background: {
    primary: '#FFFFFF',
    secondary: '#F4F4F4',
    tertiary: '#E4E5E7',
    additional: '#2B2D43',
    additional2: '#E9EAEC',
    footer: '#15181D',
    highlight: '#F9F9F9',
    overlay: '#000000'
  },
  border: {
    normal: '#D7D9DC',
    selected: '#9B9FA7',
    secondary: '#37404E',
    divider: '#D7D9DC',
    additional: '#2B2D43'
  },
  button: {
    primary: {
      normal: '#E02873',
      hover: '#D11E67',
      pressed: '#BB1B5C'
    },
    secondarySolid: {
      normal: '#37404E',
      hover: '#414C5D',
      pressed: '#4C586C'
    },
    secondaryOutline: {
      normal: 'transparent',
      hover: 'rgba(55, 64, 78, 0.1)',
      pressed: 'rgba(55, 64, 78, 0.1)'
    },
    ghostOnPrimary: {
      normal: '#FFFFFF',
      hover: '#F4F4F4',
      pressed: '#E6E6E6'
    },
    ghostOnSecondary: {
      normal: '#F4F4F4',
      hover: '#FFFFFF',
      pressed: '#E6E6E6'
    },
    tinyGhostPrimary: {
      normal: '#FFFFFF',
      hover: '#F4F4F4',
      pressed: '#E6E6E6'
    },
    tinyGhostSecondary: {
      normal: '#F4F4F4',
      hover: '#FFFFFF',
      pressed: '#E6E6E6'
    },
    secondaryFilled: {
      normal: '#37404E',
      hover: '#414C5D',
      pressed: '#4C586C'
    },
    tertiary: {
      normal: '#C6C8CD',
      hover: '#B8BBC1',
      pressed: '#ABAEB5'
    }
  },
  content: {
    primary: '#15181D',
    secondary: '#37404E',
    tertiary: '#FFFFFF',
    additional: '#9b9fA7',
    additionalTablet: '#727781',
    link: {
      normal: '#E02873',
      hover: '#BB1B5C'
    }
  },
  state: {
    error: '#FB4D46',
    error10Percent: 'rgba(251, 77, 70, 0.1)',
    warning: '#FFBF00',
    success: '#21C147',
    infoLight: '#2BCFE0',
    infoLight10Percent: 'rgba(47, 128, 237, 0.1)',
    infoDark: '#2F80ED',
    searchHighlight: 'rgba(255, 191, 0, 0.3)'
  },
  printable: {
    primary: '#000000',
    secondary: '#666666',
    additional: '#CCCCCC'
  }
};
