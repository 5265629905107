import { FC } from 'react';
import { Box, StackDivider, HStack, VStack, Text } from '@chakra-ui/react';
import { useAddressesStore, addressByIdSelector } from 'store';

interface SummaryProps {
  deliveryAddress: string;
  comment: string;
}

export const Summary: FC<SummaryProps> = ({ deliveryAddress, comment }) => {
  const address = useAddressesStore(addressByIdSelector(deliveryAddress));
  return (
    <Box
      maxW="840px"
      w="100%"
      p="12px 16px"
      border="1px solid"
      borderRadius="8px"
      borderColor="border.normal"
      mb="32px"
    >
      <HStack
        spacing="12px"
        h="100%"
        alignItems="start"
        divider={<StackDivider borderColor="border.divider" />}
      >
        <VStack spacing="8px" alignItems="start" flex="1">
          <Text variant="bodyShort" color="content.primary" fontWeight="heavy">
            Address
          </Text>
          <Text variant="bodyShort">{address.address}</Text>
        </VStack>
        <VStack spacing="8px" alignItems="start" flex="1">
          <Text variant="bodyShort" color="content.primary" fontWeight="heavy">
            Comment
          </Text>
          <Text variant="bodyShort">{comment}</Text>
        </VStack>
      </HStack>
    </Box>
  );
};
