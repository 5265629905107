import { useEffect } from 'react';
import { useNewOrderStore } from 'store';

export const useIncompatibilityNotifications = () => {
  const validateIncompatibilities = useNewOrderStore(
    (state) => state.validateIncompatibilities
  );
  const additionalExtras = useNewOrderStore((state) => state.additionalExtras);
  const panels = useNewOrderStore((state) => state.panels);

  useEffect(() => {
    validateIncompatibilities();
  }, [additionalExtras, panels, validateIncompatibilities]);
};
