import { Center, HStack, Spinner, Heading } from '@chakra-ui/react';

export const PanelLoader = () => {
  return (
    <Center h="100%" w="100%">
      <HStack spacing="4px">
        <Spinner color="content.additional" size="xl" />
        <Heading color="content.additional" as="span">
          Loading panel...
        </Heading>
      </HStack>
    </Center>
  );
};
