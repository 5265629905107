import { Stack, Heading } from '@chakra-ui/react';
import { Address } from '../Address';
import { AddressType } from 'generated/types';
import { useAddressesStore, addressByTypeSelector } from 'store';

export const BillingAddresses = () => {
  const addresses = useAddressesStore(
    addressByTypeSelector(AddressType.billing)
  );
  return addresses.length ? (
    <Stack spacing="28px" w="100%">
      <Heading as="h2" variant="subtitle">
        Billing Addresses
      </Heading>
      <Stack spacing="16px" w="100%">
        {addresses.map((address) => (
          <Address
            key={address.addressId}
            addressId={address.addressId}
            address={address.address}
          />
        ))}
      </Stack>
    </Stack>
  ) : null;
};
