import { CheckboxProps, ComponentWithAs, forwardRef } from '@chakra-ui/react';
import { MutableRefObject, useEffect, useRef } from 'react';
import { TableToggleRowsSelectedProps } from 'react-table';
import { CheckboxInput } from '../CheckboxInput';

export const IndeterminateCheckbox = forwardRef<
  TableToggleRowsSelectedProps,
  ComponentWithAs<'input', CheckboxProps>
>(({ indeterminate, checked, ...rest }, ref) => {
  const defaultRef = useRef<boolean>(null);
  const resolvedRef = (ref as MutableRefObject<HTMLInputElement>) || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = !!indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <span style={{ width: '32px', maxWidth: '32px', height: '100%' }}>
      <CheckboxInput
        ref={resolvedRef}
        size="sm"
        {...rest}
        isChecked={checked}
        isIndeterminate={indeterminate}
        h="100%"
        w="100%"
        p="0 4px"
        justifyContent="center"
      />
    </span>
  );
});
