import { FC, useRef, useEffect, useState } from 'react';
import { Flex, Text, IconButton, Fade, Box } from '@chakra-ui/react';
import { CloseIcon } from './assets';
import { SearchButton } from './components';
import { SearchField } from '@vizgen/vizgen-ui';

interface TitleProps {
  panelsCount: number;
  isSearchVisible: boolean;
  setIsSearchVisible: (value: boolean) => void;
  onSearchChange: (value: string) => void;
}

export const Title: FC<TitleProps> = ({
  panelsCount,
  isSearchVisible,
  setIsSearchVisible,
  onSearchChange
}) => {
  const searchInput = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (isSearchVisible) {
      searchInput?.current?.focus();
    }
  }, [isSearchVisible]);

  const hideSearch = () => {
    setIsSearchVisible(false);
    updateValue('');
  };

  const showSearch = () => {
    setIsSearchVisible(true);
  };

  const updateValue = (value: string) => {
    setValue(value);
    onSearchChange(value);
  };

  return (
    <Flex w="100%" justify="space-between">
      {isSearchVisible ? (
        <Box w="100%">
          <Fade in={isSearchVisible}>
            <Flex alignItems="center" justify="space-between" w="100%">
              <SearchField
                value={value}
                ref={searchInput}
                name="panelsSearch"
                placeholder="Search panels"
                onChange={(e) => updateValue(e.target.value)}
                isRightElementShown={!!value}
                rightElementProps={{
                  onClick: () => updateValue('')
                }}
              />
              <IconButton
                onClick={hideSearch}
                size="icon"
                variant="ghost"
                aria-label="Close search panels"
                ml="4px"
                icon={<CloseIcon />}
              />
            </Flex>
          </Fade>
        </Box>
      ) : (
        <>
          <Text
            as="span"
            fontSize="inherit"
            lineHeight="inherit"
            color="content.primary"
          >
            Add Panels{' '}
            <Text
              as="span"
              color="content.additional"
              fontSize="inherit"
              lineHeight="inherit"
            >
              ({panelsCount})
            </Text>
          </Text>
          <SearchButton onOpenSearch={showSearch} />
        </>
      )}
    </Flex>
  );
};
