import { DeepOmit, getTissueListQuery, getTranscriptomeListQuery } from 'api';
import { ListTranscriptomesQuery, ListTissuesQuery } from 'generated/types';
import { create } from 'zustand';

export type TissueModel = DeepOmit<ListTissuesQuery>['listTissues'][0];
export type ReferenceModel =
  DeepOmit<ListTranscriptomesQuery>['listTranscriptomes'][0];

type GlobalListsState = {
  tissues: TissueModel[];
  transcriptomes: ReferenceModel[];
  getLists: (transcriptomeIds: string[] | null) => Promise<void>;
};

export const useGlobalListsStore = create<GlobalListsState>()((set) => ({
  tissues: [],
  transcriptomes: [],

  getLists: async (transcriptomeIds) => {
    const [listTissues, listTranscriptomes] = await Promise.all([
      getTissueListQuery(),
      getTranscriptomeListQuery(transcriptomeIds)
    ]);
    const tissues = listTissues
      .filter((tissue) => tissue.enabled !== false)
      .sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      ) as unknown as TissueModel[];
    const transcriptomes = listTranscriptomes
      .filter(({ transcriptomeId }) =>
        tissues.some((tissue) => transcriptomeId === tissue.transcriptomeId)
      )
      .sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      ) as unknown as ReferenceModel[];
    set({
      tissues,
      transcriptomes
    });
  }
}));
