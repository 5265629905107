import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { fontStyles } from '../fonts';

const getIsDisabled = (colorScheme: string) => colorScheme === 'disabled';

export const Badge = {
  baseStyle: {
    py: '2px',
    px: '8px',
    fontSize: fontStyles.fontSizes[12],
    lineHeight: fontStyles.lineHeights[16],
    textTransform: 'none',
    borderRadius: '16px',
    fontWeight: 'heavy',
    width: 'fit-content'
  },
  variants: {
    green: ({ colorScheme }: StyleFunctionProps) => ({
      bg: 'state.success',
      color: 'content.tertiary',
      opacity: getIsDisabled(colorScheme) ? 0.25 : 1
    }),
    yellow: ({ colorScheme }: StyleFunctionProps) => ({
      bg: getIsDisabled(colorScheme)
        ? 'state.searchHighlight'
        : 'state.warning',
      color: getIsDisabled(colorScheme)
        ? 'content.additional'
        : 'content.secondary'
    }),
    red: ({ colorScheme }: StyleFunctionProps) => ({
      bg: getIsDisabled(colorScheme) ? 'state.error10Percent' : 'state.error',
      color: getIsDisabled(colorScheme)
        ? 'rgba(55, 64, 78, 0.25)'
        : 'content.tertiary'
    }),
    gray: ({ colorScheme }: StyleFunctionProps) => ({
      bg: getIsDisabled(colorScheme)
        ? 'background.secondary'
        : 'border.selected',
      color: getIsDisabled(colorScheme)
        ? 'rgba(55, 64, 78, 0.25)'
        : 'content.tertiary'
    })
  }
};
