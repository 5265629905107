import { listRegionsQuery } from 'api';
import { useQuery } from 'react-query';

export const useCountryRegionsItems = (countryId?: string) => {
  const { data, isLoading } = useQuery(
    ['listCountryRegions', countryId],
    async () => {
      if (!countryId) {
        return [];
      }
      return (await listRegionsQuery({ countryId }))?.map((c) => ({
        label: c.name,
        value: c.regionId
      }));
    }
  );
  return {
    items: data || [],
    isLoading
  };
};
