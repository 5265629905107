import { FC } from 'react';
import {
  HStack,
  MenuList,
  MenuItem,
  Menu,
  MenuButton,
  Box
} from '@chakra-ui/react';
import { UserIcon, TermsOfUseIcon, SignOutIcon, EditIcon } from './assets';
import { Link as BrowserLink, useNavigate } from 'react-router-dom';
import { Paths } from 'routes';
import { theme } from '@vizgen/vizgen-ui';
import { useClearCache } from 'hooks';
import { useUserStore } from 'store';
import { shallow } from 'zustand/shallow';
import { UserProperties } from 'api';
const { colors } = theme;

export const UserMenu: FC = () => {
  const clearCache = useClearCache();
  const navigate = useNavigate();
  const [attributes, signOut] = useUserStore(
    (state) => [state.attributes || {}, state.signOut],
    shallow
  );
  const { name = '', familyName = '' } = (attributes || {}) as UserProperties;
  const username = `${name} ${familyName}`.trim() || 'Profile';

  return (
    <Menu>
      <MenuButton
        borderRadius="4px"
        p="12px"
        bg="button.ghostOnPrimary.normal"
        color="content.additional"
        _hover={{
          bg: 'button.ghostOnPrimary.hover',
          color: 'content.secondary'
        }}
        _active={{
          bg: 'button.ghostOnPrimary.pressed',
          color: 'content.secondary',
          path: {
            stroke: colors.content.secondary
          }
        }}
        css={{
          '&:hover path': {
            transition: 'all 250ms ease-in-out',
            stroke: colors.content.secondary
          }
        }}
        data-testid="userMenuHeaderButton"
      >
        <HStack spacing="4px">
          <Box>
            <UserIcon />
          </Box>
          <Box
            display={{
              xs: 'none',
              sm: 'none',
              md: 'initial'
            }}
            textAlign="left"
          >
            {username}
          </Box>
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuItem
          as={BrowserLink}
          to={`${Paths.Profile}`}
          data-testid="profilePageLink"
        >
          <HStack spacing="4px">
            <Box>
              <EditIcon />
            </Box>
            <Box>Edit Profile</Box>
          </HStack>
        </MenuItem>
        <MenuItem
          as={BrowserLink}
          to={`${Paths.TermsOfUse}`}
          data-testid="termsOfUseLink"
        >
          <HStack spacing="4px">
            <Box>
              <TermsOfUseIcon />
            </Box>
            <Box>EULA</Box>
          </HStack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            signOut();
            clearCache();
            navigate(Paths.Home);
          }}
          data-testid="signOutButton"
        >
          <HStack spacing="4px">
            <Box>
              <SignOutIcon />
            </Box>
            <Box>Sign Out</Box>
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
