import { useEffect } from 'react';

export const useKeyPress = (keys: string[], action: () => void) => {
  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      if (keys.includes(e.key)) {
        action();
      }
    };
    window.addEventListener('keyup', onKeyUp);
    return () => window.removeEventListener('keyup', onKeyUp);
  }, [keys, action]);
};
