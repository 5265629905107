import { MenuItem, Box } from '@chakra-ui/react';
import { FC } from 'react';
import { useCreatePanelStore, transcriptByIdSelector } from 'store';
import { CheckIcon, CloseIcon } from './assets';

interface IncludeItemProps {
  isVisible: boolean;
  transcriptId: string;
}

export const IncludeItem: FC<IncludeItemProps> = ({
  isVisible,
  transcriptId
}) => {
  const transcript = useCreatePanelStore(transcriptByIdSelector(transcriptId));
  const includeInsufficient = useCreatePanelStore(
    (state) => state.includeInsufficient
  );

  if (!isVisible) {
    return null;
  }

  const isIncluded = transcript?.isIncluded || false;

  const onClickHandler = () => {
    includeInsufficient(transcriptId, isIncluded ? true : false);
  };

  return (
    <MenuItem onClick={onClickHandler}>
      <Box>{isIncluded ? <CloseIcon /> : <CheckIcon />}</Box>
      <Box ml="4px">{isIncluded ? 'Exclude from panel' : 'Include anyway'}</Box>
    </MenuItem>
  );
};
