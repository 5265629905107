import { Text } from '@chakra-ui/react';
import { GeneStatusIcon } from 'components';
import { FC } from 'react';
import { PanelTranscriptStatusExtended } from 'types';

interface DuplicatedGenesDetailProps {
  amount: number;
}

export const DuplicatedGenesDetail: FC<DuplicatedGenesDetailProps> = ({
  amount
}) => {
  return (
    <Text as="span" d="flex" alignItems="center">
      <GeneStatusIcon type={PanelTranscriptStatusExtended.duplicated} />
      <Text as="span" fontWeight="800" ml="8px">
        {amount}
      </Text>
      <Text
        color="content.additional"
        as="span"
        ml="4px"
        whiteSpace="nowrap"
        d={{ base: 'none', lg: 'block' }}
      >
        duplicates
      </Text>
    </Text>
  );
};
