import { Flex, HStack, Text } from '@chakra-ui/react';
import CheckIcon from './assets/check.svg';
import ErrorIcon from './assets/error.svg';
import { FC } from 'react';

interface MessageProps {
  hasError: boolean;
}

export const Message: FC<MessageProps> = ({ hasError }) => {
  return (
    <Flex
      h="40px"
      p="8px 16px"
      borderRadius="24px"
      bg={hasError ? 'state.error10Percent' : 'background.secondary'}
      alignItems="center"
      justify="center"
    >
      <HStack spacing="4px">
        {hasError ? <ErrorIcon /> : <CheckIcon />}
        <Text>
          {hasError
            ? 'An error occurred while saving the changes, please, try again'
            : 'Changes saved successfully!'}
        </Text>
      </HStack>
    </Flex>
  );
};
