import { Spinner, Flex } from '@chakra-ui/react';
import { FC } from 'react';

export const Calculating: FC = () => {
  return (
    <Flex h="100%" w="100%" alignItems="center" justify="center">
      <Spinner
        thickness="1.5px"
        color="content.secondary"
        emptyColor="content.additional"
        size="icon"
      />
    </Flex>
  );
};
