import { matchGeneSet } from '../../utils';
import { LibraryCategory } from 'store';

export const getTotalItems = (libraryCategories: LibraryCategory[]) => {
  let totalGeneSetsAmount = 0;
  let totalLibrariesCategoriesAmount = 0;
  for (let i = 0; i < libraryCategories.length; i++) {
    totalLibrariesCategoriesAmount += libraryCategories[i].children.length;
    for (let j = 0; j < libraryCategories[i].children.length; j++) {
      totalGeneSetsAmount += libraryCategories[i].children[j].children.length;
    }
  }
  return {
    totalGeneSetsAmount,
    totalLibrariesCategoriesAmount
  };
};

export const getFoundItems = (
  libraryCategories: LibraryCategory[],
  searchValue: string
) => {
  let foundGeneSetsAmount = 0;
  let foundLibrariesCategoriesAmount = 0;
  for (let i = 0; i < libraryCategories.length; i++) {
    for (let j = 0; j < libraryCategories[i].children.length; j++) {
      let librariesAmount = 0;
      for (
        let k = 0;
        k < libraryCategories[i].children[j].children.length;
        k++
      ) {
        const isMatch = matchGeneSet(
          libraryCategories[i].children[j].children[k].label,
          searchValue
        );
        if (isMatch) {
          foundGeneSetsAmount++;
          if (!librariesAmount) {
            librariesAmount++;
            foundLibrariesCategoriesAmount++;
          }
        }
      }
    }
  }
  return {
    foundGeneSetsAmount,
    foundLibrariesCategoriesAmount
  };
};
