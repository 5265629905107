import { FC, useState, useEffect } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { PanelsList } from 'components';
import { Panel } from 'generated/types';

const tabStyles = {
  border: 'none',
  h: '40px',
  mb: '0',
  color: 'content.additional',
  bg: 'background.primary'
};

const tabSelectedStyles = {
  fontWeight: 'heavy',
  color: 'content.primary',
  borderColor: 'border.normal'
};

const tabHoverStyles = {
  bg: 'background.secondary'
};

interface PanelsProps {
  finalizedPanels: Panel[];
  promotedPanels: Panel[];
}

export const Panels: FC<PanelsProps> = ({
  finalizedPanels,
  promotedPanels
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const finalizedPanelsAmount = finalizedPanels.length;
  const promotedPanelsAmount = promotedPanels.length;

  useEffect(() => {
    if (finalizedPanelsAmount && !promotedPanelsAmount) {
      setTabIndex(0);
    } else if (!finalizedPanelsAmount && promotedPanelsAmount) {
      setTabIndex(1);
    }
  }, [finalizedPanelsAmount, promotedPanelsAmount]);

  return (
    <Tabs index={tabIndex} onChange={setTabIndex} isLazy>
      <TabList border="1px solid" borderRadius="4px" color="border.normal">
        <Tab
          {...tabStyles}
          w="45%"
          borderLeftRadius="4px"
          _selected={{
            borderRight: '1px solid',
            ...tabSelectedStyles
          }}
          _hover={{
            ...tabHoverStyles
          }}
        >
          My Panels ({finalizedPanelsAmount})
        </Tab>
        <Tab
          {...tabStyles}
          w="55%"
          borderRightRadius="4px"
          _selected={{
            borderLeft: '1px solid',
            ...tabSelectedStyles
          }}
          _hover={{
            ...tabHoverStyles
          }}
        >
          MERSCOPE Panels ({promotedPanelsAmount})
        </Tab>
      </TabList>
      <TabPanels border="none" mt="8px">
        <TabPanel p="0">
          <PanelsList panels={finalizedPanels} />
        </TabPanel>
        <TabPanel p="0">
          <PanelsList panels={promotedPanels} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
