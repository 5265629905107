import { Box, VStack, Heading, Button, useDisclosure } from '@chakra-ui/react';
import { ChangePasswordForm } from './components';

export const AuthSettings = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box w="50%">
      <Heading as="h2" variant="subtitle" mb="28px">
        Password
      </Heading>
      <VStack spacing="16px" alignItems="start">
        {isOpen ? (
          <ChangePasswordForm onClose={onClose} />
        ) : (
          <Button
            size="tiny"
            variant="tinyGhostPrimary"
            data-testid="changePasswordButton"
            onClick={onOpen}
          >
            Change Password
          </Button>
        )}
      </VStack>
    </Box>
  );
};
