import {
  HeaderNotification,
  NotificationType
} from '../components/HeaderNotification';
import { useNotificationToast } from './useNotificationToast';

export const useFailureNotification = () => {
  const toast = useNotificationToast(null);

  const showFailureNotification = (message: string) => {
    toast({
      render: ({ onClose }) => (
        <HeaderNotification
          type={NotificationType.Error}
          text={message}
          onClose={onClose}
        />
      )
    });
  };

  const closeFailureNotifications = () => {
    toast.closeAll();
  };

  return { showFailureNotification, closeFailureNotifications };
};
