import { FC } from 'react';
import { Flex, Spinner, HStack, Heading } from '@chakra-ui/react';

export interface LoaderProps {
  message: string;
}

export const Loader: FC<LoaderProps> = ({ message }) => {
  return (
    <Flex
      pos="absolute"
      w="100%"
      h="100%"
      align="center"
      justify="center"
      bg="rgba(155, 159, 167, 0.8)"
      zIndex="loader"
    >
      <HStack spacing="8px">
        <Spinner color="content.secondary" size="xl" />
        <Heading color="content.secondary" as="p">
          {message}
        </Heading>
      </HStack>
    </Flex>
  );
};
