import { FC } from 'react';
import { TranscriptModel } from 'store';
import { VStack, Text, Table, Tbody } from '@chakra-ui/react';
import { Row, Head } from './components';

interface TranscriptsTableProps {
  title: string;
  transcripts: TranscriptModel[];
}

export const TranscriptsTable: FC<TranscriptsTableProps> = ({
  title,
  transcripts
}) => {
  return (
    <VStack spacing="4px" w="100%" align="start">
      <Text variant="accentCaptionHigh">{title}</Text>
      <Table
        mb="33px"
        d="flex"
        flexDir="column"
        m="0"
        border="1px solid"
        color="printable.additional"
        borderBottom="none"
      >
        <Head />
        <Tbody>
          {transcripts.map((transcript) => (
            <Row transcript={transcript} key={transcript.transcriptId} />
          ))}
        </Tbody>
      </Table>
    </VStack>
  );
};
