import { Box, Button, ButtonProps, HStack } from '@chakra-ui/react';
import DownloadIcon from './assets/downloadIcon.svg';
import { FC } from 'react';

export const DownloadButton: FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button variant="tinyGhostPrimary" {...rest}>
      <HStack spacing="4px">
        <DownloadIcon />
        <Box>{children}</Box>
      </HStack>
    </Button>
  );
};
