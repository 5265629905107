import { HStack, IconButton, StackProps, useClipboard } from '@chakra-ui/react';
import { FC } from 'react';
import { CopyIcon, CollapseIcon, ExpandIcon } from './assets';

interface SequenceActionsProps {
  value: string;
  isCollapsed: boolean;
  onCollapseButtonClick: () => void;
  containerProps?: StackProps;
}

export const SequenceActions: FC<SequenceActionsProps> = ({
  value,
  isCollapsed,
  onCollapseButtonClick,
  containerProps = {}
}) => {
  const { onCopy } = useClipboard(value);

  return (
    <HStack
      spacing="8px"
      pos="absolute"
      right="0"
      top="-8px"
      zIndex="docked"
      {...containerProps}
    >
      <IconButton
        aria-label="Copy to clipboard"
        variant="primaryGhost"
        size="auto"
        icon={<CopyIcon />}
        onClick={onCopy}
      />
      <IconButton
        aria-label={isCollapsed ? 'Expand' : 'Collapse'}
        variant="primaryGhost"
        size="auto"
        icon={isCollapsed ? <ExpandIcon /> : <CollapseIcon />}
        onClick={onCollapseButtonClick}
      />
    </HStack>
  );
};
