import { MutableRefObject, useEffect, useRef } from 'react';
import { TableToggleRowsSelectedProps } from 'react-table';
import {
  Radio,
  RadioProps,
  ComponentWithAs,
  forwardRef
} from '@chakra-ui/react';

export const TableRadioButton = forwardRef<
  TableToggleRowsSelectedProps,
  ComponentWithAs<'input', RadioProps>
>(({ indeterminate, checked, ...rest }, ref) => {
  const defaultRef = useRef<boolean>(null);
  const resolvedRef = (ref as MutableRefObject<HTMLInputElement>) || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = !!indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div
      style={{
        width: '32px',
        maxWidth: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Radio ref={resolvedRef} {...rest} variant="small" isChecked={checked} />
    </div>
  );
});
