import { FC } from 'react';
import { Text, HStack, StackProps, Box } from '@chakra-ui/react';
import ErrorIcon from './assets/error.svg';

export interface ErrorMessageProps extends StackProps {
  warning?: boolean;
  error?: string;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({
  error,
  warning,
  ...rest
}) => {
  if (!error) {
    return null;
  }

  return (
    <HStack
      alignContent="center"
      padding="8px 16px"
      spacing="8px"
      bg={warning ? 'rgba(251, 77, 70, 0.1);' : '#FDE0DF'}
      borderRadius="24px"
      w="100%"
      flex="1"
      data-testid="errorMessage"
      {...rest}
    >
      <Box w="32px" h="32px">
        <ErrorIcon />
      </Box>
      <Text p="0" color={warning ? 'state.warning' : 'state.error'}>
        {error}
      </Text>
    </HStack>
  );
};
