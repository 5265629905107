export const CSV_MIME_TYPES = [
  'text/plain',
  'text/x-csv',
  'application/vnd.ms-excel',
  'application/csv',
  'application/x-csv',
  'text/csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
  'text/tab-separated-values'
];

export const CSV_MAX_FILESIZE = 5000000; // 5Mb
