import { validatePassword } from './validatePassword';
import { ValidationError } from 'models';
import { validateConfirmationCode } from './validateConfirmationCode';

export interface NewPasswordData {
  code?: string;
  password?: string;
  repeatPassword?: string;
}

export const validateChangePasswordForm = (
  formData: NewPasswordData
): ValidationError | undefined => {
  const { password = '', repeatPassword = '', code = '' } = formData;
  return (
    validateConfirmationCode(code) || validatePassword(password, repeatPassword)
  );
};

export const validateEmail = (email: string): ValidationError | undefined => {
  if (!email) {
    return {
      code: 'validationError',
      field: 'email',
      message: 'Please fill in email'
    };
  }
};
