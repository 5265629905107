import { Draggable } from 'react-beautiful-dnd';
import { ColumnInstance } from 'react-table';
import { ColumnSelect } from './components';
import { Portal } from '@chakra-ui/react';
import { TableColumnsLabels } from '../../../../../../../../../../types';
import { ColumnFilter } from '../../../../ManageTableColumns';

interface ColumnProps<T extends {}> {
  column: ColumnInstance<T>;
  labels: TableColumnsLabels;
  index: number;
  disabledColumns: string[];
  setFilter: ColumnFilter;
}

export const Column = <T extends {}>({
  column,
  labels,
  index,
  disabledColumns,
  setFilter
}: ColumnProps<T>) => {
  const isDragDisabled = disabledColumns.some(
    (columnId) => columnId === column.id
  );

  return (
    <Draggable
      draggableId={column.id}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {({ innerRef, dragHandleProps, draggableProps }, { isDragging }) => {
        const Component = (
          <div ref={innerRef} {...dragHandleProps} {...draggableProps}>
            <ColumnSelect
              column={column}
              isDragging={isDragging}
              labels={labels}
              disabledColumns={disabledColumns}
              setFilter={setFilter}
            />
          </div>
        );

        return isDragging ? <Portal>{Component}</Portal> : Component;
      }}
    </Draggable>
  );
};
