import { FC } from 'react';
import { WarningIcon, NotFoundIcon, DuplicateIcon } from './assets';
import { PanelTranscriptStatus } from 'generated/types';
import { ErrorFilledSmallIcon } from 'assets';
import { PanelTranscriptStatusExtended } from 'types';

interface IconProps {
  type: PanelTranscriptStatus | PanelTranscriptStatusExtended;
}

export const GeneStatusIcon: FC<IconProps> = ({ type }) => {
  switch (type) {
    case PanelTranscriptStatusExtended.duplicated: {
      return <DuplicateIcon />;
    }
    case PanelTranscriptStatus.insufficient_tr: {
      return <WarningIcon />;
    }
    case PanelTranscriptStatus.untargetable: {
      return <ErrorFilledSmallIcon />;
    }
    case PanelTranscriptStatus.not_found: {
      return <NotFoundIcon />;
    }
    default: {
      return null;
    }
  }
};
