import { ConfirmModal, useSuccessNotification } from '@vizgen/vizgen-ui';
import { useRemoveInstrumentUserMutation } from 'api/hooks';
import { InstrumentUserRole } from 'generated/types';
import { FC } from 'react';
import { useUserStore } from 'store';
import { AdminDeletingWarning } from './components';

interface RemoveUserModalProps {
  isAdmin: boolean;
  userEmail: string;
  instrumentUniqueId: string;
  onClose: () => void;
}

const ModalComponent: FC<RemoveUserModalProps> = ({
  isAdmin,
  userEmail,
  instrumentUniqueId,
  onClose
}) => {
  const showSuccessNotification = useSuccessNotification();
  const { mutateAsync: removeInstrumentUser, isLoading } =
    useRemoveInstrumentUserMutation();
  const currentUserEmail = useUserStore(
    (state) => state.attributes?.email || ''
  );

  const isDeletingMyself = isAdmin && currentUserEmail === userEmail;
  const role = isAdmin ? 'administrator' : 'viewer';
  const title = `Remove instrument ${role}`;
  const text = `You are about to remove user from the instrument ${
    isAdmin ? 'administrators' : 'viewers'
  } list. The user will no longer has an access to the instrument details`;
  const notification = `The ${role} has been removed from the instrument`;
  const submitText = isDeletingMyself
    ? 'I understand, remove ME anyway'
    : 'Remove';

  const onSubmit = async () => {
    await removeInstrumentUser({
      instrumentUniqueId,
      userEmail,
      role: isAdmin ? InstrumentUserRole.Admin : InstrumentUserRole.Viewer
    });
    onClose();
    showSuccessNotification(notification);
  };

  return (
    <ConfirmModal
      title={title}
      text={isDeletingMyself ? <AdminDeletingWarning /> : text}
      isInProgress={isLoading}
      onClose={onClose}
      submitText={submitText}
      contentProps={{ maxW: isAdmin ? '453px' : '384px' }}
      onSubmit={onSubmit}
    />
  );
};

export const RemoveUserModal: FC<RemoveUserModalProps> = (props) => {
  if (!props.userEmail) {
    return null;
  }

  return <ModalComponent {...props} />;
};
