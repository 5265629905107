import { FC } from 'react';
import { Box, Button, ButtonProps, Center, HStack } from '@chakra-ui/react';
import EditIcon from './assets/editIcon.svg';
import AddIcon from './assets/addIcon.svg';

export enum SecondaryButtonIcon {
  Edit = 'Edit',
  Add = 'Add'
}

interface SecondaryButtonProps extends ButtonProps {
  icon?: SecondaryButtonIcon;
  to?: string;
}

export const SecondaryButton: FC<SecondaryButtonProps> = ({
  children,
  icon,
  ...rest
}) => {
  let buttonIcon = null;

  switch (icon) {
    case SecondaryButtonIcon.Add: {
      buttonIcon = <AddIcon />;
      break;
    }
    case SecondaryButtonIcon.Edit: {
      buttonIcon = <EditIcon />;
      break;
    }
  }

  return (
    <Button h="40px" w="196px" variant="secondarySolid" {...rest}>
      <HStack spacing="4px" w="100%">
        {buttonIcon && <Box w="24px">{buttonIcon}</Box>}
        <Center w="100%">{children}</Center>
      </HStack>
    </Button>
  );
};
