import { updatePanelUserViewMutation } from 'api';
import { getGeneSetsSource } from 'hooks';
import { useUserStore } from 'store';
import { convertGeneSetLibraries } from 'utils/geneset/convertGeneSetLibraries';
import { convertGeneSetOptions } from 'utils/geneset/convertGeneSetOptions';
import { getSelectedGeneSets } from 'utils/geneset/getSelectedGeneSets';
import { create } from 'zustand';
import { PanelCompositionState } from './models';
import { fetchUserViewSettings } from './utils';

const initialState = {
  panelId: '',
  transcripts: [],
  geneSets: [],
  geneSetLibraries: [],
  geneSetOptions: [],
  hoveredRow: undefined,
  hoveredColumn: undefined,
  hoveredTile: undefined,
  selectedItem: undefined,
  transcriptomeId: '',
  userViewSettings: undefined,
  isFetchingData: false,
  hasFetchingError: false,
  geneSetsSource: {
    metadata: {
      species: 'abc',
      schemaVersion: '1.0',
      schemaLocation: 'abc'
    },
    categories: {}
  }
};

export const usePanelCompositionStore = create<PanelCompositionState>()(
  (set, get) => ({
    ...initialState,

    setTranscripts: (transcripts) => {
      set({ transcripts: transcripts.filter((t) => !!t.geneName) });
    },

    setGeneSets: (geneSets) => {
      set({ geneSets });
    },

    setHoveredRow: (hoveredRow) => {
      set({ hoveredRow });
    },

    setHoveredColumn: (hoveredColumn) => {
      set({ hoveredColumn });
    },

    setHoveredTile: (hoveredTile) => {
      set({ hoveredTile });
    },

    setSelectedItem: (selectedItem) => {
      set({
        selectedItem,
        hoveredTile: undefined,
        hoveredColumn: undefined
      });
    },

    setSelectedGeneSets: async (geneSetOptions) => {
      const { updateUserViewSettings } = get();
      const geneSets = [...getSelectedGeneSets(geneSetOptions, 'id')];
      await updateUserViewSettings({ geneSets });
      set({ geneSetOptions });
    },

    fetchUserView: async (panelId, transcriptomeId) => {
      const userId = useUserStore.getState().attributes?.userId || '';
      set({ isFetchingData: true, transcriptomeId, panelId });
      try {
        const userViewSettings = await fetchUserViewSettings(
          panelId,
          userId,
          transcriptomeId
        );
        const geneSetsSource = await getGeneSetsSource(
          userViewSettings?.geneSetS3Key || ''
        );
        const selectedGeneSets = new Set(userViewSettings.geneSets);
        set({
          userViewSettings,
          geneSetsSource,
          geneSetOptions: convertGeneSetOptions(
            geneSetsSource,
            selectedGeneSets
          ),
          geneSetLibraries: convertGeneSetLibraries(geneSetsSource),
          isFetchingData: false
        });
      } catch (e) {
        set({ isFetchingData: false, hasFetchingError: true });
      }
    },

    updateUserViewSettings: async (data) => {
      const { panelId } = get();
      const userId = useUserStore.getState().attributes?.userId || '';
      const userViewSettings = await updatePanelUserViewMutation({
        panelId,
        userId,
        ...data
      });
      if (userViewSettings) {
        set({ userViewSettings });
      }
    },

    resetState: () => {
      set({
        ...initialState
      });
    }
  })
);
