import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface LibraryCategoryTitleProps {
  title: string;
}

export const LibraryCategoryTitle: FC<LibraryCategoryTitleProps> = ({
  title
}) => {
  return (
    <Box
      zIndex="1"
      pos="sticky"
      top="0"
      bg="background.tertiary"
      w="100%"
      borderBottom="1px solid"
      borderTop="1px solid"
      borderColor="border.selected"
    >
      <Text p="8px 24px" variant="accentBase">
        {title}
      </Text>
    </Box>
  );
};
