import { FC } from 'react';
import { Button, Flex, FlexProps, HStack } from '@chakra-ui/react';
import { Message } from './components';
import { SecondaryButton, SecondaryButtonIcon } from '../SecondaryButton';

interface ProfileTopbarProps {
  isEditing: boolean;
  isFetching: boolean;
  isMessageVisible: boolean;
  hasError: boolean;
  containerProps: FlexProps;
  onEdit: () => void;
  onCancel: () => void;
}

export const ProfileTopbar: FC<ProfileTopbarProps> = ({
  isEditing,
  isFetching,
  isMessageVisible,
  hasError,
  children,
  containerProps,
  onEdit,
  onCancel
}) => {
  return (
    <Flex justify="space-between" {...containerProps}>
      {children}
      <HStack spacing="24px">
        {isMessageVisible && <Message hasError={hasError} />}
        <Button
          variant="secondarySolid"
          h="40px"
          w="168px"
          type="submit"
          display={isEditing ? 'flex' : 'none'}
          isLoading={isFetching}
          disabled={isFetching}
          data-testid="saveProfileButton"
        >
          Save changes
        </Button>
        <Button
          onClick={onCancel}
          variant="secondarySolid"
          h="40px"
          w="168px"
          display={isEditing && !isFetching ? 'flex' : 'none'}
          data-testid="cancelButton"
        >
          Cancel
        </Button>
        <SecondaryButton
          p="8px 12px"
          icon={SecondaryButtonIcon.Edit}
          onClick={onEdit}
          display={isEditing ? 'none' : 'flex'}
          type="button"
        >
          Edit profile
        </SecondaryButton>
      </HStack>
    </Flex>
  );
};
