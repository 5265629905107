import { Button, VStack, Text } from '@chakra-ui/react';
import { shallow } from 'zustand/shallow';
import { useForm } from 'react-hook-form';
import { useResetPasswordStore } from 'store';
import { InputField } from '@vizgen/vizgen-ui';

interface EmailForm {
  email?: string;
}

export const SendCode = () => {
  const { handleSubmit, register, formState } = useForm();
  const [sendCode, isFetching, errorValidation] = useResetPasswordStore(
    (state) => [state.sendCode, state.isFetching, state.errorValidation],
    shallow
  );

  const submitEmail = (form: EmailForm) => {
    sendCode(form?.email || '');
  };

  return (
    <VStack
      as="form"
      w="100%"
      spacing="24px"
      align="stretch"
      onSubmit={handleSubmit(submitEmail)}
    >
      <Text fontSize="20px" lineHeight="24px">
        Please enter your email address we will send you a confirmation code
      </Text>
      <InputField
        disabled={isFetching}
        w="100%"
        {...register('email')}
        label="Email"
        type="email"
        controlProps={{
          isInvalid: !!errorValidation && errorValidation.field === 'email'
        }}
        placeholder="Enter your email"
        error={
          errorValidation && errorValidation.field === 'email'
            ? errorValidation.message
            : undefined
        }
      />
      <Button
        disabled={isFetching}
        type="submit"
        textTransform="none"
        isLoading={formState.isSubmitting}
        w="100%"
        size="md"
        fontSize="16px"
        h="40px"
        data-testid="sendCodeButton"
      >
        Send Code
      </Button>
    </VStack>
  );
};
