import { FC } from 'react';
import { Table, Tbody } from '@chakra-ui/react';
import { SmallSummaryRow } from '@vizgen/vizgen-ui';
import { formatDateTime } from 'utils';
import { Sequence } from './components';
import { ExogenousSequence } from 'generated/types';

interface SequenceSummaryProps {
  sequence: ExogenousSequence;
}

export const SequenceSummary: FC<SequenceSummaryProps> = ({ sequence }) => {
  return (
    <>
      <Table>
        <Tbody>
          <SmallSummaryRow field="Abundance" value={sequence.fpkm} />
          <SmallSummaryRow field="Length" value={sequence.sequence.length} />
          <SmallSummaryRow
            field="Created"
            value={formatDateTime(sequence.createdAt)}
          />
          <SmallSummaryRow field="Sequence" value="" />
        </Tbody>
      </Table>
      <Sequence sequence={sequence.sequence} />
    </>
  );
};
