import { FC, useEffect, useState } from 'react';
import { ExtraModel } from 'api/types';
import { Box, Button, Stack } from '@chakra-ui/react';
import { Dropdown, DefaultItemType } from '@vizgen/vizgen-ui';
import { useNewOrderStore } from 'store';
import { Extra, ExtraContainer } from 'components';

interface ExtraControlProps {
  extras: ExtraModel[];
}

export const ExtraControl: FC<ExtraControlProps> = ({ extras }) => {
  const [extra, setExtra] = useState<ExtraModel | null>(null);
  const [options, setOptions] = useState<DefaultItemType[]>([]);
  const additionalExtras = useNewOrderStore((state) => state.additionalExtras);
  const editExtras = useNewOrderStore((state) => state.editAdditionalExtras);

  useEffect(() => {
    const options = extras
      .filter(
        ({ extraId }) =>
          !additionalExtras.find(
            (additionalExtra) => additionalExtra.extraId === extraId
          )
      )
      .map(({ extraName, extraId }) => ({
        value: extraId,
        label: extraName
      }));
    setOptions(options);
  }, [extras, additionalExtras]);

  const onAdd = () => {
    if (extra) {
      editExtras(extra, 1);
      setExtra(null);
    }
  };

  const onSelect = (e: DefaultItemType | null) => {
    const extra = extras.find(({ extraId }) => extraId === e?.value);
    if (extra) {
      setExtra(extra);
    }
  };

  return (
    <Stack spacing="16px">
      {extras
        .filter(({ extraId }) =>
          additionalExtras.find(
            (additionalExtra) => additionalExtra.extraId === extraId
          )
        )
        .map((extra) => (
          <Extra key={extra.extraId} extra={extra} />
        ))}
      {options.length && (
        <ExtraContainer>
          <Box w="232px">
            <Dropdown
              name="extras"
              placeholder="Select kit type"
              items={options}
              value={
                extra ? { value: extra.extraId, label: extra.extraName } : null
              }
              onChange={onSelect}
            />
          </Box>
          <Button
            variant="secondaryFilled"
            minW="132px"
            onClick={onAdd}
            data-testid="addButton"
          >
            Add
          </Button>
        </ExtraContainer>
      )}
    </Stack>
  );
};
