import { useState, useEffect } from 'react';

const RENDER_COUNT = 100;

export const useIncrementalListRendering = (itemsList: any[]) => {
  const [renderCount, setRenderCount] = useState(RENDER_COUNT);

  useEffect(() => {
    let counter = renderCount;
    const interval = setInterval(() => {
      if (counter >= itemsList.length) {
        clearInterval(interval);
      } else {
        setRenderCount((prev) => prev + RENDER_COUNT);
        counter += RENDER_COUNT;
      }
    }, 0);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsList]);

  return renderCount;
};
