import { getQuotesList } from 'api';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';

const QUOTES_LIST_QUERY_KEY = 'quotesList';

type Params = {
  limit?: number;
};

export const useQuotesListQuery = (params: Params = {}) => {
  return useQuery([QUOTES_LIST_QUERY_KEY, params], async () => {
    const { listQuotes } = await getQuotesList(params.limit);
    return listQuotes.items
      .map((quote) => ({
        ...quote,
        updatedAt: dayjs(quote.updatedAt).valueOf()
      }))
      .sort((a, b) => b.updatedAt - a.updatedAt);
  });
};
