import { FC } from 'react';
import { Box, Flex, Container } from '@chakra-ui/react';

interface NavigationProps {
  leftButton?: JSX.Element;
  rightButton?: JSX.Element;
}

export const Navigation: FC<NavigationProps> = ({
  leftButton,
  rightButton
}) => {
  return (
    <Box
      bg="background.secondary"
      borderColor="border.divider"
      w="100%"
      borderTop="1px solid"
      borderTopColor="background.tertiary"
    >
      <Container maxW="1440px" p="15px 60px">
        <Flex justify="space-between" w="100%">
          <Box>{leftButton}</Box>
          <Box>{rightButton}</Box>
        </Flex>
      </Container>
    </Box>
  );
};
