import { Box, HStack } from '@chakra-ui/react';
import { Dropdown, DropdownSize } from '@vizgen/vizgen-ui';
import { PanelTranscriptSequentialAuxProbes } from 'generated/types';
import { FC, useState } from 'react';
import { transcriptByIdSelector, useCreatePanelStore } from 'store';
import { ErrorTooltip } from './components';
import { shallow } from 'zustand/shallow';

const getOption = (aux: PanelTranscriptSequentialAuxProbes) => ({
  value: aux,
  label: `Aux ${aux.slice(-1)}`
});

const OPTIONS = Object.values(PanelTranscriptSequentialAuxProbes).map((aux) =>
  getOption(aux)
);

interface ProbeProps {
  transcriptId: string;
}

export const Probe: FC<ProbeProps> = ({ transcriptId }) => {
  const [transcript, isSubmitted, updateAuxProbe] = useCreatePanelStore(
    (state) => [
      transcriptByIdSelector(transcriptId)(state),
      state.isSubmitted,
      state.updateAuxProbe
    ],
    shallow
  );
  const selectedOption = transcript?.auxProbes
    ? getOption(transcript?.auxProbes)
    : null;
  const [isLoading, setIsLoading] = useState(false);
  let error = '';

  if (isSubmitted && !transcript?.auxProbes) {
    error = 'No probe selected for this sequential gene';
  }

  return (
    <HStack
      justify="end"
      w="100%"
      h="100%"
      pr="12px"
      bg={error ? 'state.error10Percent' : ''}
      spacing="4px"
    >
      <ErrorTooltip label={error} />
      <Box w="125px">
        <Dropdown
          usePortal
          size={DropdownSize.Xs}
          items={OPTIONS}
          value={selectedOption}
          name="auxProbes"
          isDisabled={isLoading}
          isLoading={isLoading}
          onChange={async (e) => {
            const { value } = e || {};
            if (value && value !== selectedOption?.value) {
              setIsLoading(true);
              await updateAuxProbe(transcriptId, value);
              setIsLoading(false);
            }
          }}
        />
      </Box>
    </HStack>
  );
};
