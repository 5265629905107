import { ExtraModel, getListExtras } from 'api';
import { useQuery } from 'react-query';

const LIST_EXTRAS_QUERY_KEY = 'listExtras';

export const useListExtrasQuery = (
  extraType?: string,
  refetchOnWindowFocus = true
) => {
  return useQuery(
    LIST_EXTRAS_QUERY_KEY,
    async () => {
      return (await getListExtras(extraType)) as ExtraModel[];
    },
    { refetchOnWindowFocus }
  );
};
