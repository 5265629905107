import { Box, VStack, Text, Button, HStack } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AuthFlow, useUserStore } from 'store';
import { shallow } from 'zustand/shallow';
import { InputPassword, ErrorMessage } from '@vizgen/vizgen-ui';
import { PasswordTips } from 'components';
import { NewPasswordData } from 'utils';

export const TemporaryPasswordForm: FC = () => {
  const { handleSubmit, register, formState, watch } = useForm();
  const formData: NewPasswordData = watch();
  const [
    changeTemporaryPassword,
    isFetching,
    errorValidation,
    error,
    resetErrors,
    setAuthFlow
  ] = useUserStore(
    (state) => [
      state.changeTemporaryPassword,
      state.isFetching,
      state.errorValidation,
      state.error,
      state.resetErrors,
      state.setAuthFlow
    ],
    shallow
  );

  useEffect(
    () => () => {
      resetErrors();
    },
    [] // eslint-disable-line
  );

  return (
    <Box maxW="552px" m="0 auto">
      <VStack w="100%" spacing="24px" align="stretch" p="40px 36px 24px 36px">
        <VStack
          as="form"
          w="100%"
          spacing="24px"
          align="stretch"
          onSubmit={handleSubmit(changeTemporaryPassword)}
        >
          <Text fontSize="20px" lineHeight="24px">
            You logged in using the temporary password, please change password
          </Text>
          <InputPassword
            {...register('password')}
            id="password"
            label="Password"
            placeholder="Enter your password"
            controlProps={{
              isInvalid:
                !!errorValidation && errorValidation.field === 'password'
            }}
            error={
              errorValidation && errorValidation.field === 'password'
                ? errorValidation.message
                : undefined
            }
          />
          <PasswordTips value={formData.password || ''} />
          <InputPassword
            {...register('repeatPassword')}
            id="repeatPassword"
            label="Confirm Password"
            placeholder="Confirm your password"
          />
          <Button
            disabled={isFetching}
            type="submit"
            textTransform="none"
            isLoading={formState.isSubmitting}
            w="100%"
            size="md"
            fontSize="16px"
            h="40px"
            data-testid="changeButton"
          >
            Change password
          </Button>
          <ErrorMessage error={error?.message} />
          <HStack justify="center" spacing="4px">
            <Text>Back to</Text>
            <Button
              minW={0}
              variant="tinyGhostPrimary"
              size="sm"
              h="32px"
              p="4px 8px"
              onClick={() => setAuthFlow(AuthFlow.SignIn)}
              data-testid="signInButton"
            >
              Sign In
            </Button>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};
