import { Text, TextProps } from '@chakra-ui/react';
import { FC } from 'react';

export const ValidationMessage: FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text
      pos="absolute"
      color="state.error"
      variant="captionShort"
      fontSize="14"
      pl="12px"
      mt="3px"
      {...rest}
    >
      {children}
    </Text>
  );
};
