import { Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { GeneLink, GeneStatusIcon } from 'components';
import { FC } from 'react';
import { duplicatedGeneNamesSelector, useCreatePanelStore } from 'store';
import { PanelTranscriptStatusExtended } from 'types';

export const DuplicatesRow: FC = () => {
  const duplicatedGenes = useCreatePanelStore(duplicatedGeneNamesSelector);
  const toggleIssuesPanelExpanded = useCreatePanelStore(
    (state) => state.toggleIssuesPanelExpanded
  );
  const setSearchValue = useCreatePanelStore((state) => state.setSearchValue);

  return (
    <Flex
      p="8px 0 0px 16px"
      alignItems="flex-start"
      justify="start"
      css={{
        '&:first-of-type .issues-icon, &:first-of-type .issues-text': {
          paddingTop: '4px'
        },
        '&:last-of-type .issues-main-content': {
          borderBottom: 0
        }
      }}
    >
      <Flex overflow="hidden" minW="24px" pt="6px" className="issues-icon">
        <GeneStatusIcon type={PanelTranscriptStatusExtended.duplicated} />
      </Flex>
      <Box
        w="calc(100% - 24px)"
        className="issues-main-content"
        borderBottom="1px solid"
        borderColor="border.divider"
        pb="12px"
        pr="12px"
        ml="10px"
      >
        <Flex>
          <Flex
            overflow="hidden"
            direction="column"
            pt="6px"
            className="issues-text"
          >
            <Text>
              <Text as="span" mr="4px" d="inline" variant="accentBodyShort">
                {duplicatedGenes.length}
              </Text>
              <Text
                color="content.additional"
                d="inline"
                as="span"
                variant="bodyShort"
              >
                genes are duplicated:
              </Text>
            </Text>
          </Flex>
        </Flex>
        <VStack spacing="0" align="start">
          {[
            ...new Map(
              duplicatedGenes.map((gene) => [gene.geneName, gene])
            ).values()
          ].map(({ geneName, transcriptId }) => (
            <HStack spacing="16px" key={geneName + transcriptId}>
              <GeneLink gene={geneName} transcriptId={transcriptId} />
              <Button
                variant="tinyGhostPrimary"
                size="tiny"
                onClick={() => {
                  setSearchValue(geneName);
                  toggleIssuesPanelExpanded();
                }}
              >
                Show gene
              </Button>
            </HStack>
          ))}
        </VStack>
      </Box>
    </Flex>
  );
};
