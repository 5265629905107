import { FC, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Flex } from '@chakra-ui/react';
import { SearchField, useDebounce } from '@vizgen/vizgen-ui';

interface QuotesFiltersProps {
  setFilter: Dispatch<SetStateAction<string>>;
  filter: string;
}

export const QuotesFilters: FC<QuotesFiltersProps> = ({ setFilter }) => {
  const [searchValue, setSearchValue] = useState('');
  const { value: debouncedSearchValue } = useDebounce(searchValue, 500);

  useEffect(() => {
    setFilter(debouncedSearchValue.toLowerCase().trim());
  }, [debouncedSearchValue, setFilter]);

  return (
    <Flex justify="space-between" alignItems="flex-end">
      <SearchField
        name="genePanel"
        placeholder="Search quote requests"
        controlProps={{
          maxW: '264px',
          ml: 'auto'
        }}
        value={searchValue}
        onChange={(e: any) => setSearchValue(e.target.value)}
      />
    </Flex>
  );
};
