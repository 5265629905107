import { VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useNotificationsStore, NotificationLevel } from 'store';
import { shallow } from 'zustand/shallow';
import { Notification, NotificationType } from './components';

interface NotificationsProps {
  isCloseAllowed?: boolean;
}

export const Notifications: FC<NotificationsProps> = ({
  isCloseAllowed = true,
  children
}) => {
  const [notifications, getErrors, getWarnings, getInfo, clearNotifications] =
    useNotificationsStore(
      (state) => [
        state.notifications,
        state.getErrors,
        state.getWarnings,
        state.getInfo,
        state.clearNotifications
      ],
      shallow
    );
  const errors = getErrors();
  const warnings = getWarnings();
  const infos = getInfo();

  if (!notifications.length) {
    return <>{children}</>;
  }

  return (
    <VStack spacing="8px" maxW="840px" mb="20px" mr="60px" w="100%">
      <Notification
        type={NotificationType.Error}
        messages={errors}
        isCloseAllowed={isCloseAllowed}
        onClose={() => clearNotifications(NotificationLevel.ERROR)}
      />
      <Notification
        type={NotificationType.Warning}
        messages={warnings}
        isCloseAllowed={isCloseAllowed}
        onClose={() => clearNotifications(NotificationLevel.WARN)}
      />
      <Notification
        type={NotificationType.Info}
        messages={infos}
        isCloseAllowed={isCloseAllowed}
        onClose={() => clearNotifications(NotificationLevel.INFO)}
      />
    </VStack>
  );
};
