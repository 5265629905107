import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { FC } from 'react';

interface PanelConfigurationProps {
  items: string[];
}

export const PanelConfiguration: FC<PanelConfigurationProps> = ({
  items = []
}) => {
  if (!items.length) {
    return null;
  }

  return (
    <Breadcrumb spacing="4px">
      {items.map(
        (item) =>
          item && (
            <BreadcrumbItem key={item}>
              <BreadcrumbLink
                color="content.secondary"
                _hover={{
                  textDecoration: 'none',
                  cursor: 'default'
                }}
              >
                {item}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )
      )}
    </Breadcrumb>
  );
};
