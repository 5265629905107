import { useQuery } from 'react-query';
import { getFinalizedPanelsByTissue } from 'api';
import { Panel } from 'generated/types';

export type Params = {
  tissueId: string;
  transcriptomeId: string;
};

const FINALIZED_PANELS_BY_TISSUE = 'finalizedPanelsByTissue';

export const useFinalizedPanelsByTissueQuery = ({
  tissueId,
  transcriptomeId
}: Params) => {
  return useQuery<Panel[]>(
    [FINALIZED_PANELS_BY_TISSUE, tissueId, transcriptomeId],
    async () => {
      if (!tissueId || !transcriptomeId) {
        return [] as Panel[];
      }
      const panels =
        (await getFinalizedPanelsByTissue({ tissueId })) || ([] as Panel[]);
      return panels.filter(
        (panel) => panel?.transcriptomeId === transcriptomeId
      ) as Panel[];
    }
  );
};
