import { FC } from 'react';
import { Text, Button, HStack, VStack } from '@chakra-ui/react';
import { ReactComponent as LocationIcon } from './assets/location.svg';
import { useAddressesStore } from 'store';

interface AddressProps {
  addressId: string;
  address: string;
}

export const Address: FC<AddressProps> = ({ address, addressId }) => {
  const removeAddress = useAddressesStore((state) => state.removeAddress);

  return (
    <VStack spacing="24px">
      <HStack justify="space-between" w="100%" h="32px">
        <HStack spacing="4px">
          <LocationIcon />
          <Text color="content.secondary" transition="all 250ms ease">
            {address}
          </Text>
        </HStack>
        <Button
          size="tiny"
          variant="tinyGhostPrimary"
          onClick={() => removeAddress(addressId)}
          data-testid={`removeAddressButton_${addressId}`}
        >
          Remove
        </Button>
      </HStack>
    </VStack>
  );
};
