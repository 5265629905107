import { Text } from '@chakra-ui/react';
import { FC, ReactFragment } from 'react';
import { MiniModal, MiniModalProps } from '../MiniModal';

interface ConfirmModalProps extends MiniModalProps {
  text: ReactFragment | string;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({ text, ...rest }) => {
  return (
    <MiniModal {...rest}>
      <Text>{text}</Text>
    </MiniModal>
  );
};
