export enum Placement {
  Top = 'Top',
  Bottom = 'Bottom'
}

export interface AutocompleteSuggestion {
  /**
   * The suggestion id, may be useful with backend interaction
   * to uniquely identify the entity
   */
  value: string;
  /**
   * The text of suggestion which will be visible to user
   * in the suggestions list
   * */
  label: string;
}

export interface StickyAutocompleteSuggestion extends AutocompleteSuggestion {
  /**
   * Allows stick the first suggestion on top of the suggestions list
   * during the scroll
   * */
  isSticky?: boolean;
}

export enum AutocompleteFieldSize {
  Sm = 'smSearch',
  Md = 'mdSearch'
}
