import { FC } from 'react';
import { InfoIcon, ErrorIcon, WarningIcon } from './assets';
import { NotificationType } from '../../Notification';

interface IconProps {
  type: NotificationType;
}

export const Icon: FC<IconProps> = ({ type }) => {
  switch (type) {
    case NotificationType.Error:
      return <ErrorIcon />;
    case NotificationType.Warning:
      return <WarningIcon />;
    case NotificationType.Info:
      return <InfoIcon />;
  }
};
