import {
  Flex,
  HStack,
  Text,
  Box,
  IconButton,
  UnorderedList,
  ListItem
} from '@chakra-ui/react';
import { FC } from 'react';
import { CloseIcon } from './assets';
import { Icon } from './components';

export enum NotificationType {
  Warning = 'Warning',
  Error = 'Error',
  Info = 'Info'
}

interface NotificationProps {
  type: NotificationType;
  messages: string[];
  isCloseAllowed: boolean;
  onClose: () => void;
}

export const Notification: FC<NotificationProps> = ({
  type,
  messages,
  isCloseAllowed,
  onClose
}) => {
  const getColor = () => {
    switch (type) {
      case NotificationType.Error:
        return 'state.error10Percent';
      case NotificationType.Warning:
        return 'state.searchHighlight';
      case NotificationType.Info:
        return 'state.infoLight10Percent';
    }
  };

  if (!messages.length) {
    return null;
  }

  return (
    <Flex
      w="100%"
      borderRadius="24px"
      background={getColor()}
      padding="8px 16px"
      alignItems="center"
    >
      <HStack spacing="12px" pr="16px" w="100%">
        <Box w="32px" h="32px">
          <Icon type={type} />
        </Box>
        {messages.length === 1 ? (
          <Text as="span" color="content.primary">
            {messages[0]}
          </Text>
        ) : (
          <Box>
            <UnorderedList>
              {messages.map((message) => (
                <ListItem key={message}>
                  <Text color="content.primary">{message}</Text>
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        )}
      </HStack>
      {isCloseAllowed && (
        <IconButton
          icon={<CloseIcon />}
          size="icon"
          variant="ghost"
          aria-label="close"
          cursor="pointer"
          onClick={onClose}
        />
      )}
    </Flex>
  );
};
