import { Text } from '@chakra-ui/react';
import { FC, Fragment } from 'react';
import { geneSetSelector, usePanelCompositionStore } from 'store';

export const GeneList: FC = () => {
  const transcripts = usePanelCompositionStore((state) => state.transcripts);
  const geneSet = usePanelCompositionStore(geneSetSelector);
  const geneSetSize = geneSet?.size || 0;
  const transcriptsSet = new Set(
    transcripts.map((t) => t.geneName.toUpperCase())
  );

  return (
    <Text color="content.additionalTablet" data-testid="geneList">
      {Array.from(geneSet || []).map((t, i) => (
        <Fragment key={t}>
          {transcriptsSet?.has(t) ? (
            <Text as="span" color="content.secondary" fontWeight="heavy">
              {t}
            </Text>
          ) : (
            t
          )}
          {i + 1 === geneSetSize ? '' : ', '}
        </Fragment>
      ))}
    </Text>
  );
};
