import { MenuItem, Box } from '@chakra-ui/react';
import { SEQUENTIAL_TRANSCRIPTS_MAX_AMOUNT } from 'consts';
import { PanelTranscriptType } from 'generated/types';
import { FC } from 'react';
import { transcriptsByTypeAmountSelector, useCreatePanelStore } from 'store';
import { ArrowDown } from './assets';

interface MoveToItemProps {
  isVisible: boolean;
  type: PanelTranscriptType;
  transcriptId: string;
}

const Component: FC<Omit<MoveToItemProps, 'isVisible'>> = ({
  type,
  transcriptId
}) => {
  const sequentialTranscriptsAmount = useCreatePanelStore(
    transcriptsByTypeAmountSelector(PanelTranscriptType.sequential)
  );
  const toggleTranscriptPanel = useCreatePanelStore(
    (state) => state.toggleTranscriptPanel
  );

  const togglePanel = () => {
    toggleTranscriptPanel([{ transcriptId, type }]);
  };

  const isPrimary = type === PanelTranscriptType.primary;
  const isDisabled =
    isPrimary &&
    SEQUENTIAL_TRANSCRIPTS_MAX_AMOUNT === sequentialTranscriptsAmount;

  return (
    <MenuItem onClick={togglePanel} isDisabled={isDisabled}>
      <Box transform={isPrimary ? undefined : 'rotate(180deg)'}>
        <ArrowDown />
      </Box>
      <Box ml="4px">Move to {isPrimary ? 'Sequential' : 'Primary'} Panel</Box>
    </MenuItem>
  );
};

export const MoveToItem: FC<MoveToItemProps> = ({ isVisible, ...rest }) => {
  if (!isVisible) {
    return null;
  }

  return <Component {...rest} />;
};
