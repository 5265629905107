import { listExogenousSequencesQuery } from 'api';
import { ExogenousSequence } from 'generated/types';
import { useQuery } from 'react-query';

export const LIST_EXOGENOUS_SEQUENCES_QUERY_KEY = 'listExogenousSequences';

export const useListExogenousSequencesQuery = () => {
  return useQuery(LIST_EXOGENOUS_SEQUENCES_QUERY_KEY, async () => {
    const res = await listExogenousSequencesQuery();
    return (res.listExogenousSequences?.items || []) as ExogenousSequence[];
  });
};
