import { Box, Spinner } from '@chakra-ui/react';
import DownChevronIcon from './assets/downChevron.svg';
import { DropdownIndicatorProps } from 'react-select';
import { FC } from 'react';

interface IndicatorProps extends DropdownIndicatorProps {
  isLoading: boolean;
}

export const DropdownIndicator: FC<IndicatorProps> = (props) => {
  const { className, cx, getStyles, innerProps, isLoading } = props;
  return (
    <Box
      __css={getStyles('dropdownIndicator', props)}
      className={cx(
        {
          indicator: true,
          'dropdown-indicator': true
        },
        className
      )}
      {...innerProps}
      cursor="pointer"
    >
      {isLoading ? <Spinner w="24px" h="24px" /> : <DownChevronIcon />}
    </Box>
  );
};
