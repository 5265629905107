export function getIntersection<T>(setA: Set<T>, setB: Set<T>) {
  return [...setA].filter((element) => setB.has(element));
}

export function getIntersectionSize<T>(setA: Set<T>, setB: Set<T>) {
  return getIntersection(setA, setB).length;
}

export function getUnionSize<T>(setA: Set<T>, setB: Set<T>) {
  return new Set<T>([...setA, ...setB]).size;
}
