import { useState, FC, useMemo } from 'react';
import { Title, Panels } from './components';
import { CreatePanelModel, useCreatePanelStore } from 'store';
import {
  useFinalizedPanelsByTissueQuery,
  usePromotedPanelsByTissueQuery
} from 'api/hooks';
import { SingleAccordion, useDebounce } from '@vizgen/vizgen-ui';
import { Panel } from 'generated/types';

const getFilteredPanels = (panels: Panel[], searchValue: string) =>
  panels.filter((panel) =>
    panel.panelName.toLowerCase().includes(searchValue.toLowerCase())
  );

export const AddPanels: FC = () => {
  const [isShowSearch, setIsShowSearch] = useState(false);
  const panel = useCreatePanelStore(
    (state) => state.panel || ({} as CreatePanelModel)
  );
  const payload = {
    tissueId: panel.tissueId,
    transcriptomeId: panel.transcriptomeId
  };
  const { data: finalizedPanels = [] } =
    useFinalizedPanelsByTissueQuery(payload);
  const { data: promotedPanels = [] } = usePromotedPanelsByTissueQuery(payload);
  const [searchValue, setSearchValue] = useState('');
  const { value: debouncedSearchValue } = useDebounce(searchValue, 250);

  const [filteredFinalizedPanels, filteredPromotedPanels] = useMemo(
    () => [
      getFilteredPanels(finalizedPanels, debouncedSearchValue),
      getFilteredPanels(promotedPanels, debouncedSearchValue)
    ],
    [finalizedPanels, promotedPanels, debouncedSearchValue]
  );

  return (
    <SingleAccordion
      hideButton={isShowSearch}
      isDefaultExpanded={false}
      title={
        <Title
          onSearchChange={setSearchValue}
          panelsCount={finalizedPanels.length + filteredPromotedPanels.length}
          isSearchVisible={isShowSearch}
          setIsSearchVisible={setIsShowSearch}
        />
      }
    >
      <Panels
        finalizedPanels={filteredFinalizedPanels}
        promotedPanels={filteredPromotedPanels}
      />
    </SingleAccordion>
  );
};
