import { Heading, IconButton, PopoverHeader } from '@chakra-ui/react';
import { FC } from 'react';
import CloseIcon from './assets/close.svg';

interface HeaderProps {
  onPopoverClose: () => void;
}

export const Header: FC<HeaderProps> = ({ onPopoverClose }) => {
  return (
    <PopoverHeader
      p="16px 16px 18px 24px"
      border="0"
      d="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Heading variant="subtitle">Manage Columns</Heading>
      <IconButton
        aria-label="Close"
        variant="primaryGhost"
        size="auto"
        icon={<CloseIcon />}
        onClick={onPopoverClose}
      />
    </PopoverHeader>
  );
};
