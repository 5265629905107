import { FC } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { Tip } from './components';
import { LightIcon } from './assets';

interface TipValidator {
  message: string;
  validate: (value: string) => boolean;
}

const tips: TipValidator[] = [
  {
    message: 'At least 9 characters, the more the better',
    validate: (value) => {
      const validator = new RegExp('^(?=.{9,})');
      return validator.test(value);
    }
  },
  {
    message: 'Use lowercase and capital English letters',
    validate: (value) => {
      const validator = new RegExp('^(?=.*[a-z])(?=.*[A-Z])');
      return validator.test(value);
    }
  },
  {
    message: 'Include at least one special character, e.g., ! @ # ?',
    validate: (value) => {
      return value.match(/^(?=.*[\W_]).+$/) ? true : false;
    }
  },
  {
    message: 'Include at least one digit',
    validate: (value) => {
      const validator = new RegExp('^(?=.*[0-9])');
      return validator.test(value);
    }
  }
];

interface PasswordTipsProps {
  value: string;
}

export const PasswordTips: FC<PasswordTipsProps> = ({ value }) => {
  return (
    <HStack
      spacing="12px"
      w="100%"
      p="12px 24px 12px 12px"
      bg="background.secondary"
      borderRadius="24px"
      alignItems="flex-start"
    >
      <VStack spacing="0" alignItems="start" w="100%">
        {tips.map((tip) => {
          const { message, validate } = tip;
          return (
            <Tip key={message} message={message} isValid={validate(value)} />
          );
        })}
      </VStack>
      <LightIcon />
    </HStack>
  );
};
