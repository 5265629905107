import { useMemo } from 'react';
import { FilterTypes, IdType, Row } from 'react-table';

const multiselect = <T extends {}>(
  rows: Row<T>[],
  ids: IdType<T>[],
  filterValue: string[]
) => {
  return rows.filter((row) =>
    ids.some((id) => filterValue.includes(row.values[id]))
  );
};

multiselect.autoRemove = (values: string[]) => !values || !values.length;

export const useFilterTypes = () => {
  return useMemo(
    () =>
      ({
        multiselect
      } as FilterTypes<any>),
    []
  );
};
