import { Box, List, Flex, Text } from '@chakra-ui/react';
import { FC, useCallback, CSSProperties } from 'react';
import { FixedSizeList } from 'react-window';
import { SequenceItem } from './components';
import { ScrollbarVirtual } from '@vizgen/vizgen-ui';
import { ExogenousSequence } from 'generated/types';

interface ExogenousSequencesListProps {
  data: ExogenousSequence[];
}

export const ExogenousSequencesList: FC<ExogenousSequencesListProps> = ({
  data
}) => {
  const RenderListItem = useCallback(
    ({ index, style }: { index: number; style: CSSProperties }) => (
      <SequenceItem sequence={data[index]} style={style} />
    ),
    [data]
  );

  return data.length ? (
    <Box overflow="hidden" bg="background.primary" borderRadius="2px">
      <List>
        <FixedSizeList
          height={192}
          outerElementType={ScrollbarVirtual}
          width="100%"
          itemCount={data.length}
          itemSize={64}
        >
          {RenderListItem}
        </FixedSizeList>
      </List>
    </Box>
  ) : (
    <Flex h="192px" pt="16px" justify="center">
      <Text fontWeight="heavy" color="content.additional">
        No sequences found
      </Text>
    </Flex>
  );
};
