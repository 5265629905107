import { Heading, Box, Stack, HStack, Text } from '@chakra-ui/react';
import { ExtraItem } from './components';
import { ExtrasIcon } from 'assets';
import { useListExtrasQuery } from 'api/hooks';
import { SingleAccordion } from '@vizgen/vizgen-ui';

export const Extras = () => {
  const { data = [] } = useListExtrasQuery();

  return (
    <Box my="24px">
      <SingleAccordion
        variant="open"
        title={
          <HStack>
            <ExtrasIcon />
            <Heading variant="subtitle">Additional Extras</Heading>
          </HStack>
        }
      >
        {!!data.length && (
          <Stack spacing="32px" mt="12px">
            <ExtraItem
              extras={data}
              title="MERSCOPE Gene Imaging Kits"
              type="Imaging Kit"
              caption="Select Gene Imaging Kits to add. Mind that FFPE samples require double the volume of MERSCOPE™ Gene Panel Mix for sample processing"
            />
            <ExtraItem
              extras={data}
              title="MERSCOPE Slides"
              type="MERSCOPE Slide"
            />
            <ExtraItem
              extras={data}
              title="MERSCOPE Sample Verification Kits"
              type="Sample Verification Kit"
            />
            <Stack spacing="12px">
              <Text variant="accentBodyShort">MERSCOPE Stain Kits</Text>
              <Stack spacing="18px">
                <ExtraItem
                  isCompact
                  extras={data}
                  title="MERSCOPE Cell Boundary Staining Kit"
                  type="Cell Boundary Staining Kit"
                />
                <ExtraItem
                  isDropdownView
                  extras={data}
                  title="MERSCOPE Protein Stain Kits"
                  type="Antibody MERSCOPE Kit"
                />
                <ExtraItem
                  isDropdownView
                  extras={data}
                  title="MERSCOPE Protein Stain Verification Kits"
                  type="Antibody Verification Kit"
                />
                <ExtraItem
                  isDropdownView
                  extras={data}
                  title="MERSCOPE Region of Interest Stain Kits"
                  type="Antibody Pre-stain MERSCOPE Kit"
                />
                <ExtraItem
                  isDropdownView
                  extras={data}
                  title="MERSCOPE Region of Interest Verification Kits"
                  type="Antibody Pre-stain Verification Kit"
                />
              </Stack>
            </Stack>
          </Stack>
        )}
      </SingleAccordion>
    </Box>
  );
};
