import {
  FC,
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react';
import { Box, HStack, Button } from '@chakra-ui/react';
import { EditIcon } from './assets';
import { useKeyPress } from 'hooks';
import { useCreatePanelStore } from 'store';
import { InputField, theme, TooltipText } from '@vizgen/vizgen-ui';
const { colors } = theme;

interface NotesProps {
  value: string;
  rowId: string;
  transcriptId: string;
  setRowId: Dispatch<SetStateAction<string | null>>;
}

export const Notes: FC<NotesProps> = ({
  value,
  rowId,
  transcriptId,
  setRowId
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const changeNote = useCreatePanelStore((state) => state.changeNote);
  const input = useRef<HTMLInputElement>(null);

  useKeyPress(['Escape'], () => {
    setIsInputVisible(false);
    setIsHovered(false);
    setRowId(null);
  });

  useEffect(() => {
    if (isInputVisible) {
      input?.current?.focus();
    }
  }, [isInputVisible]);

  if (isInputVisible) {
    return (
      <Box w="100%">
        <InputField
          ref={input}
          name="notes"
          controlProps={{
            onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
              if (value !== e.target.value) {
                changeNote(transcriptId, e.target.value);
              }
              setIsInputVisible(false);
              setIsHovered(false);
              setRowId(null);
            }
          }}
          defaultValue={value}
          size="sm"
          variant="table"
        />
      </Box>
    );
  }

  const width = isHovered ? '72px' : '96px';

  return (
    <HStack
      m="8px 12px"
      spacing="0"
      justify="space-between"
      h="100%"
      cursor="pointer"
      onClick={() => {
        setIsInputVisible(true);
        setRowId(rowId);
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <TooltipText
        textProps={{
          w: width,
          maxW: width
        }}
      >
        {value}
      </TooltipText>
      {isHovered && (
        <Button
          size="auto"
          variant="ghost"
          css={{
            '&:hover path': {
              stroke: colors.content.link.normal
            }
          }}
          data-testid="editGeneNoteButton"
        >
          <EditIcon />
        </Button>
      )}
    </HStack>
  );
};
