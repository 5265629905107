import { ChangeEvent, FC, useEffect, useState } from 'react';
import { HStack, Text, Flex } from '@chakra-ui/react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { InputField } from '@vizgen/vizgen-ui';

interface PanelNameFieldProps {
  control: Control<FieldValues>;
  isDisabled?: boolean;
  panelName: string;
}

export const PanelNameField: FC<PanelNameFieldProps> = ({
  control,
  isDisabled = false,
  panelName
}) => {
  const { panelId } = useParams<{ panelId: string }>();

  const {
    field: { ref, onChange, name, value },
    fieldState: { invalid }
  } = useController({
    name: 'panelName',
    control,
    rules: { required: true },
    defaultValue: panelName || ''
  });
  const [isLoaded, setIsLoaded] = useState(false);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value.replace(/[^a-zA-Z0-9-]/g, ''));
  };

  useEffect(() => {
    if (panelId) {
      if (!isLoaded && panelName) {
        onChange(panelName);
        setIsLoaded(true);
      }
    } else {
      setIsLoaded(true);
    }
  }, [isLoaded, panelName, onChange, setIsLoaded, panelId]);

  const isDisabledOnLoad = !isLoaded && !!panelName;

  return (
    <HStack spacing="24px" alignItems="flex-start">
      <Flex maxW={264} w="40%" direction="column">
        <Text fontSize="16px" maxW={264} w="40%">
          Panel name
        </Text>
        <Text color="content.additional" variant="captionShort">
          Up to 40 characters
        </Text>
      </Flex>
      <InputField
        isDisabled={isDisabledOnLoad || isDisabled}
        maxLength={40}
        value={value}
        placeholder="Type name"
        onChange={onChangeHandler}
        controlProps={{
          w: '60%',
          maxW: 360,
          isInvalid: invalid,
          d: 'flex',
          flexDir: 'column'
        }}
        errorProps={{ pos: 'relative' }}
        _disabled={{
          opacity: '0.6'
        }}
        name={name}
        ref={ref}
        error={invalid ? 'Fill the required field' : undefined}
      />
    </HStack>
  );
};
