import { Box, Link } from '@chakra-ui/react';
import { FC, RefObject } from 'react';
import { Cell, TableInstance } from 'react-table';
import { GeneType } from '../GeneType';
import { TablePlaceholder } from '../TablePlaceholder';
import { CustomColumn, TableTemplate } from '../TableTemplate';

export interface Transcript {
  userSpecifiedName: string;
  geneName?: string | null;
  comment?: string | null;
  abundance?: number | null;
  auxProbes?: string | null;
  targetRegionQty?: number | null;
  source: string;
}

enum CellType {
  SpecifiedName = 'userSpecifiedName',
  GeneName = 'geneName',
  Comment = 'comment',
  Abundance = 'abundance',
  Probe = 'auxProbes',
  Regions = 'targetRegionQty',
  Source = 'source'
}

interface GenesTableProps {
  tableRef: RefObject<TableInstance<Transcript>>;
  transcripts?: Transcript[];
  isPrimary?: boolean;
  title?: React.ReactNode | string;
}

const getTableColumns = (isPrimary: boolean): CustomColumn<Transcript>[] => {
  return [
    {
      Header: '',
      id: CellType.Source,
      accessor: CellType.Source,
      cellProps: { p: '0px' },
      width: 40,
      isSortable: true,
      Cell: (cell: Cell<Transcript>) => {
        return <GeneType type={cell.value} />;
      }
    },

    {
      Header: 'Gene Input',
      accessor: CellType.SpecifiedName,
      columnMaxW: 140,
      width: '100%',
      Cell: (cell: Cell<Transcript>) => {
        return <Box isTruncated>{cell.value}</Box>;
      },
      isSortable: true
    },
    {
      Header: 'Identified Gene Name',
      accessor: CellType.GeneName,
      isSortable: true,
      columnMaxW: 146,
      width: '100%',
      Cell: (cell: Cell<Transcript>) => {
        return (
          cell.value && (
            <Box
              maxW="116px"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              <Link
                variant="underline"
                target="_blank"
                href={`https://www.ensembl.org/Multi/Search/Results?q=${cell.value}`}
              >
                {cell.value}
              </Link>
            </Box>
          )
        );
      }
    },
    {
      Header: 'Notes',
      accessor: CellType.Comment,
      columnMaxW: 512,
      width: '100%',
      cellProps: { p: '12px' },
      Cell: (cell: Cell<Transcript>) => {
        return <Box isTruncated>{cell.value}</Box>;
      }
    },
    ...((isPrimary
      ? [
          {
            Header: () => (
              <Box as="span">
                Abundance,
                <br />
                FPKM
              </Box>
            ),
            accessor: CellType.Abundance,
            isSortable: true,
            columnMaxW: 174,
            width: '100%',
            cellProps: { p: '12px' },
            sortType: 'basic',
            Cell: (cell: Cell<Transcript>) => {
              return (
                <Box w="100%" isTruncated textAlign="right">
                  {cell.value ? cell.value.toFixed(5) : 0}
                </Box>
              );
            }
          }
        ]
      : [
          {
            Header: 'Probe',
            accessor: CellType.Probe,
            isSortable: true,
            columnMaxW: 174,
            width: '100%',
            cellProps: { p: '12px' },
            sortType: 'basic',
            Cell: (cell: Cell<Transcript>) => {
              return <Box>{cell.value || '—'}</Box>;
            }
          }
        ]) as CustomColumn<Transcript>[]),
    {
      Header: 'Target Regions',
      accessor: CellType.Regions,
      isSortable: true,
      columnMaxW: 116,
      width: '100%',
      Cell: (cell: Cell<Transcript>) => {
        return (
          <Box maxW="92px" w="100%" textAlign="right">
            {cell.value || '-'}
          </Box>
        );
      }
    }
  ];
};

const MAX_HEIGHT = 318;

export const GenesTable: FC<GenesTableProps> = ({
  transcripts = [],
  isPrimary = false,
  title,
  tableRef
}) => {
  if (!transcripts.length) {
    return null;
  }

  return (
    <TableTemplate
      tableName={`${isPrimary ? 'sequential' : 'primary'}Table`}
      ref={tableRef}
      columns={getTableColumns(isPrimary)}
      state={transcripts}
      hasSortPlugin
      title={title}
      maxHeight={MAX_HEIGHT}
      hasGlobalFilterPlugin
      placeholder={<TablePlaceholder>No genes found</TablePlaceholder>}
      wrapperProps={{
        d: 'flex',
        overflowY: 'hidden',
        height: '100%',
        flexDirection: 'column',
        maxW: '1128px',
        w: '100%'
      }}
    />
  );
};
