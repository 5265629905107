import { FC } from 'react';
import { Box, Container, Flex, HStack, useMediaQuery } from '@chakra-ui/react';
import { Navigation, TermsContent } from './components';
import { useFetchStaticFile } from 'hooks';

export const TermsOfUse: FC = () => {
  const [isLargerThan1248] = useMediaQuery('(min-width: 1248px)');
  const { data } = useFetchStaticFile('/files/termsOfUse.html');

  return (
    <Box h="100%" overflow="hidden">
      <Container
        h="100%"
        maxW="calc(100% - calc((100vw - 1248px) / 2))"
        ml={isLargerThan1248 ? 'calc((100vw - 1248px) / 2)' : 0}
        p="0"
      >
        <Flex direction="column" h="100%" overflowY="hidden">
          <HStack spacing="64px" h="100%" align="start">
            <Navigation termsOfUse={data} />
            <TermsContent termsOfUse={data} />
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};
