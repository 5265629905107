import { zIndices } from '../zIndices';

export const Popover = {
  baseStyle: {
    popper: {
      width: 'fit-content',
      maxWidth: 'fit-content',
      zIndex: zIndices.popover
    }
  }
};
