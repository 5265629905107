import { ChangeEvent, FC } from 'react';
import { RadioGroup, Radio } from '@chakra-ui/react';
import { Stack } from '@chakra-ui/react';
import { useAddressesStore, useNewOrderStore } from 'store';

export const AddressesList: FC = () => {
  const selectedAddress = useNewOrderStore((state) => state.selectedAddress);
  const addresses = useAddressesStore((state) => state.addresses);
  const setSelectedAddress = useNewOrderStore(
    (state) => state.setSelectedAddress
  );
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedAddress(event.target.value);
  };

  return addresses.length ? (
    <RadioGroup pl="12px" value={selectedAddress}>
      <Stack spacing="24px">
        {addresses.map((address) => (
          <Radio
            key={address.addressId}
            value={address.addressId}
            onChange={onChange}
          >
            {address.address}
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  ) : null;
};
