import { useEffect } from 'react';
import { useNewOrderStore, useNotificationsStore } from 'store';

export const useClearState = () => {
  const clearOrderState = useNewOrderStore((state) => state.clearState);
  const clearNotificationsState = useNotificationsStore(
    (state) => state.clearState
  );

  useEffect(() => {
    clearOrderState();
    clearNotificationsState();
    return () => {
      clearOrderState();
      clearNotificationsState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
