import { Table, Tbody } from '@chakra-ui/react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { useUserStore } from 'store';
import { ProfileRow, InputField } from '@vizgen/vizgen-ui';
import { UserType } from 'generated/types';
import { FC } from 'react';

interface DetailsProps {
  isProfileEditing: boolean;
  register: UseFormRegister<FieldValues>;
}

const userTypeLabels = {
  [UserType.MERSCOPE]: 'MERSCOPE customer',
  [UserType.VizgenLab]: 'Vizgen Lab Service customer',
  [UserType.RnD]: 'Vizgen RnD'
};

export const Details: FC<DetailsProps> = ({ isProfileEditing, register }) => {
  const attributes = useUserStore((state) => state.attributes);
  const isFetching = useUserStore((state) => state.isFetching);
  const email = attributes?.email || '';
  const country = attributes?.countryName || '';
  const region = attributes?.regionName || '';
  const laboratory = attributes?.laboratory || '';
  const organization = attributes?.organization || '';
  const userTypeLabel = attributes?.userType
    ? userTypeLabels[attributes.userType]
    : '';

  return (
    <Table w="552px">
      <Tbody>
        <ProfileRow label="Email" value={email} />
        <ProfileRow label="Type" value={userTypeLabel} />
        <ProfileRow label="Country" value={country}></ProfileRow>
        <ProfileRow label="State/Province" value={region}></ProfileRow>
        <ProfileRow
          isEditing={isProfileEditing}
          label="Organization"
          value={organization || '-'}
        >
          <InputField
            {...register('organization')}
            size="sm"
            placeholder="Your organization"
            defaultValue={organization}
            disabled={isFetching}
          />
        </ProfileRow>
        <ProfileRow
          isEditing={isProfileEditing}
          label="Laboratory"
          value={laboratory || '-'}
        >
          <InputField
            {...register('laboratory')}
            size="sm"
            placeholder="Your laboratory"
            defaultValue={laboratory}
            disabled={isFetching}
          />
        </ProfileRow>
      </Tbody>
    </Table>
  );
};
