import { fontStyles } from '../fonts';

export const Text = {
  baseStyle: {
    color: 'content.secondary',
    fontSize: fontStyles.fontSizes[16],
    lineHeight: fontStyles.lineHeights[24],
    fontWeight: fontStyles.fontWeights.normal
  },
  variants: {
    accentBase: {
      fontWeight: fontStyles.fontWeights.heavy
    },
    bodyShort: {
      fontSize: fontStyles.fontSizes[16],
      lineHeight: fontStyles.lineHeights[20],
      fontWeight: fontStyles.fontWeights.normal
    },
    accentBodyShort: {
      fontSize: fontStyles.fontSizes[16],
      lineHeight: fontStyles.lineHeights[20],
      fontWeight: fontStyles.fontWeights.heavy
    },
    captionHigh: {
      fontSize: fontStyles.fontSizes[14],
      lineHeight: fontStyles.lineHeights[20],
      fontWeight: fontStyles.fontWeights.normal
    },
    accentCaptionHigh: {
      fontSize: fontStyles.fontSizes[14],
      lineHeight: fontStyles.lineHeights[20],
      fontWeight: fontStyles.fontWeights.heavy
    },
    captionShort: {
      fontSize: fontStyles.fontSizes[12],
      lineHeight: fontStyles.lineHeights[16],
      fontWeight: fontStyles.fontWeights.normal
    },
    span: {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      fontWeight: 'inherit',
      d: 'inline'
    }
  }
};
