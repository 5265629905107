import { PanelTranscriptStatus } from 'generated/types';
import { useCreatePanelStore, transcriptsByStatusSelector } from 'store';
import { Row } from '..';
import { shallow } from 'zustand/shallow';

export const InsufficientRow = () => {
  const issues = useCreatePanelStore(
    transcriptsByStatusSelector(PanelTranscriptStatus.insufficient_tr),
    shallow
  );
  return <Row genes={issues} status={PanelTranscriptStatus.insufficient_tr} />;
};
