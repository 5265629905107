import { getListPanelTranscripts } from 'api';
import { ListPanelTranscriptsQueryVariables } from 'generated/types';
import { useQuery } from 'react-query';

const LIST_PANEL_TRANSCRIPTS_QUERY_KEY = 'listPanelTranscripts';

export const useListPanelTranscriptsQuery = (
  panelId: string,
  input?: ListPanelTranscriptsQueryVariables
) => {
  return useQuery(
    [LIST_PANEL_TRANSCRIPTS_QUERY_KEY, input, panelId],
    async () => {
      if (!panelId) {
        return;
      }
      return await getListPanelTranscripts(panelId, input);
    },
    { refetchOnWindowFocus: false }
  );
};
