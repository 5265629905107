import { Flex } from '@chakra-ui/react';
import { Header, Loader } from 'components';
import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {
  useAddressesStore,
  useCreatePanelStore,
  useFeatureFlagsStore,
  useGlobalListsStore,
  useUserStore
} from 'store';

const DEFAULT_TRANSCRIPTOME_IDS = [
  '4ec3bfb3-bc52-4fd3-a055-144234809459',
  '00000000-0000-0000-0000-000000000002'
];

export const MainLayout: FC = () => {
  const getLists = useGlobalListsStore((state) => state.getLists);
  const getAddresses = useAddressesStore((state) => state.getAddresses);
  const { vcd478 } = useFeatureFlagsStore((state) => state.featureFlags);

  const fetchAttributes = useUserStore((state) => state.fetchAttributes);
  const user = useUserStore((state) => state.user);

  const isFinalizingPanel = useCreatePanelStore(
    (store) => store.isFinalizingPanel
  );

  useEffect(() => {
    if (user) {
      fetchAttributes();
      getAddresses();
    }
  }, [user, fetchAttributes, getAddresses]);

  useEffect(() => {
    if (user) {
      getLists(vcd478 ? null : DEFAULT_TRANSCRIPTOME_IDS);
    }
  }, [user, vcd478, getLists]);

  return (
    <Flex
      className="app"
      h="100vh"
      overflowY="hidden"
      direction="column"
      minW="1000px"
      pos="relative"
    >
      {isFinalizingPanel && (
        <Loader message="Finalizing panel, please, don't close the app" />
      )}
      <Header />
      <Outlet />
    </Flex>
  );
};
