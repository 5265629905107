import { HStack, Flex } from '@chakra-ui/react';
import { CheckboxInput, theme } from '@vizgen/vizgen-ui';
import { ProprietaryTooltip } from 'components';
import { FC } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
const { colors } = theme;

interface ProprietaryFieldProps {
  control: Control<FieldValues>;
  isDisabled: boolean;
  isProprietary: boolean;
}

export const ProprietaryField: FC<ProprietaryFieldProps> = ({
  control,
  isDisabled,
  isProprietary
}) => {
  const {
    field: { ref, onChange, name, value }
  } = useController({
    name: 'isProprietary',
    control,
    defaultValue: isProprietary ?? true
  });

  return (
    <HStack spacing="24px" alignItems="flex-start" mb="20px">
      <Flex maxW={264} w="40%" />
      <HStack spacing="4px">
        <CheckboxInput
          ref={ref}
          name={name}
          isChecked={value}
          isDisabled={isDisabled}
          onChange={(e: any) => onChange(e.target.checked)}
          pl="12px"
        >
          Proprietary
        </CheckboxInput>
        <ProprietaryTooltip
          color={colors.border.secondary}
          isProprietary={value}
        />
      </HStack>
    </HStack>
  );
};
