import { HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { TooltipText } from '@vizgen/vizgen-ui';
import { FC, useMemo } from 'react';
import { geneSetSelector, usePanelCompositionStore } from 'store';
import { getTranscriptsIntersection } from 'utils/geneset/getTranscriptsIntersection';

export const GeneSetInfo: FC = () => {
  const selectedItem = usePanelCompositionStore((state) => state.selectedItem);
  const geneSet = usePanelCompositionStore(geneSetSelector);
  const geneSets = usePanelCompositionStore((state) => state.geneSets);
  const transcripts = usePanelCompositionStore((state) => state.transcripts);
  const geneSetLibraries = usePanelCompositionStore(
    (state) => state.geneSetLibraries
  );

  const intersectedGenes = useMemo(
    () =>
      getTranscriptsIntersection(
        geneSets,
        transcripts,
        selectedItem?.geneSet || ''
      ),
    [geneSets, selectedItem, transcripts]
  );
  const geneSetLibrary = useMemo(
    () =>
      geneSetLibraries.find(([_, geneSets]) =>
        geneSets.has(selectedItem?.geneSet || '')
      )?.[0],
    [geneSetLibraries, selectedItem]
  );
  const geneSetGenesAmount = geneSet?.size || 0;
  const intersectedGenesAmount = intersectedGenes.length;

  return (
    <Stack
      w="100%"
      direction="column"
      spacing="18px"
      align="start"
      data-testid="geneSetInfo"
    >
      <HStack spacing="48px" w="100%">
        <VStack w="100%" align="start" spacing="4px" display="grid">
          <Text variant="accentCaptionHigh">Gene set library name</Text>
          <TooltipText
            textProps={{
              variant: 'bodyShort'
            }}
          >
            {geneSetLibrary}
          </TooltipText>
        </VStack>
        <VStack w="100%" align="start" spacing="4px" display="grid">
          <Text variant="accentCaptionHigh">Gene set name</Text>
          <TooltipText
            textProps={{
              variant: 'bodyShort'
            }}
          >
            {selectedItem?.geneSet}
          </TooltipText>
        </VStack>
      </HStack>
      <VStack align="start" spacing="4px" display="grid">
        <Text variant="accentCaptionHigh">Genes in set</Text>
        <TooltipText
          textProps={{
            variant: 'bodyShort',
            color: 'content.additionalTablet'
          }}
        >
          <Text as="span" variant="bodyShort" fontWeight="heavy">
            {intersectedGenesAmount}
          </Text>{' '}
          of{' '}
          <Text as="span" variant="bodyShort" fontWeight="heavy">
            {geneSetGenesAmount}
          </Text>{' '}
          genes were found in panel{' '}
          {intersectedGenesAmount > 0 && (
            <>
              <Text
                as="span"
                variant="bodyShort"
                color="content.additionalTablet"
              >
                –
              </Text>{' '}
              <Text as="span" variant="bodyShort" fontWeight="heavy">
                {intersectedGenes.join(', ')}
              </Text>
            </>
          )}
        </TooltipText>
      </VStack>
    </Stack>
  );
};
