import { FC, useState } from 'react';
import { HStack, Box, Flex, Heading, Container } from '@chakra-ui/react';
import { useResourcesQuery } from 'api/hooks';
import { ResourcesTabs } from './components';
import { DEFAULT_PRODUCTS_OPTION } from 'consts';
import { useOptions } from './useOptions';
import {
  SearchField,
  Dropdown,
  DropdownSize,
  TablePlaceholder
} from '@vizgen/vizgen-ui';

export const Resources: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [dropdownValue, setDropdownValue] = useState(DEFAULT_PRODUCTS_OPTION);
  const { data = [], isLoading } = useResourcesQuery();
  const options = useOptions(data);
  const placeholder = isLoading
    ? 'Loading...'
    : data.length
    ? ''
    : 'No resources found';

  return (
    <Container
      d="flex"
      flexDir="column"
      p="40px 60px"
      maxW="1268px"
      w="100%"
      m="auto"
      h="100%"
      overflowY="hidden"
    >
      <Flex justify="space-between" align="center">
        <Heading as="h1" variant="headline">
          Resources
        </Heading>
        <HStack spacing="24px">
          <Box w="264px">
            <Dropdown
              name="products"
              size={DropdownSize.Sm}
              isSearchable
              items={options}
              value={dropdownValue}
              onChange={(e) => {
                e && setDropdownValue(e);
              }}
            />
          </Box>
          <SearchField
            name="search"
            placeholder="Search"
            controlProps={{
              w: '264px'
            }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </HStack>
      </Flex>
      {placeholder ? (
        <Box pt="24px">
          <TablePlaceholder>{placeholder}</TablePlaceholder>
        </Box>
      ) : (
        <ResourcesTabs
          resources={data}
          searchValue={searchValue}
          dropdownValue={dropdownValue.value}
        />
      )}
    </Container>
  );
};
