import { HStack, Text } from '@chakra-ui/react';
import { Panel, PanelOwnershipType } from 'generated/types';
import { FC } from 'react';
import { formatDateTime } from 'utils';

interface PanelStatusProps {
  panel: Panel;
}

export const PanelStatus: FC<PanelStatusProps> = ({ panel }) => {
  const isPromoted = panel.ownershipType === PanelOwnershipType.promoted;
  const isManualDesign = !!panel.serialNumberTag?.match(/[A-D,Y]M[0-9]{4}/);

  return (
    <HStack spacing="32px" mt="16px">
      <Text>
        <Text as="span" color="content.primary" fontWeight="heavy">
          Last updated:
        </Text>{' '}
        {formatDateTime(panel.updatedAt)}
      </Text>
      <Text>
        <Text as="span" color="content.primary" fontWeight="heavy">
          Status:
        </Text>{' '}
        {isPromoted
          ? 'MERSCOPE Panel'
          : panel.isCompleted
          ? 'Completed'
          : 'Draft'}
      </Text>
      <Text>
        <Text as="span" color="content.primary" fontWeight="heavy">
          Source:
        </Text>{' '}
        {isManualDesign ? 'Manual Design' : 'Portal Design'}
      </Text>
    </HStack>
  );
};
