import { Divider } from '@chakra-ui/react';
import { FC } from 'react';

export const VerticalDivider: FC = () => {
  return (
    <Divider
      orientation="vertical"
      borderLeftWidth="2px"
      bg="background.tertiary"
      pos="absolute"
      right="0"
      h="100%"
      top="0"
    />
  );
};
