import { Resource } from 'models';
import { listFileResourcesQuery } from 'api';
import { FileResource, ListFileResourcesQueryVariables } from 'generated/types';
import { useQuery } from 'react-query';

const LIST_FILE_RESOURCES_KEY = 'listFileResources';

export const useResourcesQuery = (
  input: ListFileResourcesQueryVariables = {}
) => {
  return useQuery([LIST_FILE_RESOURCES_KEY, input], async () => {
    const res = await listFileResourcesQuery(input);

    const resources = (res?.listFileResources?.items || []) as FileResource[];

    return resources
      .filter((resource) => !resource.isHidden)
      .map(
        (resource) =>
          ({
            ...resource,
            path: JSON.parse(resource.path || '{}')
          } as Resource)
      );
  });
};
