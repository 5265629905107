import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import {
  isTotalAbundanceInvalidSelector,
  merfishThresholdGenesAmountSelector,
  useCreatePanelStore
} from 'store';
import { AbundanceTooltip } from './components';
import { theme } from '@vizgen/vizgen-ui';
const { colors } = theme;

export const AbundanceHeader: FC = () => {
  const merfishThresholdGenesAmount = useCreatePanelStore(
    merfishThresholdGenesAmountSelector
  );
  const isTotalAbundanceInvalid = useCreatePanelStore(
    isTotalAbundanceInvalidSelector
  );
  const isAbundanceInvalid =
    isTotalAbundanceInvalid || merfishThresholdGenesAmount > 0;
  const color = isAbundanceInvalid
    ? colors.state.error
    : colors.content.primary;

  return (
    <Flex as="span" color={color} align="center">
      <AbundanceTooltip
        color={color}
        maxW={merfishThresholdGenesAmount ? '256px' : '168px'}
      />
      Abundance, FPKM
    </Flex>
  );
};
