import { Box, Flex, Text, Stack, HStack } from '@chakra-ui/react';
import { ReactComponent as UserIcon } from './assets/user.svg';
import { Details } from './components';
import { Control, UseFormRegister, FieldValues } from 'react-hook-form';
import { useUserStore } from 'store';
import { FC } from 'react';
import { ProfileUsername } from '@vizgen/vizgen-ui';

interface PersonProps {
  isProfileEditing: boolean;
  control: Control<FieldValues>;
  register: UseFormRegister<FieldValues>;
}

export const Person: FC<PersonProps> = ({ isProfileEditing, register }) => {
  const attributes = useUserStore((state) => state.attributes);
  const userId = attributes?.userId || '';
  const name = attributes?.name || '';
  const familyName = attributes?.familyName || '';
  const isFetching = useUserStore((state) => state.isFetching);

  return (
    <Flex mb="24px" justify="space-between">
      <HStack spacing="24px" alignItems="start" h="96px" w="552px">
        <Box borderRadius="8px">
          <UserIcon />
        </Box>
        <Stack justify="flex-start" h="100%" spacing="0" w="100%">
          <ProfileUsername
            isFetching={isFetching}
            isEditing={isProfileEditing}
            register={register}
            name={name}
            familyName={familyName}
          />
          <HStack spacing="4px" w="100%">
            <Text color="content.primary" fontWeight="heavy">
              User ID:
            </Text>
            <Text>{userId}</Text>
          </HStack>
        </Stack>
      </HStack>
      <Details register={register} isProfileEditing={isProfileEditing} />
    </Flex>
  );
};
