import { TranscriptModel } from 'store';
import { GeneSetEntry } from 'utils/geneset/types';
import { usePanelCompositionStore } from 'store';
import { shallow } from 'zustand/shallow';
import { useEffect } from 'react';

export const useSetStoreData = (data: {
  geneSetEntries: GeneSetEntry[];
  transcripts: TranscriptModel[];
}) => {
  const { geneSetEntries, transcripts } = data;
  const [setGeneSets, setTranscripts] = usePanelCompositionStore(
    (state) => [state.setGeneSets, state.setTranscripts],
    shallow
  );

  useEffect(() => {
    setGeneSets(geneSetEntries);
  }, [geneSetEntries, setGeneSets]);

  useEffect(() => {
    setTranscripts(transcripts);
  }, [transcripts, setTranscripts]);
};
