import { useMemo } from 'react';
import { TranscriptModel } from 'store';
import { convertGeneSets } from 'utils/geneset/convertGeneSets';
import { sortGenes } from 'utils/geneset/sortGenes';
import { sortGeneSets } from 'utils/geneset/sortGeneSets';
import { ClustergrammerData, GeneSetsSource } from 'utils/geneset/types';
import { GeneSortMethod, GeneSetSortMethod } from 'generated/types';

const GENE_SETS_LIMIT = 1000;

export function useClustergrammerData(
  geneSetsSource: GeneSetsSource,
  genes: TranscriptModel[],
  genesSortMode: GeneSortMethod,
  geneSetsSortMode: GeneSetSortMethod,
  selectedGeneSets?: Set<string>,
  searchedItem?: string
) {
  const geneSetEntries = useMemo(
    () =>
      convertGeneSets(geneSetsSource, selectedGeneSets).slice(
        0,
        GENE_SETS_LIMIT
      ),
    [geneSetsSource, selectedGeneSets]
  );

  const genesSet = useMemo(
    () => new Set(genes.filter(Boolean).map((g) => g.geneName?.toUpperCase())),
    [genes]
  );

  const sortedGenes = useMemo(
    () => sortGenes(genes, geneSetEntries, genesSortMode),
    [geneSetEntries, genes, genesSortMode]
  );

  const sortedGeneSetEntries = useMemo(
    () => sortGeneSets(geneSetEntries, genesSet, geneSetsSortMode),
    [geneSetEntries, genesSet, geneSetsSortMode]
  );

  const searchedGene =
    searchedItem && genesSet.has(searchedItem.toUpperCase())
      ? searchedItem
      : '';
  const searchedGeneSet =
    searchedItem && !searchedGene && selectedGeneSets?.has(searchedItem)
      ? searchedItem
      : '';

  const filteredGeneSetEntries = useMemo(() => {
    if (searchedGene) {
      return sortedGeneSetEntries.filter(([, genes]) =>
        genes.has(searchedGene.toUpperCase())
      );
    } else if (searchedGeneSet) {
      const foundGeneSet = sortedGeneSetEntries.find(
        ([name]) => name === searchedGeneSet
      );
      return foundGeneSet ? [foundGeneSet] : [];
    } else {
      return sortedGeneSetEntries;
    }
  }, [searchedGene, searchedGeneSet, sortedGeneSetEntries]);

  const clustergrammerData = useMemo<ClustergrammerData>(() => {
    const links = [];
    for (let i = 0; i < filteredGeneSetEntries.length; i++) {
      const geneSet = filteredGeneSetEntries[i][1];
      for (let j = 0; j < sortedGenes.length; j++) {
        const geneName = sortedGenes[j].geneName?.toUpperCase();
        if (geneSet.has(geneName)) {
          links.push({
            source: j,
            target: i,
            value: 1
          });
        }
      }
    }
    return {
      row_nodes: sortedGenes.map((g) => ({ name: g.geneName || '' })),
      col_nodes: filteredGeneSetEntries.map((gs) => ({ name: gs[0] })),
      links
    };
  }, [sortedGenes, filteredGeneSetEntries]);

  return { clustergrammerData, searchedGene, searchedGeneSet, geneSetEntries };
}
