import { Text, useDisclosure, VStack } from '@chakra-ui/react';
import { InputField, EMAIL_REGEXP } from '@vizgen/vizgen-ui';
import { UserProps } from 'generated/types';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { ConfirmAddUserModal, Footer } from './components';

interface AddUserProps {
  isAdmin: boolean;
  instrumentUniqueId: string;
  users: UserProps[];
  otherUsers: UserProps[];
  onCancel: () => void;
}

interface FormData {
  email?: string;
}

export const AddUser: FC<AddUserProps> = ({
  instrumentUniqueId,
  isAdmin,
  users,
  otherUsers,
  onCancel
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit, formState, setError, watch } =
    useForm<FormData>();
  const formData = watch();
  const errorMessage = formState.errors.email?.message;

  const validate = (value: any) => {
    const user = users.find((user) => user.email === value);
    if (user) {
      return 'User with this email is already added';
    }
    const regexp = new RegExp(EMAIL_REGEXP);
    return regexp.test(value) || 'Enter valid email address';
  };

  const onFailure = () => {
    onClose();
    setError('email', {
      message: 'User with this email isn’t found'
    });
  };

  const onSuccess = () => {
    onClose();
    onCancel();
  };

  return (
    <>
      <VStack
        as="form"
        onSubmit={handleSubmit(onOpen)}
        p="8px"
        align="start"
        w="100%"
        spacing="24px"
        border="1px solid"
        borderColor="border.normal"
        borderRadius="8px"
        backgroundColor="background.secondary"
      >
        <VStack spacing="20px" w="100%" align="start">
          <Text fontWeight="heavy" p="4px 0 0 12px">
            Add User
          </Text>
          <InputField
            {...register('email', {
              required: 'Fill the required field',
              validate
            })}
            w="100%"
            label="Enter email address"
            placeholder="Enter your email address"
            error={errorMessage}
            controlProps={{
              isInvalid: !!errorMessage,
              d: 'flex',
              flexDir: 'column'
            }}
            errorProps={{ pos: 'relative' }}
          />
          <Footer onCancel={onCancel} />
        </VStack>
      </VStack>
      <ConfirmAddUserModal
        instrumentUniqueId={instrumentUniqueId}
        email={formData.email || ''}
        users={otherUsers}
        isVisible={isOpen}
        isAdmin={isAdmin}
        onClose={onClose}
        onFailure={onFailure}
        onSuccess={onSuccess}
      />
    </>
  );
};
