import { Box, BoxProps, UnorderedList, ListItem } from '@chakra-ui/react';
import { FC, useEffect, useRef } from 'react';
import { Scrollbar } from '../../../../Scrollbar';
import { MAX_LIST_HEIGHT } from '../../consts';
import {
  Placement,
  AutocompleteSuggestion,
  StickyAutocompleteSuggestion,
  AutocompleteFieldSize
} from '../../models';
import AddIcon from './assets/add.svg';

interface SuggestionListProps {
  size: AutocompleteFieldSize;
  suggestions: StickyAutocompleteSuggestion[];
  placement: Placement;
  listHeight: number;
  suggestionsLimit?: number;
  onSuggestionSelect: (suggestion?: AutocompleteSuggestion) => void;
  onListHeightChange: (height: number) => void;
}

export const SuggestionList: FC<SuggestionListProps> = ({
  size,
  suggestions,
  placement,
  listHeight,
  suggestionsLimit,
  onSuggestionSelect,
  onListHeightChange
}) => {
  const limit = suggestionsLimit || suggestions.length;
  const listRef = useRef<HTMLUListElement>(null);
  const pos = size === AutocompleteFieldSize.Md ? '48px' : '40px';
  const containerProps: BoxProps =
    placement === Placement.Bottom
      ? { top: pos, borderBottomRadius: '4px', borderTop: 'none' }
      : { bottom: pos, borderTopRadius: '4px', borderBottom: 'none' };

  useEffect(() => {
    const height = listRef.current?.clientHeight || 0;
    const listHeight = height > MAX_LIST_HEIGHT ? MAX_LIST_HEIGHT : height;
    onListHeightChange(listHeight);
  }, [listRef, suggestions, onListHeightChange]);

  return (
    <Box
      overflow="auto"
      pos="absolute"
      w="100%"
      h={listHeight + 'px'}
      m="0"
      bg="background.primary"
      border="1px solid"
      borderColor="border.selected"
      {...containerProps}
    >
      <Scrollbar
        style={{
          maxHeight: '240px'
        }}
      >
        <UnorderedList
          ref={listRef}
          listStyleType="none"
          m="0"
          __css={{
            'li:not(:last-child)': {
              borderBottom: '1px solid',
              borderColor: 'border.divider'
            }
          }}
        >
          {suggestions.slice(0, limit).map((s) => (
            <ListItem
              key={s.value}
              data-testid={s.value}
              cursor="pointer"
              p="7.5px 12px"
              transition="all 0.2s ease-in-out"
              onClick={() =>
                onSuggestionSelect({
                  value: s.value,
                  label: s.label
                })
              }
              color="content.secondary"
              fontSize="16px"
              lineHeight="24px"
              display="flex"
              alignItems="center"
              {...(s.isSticky
                ? {
                    pos: 'sticky',
                    top: '0',
                    zIndex: '1',
                    bg: 'background.highlight',
                    _hover: {
                      bg: 'background.secondary'
                    }
                  }
                : {
                    bg: 'background.primary',
                    _hover: {
                      bg: 'background.highlight'
                    }
                  })}
            >
              <>
                {s.isSticky && (
                  <Box mr="4px" data-testid="addIcon">
                    <AddIcon />
                  </Box>
                )}
                {s.label}
              </>
            </ListItem>
          ))}
        </UnorderedList>
      </Scrollbar>
    </Box>
  );
};
