import { fontStyles } from '../fonts';

export const Link = {
  baseStyle: {
    color: 'content.secondary',
    fontSize: fontStyles.fontSizes[16],
    lineHeight: fontStyles.lineHeights[24],
    _hover: {
      color: 'content.link.hover'
    }
  },
  variants: {
    underline: {
      textDecoration: 'underline'
    },
    simple: {
      textDecoration: 'none',
      _hover: {
        textDecoration: 'none'
      }
    },
    primary: {
      color: 'content.link.normal'
    }
  },
  sizes: {
    sm: {
      fontSize: fontStyles.fontSizes[12],
      lineHeight: fontStyles.lineHeights[16]
    },
    md: {
      fontSize: fontStyles.fontSizes[14],
      lineHeight: fontStyles.lineHeights[20]
    },
    lg: {
      fontSize: fontStyles.fontSizes[20],
      lineHeight: fontStyles.lineHeights[24]
    }
  },
  defaultProps: {
    variant: 'simple'
  }
};
