import {
  HeaderNotification,
  NotificationType
} from '../components/HeaderNotification';
import { useNotificationToast } from './useNotificationToast';

export const useSuccessNotification = (duration: number | null = 5000) => {
  const toast = useNotificationToast(duration);

  const show = (message: string) => {
    toast({
      render: ({ onClose }) => (
        <HeaderNotification
          type={NotificationType.Success}
          text={message}
          onClose={duration ? undefined : onClose}
        />
      )
    });
  };

  return show;
};
