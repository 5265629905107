import { FC } from 'react';
import { Box, Heading, Spinner, HStack } from '@chakra-ui/react';

const styles = {
  m: '0',
  maxW: '744px',
  bg: 'background.highlight',
  p: '24px',
  borderRadius: '8px',
  border: '1px solid',
  borderColor: 'border.normal'
};

interface ListProps {
  termsOfUse: string;
}

export const List: FC<ListProps> = ({ termsOfUse }) => {
  return (
    <>
      {termsOfUse ? (
        <Box
          {...styles}
          className="terms-of-use"
          dangerouslySetInnerHTML={{ __html: termsOfUse }}
        />
      ) : (
        <HStack {...styles} align="center" justify="center" spacing="4px">
          <Spinner color="content.additional" size="xl" />
          <Heading color="content.additional" as="span">
            Loading EULA...
          </Heading>
        </HStack>
      )}
    </>
  );
};
