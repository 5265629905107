import {
  FormControl,
  FormLabel,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps
} from '@chakra-ui/react';
import { FC } from 'react';

interface BaseFieldProps {
  id: string;
  error?: string;
  label?: string | JSX.Element;
  controlProps?: FormControlProps;
  errorProps?: FormErrorMessageProps;
}

export const BaseField: FC<BaseFieldProps> = ({
  id,
  label,
  error,
  errorProps,
  controlProps,
  children
}) => {
  return (
    <FormControl {...controlProps} id={id}>
      {label && <FormLabel>{label}</FormLabel>}
      {children}
      {error && (
        <FormErrorMessage
          fontWeight="normal"
          pos="absolute"
          wordBreak="break-word"
          {...errorProps}
        >
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
