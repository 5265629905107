import { approveAccountMutation } from 'api';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export enum ApproveStatus {
  InProgress = 'InProgress',
  Success = 'Success',
  Error = 'Error'
}

export const useApprovingStatus = () => {
  const [status, setStatus] = useState(ApproveStatus.InProgress);
  const { requestId } = useParams();

  useEffect(() => {
    (async () => {
      if (requestId) {
        try {
          await approveAccountMutation(requestId);
          setStatus(ApproveStatus.Success);
        } catch {
          setStatus(ApproveStatus.Error);
        }
      } else {
        setStatus(ApproveStatus.Error);
      }
    })();
  }, [requestId]);

  return status;
};
