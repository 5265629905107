import { GraphQLResult } from '@aws-amplify/api-graphql';

export const formatErrorMessages = (e: any): string => {
  const errors = (
    (e as GraphQLResult)?.errors?.map(({ message }) => `"${message}"`) || [
      e?.message ? `"${e.message}"` : 'Unknown error'
    ]
  ).join('; ');
  return `Request failed: ${errors}`;
};
