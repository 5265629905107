import { NewOrderState } from './models';

export const additionalExtraQuantitySelector = (
  extraId: string,
  state: NewOrderState
) => {
  const targetExtraPosition = state.additionalExtras.find(
    (e) => e.extraId === extraId
  );
  return !!targetExtraPosition ? targetExtraPosition.quantity : 0;
};
