import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import { ChevronDown } from 'assets';
import { DetailInfo, Title } from './components';

interface TopbarProps {
  isOpen: boolean;
  onToggle: () => void;
}

export const Topbar: FC<TopbarProps> = ({ onToggle, isOpen }) => {
  return (
    <Flex p="16px 12px 16px 16px" alignItems="start">
      <Title />
      <DetailInfo />
      <Button
        onClick={onToggle}
        variant="ghost"
        size="icon"
        ml="auto"
        alignSelf="center"
      >
        <Box
          as="span"
          transition="all 0.2s ease"
          transform={isOpen ? 'rotate(180deg)' : undefined}
        >
          <ChevronDown />
        </Box>
      </Button>
    </Flex>
  );
};
