import { getPanel, getPromotedPanel } from 'api';
import { Panel } from 'generated/types';
import { useGetQuery } from 'hooks';
import { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  PanelFormData,
  ReferenceModel,
  TissueModel,
  useCreatePanelStore,
  useGlobalListsStore
} from 'store';

export enum FormState {
  Invalid = 'Invalid',
  ExistingUnsaved = 'ExistingUnsaved',
  NewUnsaved = 'NewUnsaved',
  Valid = ''
}

type ValueSetter = (
  name: string,
  value: any,
  config?:
    | Partial<{
        shouldValidate: boolean;
        shouldDirty: boolean;
      }>
    | undefined
) => void;

export const useGetPanelData = () => {
  const [isFetchingPanelData, setIsFetchingPanelData] = useState(false);
  const [initialFormData, setInitialFormData] = useState<PanelFormData | null>(
    null
  );
  const query = useGetQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const panel = useCreatePanelStore((state) => state.panel);
  const { panelId } = useParams<{ panelId: string }>();
  const references = useGlobalListsStore((state) => state.transcriptomes);
  const tissues = useGlobalListsStore((state) => state.tissues);
  const templatePanelId = query.get('panelTemplate');

  useEffect(() => {
    if (!isFetchingPanelData && tissues.length) {
      if (templatePanelId) {
        setIsFetchingPanelData(true);
        (async () => {
          const isPromoted = query.get('isPromoted');
          const res = isPromoted
            ? await getPromotedPanel(templatePanelId)
            : ((await getPanel(templatePanelId)) as Panel);
          const { transcriptomeId, tissueId, comment } = res;
          const reference = getReference(references, transcriptomeId);
          const tissue = getTissue(tissues, transcriptomeId, tissueId);
          const data = {
            comment,
            panelName: '',
            reference,
            tissue,
            sourcePanel: res,
            isProprietary: true
          };
          setInitialFormData(data);
          navigate(location.pathname, { replace: true });
          setIsFetchingPanelData(false);
        })();
      } else if (panel) {
        const {
          transcriptomeId,
          tissueId,
          comment,
          panelName,
          // Temporarily set as true for all new panels VCD-352
          isProprietary = true
        } = panel;
        const reference = getReference(references, transcriptomeId);
        const tissue = getTissue(tissues, transcriptomeId, tissueId);
        const data = {
          comment,
          panelName,
          reference,
          tissue,
          sourcePanel: undefined,
          isProprietary
        };
        setInitialFormData(data);
      }
    }
  }, [panelId, panel, references, tissues, templatePanelId]); // eslint-disable-line
  return { initialFormData, isFetchingPanelData };
};

export const useSetSourcePanel = (
  { sourcePanel }: PanelFormData,
  setValue: ValueSetter
) => {
  const references = useGlobalListsStore((state) => state.transcriptomes);
  const tissues = useGlobalListsStore((state) => state.tissues);
  useEffect(() => {
    if (sourcePanel && sourcePanel.panelId) {
      const { tissueId, transcriptomeId, comment } = sourcePanel;
      const newReference = getReference(references, transcriptomeId);
      const newTissue = getTissue(tissues, transcriptomeId, tissueId);
      setValue('comment', comment);
      setValue('panelName', '');
      setValue('reference', newReference);
      setValue('tissue', newTissue);
      setValue('sourcePanel', sourcePanel);
    }
  }, [sourcePanel?.panelId]); // eslint-disable-line
};

export const useSetInitialValues = (
  initialFormData: PanelFormData | null,
  setValue: ValueSetter
) => {
  useEffect(() => {
    if (initialFormData) {
      setValue('comment', initialFormData.comment);
      setValue('panelName', initialFormData.panelName);
      setValue('reference', initialFormData.reference);
      setValue('tissue', initialFormData.tissue);
      setValue('isProprietary', initialFormData.isProprietary);
      initialFormData.sourcePanel &&
        setValue('sourcePanel', initialFormData.sourcePanel);
    }
  }, [initialFormData]); // eslint-disable-line
};

export const useFormState = (formData: PanelFormData) => {
  const panel = useCreatePanelStore((state) => state.panel);

  return useMemo<FormState>(() => {
    const {
      panelName,
      tissue,
      comment,
      sourcePanel,
      reference,
      // Temporarily set as true for all new panels VCD-352
      isProprietary = true
    } = formData;
    if (panel?.panelId) {
      if (
        panelName === panel?.panelName &&
        comment === panel?.comment &&
        tissue?.tissueId === panel?.tissueId &&
        reference?.transcriptomeId === panel?.transcriptomeId &&
        isProprietary === panel.isProprietary
      ) {
        return FormState.Valid;
      }
      if (panelName && reference && tissue) {
        return FormState.ExistingUnsaved;
      }
    } else {
      if (
        !panelName &&
        !comment &&
        sourcePanel?.panelId === 'custom' &&
        !reference?.transcriptomeId &&
        !tissue?.tissueId &&
        isProprietary
      ) {
        return FormState.Valid;
      }
      if (panelName && reference && tissue) {
        return FormState.NewUnsaved;
      }
    }
    return FormState.Invalid;
  }, [panel, formData]);
};

const getReference = (
  referencesData: ReferenceModel[] = [],
  transcriptomeId = ''
) => {
  return referencesData.find(
    (reference) => reference.transcriptomeId === transcriptomeId
  );
};

const getTissue = (
  tissuesData: TissueModel[] = [],
  transcriptomeId = '',
  tissueId = ''
) => {
  return tissuesData
    .filter((tis) => tis.transcriptomeId === transcriptomeId)
    .find((data) => data.tissueId === tissueId);
};
