import { FC } from 'react';
import { Box, CSSObject } from '@chakra-ui/react';
import CheckIcon from './assets/checkIcon.svg';
import IndeterminateIcon from './assets/indeterminateIcon.svg';

export interface CheckboxIconProps {
  __css?: CSSObject;
  isIndeterminate?: boolean;
  isChecked?: boolean;
}

export const CheckboxIcon: FC<CheckboxIconProps> = ({
  __css,
  isIndeterminate
}) => {
  return (
    <Box
      as="span"
      __css={{
        ...__css,
        d: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        w: 'auto',
        h: 'auto',
        '& > svg': {
          w: 'auto',
          h: 'auto'
        }
      }}
    >
      {isIndeterminate ? <IndeterminateIcon /> : <CheckIcon />}
    </Box>
  );
};
