import { Text } from '@chakra-ui/react';
import { GeneSetLibraryDivider } from 'components';
import { FC } from 'react';

export const InfoMessage: FC = ({ children }) => {
  return (
    <>
      <Text w="100%" pl="64px">
        {children}
      </Text>
      <GeneSetLibraryDivider zIndex="1" pos="sticky" top="48px" />
    </>
  );
};
