import { FC, RefObject, useMemo } from 'react';
import { Cell, TableInstance } from 'react-table';
import { Box } from '@chakra-ui/react';
import { ActionCell, LinkCell, MenuCell } from './components';
import {
  CustomColumn,
  TableTemplate,
  sortByDate,
  TablePlaceholder
} from '@vizgen/vizgen-ui';
import { PersonalGenePanelModel } from 'models';
import { formatDateTime } from 'utils';
import { PanelOwnershipType } from 'generated/types';

enum CellType {
  Ref = 'ref',
  PanelName = 'panelName',
  Genes = 'genesSummary',
  SerialNumberTag = 'serialNumberTag',
  UpdatedAt = 'updatedAt',
  Comment = 'comment',
  Status = 'isCompleted',
  Actions = 'actions',
  Menu = 'menu',
  OwnershipType = 'ownershipType'
}

const getPanelStatus = (
  ownershipType: PanelOwnershipType,
  isCompleted?: boolean
) =>
  ownershipType === PanelOwnershipType.promoted
    ? 'MERSCOPE panel'
    : isCompleted
    ? 'Completed'
    : 'Draft';

const getTableColumns = (
  isShort: boolean
): CustomColumn<PersonalGenePanelModel>[] => {
  return [
    {
      Header: 'Ref',
      accessor: CellType.Ref,
      isSortable: !isShort,
      cellProps: { p: 0 },
      width: 81,
      Cell: (cell) => {
        const { panelId, ownershipType } = cell.row.original;
        return (
          <LinkCell panelId={panelId} ownershipType={ownershipType}>
            {cell.value}
          </LinkCell>
        );
      }
    },
    {
      Header: 'Name',
      accessor: CellType.PanelName,
      isSortable: !isShort,
      cellProps: { p: 0 },
      columnMaxW: 214,
      width: '100%',
      Cell: (cell) => {
        const { panelId, ownershipType } = cell.row.original;
        return (
          <LinkCell panelId={panelId} ownershipType={ownershipType}>
            {cell.value}
          </LinkCell>
        );
      }
    },
    {
      Header: 'Genes',
      accessor: CellType.Genes,
      cellProps: { p: 0 },
      columnMaxW: 92,
      width: '100%',
      Cell: (cell) => {
        const { panelId, ownershipType } = cell.row.original;
        return (
          <LinkCell panelId={panelId} ownershipType={ownershipType}>
            <Box as="span" ml="auto">
              {cell.value?.totalNumber || 0}
            </Box>
          </LinkCell>
        );
      },
      isSortable: !isShort
    },
    {
      Header: 'Serial Number',
      accessor: CellType.SerialNumberTag,
      cellProps: { p: 0 },
      columnMaxW: 174,
      width: '100%',
      isSortable: !isShort,
      Cell: (cell) => {
        const { panelId, ownershipType } = cell.row.original;
        return (
          <LinkCell panelId={panelId} ownershipType={ownershipType}>
            <Box as="span" ml="auto">
              {cell.value || ''}
            </Box>
          </LinkCell>
        );
      }
    },
    {
      Header: 'Last Updated',
      accessor: CellType.UpdatedAt,
      isSortable: !isShort,
      sortType: sortByDate,
      cellProps: { p: 0 },
      columnMaxW: 192,
      width: '100%',
      Cell: (cell) => {
        const { panelId, ownershipType } = cell.row.original;
        return (
          <LinkCell panelId={panelId} ownershipType={ownershipType}>
            {formatDateTime(cell.value)}
          </LinkCell>
        );
      }
    },
    {
      Header: 'Description',
      accessor: CellType.Comment,
      isSortable: !isShort,
      cellProps: { p: 0 },
      columnMaxW: 184,
      width: '100%',
      Cell: (cell: Cell<PersonalGenePanelModel, string>) => {
        const { panelId, ownershipType } = cell.row.original;
        return (
          <LinkCell panelId={panelId} ownershipType={ownershipType}>
            {cell.value || '-'}
          </LinkCell>
        );
      }
    },
    {
      Header: 'Status',
      accessor: CellType.Status,
      isSortable: !isShort,
      sortType: 'basic',
      cellProps: { p: 0 },
      columnMaxW: 120,
      width: '100%',
      Cell: (cell) => {
        const { panelId, ownershipType } = cell.row.original;
        return (
          <LinkCell panelId={panelId} ownershipType={ownershipType}>
            <Box as="span">{getPanelStatus(ownershipType, cell.value)}</Box>
          </LinkCell>
        );
      }
    },
    {
      Header: 'Actions',
      id: CellType.Actions,
      cellProps: { p: '0' },
      width: 166,
      Cell: (cell: Cell<PersonalGenePanelModel>) => {
        const {
          isCompleted = false,
          panelId,
          panelName,
          hasCodebook = false,
          serialNumberTag = ''
        } = cell.row.original;
        return (
          <ActionCell
            isCompleted={isCompleted}
            panelId={panelId}
            panelName={panelName}
            hasCodebook={hasCodebook}
            serialNumberTag={serialNumberTag}
          />
        );
      }
    },
    {
      Header: '',
      id: CellType.Menu,
      cellProps: { p: '0px' },
      width: 32,
      Cell: (cell: Cell<PersonalGenePanelModel>) => {
        const {
          isCompleted = false,
          panelId,
          panelName,
          serialNumberTag = '',
          genesSummary,
          ownershipType
        } = cell.row.original;
        return (
          <MenuCell
            ownershipType={ownershipType}
            hasGenes={(genesSummary?.totalNumber || 0) > 0}
            isCompleted={isCompleted}
            panelId={panelId}
            panelName={panelName}
            serialNumberTag={serialNumberTag}
          />
        );
      }
    },
    {
      id: CellType.OwnershipType,
      accessor: ({ ownershipType }) => ownershipType
    }
  ];
};

interface GenePanelsTableProps {
  data: PersonalGenePanelModel[];
  isShort?: boolean;
  tableRef?: RefObject<TableInstance<PersonalGenePanelModel>>;
  hasFilterPlugin?: boolean;
  title?: string | React.ReactNode;
  isLoading: boolean;
}

export const GenePanelsTable: FC<GenePanelsTableProps> = ({
  tableRef,
  data,
  isShort = false,
  hasFilterPlugin = false,
  title,
  isLoading
}) => {
  const columnsData = useMemo(() => getTableColumns(isShort), [isShort]);
  const placeholder = isLoading ? 'Loading...' : 'No panels found';

  return (
    <TableTemplate
      tableName="genePanelsTable"
      ref={tableRef}
      columns={columnsData}
      initialState={{ hiddenColumns: [CellType.OwnershipType] }}
      state={data}
      hasSortPlugin
      hasFilterPlugin={hasFilterPlugin}
      wrapperProps={{
        d: 'flex',
        overflowY: 'hidden',
        height: '100%',
        flexDirection: 'column',
        maxW: '1128px',
        w: '100%'
      }}
      title={title}
      placeholder={<TablePlaceholder>{placeholder}</TablePlaceholder>}
    />
  );
};
