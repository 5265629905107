import { FC, useEffect } from 'react';
import { HStack, VStack, Button, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { AuthFlow, useResetPasswordStore, useUserStore } from 'store';
import { shallow } from 'zustand/shallow';
import { NewPasswordData } from 'utils';
import { PasswordTips } from 'components';
import {
  InputField,
  InputPassword,
  useSuccessNotification
} from '@vizgen/vizgen-ui';

export const NewPassword: FC = () => {
  const { handleSubmit, register, formState, watch } = useForm();
  const formData: NewPasswordData = watch();
  const [
    email,
    isFetching,
    errorValidation,
    clearStore,
    isPasswordChanged,
    changePassword
  ] = useResetPasswordStore(
    (state) => [
      state.email,
      state.isFetching,
      state.errorValidation,
      state.clearState,
      state.isPasswordChanged,
      state.changePassword
    ],
    shallow
  );
  const setAuthFlow = useUserStore((state) => state.setAuthFlow);
  const showSuccessNotification = useSuccessNotification();

  useEffect(() => {
    if (isPasswordChanged) {
      setAuthFlow(AuthFlow.SignIn);
      showSuccessNotification('Password successfully updated');
    }
  }, [isPasswordChanged]); // eslint-disable-line

  return (
    <VStack
      as="form"
      w="100%"
      spacing="24px"
      align="stretch"
      onSubmit={handleSubmit(changePassword)}
    >
      <Text>
        A confirmation code was sent to {email}. Enter the code and reset your
        password
      </Text>
      <InputField
        disabled={isFetching}
        w="100%"
        {...register('code')}
        label="Confirmation code"
        inputMode="numeric"
        type="text"
        placeholder="______"
        variant="code"
        maxLength={6}
        autoComplete="one-time-code"
        controlProps={{
          isInvalid: !!errorValidation && errorValidation.field === 'code'
        }}
        error={
          errorValidation && errorValidation.field === 'code'
            ? errorValidation.message
            : undefined
        }
      />
      <InputPassword
        disabled={isFetching}
        {...register('password')}
        id="password"
        label="Password"
        placeholder="Enter your password"
        controlProps={{
          isInvalid: !!errorValidation && errorValidation.field === 'password'
        }}
        error={
          errorValidation && errorValidation.field === 'password'
            ? errorValidation.message
            : undefined
        }
      />
      <PasswordTips value={formData.password || ''} />
      <InputPassword
        disabled={isFetching}
        {...register('repeatPassword')}
        id="repeatPassword"
        label="Confirm Password"
        placeholder="Confirm your password"
      />
      <HStack spacing="16px">
        <Button
          textTransform="none"
          disabled={isFetching || formState.isSubmitting}
          w="100%"
          size="md"
          fontSize="16px"
          h="40px"
          variant="secondaryOutline"
          data-testid="backLink"
          onClick={() => {
            clearStore();
          }}
        >
          Back
        </Button>
        <Button
          type="submit"
          textTransform="none"
          isLoading={formState.isSubmitting}
          w="100%"
          size="md"
          fontSize="16px"
          h="40px"
          data-testid="resetPasswordButton"
        >
          Reset Password
        </Button>
      </HStack>
    </VStack>
  );
};
