import { Link } from '@chakra-ui/react';
import { TooltipText } from '@vizgen/vizgen-ui';
import { Paths } from 'routes';
import { FC } from 'react';
import { generatePath, Link as BrowserLink } from 'react-router-dom';
import { PanelOwnershipType } from 'generated/types';

interface LinkCellProps {
  ownershipType: PanelOwnershipType;
  panelId: string;
}

export const LinkCell: FC<LinkCellProps> = ({
  panelId,
  ownershipType,
  children
}) => {
  return (
    <Link
      as={BrowserLink}
      to={generatePath(
        ownershipType === PanelOwnershipType.proprietary
          ? Paths.PanelDetails
          : Paths.PromotedPanelDetails,
        { panelId }
      )}
      display="flex"
      h="100%"
      alignItems="center"
      p="0px 12px"
      w="100%"
      isTruncated
      lineHeight="40px"
      _hover={{
        textDecoration: 'none'
      }}
    >
      <TooltipText>{children}</TooltipText>
    </Link>
  );
};
