import { Divider, HStack, useRadioGroup, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';
import { PANEL_TYPES } from '../../PanelsDropdown';
import { RadioBarButton } from './components';

interface PanelsSwitcherProps {
  currentType: string;
  promotedPanelsAmount: number;
  myPanelsAmount: number;
  setPanelType: (value: string) => void;
}

export const PanelsTypeSwitcher: FC<PanelsSwitcherProps> = ({
  setPanelType,
  currentType,
  promotedPanelsAmount,
  myPanelsAmount
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'panelSwitcher',
    defaultValue: currentType,
    value: currentType,
    onChange: setPanelType
  });
  const labelProps: Record<string, BoxProps> = {
    [PANEL_TYPES[0]]: { w: '40%' },
    [PANEL_TYPES[1]]: { w: '60%' }
  };

  const group = getRootProps();

  return (
    <HStack
      {...group}
      m="8px"
      border="1px solid"
      borderRadius="4px"
      borderColor="border.normal"
      spacing="0"
      h="40px"
      overflow="hidden"
      divider={<Divider bg="border.divider" orientation="vertical" />}
    >
      {PANEL_TYPES.map((optionValue, index) => {
        const radio = getRadioProps({
          value: optionValue,
          enterKeyHint: undefined
        });
        return (
          <RadioBarButton
            key={optionValue}
            value={optionValue}
            labelProps={labelProps[optionValue]}
            {...radio}
          >
            {optionValue} ({index === 0 ? myPanelsAmount : promotedPanelsAmount}
            )
          </RadioBarButton>
        );
      })}
    </HStack>
  );
};
