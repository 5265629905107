import { PropsWithChildren } from 'react';
import { Td, TableCellProps } from '@chakra-ui/react';
import { Cell, Row } from 'react-table';

interface TdTemplateProps<T extends {} = {}> {
  row: Row<T>;
  cell: Cell<T, any>;
  cellProps: TableCellProps;
}

export const TdTemplate = <T extends {}>({
  cell,
  cellProps,
  children
}: PropsWithChildren<TdTemplateProps<T>>): JSX.Element => {
  const columnMaxW = (cell.column as any).columnMaxW;
  const { style, ...rest } = cell.getCellProps();

  return (
    <Td
      className="td"
      as="div"
      d="flex"
      alignItems="center"
      overflow="hidden"
      h="100%"
      w="100%"
      bg="background.primary"
      color="content.secondary"
      {...rest}
      {...(cellProps ? cellProps : { p: '0 12px', h: '40px' })}
      {...(columnMaxW ? { maxW: columnMaxW } : {})}
      style={{
        ...style,
        ...cellProps.style
      }}
    >
      {children}
    </Td>
  );
};
