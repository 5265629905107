import { Box, Center, Flex, Heading, HStack, Spinner } from '@chakra-ui/react';
import { Scrollbar, SearchField } from '@vizgen/vizgen-ui';
import { GenesTable } from 'components';
import { PanelTranscriptType } from 'generated/types';
import { FC, memo, useEffect, useRef } from 'react';
import { TableInstance } from 'react-table';
import {
  TranscriptModel,
  transcriptsByTypeSelector,
  useCreatePanelStore
} from 'store';
import { shallow } from 'zustand/shallow';
import { GenesStatuses } from './components';

const renderLoader = (text: string) => (
  <HStack spacing="8px">
    <Spinner color="content.additional" size="xl" />
    <Heading color="content.additional" as="span">
      {text}
    </Heading>
  </HStack>
);

const Component: FC = () => {
  const primaryTableInstance = useRef<TableInstance<TranscriptModel>>(null);
  const sequentialTableInstance = useRef<TableInstance<TranscriptModel>>(null);
  const [
    primaryTranscripts,
    isUploadingGenes,
    isUpdatingGenes,
    isFetchingGenes,
    searchValue,
    setSearchValue
  ] = useCreatePanelStore(
    (state) => [
      transcriptsByTypeSelector(PanelTranscriptType.primary)(state),
      state.isUploadingGenes,
      state.isUpdatingGenes,
      state.isFetchingGenes,
      state.searchValue,
      state.setSearchValue
    ],
    shallow
  );

  useEffect(() => {
    primaryTableInstance.current?.setGlobalFilter(searchValue);
    sequentialTableInstance.current?.setGlobalFilter(searchValue);
  }, [searchValue, primaryTranscripts]);

  const loadingProps = {
    isVisible: isUploadingGenes || isUpdatingGenes,
    text: renderLoader('Verifying added genes...')
  };

  if (isFetchingGenes) {
    return <Center pt="64px">{renderLoader('Loading genes...')}</Center>;
  }

  return (
    <Flex
      direction="column"
      justify="space-between"
      h="100%"
      position="relative"
      overflowY="hidden"
    >
      <Scrollbar
        className="add-genes-main-content"
        style={{ marginTop: '16px', paddingRight: '60px' }}
      >
        <Box maxW="840px" mr="60px">
          <SearchField
            isLeftElementShown={false}
            name="geneSearch"
            placeholder="Search gene"
            controlProps={{
              maxW: '264px'
            }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value || '')}
            isRightElementShown={!!searchValue}
            rightElementProps={{
              onClick: () => setSearchValue('')
            }}
          />
          <GenesStatuses />
          <GenesTable
            tableRef={primaryTableInstance}
            primaryTranscripts={primaryTranscripts}
            isPrimary
            loadingProps={loadingProps}
          />
          <Box my="16px">
            <GenesTable
              tableRef={sequentialTableInstance}
              loadingProps={loadingProps}
            />
          </Box>
        </Box>
      </Scrollbar>
    </Flex>
  );
};

export const GeneListTab = memo(Component);
