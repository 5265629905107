import { Flex, HStack, Text, Spinner } from '@chakra-ui/react';

export const Load = () => {
  return (
    <Flex
      justify="center"
      alignItems="center"
      h="96px"
      border="1px solid"
      borderRadius="8px"
      borderColor="border.normal"
      bg="background.primary"
    >
      <HStack spacing="8px">
        <Flex w="32px" h="32px" justify="center" alignItems="center">
          <Spinner
            w="24px"
            h="24px"
            thickness="3px"
            speed="0.6s"
            color="content.additional"
          />
        </Flex>
        <Text color="content.additional">Reading file...</Text>
      </HStack>
    </Flex>
  );
};
