import { HStack, Text, Badge, Box, FlexProps } from '@chakra-ui/react';
import { Resource } from 'models';
import { FC } from 'react';
import { SoftwareTable, DocumentTable } from './components';
import { PRODUCT_LABELS } from 'consts';

interface ResourceItemProps {
  resource: Resource;
  isSoftware?: boolean;
  isCompact?: boolean;
}

export const ResourceItem: FC<ResourceItemProps> = ({
  resource,
  isSoftware = false,
  isCompact = false
}) => {
  const { description, product, isFeatured, isNew } = resource;

  const containerStyles: FlexProps = isCompact
    ? {
        p: '8px'
      }
    : {
        p: '12px 16px',
        border: '1px solid',
        borderColor: 'border.normal',
        borderRadius: '8px'
      };

  return (
    <Box {...containerStyles} w="100%" overflow="hidden">
      <HStack spacing="32px" justifyContent="space-between" w="100%">
        <HStack spacing="8px" minW="0">
          <Text isTruncated variant="accentBodyShort" color="content.primary">
            {isSoftware ? PRODUCT_LABELS[product] : description}
          </Text>
          {isFeatured && <Badge variant="yellow">Featured</Badge>}
          {isNew && <Badge variant="green">New</Badge>}
        </HStack>
        {isSoftware ? (
          <SoftwareTable resource={resource} />
        ) : (
          <DocumentTable resource={resource} isCompact={isCompact} />
        )}
      </HStack>
    </Box>
  );
};
