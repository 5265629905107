import { useEffect } from 'react';
import { Paths } from 'routes';

const anchoredUrls = [Paths.TermsOfUse, Paths.GeneSetLibraries] as string[];

export const useRemoveAnchor = () => {
  useEffect(() => {
    if (!anchoredUrls.includes(window.location.pathname)) {
      window.history.replaceState('', document.title, window.location.pathname);
    }
  }, []);
};
