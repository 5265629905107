import {
  Flex,
  HStack,
  TabList,
  TabPanel,
  TabPanelProps,
  TabPanels,
  Tabs
} from '@chakra-ui/react';
import { CustomTab, Tab } from '@vizgen/vizgen-ui';
import { Instrument } from 'generated/types';
import { FC, useState } from 'react';
import { Details, Experiments } from './components';

interface InstrumentTabsProps {
  instrument: Instrument;
}

const tabPanelStyles: TabPanelProps = {
  p: '0',
  h: '100%',
  d: 'flex',
  flexDirection: 'column',
  overflowY: 'hidden',
  w: '100%'
};

export const InstrumentTabs: FC<InstrumentTabsProps> = ({ instrument }) => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Flex maxW="744px" w="100%">
      <Tabs
        isLazy
        variant="unstyled"
        w="100%"
        h="100%"
        bg="background.primary"
        d="flex"
        flexDirection="column"
        pos="relative"
        alignItems="center"
        index={tabIndex}
        onChange={setTabIndex}
      >
        <Flex w="100%" justify="flex-start">
          <TabList h="48px">
            <HStack spacing="24px">
              <CustomTab data-testid="detailsButton">Details</CustomTab>
              <CustomTab
                data-testid="experimentsButton"
                renderButtonContent={(isActive) => (
                  <Tab count={0} isActive={isActive}>
                    Experiments
                  </Tab>
                )}
              />
            </HStack>
          </TabList>
        </Flex>
        <TabPanels
          pt="12px"
          px="0"
          h="100%"
          d="flex"
          flexDirection="column"
          overflowY="hidden"
          alignItems="center"
        >
          <TabPanel {...tabPanelStyles}>
            <Details instrument={instrument} />
          </TabPanel>
          <TabPanel {...tabPanelStyles}>
            <Experiments />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
