import { FC } from 'react';
import { AddIcon } from 'assets';
import { Box, Button, HStack } from '@chakra-ui/react';
import { theme } from '@vizgen/vizgen-ui';
const { colors } = theme;

interface AddButtonProps {
  onClick: () => void;
}

export const AddButton: FC<AddButtonProps> = ({ onClick, children }) => {
  return (
    <Button
      variant="primaryGhost"
      h="28px"
      px="8px"
      minW="auto"
      data-testid="addUserButton"
      onClick={onClick}
    >
      <HStack
        spacing="4px"
        css={{
          path: {
            stroke: colors.content.link.normal
          }
        }}
      >
        <AddIcon />
        <Box>{children}</Box>
      </HStack>
    </Button>
  );
};
