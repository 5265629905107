import { useState } from 'react';
import { Stack, Heading, Button, HStack, Box } from '@chakra-ui/react';
import { Address } from '../Address';
import { AddIcon } from 'assets';
import { AddAddressForm } from 'components';
import { addressByTypeSelector, useAddressesStore } from 'store';
import { AddressType } from 'generated/types';
import { theme } from '@vizgen/vizgen-ui';
const { colors } = theme;

export const ShippingAddresses = () => {
  const [isShowForm, setIsShowForm] = useState(false);
  const addresses = useAddressesStore(
    addressByTypeSelector(AddressType.shipping)
  );
  const addAddress = useAddressesStore((state) => state.addAddress);

  const showForm = () => {
    setIsShowForm(true);
  };
  const hideForm = () => setIsShowForm(false);

  return (
    <Stack alignItems="center" w="100%" spacing="24px">
      <Stack spacing="28px" w="100%">
        <Heading as="h2" variant="subtitle">
          Shipping Addresses
        </Heading>
        <Stack spacing="16px" w="100%">
          {addresses.map((address) => (
            <Address
              key={address.addressId}
              addressId={address.addressId}
              address={address.address}
            />
          ))}
        </Stack>
      </Stack>
      {isShowForm ? (
        <Box w="100%">
          <AddAddressForm hide={hideForm} updateAddress={addAddress} />
        </Box>
      ) : (
        <Button
          size="tiny"
          variant="tinyGhostPrimary"
          h="40px"
          w="100%"
          fontWeight="heavy"
          onClick={showForm}
          data-testid="addNewShippingAddress"
        >
          <HStack spacing="4px">
            <Box
              css={{
                path: {
                  stroke: colors.content.link.normal
                }
              }}
            >
              <AddIcon />
            </Box>
            <Box>Add New Address</Box>
          </HStack>
        </Button>
      )}
    </Stack>
  );
};
