import { TranscriptModel } from 'store';
import { GeneSetEntry } from 'utils/geneset/types';
import { sortStringsByKey } from 'utils';

export interface SplittedGenes {
  intersecting: TranscriptModel[];
  nonIntersecting: TranscriptModel[];
}

export const getSelectedGeneSetsGenes = (
  geneSets: GeneSetEntry[],
  selectedGeneSets: Set<string>
): Set<string> => {
  const geneSetsGenes: string[] = [];
  selectedGeneSets.forEach((selectedGeneSet) => {
    const geneSet = geneSets.find((geneSet) => geneSet[0] === selectedGeneSet);
    if (geneSet) {
      geneSetsGenes.push(...geneSet[1]);
    }
  });
  return new Set(geneSetsGenes);
};

export const splitGenes = (
  transcripts: TranscriptModel[],
  geneSetsGenes: Set<string>
): SplittedGenes => {
  const intersecting: TranscriptModel[] = [];
  const nonIntersecting: TranscriptModel[] = [];
  for (let i = 0; i < transcripts.length; i++) {
    if (geneSetsGenes.has(transcripts[i].geneName?.toUpperCase())) {
      intersecting.push(transcripts[i]);
    } else {
      nonIntersecting.push(transcripts[i]);
    }
  }
  return {
    intersecting: intersecting.sort(sortStringsByKey('geneName')),
    nonIntersecting: nonIntersecting.sort(sortStringsByKey('geneName'))
  };
};
