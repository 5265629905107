import { useToast } from '@chakra-ui/react';

export const useNotificationToast = (duration: number | null = 5000) => {
  return useToast({
    position: 'top',
    containerStyle: {
      width: '100%',
      maxWidth: '100%',
      minHeight: '64px',
      margin: '2px 0'
    },
    duration
  });
};
