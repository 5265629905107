import { FC, useEffect, useRef, useState } from 'react';
import { HStack, Text, Box } from '@chakra-ui/react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { useGetQuery } from 'hooks';
import { Combobox } from 'components';
import { Panel } from 'generated/types';

interface PanelFieldProps {
  control: Control<FieldValues>;
  sourcePanel: Panel | null;
  isDisabled?: boolean;
}

export const PanelField: FC<PanelFieldProps> = ({
  control,
  sourcePanel,
  isDisabled = false
}) => {
  const panelTemplate = useRef<string>();
  const query = useGetQuery();

  const [isLoaded, setIsLoaded] = useState(false);
  const {
    field: { onChange, name, value }
  } = useController({
    name: 'sourcePanel',
    control,
    rules: { required: true },
    defaultValue: {
      panelName: 'From scratch',
      panelId: 'custom',
      tissueId: '',
      transcriptomeId: ''
    }
  });
  useEffect(() => {
    const templateId = query.get('panelTemplate');
    if (templateId) {
      panelTemplate.current = templateId;
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (panelTemplate.current) {
      if (!isLoaded && sourcePanel) {
        onChange(sourcePanel);
        setIsLoaded(true);
      }
    } else {
      setIsLoaded(true);
    }
  }, [sourcePanel, isLoaded, setIsLoaded, onChange]);

  return (
    <HStack spacing="24px" alignItems="flex-start">
      <Text maxW={264} w="40%">
        Add new panel or duplicate and edit an existing one
      </Text>
      <Box maxW={360} w="60%">
        <Combobox
          onChange={onChange}
          name={name}
          value={value}
          disabled={isDisabled}
        />
      </Box>
    </HStack>
  );
};
