import { extendTheme } from '@chakra-ui/react';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { components } from './components';
import { fontStyles } from './fonts';
import { styles } from './global-styles';
import { zIndices } from './zIndices';

export const theme = extendTheme({
  colors,
  components,
  ...fontStyles,
  styles,
  zIndices,
  breakpoints
});
