import { FC } from 'react';
import { Box, useRadio, UseRadioProps, CSSObject } from '@chakra-ui/react';

interface GroupButtonProps extends UseRadioProps {
  styles: CSSObject;
}

export const GroupButton: FC<GroupButtonProps> = ({
  styles,
  children,
  ...rest
}) => {
  const { getInputProps, getCheckboxProps } = useRadio(rest);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        d="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        p="7px 12px"
        minW="96px"
        bg="button.ghostOnPrimary.normal"
        color="content.additional"
        borderColor="border.normal"
        _hover={{
          bg: 'button.ghostOnPrimary.hover'
        }}
        _checked={{
          bg: 'button.ghostOnPrimary.pressed',
          color: 'content.secondary',
          fontWeight: 'heavy'
        }}
        _focus={{
          boxShadow: 'outline',
          pos: 'relative',
          zIndex: 'docked'
        }}
        __css={{ ...styles }}
      >
        {children}
      </Box>
    </Box>
  );
};
