import { Text, TextProps, Box } from '@chakra-ui/react';
import { Tooltip } from '../Tooltip';
import { FC, useEffect, useRef, useState } from 'react';
import { theme } from '../../theme';
const { colors } = theme;

interface TooltipTextProps {
  textProps?: TextProps;
}

const STYLES = {
  color: colors.content.tertiary
};

const getIsOverflown = (element: HTMLDivElement) =>
  element.scrollWidth > element.clientWidth;

export const TooltipText: FC<TooltipTextProps> = ({
  children,
  textProps = {}
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      setIsOverflown(getIsOverflown(element));
      const observer = new ResizeObserver(() => {
        setIsOverflown(getIsOverflown(element));
      });
      observer.observe(element);
      return () => {
        observer.disconnect();
      };
    }
  }, [ref, children]);

  return (
    <Tooltip
      label={
        <Box
          __css={{
            p: STYLES,
            span: STYLES
          }}
        >
          {children}
        </Box>
      }
      isDisabled={!isOverflown}
    >
      <Text isTruncated ref={ref} {...textProps}>
        {children}
      </Text>
    </Tooltip>
  );
};
