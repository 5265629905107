import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

const thresholds = [
  { l: 's', r: 59, d: 'second' },
  { l: 'm', r: 59 },
  { l: 'mm', r: 59, d: 'minute' },
  { l: 'h', r: 59 },
  { l: 'hh', r: 23, d: 'hour' }
];
const rounding = Math.floor;

dayjs.extend(relativeTime, { thresholds, rounding });
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  relativeTime: {
    past: '%s ago',
    s: '0 minutes',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours'
  }
});

const MSECS_IN_DAY = 86_400_000;

export const getLastSeenTime = (time: string) => {
  const now = Date.now();
  const lastSeen = dayjs(time).valueOf();
  const diff = now - lastSeen;
  if (diff < MSECS_IN_DAY) {
    return dayjs(time).fromNow();
  }
  return dayjs(time).format('D MMMM YYYY, h:mm A');
};
