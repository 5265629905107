import { listCountriesQuery } from 'api';
import { useQuery } from 'react-query';

export const useCountryItems = () => {
  const result = useQuery('listCountries', async () => {
    return (await listCountriesQuery())?.map((c) => ({
      label: c.name,
      value: c.countryId
    }));
  });
  return result.data || [];
};
