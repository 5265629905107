import { FC, useCallback, CSSProperties } from 'react';
import { Box, List, Flex, Text } from '@chakra-ui/react';
import { PanelItem } from './components';
import { FixedSizeList } from 'react-window';
import { ScrollbarVirtual } from '@vizgen/vizgen-ui';
import { Panel } from 'generated/types';

export interface PanelsListProps {
  panels: Panel[];
}

export const PanelsList: FC<PanelsListProps> = ({ panels }) => {
  const RenderListItem = useCallback(
    ({ index, style }: { index: number; style: CSSProperties }) => (
      <PanelItem panel={panels[index]} style={style} />
    ),
    [panels]
  );

  return panels.length ? (
    <Box overflow="hidden" bg="background.primary" borderRadius="2px">
      <List>
        <FixedSizeList
          height={385}
          outerElementType={ScrollbarVirtual}
          width="100%"
          itemCount={panels.length}
          itemSize={64}
        >
          {RenderListItem}
        </FixedSizeList>
      </List>
    </Box>
  ) : (
    <Flex h="385px" pt="16px" justify="center">
      <Text fontWeight="heavy" color="content.additional">
        No panels found
      </Text>
    </Flex>
  );
};
