import { listPromotedPanelsQuery } from 'api';
import { useQuery } from 'react-query';

const PROMOTED_PANELS_LIST_QUERY_KEY = 'listPromotedPanels';

export const usePromotedPanelListQuery = () => {
  return useQuery(
    [PROMOTED_PANELS_LIST_QUERY_KEY],
    async () => await listPromotedPanelsQuery(),
    { refetchOnWindowFocus: false }
  );
};
