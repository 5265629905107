import { FC, useEffect } from 'react';
import {
  VStack,
  Text,
  Button,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Box
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { AuthFlow, useUserStore } from 'store';
import { shallow } from 'zustand/shallow';
import { SignUpMessage } from './components';
import { PasswordTips } from 'components';
import { Link } from 'react-router-dom';
import { Paths } from 'routes';
import { SignUpData } from 'utils';
import {
  BaseField,
  CheckboxInput,
  InputField,
  Dropdown,
  ValidationMessage,
  InputPassword,
  ErrorMessage
} from '@vizgen/vizgen-ui';
import { UserType } from 'generated/types';
import { useCountryItems, useCountryRegionsItems } from 'hooks';

export const SignUpForm: FC = () => {
  const { handleSubmit, register, formState, control, watch, setValue } =
    useForm();
  const formData: SignUpData = watch();
  const isResearchUser = formData?.email?.endsWith('@vizgen.com') || false;
  const [
    signUp,
    isFetching,
    error,
    errorValidation,
    isEmailVerified,
    resetErrors,
    setAuthFlow
  ] = useUserStore(
    (state) => [
      state.signUp,
      state.isFetching,
      state.error,
      state.errorValidation,
      state.isEmailVerified,
      state.resetErrors,
      state.setAuthFlow
    ],
    shallow
  );

  useEffect(() => {
    setValue('region', null);
  }, [formData.country?.value]); // eslint-disable-line

  useEffect(
    () => () => {
      resetErrors();
    },
    [] // eslint-disable-line
  );

  const countryItems = useCountryItems();
  const { items: countryRegionsItems, isLoading } = useCountryRegionsItems(
    formData.country?.value
  );

  if (isEmailVerified) {
    return <SignUpMessage />;
  }

  const getErrorMessage = (field: string) => {
    return errorValidation && errorValidation.field === field
      ? errorValidation.message
      : undefined;
  };

  const getIsInvalid = (field: string) => {
    return !!errorValidation && errorValidation.field === field;
  };

  const onSubmit = (formData: SignUpData) => {
    signUp({
      ...formData,
      userType: isResearchUser ? UserType.RnD : formData.userType
    });
  };

  return (
    <VStack
      as="form"
      maxW="552px"
      width="100%"
      spacing="24px"
      align="stretch"
      m="0 auto"
      p="40px 36px 24px 36px"
      overflow="hidden"
      onSubmit={handleSubmit(onSubmit)}
    >
      <InputField
        w="100%"
        {...register('firstName')}
        label="First Name"
        type="text"
        controlProps={{
          isInvalid: getIsInvalid('firstName')
        }}
        placeholder="Enter your First Name"
        error={getErrorMessage('firstName')}
      />
      <InputField
        w="100%"
        {...register('lastName')}
        label="Last Name"
        type="text"
        placeholder="Enter your Last Name"
        controlProps={{
          isInvalid: getIsInvalid('lastName')
        }}
        error={getErrorMessage('lastName')}
      />
      <InputField
        w="100%"
        {...register('email')}
        label="Email"
        type="text"
        placeholder="Enter your email"
        controlProps={{
          isInvalid: getIsInvalid('email')
        }}
        error={getErrorMessage('email')}
      />
      <InputPassword
        {...register('password')}
        id="password"
        label="Password"
        placeholder="Enter your password"
        controlProps={{
          isInvalid: getIsInvalid('password')
        }}
        error={getErrorMessage('password')}
      />
      <PasswordTips value={formData.password || ''} />
      <InputPassword
        {...register('repeatPassword')}
        id="repeatPassword"
        label="Confirm Password"
        placeholder="Confirm your password"
      />
      {isResearchUser || (
        <Box pos="relative">
          <HStack spacing="36px" align="start" pl="12px">
            <Text>Type of user:</Text>
            <Controller
              control={control}
              name="userType"
              render={({ field: { onChange, name, ref, value } }) => (
                <RadioGroup
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  name={name}
                >
                  <Stack spacing="12px">
                    <Radio value={UserType.MERSCOPE}>MERSCOPE customer</Radio>
                    <Radio value={UserType.VizgenLab}>
                      Vizgen Lab Service customer
                    </Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </HStack>
          {getIsInvalid('userType') && (
            <ValidationMessage>{getErrorMessage('userType')}</ValidationMessage>
          )}
        </Box>
      )}
      <BaseField
        id="country"
        label="Country"
        controlProps={{
          pos: 'relative'
        }}
      >
        <Controller
          control={control}
          name="country"
          defaultValue={null}
          render={({ field: { onChange, name, value } }) => (
            <Dropdown
              items={countryItems}
              onChange={onChange}
              value={value}
              name={name}
              defaultValue={{}}
              isSearchable
              placeholder="Select your country"
              isInvalid={getIsInvalid('country')}
            />
          )}
        />
        {getIsInvalid('country') && (
          <ValidationMessage>{getErrorMessage('country')}</ValidationMessage>
        )}
      </BaseField>
      {formData.country?.value && (
        <BaseField
          id="region"
          label="State/Province"
          controlProps={{
            pos: 'relative'
          }}
        >
          <Controller
            control={control}
            name="region"
            defaultValue={''}
            render={({ field: { onChange, name, value } }) => (
              <Dropdown
                items={countryRegionsItems}
                onChange={onChange}
                value={value}
                name={name}
                defaultValue={{}}
                isSearchable
                isDisabled={isLoading}
                isLoading={isLoading}
                placeholder="Select your state/province"
                isInvalid={getIsInvalid('region')}
              />
            )}
          />
          {getIsInvalid('region') && (
            <ValidationMessage>{getErrorMessage('region')}</ValidationMessage>
          )}
        </BaseField>
      )}
      <InputField
        w="100%"
        {...register('organization')}
        label="Organization"
        type="text"
        placeholder="Enter your Organization name"
        controlProps={{
          isInvalid: getIsInvalid('organization')
        }}
        error={getErrorMessage('organization')}
      />
      <InputField
        w="100%"
        {...register('laboratory')}
        label="Laboratory"
        type="text"
        placeholder="Enter your Laboratory name"
        controlProps={{
          isInvalid: getIsInvalid('laboratory')
        }}
        error={getErrorMessage('laboratory')}
      />
      <HStack display="flex" w="100%" p="4px 12px">
        <CheckboxInput ml="0" {...register('tosCheck')}>
          I accept the Vizgen
          <Button
            p="0 4px"
            minW="auto"
            h="auto"
            textTransform="none"
            background="none"
            color="content.secondary"
            textDecoration="underline"
            fontWeight="initial"
            _hover={{
              background: 'none',
              color: 'content.link.hover'
            }}
            _active={{
              background: 'none'
            }}
            as={Link}
            to={Paths.TermsOfUse}
            target="_blank"
            data-testid="EULAButton"
          >
            End User License Agreement
          </Button>
        </CheckboxInput>
      </HStack>
      <Button
        disabled={isFetching}
        type="submit"
        textTransform="none"
        isLoading={formState.isSubmitting}
        w="100%"
        size="md"
        fontSize="16px"
        h="40px"
        data-testid="createAccountLink"
      >
        Create Account
      </Button>
      <ErrorMessage
        error={error?.message || errorValidation?.message}
        mt="8px"
      />
      <HStack justify="center" spacing="4px" p="4px 0">
        <Text>Already have an account?</Text>
        <Button
          minW={0}
          variant="tinyGhostPrimary"
          size="sm"
          h="32px"
          padding="4px 8px"
          onClick={() => setAuthFlow(AuthFlow.SignIn)}
          data-testid="signInButton"
        >
          Sign In
        </Button>
      </HStack>
    </VStack>
  );
};
