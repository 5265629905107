import { HStack, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { GeneInfo, TranscriptModel } from 'store';

interface TooltipLabelProps {
  duplicateGenes?: GeneInfo[];
  duplicatedGeneNames: TranscriptModel[];
}

const renderTitle = (title: string) => {
  return (
    <Text
      variant="captionShort"
      color="content.tertiary"
      fontSize="10px"
      textTransform="uppercase"
    >
      {title}
    </Text>
  );
};

export const TooltipLabel: FC<TooltipLabelProps> = ({
  duplicateGenes,
  duplicatedGeneNames
}) => {
  return (
    <VStack spacing="12px" align="start">
      {!!duplicateGenes?.length && (
        <VStack spacing="12px" align="start">
          <Text color="content.ternary" variant="bodyShort">
            The following duplicating genes were removed from the panel:
          </Text>
          <HStack spacing="24px">
            <VStack alignItems="flex-start" spacing="4px">
              {renderTitle('Name')}
              {duplicateGenes.map((gene, i) => (
                <Text color="content.tertiary" key={i}>
                  {gene.name}
                </Text>
              ))}
            </VStack>
            <VStack alignItems="flex-start" spacing="4px">
              {renderTitle('Gene input')}
              {duplicateGenes.map((gene, i) => (
                <Text color="content.tertiary" key={i}>
                  {gene.geneInput}
                </Text>
              ))}
            </VStack>
          </HStack>
        </VStack>
      )}
      {!!duplicatedGeneNames?.length && (
        <VStack spacing="12px" align="start">
          <Text color="content.ternary" variant="bodyShort">
            The following gene entries are duplicates:
          </Text>
          <HStack spacing="24px">
            <VStack alignItems="flex-start" spacing="4px">
              {renderTitle('Name')}
              {duplicatedGeneNames.map((gene, i) => (
                <Text color="content.tertiary" key={i}>
                  {gene.geneName}
                </Text>
              ))}
            </VStack>
            <VStack alignItems="flex-start" spacing="4px">
              {renderTitle('Gene input')}
              {duplicatedGeneNames.map((gene, i) => (
                <Text color="content.tertiary" key={i}>
                  {gene.userSpecifiedName}
                </Text>
              ))}
            </VStack>
            <VStack alignItems="flex-start" spacing="4px">
              {renderTitle('Transcript ID')}
              {duplicatedGeneNames.map((gene, i) => (
                <Text color="content.tertiary" key={i}>
                  {gene.transcriptId}
                </Text>
              ))}
            </VStack>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};
