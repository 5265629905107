import { FC } from 'react';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import {
  SignInForm,
  ResetPasswordForm,
  SignUpForm,
  ConfirmRegistrationForm,
  TemporaryPasswordForm
} from './components';
import { AuthFlow, useUserStore } from 'store';
import { Logo } from 'assets';
import { Content } from 'components';
import { useRemoveAnchor } from 'hooks';
import { Paths } from 'routes';
import { Link, useMatch } from 'react-router-dom';
import { useInitialFlow } from './useInitialFlow';

export const AuthWrapper: FC = ({ children }) => {
  const [isEmailVerified, user, authFlow, setAuthFlow] = useUserStore(
    (state) => [
      state.isEmailVerified,
      state.user,
      state.authFlow,
      state.setAuthFlow
    ]
  );
  const isTermsOfUse = !!useMatch(Paths.TermsOfUse);
  const isApproveAccount = !!useMatch(Paths.ApproveAccount);
  const isChildrenAllowed = isTermsOfUse || isApproveAccount;

  useInitialFlow();
  useRemoveAnchor();

  if (user) {
    return <>{children}</>;
  }

  return (
    <Flex h="100vh" overflowY="hidden" direction="column" overflow="hidden">
      <Box
        zIndex="docked"
        h="80px"
        bg="background.primary"
        filter="drop-shadow(0px 8px 24px rgba(215, 217, 220, 0.4))"
      >
        <Flex justify="center" h="80px" align="center" pos="relative">
          <Logo />
          {isTermsOfUse && (
            <Button
              pos="absolute"
              right="60px"
              textTransform="none"
              w="168px"
              size="md"
              fontSize="16px"
              h="40px"
              as={Link}
              to={Paths.Home}
              onClick={() => {
                setAuthFlow(AuthFlow.SignUp);
              }}
              data-testid="signUpButton"
            >
              Sign Up
            </Button>
          )}
        </Flex>
      </Box>
      {isChildrenAllowed && children}
      <Flex
        flex="1"
        display="flex"
        direction="column"
        align="stretch"
        overflow="hidden"
        justify="flex-start"
        position="relative"
      >
        <Heading
          color="content.additional"
          fontWeight="heavy"
          lineHeight="48px"
          fontSize="32px"
          textAlign="center"
          m="40px auto 32px"
        >
          {isEmailVerified ? 'Awaiting Vizgen Approval' : authFlow}
        </Heading>
        <Content>
          {authFlow === AuthFlow.SignIn && <SignInForm />}
          {authFlow === AuthFlow.SignUp && <SignUpForm />}
          {authFlow === AuthFlow.ResetPassword && <ResetPasswordForm />}
          {authFlow === AuthFlow.ConfirmRegistration && (
            <ConfirmRegistrationForm />
          )}
          {authFlow === AuthFlow.TemporaryPassword && <TemporaryPasswordForm />}
        </Content>
      </Flex>
    </Flex>
  );
};
