import { useMemo } from 'react';
import { GeneSetLibrary } from 'store';

export const useAutocompleteData = (geneSetLibraries: GeneSetLibrary[]) => {
  const geneSets = useMemo(
    () =>
      new Set(
        geneSetLibraries
          .map((geneSetLibrary) =>
            geneSetLibrary.children.map((geneSet) => geneSet.label)
          )
          .flat()
          .sort()
      ),
    [geneSetLibraries]
  );
  const genes = useMemo(
    () =>
      new Set(
        geneSetLibraries
          .map((geneSetLibrary) =>
            geneSetLibrary.children
              .map((geneSet) => Array.from(geneSet.genes))
              .flat()
          )
          .flat()
          .sort()
      ),
    [geneSetLibraries]
  );
  const suggestions = useMemo(
    () => [
      ...Array.from(genes).map((g) => ({
        value: g,
        label: g
      })),
      ...Array.from(geneSets).map((gs) => ({
        value: gs,
        label: gs
      }))
    ],
    [geneSets, genes]
  );

  return {
    suggestions,
    geneSets,
    genes
  };
};
