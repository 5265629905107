import { useState } from 'react';
import { Stack, Heading, HStack, Button, Box } from '@chakra-ui/react';
import { AddressesList } from './components';
import { AddIcon, DeliveryIcon } from './assets';
import { AddAddressForm } from 'components';
import { useAddressesStore, useNewOrderStore } from 'store';

export const Deliveries = () => {
  const [isShowForm, setIsShowForm] = useState(false);
  const addAddress = useAddressesStore((state) => state.addAddress);
  const setSelectedAddress = useNewOrderStore(
    (state) => state.setSelectedAddress
  );
  const updateAddress = async (address: string) => {
    const addressId = await addAddress(address);
    setSelectedAddress(addressId);
  };

  const showForm = () => setIsShowForm(true);
  const hideForm = () => setIsShowForm(false);

  return (
    <Stack spacing="28px" pt="24px">
      <Stack spacing="36px">
        <Heading as="h2" variant="subtitle">
          <HStack spacing="8px">
            <DeliveryIcon />
            <Box>Delivery Address</Box>
          </HStack>
        </Heading>
        <AddressesList />
      </Stack>
      {isShowForm ? (
        <AddAddressForm hide={hideForm} updateAddress={updateAddress} />
      ) : (
        <Button
          variant="primaryGhost"
          onClick={showForm}
          data-testid="addNewAddressButton"
        >
          <HStack spacing="4px">
            <AddIcon />
            <Box>Add New Address</Box>
          </HStack>
        </Button>
      )}
    </Stack>
  );
};
