import { useState, FC, CSSProperties } from 'react';
import { Box, TableCellProps, Text } from '@chakra-ui/react';
import { Row as RowData, Cell } from 'react-table';
import { Notes, Abundance, Probe } from './components';
import { CellType } from '../../GenesTable';
import { TranscriptModel } from 'store';
import { PanelTranscriptStatus } from 'generated/types';
import { TdTemplate, TrTemplate } from '@vizgen/vizgen-ui';

interface RowProps {
  row: RowData<TranscriptModel>;
  style?: CSSProperties;
  isReadonly?: boolean;
}

const getNotesProps = (
  cell: Cell<TranscriptModel, any>,
  rowId: string | null
): TableCellProps => {
  if (cell.row.id === rowId && cell.column.id === CellType.Comment) {
    return { w: '410px!important', flex: '410 0 auto' };
  }
  return {};
};

const getHiddenCellsProps = (
  cell: Cell<TranscriptModel, any>,
  rowId: string | null
): TableCellProps => {
  if (
    (cell.column.id === CellType.Abundance ||
      cell.column.id === CellType.Regions ||
      cell.column.id === CellType.Probe) &&
    cell.row.id === rowId
  ) {
    return { d: 'none' };
  }
  return {};
};

export const Row: FC<RowProps> = ({ row, style = {}, isReadonly = false }) => {
  const [rowId, setRowId] = useState<string | null>(null);

  const getCell = (cell: Cell<TranscriptModel, any>) => {
    if (cell.column.id === CellType.Comment) {
      return isReadonly ? (
        <Box p="8px 12px 8px 8px">
          <Text w="488px" isTruncated>
            {cell.value}
          </Text>
        </Box>
      ) : (
        <Notes
          value={cell.value}
          setRowId={setRowId}
          rowId={cell.row.id}
          transcriptId={row.original.transcriptId}
        />
      );
    }
    if (cell.column.id === CellType.Abundance) {
      return isReadonly ? (
        <Box w="100%" p="12px" textAlign="right">
          {cell.value ? +cell.value.toFixed(5) : 0}
        </Box>
      ) : (
        <Abundance
          value={cell.value}
          isCalculating={
            cell.row.original.status === PanelTranscriptStatus.calculating
          }
        />
      );
    }
    if (cell.column.id === CellType.Probe) {
      return isReadonly ? (
        <Box p="10px 12px">
          <Text>{cell.value ? `Aux ${cell.value.slice(-1)}` : ''}</Text>
        </Box>
      ) : (
        <Probe transcriptId={row.original.transcriptId} />
      );
    }
    return cell.render('Cell');
  };

  return (
    <TrTemplate row={row} style={style}>
      {row.cells.map((cell) => (
        <TdTemplate
          row={row}
          cell={cell}
          cellProps={{
            ...(cell.column as any)?.cellProps,
            ...getNotesProps(cell, rowId),
            ...getHiddenCellsProps(cell, rowId)
          }}
          key={`${cell.row.id}-${cell.value}-${cell.column.id}`}
        >
          {getCell(cell)}
        </TdTemplate>
      ))}
    </TrTemplate>
  );
};
