import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Box, VStack, HStack, Text, Button } from '@chakra-ui/react';
import { useDropzone, FileError } from 'react-dropzone';
import { Error, Load } from './components';
import { CSV_MIME_TYPES, CSV_MAX_FILESIZE } from 'consts';
import { parse } from 'papaparse';

interface DropzoneProps {
  setIsDropzoneVisible: Dispatch<SetStateAction<boolean>>;
  setGenes: Dispatch<SetStateAction<string>>;
}

export const Dropzone: FC<DropzoneProps> = ({
  setIsDropzoneVisible,
  setGenes
}) => {
  const [isReadingError, setIsReadingError] = useState(false);
  const [isParsingFile, setIsParsingFile] = useState(false);

  const onDropAccepted = async (acceptedFiles: File[]) => {
    setIsParsingFile(true);
    try {
      const textContent = await acceptedFiles[0].text();
      const data = parse(textContent, {
        delimiter: ','
      }).data as string[][];
      const text = data.map((genes) => genes.join(',')).join('\n');
      setGenes(text);
    } catch {
      setIsReadingError(true);
    }
    setIsParsingFile(false);
    setIsDropzoneVisible(false);
  };

  const onDropRejected = () => {
    setIsReadingError(true);
    setTimeout(() => {
      setIsReadingError(false);
    }, 3000);
  };

  const csvValidator = (file: File) => {
    if (file.size > CSV_MAX_FILESIZE) {
      return {
        code: 'file-too-large',
        message: 'The file is exceeds the maximum file size'
      } as FileError;
    }

    if (!CSV_MIME_TYPES.includes(file.type)) {
      return {
        code: 'file-not-supported',
        message: 'Only CSV files are supported'
      } as FileError;
    }

    return null;
  };

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDropRejected,
    onDropAccepted,
    accept: '.csv',
    maxFiles: 1,
    validator: csvValidator
  });

  const closeDropzone = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setIsDropzoneVisible(false);
    e.stopPropagation();
  };

  const openFileDialog = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    open();
    e.stopPropagation();
  };

  const getColor = (defaultColor = 'content.additional') =>
    isDragActive ? 'state.infoDark' : defaultColor;

  if (isParsingFile) {
    return <Load />;
  }

  if (isReadingError) {
    return <Error />;
  }

  return (
    <Box
      {...getRootProps({
        onClick: closeDropzone
      })}
      data-testid="customInput"
      borderRadius="8px"
      border={isDragActive ? '1px solid' : '1px dashed'}
      borderColor={getColor()}
      bg="background.primary"
      h="96px"
      boxSizing="border-box"
      p="12px"
    >
      <input {...getInputProps()} />
      <VStack spacing="0">
        <Text color={getColor()}>Type genes on separate lines</Text>
        <Text color={getColor()}>or</Text>
        <HStack spacing="4px">
          <Text color={getColor()}>Drop .csv files to attach or</Text>
          <Button
            variant="ghost"
            p="0"
            fontWeight="normal"
            minW="auto"
            h="auto"
            color={getColor('content.secondary')}
            textDecoration="underline"
            _hover={{
              color: 'content.link.normal'
            }}
            onClick={openFileDialog}
            data-testid="browseCSVButton"
          >
            browse
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};
