import { HStack, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { usePanelCompositionStore } from 'store';

export const GeneInfo: FC = () => {
  const selectedItem = usePanelCompositionStore((state) => state.selectedItem);
  const transcripts = usePanelCompositionStore((state) => state.transcripts);

  const abundance =
    transcripts.find(
      (t) => t.geneName.toUpperCase() === selectedItem?.gene?.toUpperCase()
    )?.abundance || 0;

  return (
    <HStack spacing="48px" data-testid="geneInfo">
      <VStack align="start" spacing="4px">
        <Text variant="accentCaptionHigh">Gene symbol</Text>
        <Text variant="bodyShort">{selectedItem?.gene}</Text>
      </VStack>
      <VStack align="start" spacing="4px">
        <Text variant="accentCaptionHigh">Gene abundance</Text>
        <Text variant="bodyShort">{abundance}</Text>
      </VStack>
    </HStack>
  );
};
