import { FC, useRef } from 'react';
import { QuotesTable } from 'components';
import { Heading, HStack, Flex, Box, Text } from '@chakra-ui/react';
import { useParams } from 'react-router';
import { useQuotesListQuery } from 'api/hooks';
import { SearchField } from '@vizgen/vizgen-ui';
import { TableInstance, useAsyncDebounce } from 'react-table';
import { Quote } from 'api/types';

interface PanelQuotesProps {
  isCompleted: boolean;
}

const MAX_HEIGHT = 318;

export const PanelQuotes: FC<PanelQuotesProps> = ({ isCompleted }) => {
  const { panelId } = useParams<{ panelId: string }>();
  const { data = [], isLoading } = useQuotesListQuery();
  const tableRef = useRef<TableInstance<Quote>>(null);

  const onSearchChange = useAsyncDebounce((value) => {
    tableRef.current?.setGlobalFilter(value || undefined);
  }, 200);

  const quotes =
    data.length && panelId
      ? data.filter((quote) =>
          quote.quotepositions.items.some(
            (item) => item.panel?.panelId === panelId
          )
        )
      : [];

  const quotesAmount = quotes.length;

  if (!isCompleted) {
    return null;
  }

  return (
    <Flex w="100%" h="100%" direction="column" alignItems="center">
      <Flex justify="space-between" alignItems="center" w="100%" mb="16px">
        <Heading as="h2" variant="subtitle" w="100%">
          <HStack as="span" spacing="8px">
            <Heading as="span" variant="subtitle">
              Quote Requests
            </Heading>
            {quotesAmount && (
              <Heading as="span" variant="subtitle" color="content.additional">
                ({quotesAmount})
              </Heading>
            )}
          </HStack>
        </Heading>
        {!!quotesAmount && (
          <SearchField
            name="quoteSearch"
            placeholder="Search quote request"
            onChange={(e) => {
              const { value } = e.target;
              onSearchChange(value);
            }}
            controlProps={{
              maxW: '264px'
            }}
          />
        )}
      </Flex>
      {quotesAmount ? (
        <Box overflow="hidden" w="100%">
          <QuotesTable
            quotesList={quotes}
            maxHeight={MAX_HEIGHT}
            tableRef={tableRef}
            hasGlobalFilterPlugin
            isShort
            isLoading={isLoading}
          />
        </Box>
      ) : (
        <Box w="100%">
          <Text mt="16px" variant="accentBase" color="content.additional">
            This panel was not included in any quotes yet
          </Text>
        </Box>
      )}
    </Flex>
  );
};
