import { Flex, Text } from '@chakra-ui/react';
import { GeneSetLibraryDivider, GeneSetLibraryExpandButton } from 'components';
import { FC, Fragment, memo } from 'react';
import pluralize from 'pluralize';

interface GeneSetProps {
  label: string;
  searchedGene: string;
  isGeneSetExpanded: boolean;
  genes: Set<string>;
  onGeneSetExpand: (id: string) => void;
}

const Component: FC<GeneSetProps> = ({
  label,
  searchedGene,
  isGeneSetExpanded,
  genes,
  onGeneSetExpand
}) => {
  const genesAmount = genes?.size || 0;
  const subtitle = `${genesAmount} ${pluralize('gene', genesAmount)}`;

  return (
    <>
      <Flex w="100%" pl="64px" zIndex="1" pos="sticky" top="64px">
        <GeneSetLibraryExpandButton
          title={label}
          subtitle={subtitle}
          isExpanded={isGeneSetExpanded}
          onExpand={onGeneSetExpand}
        />
      </Flex>
      <GeneSetLibraryDivider />
      {isGeneSetExpanded && (
        <>
          <Text w="100%" pl="136px" py="8px">
            {Array.from(genes).map((g, i) => (
              <Fragment key={g}>
                {g === searchedGene ? (
                  <Text
                    as="span"
                    color="content.secondary"
                    bg="state.warning"
                    fontWeight="heavy"
                  >
                    {g}
                  </Text>
                ) : (
                  g
                )}
                {i + 1 === genesAmount ? '' : ', '}
              </Fragment>
            ))}
          </Text>
          <GeneSetLibraryDivider />
        </>
      )}
    </>
  );
};

export const GeneSet = memo(Component);
