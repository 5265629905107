import { FC, useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { GenePanelsTable } from 'components';
import { useGetPanels } from 'hooks';
import { PersonalGenePanelModel } from 'models';
import { ButtonsGroup, SearchField } from '@vizgen/vizgen-ui';
import { useNavigate, useParams } from 'react-router';
import { Paths } from 'routes';
import { produce } from 'immer';
import { useAsyncDebounce } from 'react-table';
import { PanelOwnershipType } from 'generated/types';

const OPTIONS = [
  {
    value: 'all',
    label: 'All Panels',
    count: 0
  },
  {
    value: PanelOwnershipType.proprietary,
    label: 'Custom Panels',
    count: 0
  },
  {
    value: PanelOwnershipType.promoted,
    label: 'MERSCOPE Panels',
    count: 0
  }
];

const filterPanelsByType = (
  panels: PersonalGenePanelModel[],
  panelType: PanelOwnershipType
) => panels.filter((p) => p.ownershipType === panelType);

export const GenePanels: FC = () => {
  const { panels = [], isLoading } = useGetPanels();
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState(OPTIONS);
  const { panelType = OPTIONS[0].value } = useParams<{
    panelType: PanelOwnershipType;
  }>();
  const [filter, setFilter] = useState(panelType);
  const navigate = useNavigate();
  const filteredPanelsByName = panels.filter((p) =>
    p.panelName.toLowerCase().includes(search.toLowerCase())
  );
  const filteredPanelsByType = filteredPanelsByName.filter(
    (p) => filter === OPTIONS[0].value || p.ownershipType === filter
  );

  const onSearchChange = useAsyncDebounce((e) => {
    setSearch(e.target.value || '');
  }, 200);

  useEffect(() => {
    setOptions((prev) =>
      produce(prev, (draft) => {
        draft[0].count = filteredPanelsByName.length;
        draft[1].count = filterPanelsByType(
          filteredPanelsByName,
          PanelOwnershipType.proprietary
        ).length;
        draft[2].count = filterPanelsByType(
          filteredPanelsByName,
          PanelOwnershipType.promoted
        ).length;
        return draft;
      })
    );
  }, [filteredPanelsByName]);

  useEffect(() => {
    setFilter(panelType);
  }, [panelType]);

  return (
    <Flex
      px="60px"
      w="100%"
      h="100%"
      overflowY="hidden"
      direction="column"
      alignItems="center"
    >
      <Flex
        w="100%"
        justify="space-between"
        alignItems="center"
        maxW="1128px"
        pt="32px"
      >
        <ButtonsGroup
          options={options}
          defaultValue={filter}
          value={filter}
          onChange={(value) => {
            setFilter(value);
            const path = `${Paths.Panels}${
              value === OPTIONS[0].value ? '' : `/${value.toLowerCase()}`
            }`;
            navigate(path);
          }}
        />
        <Flex alignItems="center">
          <SearchField
            name="panelSearch"
            placeholder="Search panel"
            controlProps={{
              w: '264px'
            }}
            onChange={onSearchChange}
          />
        </Flex>
      </Flex>
      <Flex
        h="100%"
        overflowY="hidden"
        direction="column"
        alignItems="center"
        w="100%"
      >
        <GenePanelsTable data={filteredPanelsByType} isLoading={isLoading} />
      </Flex>
    </Flex>
  );
};
