import {
  useNumberInput,
  HStack,
  Input,
  InputProps,
  Button
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { PlusIcon, MinusIcon } from './assets';

interface NumberInputProps {
  onChangeValue?: (valueAsNumber: number) => void;
}

export const NumberInput: FC<InputProps & NumberInputProps> = (props) => {
  const onChangeHandler = (valueAsString: string, valueAsNumber: number) => {
    if (props.onChangeValue) {
      props.onChangeValue(valueAsNumber);
    }
  };

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      value: props.value ? props.value.toString() : '',
      defaultValue: Number(props.defaultValue) || 1,
      min: 0,
      onChange: onChangeHandler
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['-', '+', 'e'].includes(event.key)) {
      event.preventDefault();
    }
  };

  const onInput = (event: React.FormEvent<HTMLInputElement>) => {
    event.currentTarget.value = event.currentTarget.value.replace(
      /* eslint-disable no-useless-escape */
      /[e\+\-]/gi,
      ''
    );
  };

  return (
    <HStack
      maxW="132px"
      spacing="0"
      border="1px solid"
      borderColor="border.normal"
      borderRadius="4px"
      overflow="hidden"
      maxH="40px"
    >
      <Button
        {...dec}
        variant="tinyGhostPrimary"
        p="0 6px"
        w="32px"
        minW="32px"
        borderRadius="0"
        css={{
          '& path': {
            transition: 'all 0.1s ease-in-out'
          }
        }}
        _active={{
          '& path': {
            stroke: 'content.secondary'
          }
        }}
      >
        <MinusIcon />
      </Button>
      <Input
        {...input}
        onKeyDown={onKeyDown}
        onInput={onInput}
        variant="numberInput"
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      />
      <Button
        {...inc}
        variant="tinyGhostPrimary"
        p="0 6px"
        w="32px"
        minW="32px"
        borderRadius="0"
        css={{
          '& path': {
            transition: 'all 0.1s ease-in-out'
          }
        }}
        _active={{
          '& path': {
            stroke: 'content.secondary'
          }
        }}
      >
        <PlusIcon />
      </Button>
    </HStack>
  );
};
