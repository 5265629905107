export const Table = {
  variants: {
    grid: {
      tr: {
        '&:last-of-type > div:not(.th)': {
          borderBottom: '0'
        }
      },
      th: {
        border: '0.5px solid',
        borderTop: '0',
        borderColor: 'border.divider',
        '&:first-of-type': {
          borderLeft: '0'
        },
        '&:last-of-type': {
          borderRight: '0'
        }
      },
      td: {
        border: '0.5px solid',
        borderColor: 'border.divider',
        '&:first-of-type': {
          borderLeft: '0'
        },
        '&:last-of-type': {
          borderRight: '0'
        }
      }
    },
    gridFixed: {
      table: {
        borderCollapse: 'separate',
        borderSpacing: '0'
      },
      td: {
        borderBottom: '0.5px solid',
        borderRight: '0.5px solid',
        borderColor: 'border.divider',
        '&:first-of-type': {
          borderLeft: '0'
        },
        '&:last-of-type': {
          borderRight: '0',
          borderLeft: '0.5px solid',
          borderColor: 'border.divider'
        },
        '&:nth-last-of-type(2)': {
          borderRight: '0'
        }
      },
      tr: {
        border: '0',
        '&:last-of-type > div:not(.th)': {
          borderBottom: '0'
        }
      },
      th: {
        position: 'sticky',
        top: '0',
        zIndex: 'docked',
        borderTop: '0',
        borderBottom: '0.5px solid',
        borderRight: '0.5px solid',
        borderColor: 'border.divider',
        bg: 'background.secondary',
        '&:first-of-type': {
          borderLeft: '0'
        },
        '&:last-of-type': {
          borderRight: '0',
          borderLeft: '0.5px solid',
          borderColor: 'border.divider'
        },
        '&:nth-last-of-type(2)': {
          borderRight: '0'
        }
      }
    }
  },
  sizes: {
    sm: {
      th: {
        px: '12px',
        py: '12px',
        lineHeight: '20px',
        fontSize: '16px',
        letterSpacing: '0',
        textTransform: 'unset'
      },
      td: {
        px: '12px',
        py: '12px',
        fontSize: '16px',
        lineHeight: '20px'
      }
    }
  }
};
