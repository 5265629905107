import { Heading, VStack } from '@chakra-ui/react';
import { InputField } from '../Fields/InputField';
import { FC } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';

interface ProfileUsernameProps {
  name: string;
  familyName: string;
  register: UseFormRegister<FieldValues>;
  isEditing: boolean;
  isFetching: boolean;
}

export const ProfileUsername: FC<ProfileUsernameProps> = ({
  isEditing,
  register,
  name,
  familyName,
  isFetching
}) => {
  return isEditing ? (
    <VStack spacing="8px" mb="8px" w="100%">
      <InputField
        {...register('firstName')}
        size="sm"
        placeholder="First Name"
        defaultValue={name}
        disabled={isFetching}
      />
      <InputField
        {...register('lastName')}
        size="sm"
        placeholder="Last Name"
        defaultValue={familyName}
        disabled={isFetching}
      />
    </VStack>
  ) : (
    <Heading as="h2" variant="subtitle">
      {`${name || ''} ${familyName || ''}`.trim() || 'N/A'}
    </Heading>
  );
};
