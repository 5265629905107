import { FC } from 'react';
import { Td, Text, Th, Tr } from '@chakra-ui/react';

interface ProfileRowProps {
  label: string;
  value: string;
  isEditing?: boolean;
}

export const ProfileRow: FC<ProfileRowProps> = ({
  label,
  value,
  isEditing,
  children
}) => {
  return (
    <Tr h="49px">
      <Th
        p="0"
        w="33%"
        textTransform="unset"
        letterSpacing="unset"
        borderBottom="1px solid"
        borderColor="border.divider"
      >
        <Text
          as="span"
          variant="accentBodyShort"
          pl="12px"
          color="content.primary"
        >
          {label}
        </Text>
      </Th>
      <Td
        w="67%"
        p="0 0 0 12px"
        borderBottom="1px solid"
        borderColor="border.divider"
      >
        {isEditing && children ? (
          children
        ) : (
          <Text
            d="flex"
            as="span"
            variant="bodyShort"
            color="content.secondary"
            pl="13px"
          >
            {value}
          </Text>
        )}
      </Td>
    </Tr>
  );
};
