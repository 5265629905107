import { GeneSetsSource, GeneSetLibraryEntry } from './types';
import { validateGeneSetsSource } from './validateGeneSetsSource';

export function convertGeneSetLibraries(
  source: GeneSetsSource
): GeneSetLibraryEntry[] {
  if (!validateGeneSetsSource(source)) {
    console.log('SOURCE DATA', source);
    console.error(validateGeneSetsSource.errors);
    throw new Error('Gene sets data format is incorrect');
  }
  const libraries = Object.values(source.categories).flatMap((category) =>
    Object.values(category.libraries)
  );

  return libraries.map(({ name, sets }) => [
    name,
    new Set(Object.values(sets).map((set) => set.name))
  ]);
}
