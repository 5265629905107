import { FC, useEffect, useState } from 'react';
import { ConfirmModal, useFailureNotification } from '@vizgen/vizgen-ui';
import { useCreatePanelStore } from 'store';
import { formatErrorMessages } from 'utils';

interface ConfirmGeneDeleteModalProps {
  isVisible: boolean;
  isPrimary: boolean;
  transcriptIds: string[];
  onClose: () => void;
}

const ModalComponent: FC<Omit<ConfirmGeneDeleteModalProps, 'isVisible'>> = ({
  transcriptIds,
  isPrimary,
  onClose
}) => {
  const { showFailureNotification, closeFailureNotifications } =
    useFailureNotification();
  const deleteTranscripts = useCreatePanelStore(
    (state) => state.deleteTranscripts
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const amount = transcriptIds.length;
  const geneText = amount > 1 ? 'genes' : 'gene';

  useEffect(() => {
    closeFailureNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteTranscripts(transcriptIds);
    } catch (e) {
      showFailureNotification(formatErrorMessages(e));
    } finally {
      onClose();
    }
  };

  return (
    <ConfirmModal
      isInProgress={isDeleting}
      title={`Delete ${geneText}`}
      text={
        <>
          You are about to delete {amount} {geneText} from{' '}
          {isPrimary ? 'MERFISH' : 'sequential'} panel.
        </>
      }
      submitText={`Delete the ${geneText}`}
      onClose={onClose}
      onSubmit={onDelete}
    />
  );
};
export const ConfirmGeneDeleteModal: FC<ConfirmGeneDeleteModalProps> = ({
  isVisible,
  ...rest
}) => {
  if (!isVisible) {
    return null;
  }

  return <ModalComponent {...rest} />;
};
