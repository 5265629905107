import {
  useListPromotedPanelTranscriptsQuery,
  usePromotedPanelQuery
} from 'api/hooks';
import { FC } from 'react';
import { useParams } from 'react-router';
import { PanelDetails } from 'components';
import { TranscriptModel } from 'store';

export const PromotedPanel: FC = () => {
  const { panelId } = useParams();
  const { data: panel } = usePromotedPanelQuery(panelId!);
  const { data: transcripts } = useListPromotedPanelTranscriptsQuery(panelId!);

  return (
    <PanelDetails
      panel={panel}
      transcripts={transcripts as TranscriptModel[]}
    />
  );
};
