import { usePanelQuery } from 'api/hooks';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CreatePanelModel, useCreatePanelStore } from 'store';

export const useGetPanel = () => {
  const { panelId } = useParams();
  const { data } = usePanelQuery(panelId!);
  const setPanel = useCreatePanelStore((state) => state.setPanel);

  useEffect(() => {
    if (data) {
      setPanel(data as CreatePanelModel);
    }
  }, [data, setPanel]);
};
