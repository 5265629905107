import { Flex } from '@chakra-ui/react';
import { FC } from 'react';

interface ExtraContainerProps {
  hasWarning?: boolean;
}

export const ExtraContainer: FC<ExtraContainerProps> = ({
  hasWarning = false,
  children
}) => {
  return (
    <Flex
      align="center"
      justify="space-between"
      boxShadow="0px 4px 8px rgba(215, 217, 220, 0.4)"
      background="background.secondary"
      border="1px solid"
      borderColor={hasWarning ? 'state.warning' : 'border.normal'}
      borderRadius="4px"
      p="7px 8px 7px 12px"
    >
      {children}
    </Flex>
  );
};
