import { Th, Thead, Tr, Text } from '@chakra-ui/react';

const textBaseStyles = {
  fontWeight: 'heavy',
  color: 'printable.primary'
};

const cellBaseStyles = {
  borderBottom: '0.5px solid',
  borderRight: '0.5px solid',
  color: 'printable.additional',
  borderColor: 'printable.additional',
  letterSpacing: 'initial'
};

export const Head = () => {
  return (
    <Thead border="none">
      <Tr>
        <Th p="0" w="34px" maxW="34px" {...cellBaseStyles}></Th>
        <Th
          w="230px"
          maxW="230px"
          textTransform="none"
          p="0 8px"
          {...cellBaseStyles}
        >
          <Text {...textBaseStyles}>Gene Input</Text>
        </Th>
        <Th
          w="150px"
          maxW="150px"
          textTransform="none"
          p="0 8px"
          {...cellBaseStyles}
        >
          <Text {...textBaseStyles}>Identified Gene Name</Text>
        </Th>
        <Th
          w="110px"
          maxW="110px"
          textTransform="none"
          p="0 8px 0 2px"
          textAlign="right"
          {...cellBaseStyles}
        >
          <Text {...textBaseStyles}>Abundance, FPKM</Text>
        </Th>
        <Th
          w="110px"
          maxW="110px"
          textTransform="none"
          p="0 8px"
          textAlign="right"
          {...cellBaseStyles}
          borderRight="none"
        >
          <Text {...textBaseStyles}>Target Regions</Text>
        </Th>
      </Tr>
    </Thead>
  );
};
