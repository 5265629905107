import { theme } from '../../theme';
const { colors, zIndices } = theme;

export const customStyles = (
  height: string,
  isInvalid: boolean,
  placement: string,
  hasWarning: boolean,
  onPlacementUpdate: (placement: string) => void
) => {
  return {
    control: (provided: any, state: any) => ({
      ...provided,
      '&:hover': {
        borderColor: isInvalid
          ? colors.state.error
          : hasWarning
          ? colors.state.warning
          : state.isFocused
          ? colors.border.selected
          : colors.border.normal
      },
      border: `1px solid ${
        isInvalid
          ? colors.state.error
          : hasWarning
          ? colors.state.warning
          : state.selectProps.menuIsOpen
          ? colors.border.selected
          : colors.border.normal
      }`,
      borderRadius: '4px',
      boxShadow: 'none',
      fontSize: theme.fontSizes[16],
      lineHeight: theme.lineHeights[24],
      minHeight: 'auto',
      height,
      backgroundColor: colors.background.primary,
      opacity: state.isDisabled ? '0.5' : '1',
      flexWrap: 'initial',
      ...(placement === 'bottom'
        ? {
            borderBottomRightRadius: state.selectProps.menuIsOpen ? 0 : '4px',
            borderBottomLeftRadius: state.selectProps.menuIsOpen ? 0 : '4px'
          }
        : {
            borderTopRightRadius: state.selectProps.menuIsOpen ? 0 : '4px',
            borderTopLeftRadius: state.selectProps.menuIsOpen ? 0 : '4px'
          })
    }),
    input: (provided: any) => ({
      ...provided,
      margin: 0
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      cursor: 'pointer',
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      color: colors.content.secondary,
      '&:hover': {
        color: colors.content.secondary
      },
      opacity: state.isDisabled ? '0.5' : '1',
      height: `calc(${height} - 2px)`,
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px'
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      paddingTop: '1px',
      paddingBottom: '1px',
      paddingLeft: '12px'
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: state.selectProps.menuIsOpen
        ? colors.content.additional
        : colors.content.secondary,
      transition: 'all .2s ease',
      margin: 0
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: theme.fontSizes[16],
      lineHeight: theme.lineHeights[24],
      color: colors.content.additional,
      margin: 0
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
    menu: (provided: any, state: any) => {
      if (placement !== state?.placement) {
        onPlacementUpdate(state?.placement || '');
      }
      return {
        ...provided,
        margin: 0,
        boxSizing: 'border-box',
        borderColor: colors.border.selected,
        border: `1px solid ${colors.border.selected}`,
        boxShadow: 'none',
        borderRadius: '4px',
        zIndex: zIndices.dropdown,
        ...(state?.placement === 'bottom'
          ? {
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderTop: 'none'
            }
          : {
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              borderBottom: 'none'
            })
      };
    },
    menuList: (provided: any) => ({
      ...provided,
      padding: 0,
      borderRadius: '4px'
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      cursor: 'pointer',
      padding: '8px 12px',
      fontSize: theme.fontSizes[16],
      lineHeight: theme.lineHeights[24],
      textAlign: 'left',
      borderBottom: `1px solid ${colors.border.divider}`,
      color: state.isDisabled
        ? colors.content.additional
        : colors.content.secondary,
      backgroundColor: state.isSelected
        ? colors.background.secondary
        : colors.background.primary,
      '&:hover': {
        backgroundColor: colors.background.highlight
      },
      '&:last-child': {
        borderBottom: 'none'
      }
    }),
    noOptionsMessage: (provided: any, state: any) => ({
      ...provided,
      padding: '8px 12px',
      fontSize: theme.fontSizes[16],
      lineHeight: theme.lineHeights[24],
      textAlign: 'left'
    })
  };
};
