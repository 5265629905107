import { useListPanelTranscriptsQuery, usePanelQuery } from 'api/hooks';
import { FC } from 'react';
import { useParams } from 'react-router';
import { PanelDetails } from 'components';

export const CustomPanel: FC = () => {
  const { panelId } = useParams();
  const { data: panel } = usePanelQuery(panelId!, false);
  const { data: transcripts } = useListPanelTranscriptsQuery(panelId!);

  return <PanelDetails panel={panel} transcripts={transcripts} />;
};
