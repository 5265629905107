import { FC } from 'react';
import { Link } from '@chakra-ui/react';

interface AnchorLinkProps {
  id: string;
  text: string;
  isActive: boolean;
  onClick: (id: string) => void;
}

export const AnchorLink: FC<AnchorLinkProps> = ({
  id,
  text,
  isActive,
  onClick
}) => {
  return (
    <Link
      href={id}
      size="lg"
      variant="simple"
      textAlign="end"
      color={isActive ? 'content.link.normal' : 'content.secondary'}
      onClick={(e) => {
        e.preventDefault();
        onClick(id);
      }}
    >
      {text}
    </Link>
  );
};
