import { FC } from 'react';
import { Box, StackDivider, HStack, VStack, Text } from '@chakra-ui/react';
import { useAddressesStore, addressByIdSelector } from 'store';

interface SummaryProps {
  quoteId?: string;
  deliveryAddress: string;
  comment: string;
  panelsAmount: number;
  extrasAmount: number;
}

export const Summary: FC<SummaryProps> = ({
  quoteId,
  deliveryAddress,
  comment,
  panelsAmount,
  extrasAmount
}) => {
  const address = useAddressesStore(addressByIdSelector(deliveryAddress));

  return (
    <Box
      w="100%"
      p="12px 16px"
      border="1px solid"
      borderRadius="8px"
      borderColor="border.normal"
    >
      <HStack
        spacing="12px"
        h="100%"
        alignItems="start"
        divider={<StackDivider borderColor="border.divider" />}
      >
        <VStack spacing="8px" alignItems="start" flex="1">
          <Text variant="bodyShort" color="content.primary" fontWeight="heavy">
            {quoteId ? quoteId : '-'}
          </Text>
          <VStack spacing="0" alignItems="start">
            <Text variant="bodyShort" h="20px">
              <Text as="span" fontWeight="heavy">
                {panelsAmount}
              </Text>{' '}
              panels
            </Text>
            <Text variant="bodyShort" h="20px">
              <Text as="span" fontWeight="heavy">
                {extrasAmount}
              </Text>{' '}
              extras
            </Text>
          </VStack>
        </VStack>
        <VStack spacing="8px" alignItems="start" flex="1">
          <Text variant="bodyShort" color="content.primary" fontWeight="heavy">
            Address
          </Text>
          <Text variant="bodyShort">{address.address}</Text>
        </VStack>
        <VStack spacing="8px" alignItems="start" flex="1">
          <Text variant="bodyShort" color="content.primary" fontWeight="heavy">
            Comment
          </Text>
          <Text variant="bodyShort">{comment}</Text>
        </VStack>
      </HStack>
    </Box>
  );
};
