import { Box, Container, HStack } from '@chakra-ui/react';
import { AddressesSettings, AuthSettings, ProfileDetails } from './components';
import { Content } from 'components';

export const Profile = () => {
  return (
    <Box h="100%" overflow="hidden">
      <Content>
        <Box p="40px 60px">
          <Container maxW="1128px" w="100%" m="auto" h="100%" p="0">
            <ProfileDetails />
            <HStack
              spacing="0"
              justify="flex-end"
              alignItems="self-start"
              pt="24px"
              borderTop="1px solid"
              borderColor="border.divider"
            >
              <AuthSettings />
              <AddressesSettings />
            </HStack>
          </Container>
        </Box>
      </Content>
    </Box>
  );
};
