import { create } from 'zustand';
import { Auth } from 'aws-amplify';
import { ErrorType, ValidationError } from 'models';
import {
  validateEmail,
  validateChangePasswordForm,
  NewPasswordData
} from 'utils';

type ResetPasswordStore = {
  email: string;
  error: ErrorType | null;
  errorValidation: ValidationError | null;
  isFetching: boolean;
  isCodeSent: boolean;
  isPasswordChanged: boolean;
  sendCode: (email: string) => void;
  changePassword: (formData: NewPasswordData) => void;
  clearState: () => void;
};

const initialState = {
  email: '',
  error: null,
  errorValidation: null,
  isFetching: false,
  isCodeSent: false,
  isPasswordChanged: false
};

export const useResetPasswordStore = create<ResetPasswordStore>()(
  (set, get) => ({
    ...initialState,
    sendCode: async (email: string) => {
      set({ isFetching: true, error: null, errorValidation: null });
      const validationError = validateEmail(email);
      if (validationError) {
        set({ errorValidation: validationError, isFetching: false });
        return;
      }

      try {
        await Auth.forgotPassword(email);
        set({ isCodeSent: true, isFetching: false, email });
      } catch (error: any) {
        set({ error, isFetching: false });
      }
    },
    changePassword: async (formData) => {
      set({ isFetching: true, error: null, errorValidation: null });
      const validationError = validateChangePasswordForm(formData);
      if (validationError) {
        set({ errorValidation: validationError, isFetching: false });
        return;
      }

      try {
        const { email, clearState } = get();
        await Auth.forgotPasswordSubmit(
          email,
          formData.code || '',
          formData.password || ''
        );
        set({ isCodeSent: true, isFetching: false, isPasswordChanged: true });
        setTimeout(() => {
          clearState();
        }, 5000);
      } catch (error: any) {
        set({ error, isFetching: false });
      }
    },
    clearState: () => {
      set({
        ...initialState
      });
    }
  })
);
