import { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { IssueText } from './components';
import { PanelTranscriptStatus } from 'generated/types';
import { GeneStatusIcon } from 'components';

interface DetailProps {
  status: PanelTranscriptStatus;
  amount: number;
}

export const Detail: FC<DetailProps> = ({ status, amount }) => {
  return (
    <Text as="span" d="flex" alignItems="center">
      <GeneStatusIcon type={status} />
      <Text as="span" fontWeight="800" ml="8px">
        {amount}
      </Text>
      <IssueText status={status} />
    </Text>
  );
};
