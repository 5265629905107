import { PanelTranscriptStatus } from 'generated/types';
import { transcriptsByStatusSelector, useCreatePanelStore } from 'store';
import { shallow } from 'zustand/shallow';
import { Row } from '..';

export const NotFoundRow = () => {
  const issues = useCreatePanelStore(
    transcriptsByStatusSelector(PanelTranscriptStatus.not_found),
    shallow
  );
  return <Row genes={issues} status={PanelTranscriptStatus.not_found} />;
};
