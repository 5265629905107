import { useEffect, useState } from 'react';

export function useDebounce<Type>(
  value: Type,
  delay: number
): { value: Type; debouncing: boolean } {
  const [debouncing, setDebouncing] = useState<boolean>(false);
  const [debouncedValue, setDebouncedValue] = useState<Type>(value);

  useEffect(() => {
    setDebouncing(true);
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setDebouncing(false);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return { value: debouncedValue, debouncing };
}
