import { Text } from './text';
import { Heading } from './heading';
import { Radio } from './radio';
import { Button } from './button';
import { FormError } from './form-error';
import { FormLabel } from './form-label';
import { Input } from './input';
import { Textarea } from './textarea';
import { Link } from './link';
import { Checkbox } from './checkbox';
import { Menu } from './menu';
import { Tooltip } from './tooltip';
import { Table } from './table';
import { Divider } from './divider';
import { Popover } from './popover';
import { Spinner } from './spinner';
import { Badge } from './badge';
import { Modal } from './modal';
import { Switch } from './switch';

export const components = {
  Button,
  Text,
  Heading,
  Radio,
  FormError,
  Input,
  FormLabel,
  Textarea,
  Link,
  Checkbox,
  Menu,
  Tooltip,
  Table,
  Divider,
  Popover,
  Spinner,
  Badge,
  Modal,
  Switch
};
