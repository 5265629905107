import { Droppable } from 'react-beautiful-dnd';
import { ColumnInstance } from 'react-table';
import { TableColumnsLabels } from '../../../../../../../../types';
import { ColumnFilter } from '../../ManageTableColumns';
import { Column } from './components';

interface ColumnListProps<T extends {}> {
  columns: ColumnInstance<T>[];
  labels: TableColumnsLabels;
  hiddenColumns: string[];
  disabledColumns: string[];
  setFilter: ColumnFilter;
}

export const ColumnList = <T extends {}>({
  columns,
  labels,
  hiddenColumns,
  disabledColumns,
  setFilter
}: ColumnListProps<T>) => {
  return (
    <Droppable droppableId="list">
      {({ innerRef, droppableProps, placeholder }) => (
        <div ref={innerRef} {...droppableProps}>
          {columns.map((column, i) =>
            hiddenColumns.includes(column.id) ? null : (
              <Column
                column={column}
                index={i}
                key={column.id}
                labels={labels}
                disabledColumns={disabledColumns}
                setFilter={setFilter}
              />
            )
          )}
          {placeholder}
        </div>
      )}
    </Droppable>
  );
};
