import { Text } from '@chakra-ui/react';
import { TextareaField } from '@vizgen/vizgen-ui';
import { useNewOrderStore } from 'store';

export const CommentsField = () => {
  const comment = useNewOrderStore((state) => state.comment);
  const setComment = useNewOrderStore((state) => state.setComment);

  return (
    <TextareaField
      h="96px"
      name="comments"
      placeholder="Add your comments"
      value={comment}
      onChange={(e) => setComment(e.target.value)}
      controlProps={{
        mt: '48px'
      }}
      label={
        <Text fontSize="inherit" lineHeight="inherit">
          Comments{' '}
          <Text
            as="span"
            fontSize="inherit"
            lineHeight="inherit"
            color="content.additional"
          >
            (optional)
          </Text>
        </Text>
      }
    />
  );
};
