import { useQuery } from 'react-query';
import { listPromotedPanelsQuery } from 'api';
import { Params } from './useFinalizedPanelsByTissueQuery';
import { Panel } from 'generated/types';

const PROMOTED_PANELS_BY_TISSUE = 'promotedPanelsByTissue';

export const usePromotedPanelsByTissueQuery = ({
  tissueId,
  transcriptomeId
}: Params) => {
  return useQuery<Panel[]>(
    [PROMOTED_PANELS_BY_TISSUE, tissueId, transcriptomeId],
    async () => {
      if (!tissueId || !transcriptomeId) {
        return [];
      }
      const panels = (await listPromotedPanelsQuery(tissueId)) || [];
      return panels.filter(
        (panel) => panel?.transcriptomeId === transcriptomeId
      );
    }
  );
};
