import { Instrument, UserProps } from 'generated/types';

export const useSearchInstrument = (
  searchValue: string,
  instruments?: Instrument[]
) => {
  return instruments?.filter(
    ({ serialNumber = '', uniqueId, viewers, admins }) => {
      const data = [
        serialNumber,
        uniqueId,
        ...getUsersProps(viewers),
        ...getUsersProps(admins)
      ];
      return data.some((item) => item.toLowerCase().includes(searchValue));
    }
  );
};

const getUsersProps = (usersProps: UserProps[]) => {
  return usersProps.reduce((accum, { email, name, familyName }) => {
    return [...accum, email, `${name} ${familyName}`];
  }, [] as string[]);
};
