import { IconButton, PopoverTrigger } from '@chakra-ui/react';
import { FC } from 'react';
import { theme } from '../../../../../../../../theme';
import SettingsIcon from './assets/settings.svg';
const { colors } = theme;

interface TriggerButtonProps {
  isPopoverOpen: boolean;
  onPopoverOpen: () => void;
}

export const TriggerButton: FC<TriggerButtonProps> = ({
  isPopoverOpen,
  onPopoverOpen
}) => {
  return (
    <PopoverTrigger>
      <IconButton
        aria-label="Open table columns manager"
        variant="primaryGhost"
        size="auto"
        mr="3px"
        _hover={{
          '& rect': {
            fill: isPopoverOpen
              ? colors.button.ghostOnPrimary.pressed
              : colors.background.secondary
          },
          '& path': {
            stroke: colors.content.secondary
          }
        }}
        css={
          isPopoverOpen
            ? {
                '& rect': {
                  fill: colors.button.ghostOnPrimary.pressed
                },
                '& path': {
                  stroke: colors.content.secondary
                }
              }
            : {}
        }
        icon={<SettingsIcon />}
        onClick={onPopoverOpen}
      />
    </PopoverTrigger>
  );
};
