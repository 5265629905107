import { Box, InputProps, useOutsideClick } from '@chakra-ui/react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { SearchField } from '../SearchField';
import { SuggestionList } from './components';
import { MAX_LIST_HEIGHT, OFFSET } from './consts';
import {
  Placement,
  AutocompleteSuggestion,
  StickyAutocompleteSuggestion,
  AutocompleteFieldSize
} from './models';

interface AutocompleteFieldProps {
  name: string;
  suggestions: AutocompleteSuggestion[];
  w?: string;
  size?: AutocompleteFieldSize;
  value?: AutocompleteSuggestion;
  defaultValue?: AutocompleteSuggestion;
  placeholder?: string;
  isDisabled?: boolean;
  isLeftElementShown?: boolean;
  stickyElementLabel?: string;
  suggestionsLimit?: number;
  onChange: (value?: AutocompleteSuggestion) => void;
}

export const AutocompleteField: FC<AutocompleteFieldProps> = ({
  w = '264px',
  size = AutocompleteFieldSize.Md,
  name,
  suggestions,
  value,
  defaultValue,
  placeholder,
  isDisabled,
  isLeftElementShown = true,
  stickyElementLabel,
  suggestionsLimit,
  onChange
}) => {
  const [searchValue, setSearchValue] = useState(defaultValue?.label || '');
  const [isOpen, setIsOpen] = useState(false);
  const [placement, setPlacement] = useState(Placement.Bottom);
  const [listHeight, setListHeight] = useState(MAX_LIST_HEIGHT);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handlePlacement = () => {
      const heightToEnd =
        window.innerHeight -
        (containerRef.current?.getBoundingClientRect().bottom || 0);
      if (heightToEnd > listHeight + OFFSET) {
        setPlacement(Placement.Bottom);
      } else {
        setPlacement(Placement.Top);
      }
    };
    handlePlacement();
    const timerId = setInterval(handlePlacement, 50);
    return () => clearInterval(timerId);
  }, [containerRef, listHeight]);

  const filteredSuggestions = useMemo(
    () =>
      [
        ...(stickyElementLabel
          ? [
              {
                value: stickyElementLabel,
                label: stickyElementLabel,
                isSticky: true
              }
            ]
          : []),
        ...suggestions.filter((s) =>
          s.label.toLowerCase().includes(searchValue.toLowerCase())
        )
      ] as StickyAutocompleteSuggestion[],
    [searchValue, suggestions, stickyElementLabel]
  );

  useEffect(() => {
    setSearchValue(value?.label || '');
  }, [value]);

  useOutsideClick({
    ref: containerRef,
    handler: () => {
      const suggestion = filteredSuggestions.find(
        (s) => s.label.toLowerCase() === searchValue.toLowerCase()
      );
      setValue(suggestion);
    }
  });

  const setValue = (suggestion?: AutocompleteSuggestion) => {
    setSearchValue(suggestion?.label || '');
    onChange(suggestion);
    setIsOpen(false);
  };

  const isSuggestionListVisible = isOpen && filteredSuggestions.length > 0;
  const inputProps: InputProps = isSuggestionListVisible
    ? placement === Placement.Bottom
      ? { borderBottomRadius: 0, borderColor: 'border.selected' }
      : {
          borderTopRadius: 0,
          borderColor: 'border.selected'
        }
    : {};

  return (
    <Box ref={containerRef} w={w} pos="relative" zIndex="docked">
      <SearchField
        autoComplete="off"
        name={name}
        placeholder={placeholder}
        value={searchValue}
        size={size}
        isRightElementShown={!!searchValue}
        isLeftElementShown={isLeftElementShown}
        onChange={(e) => setSearchValue(e.target.value || '')}
        onFocus={() => setIsOpen(true)}
        rightElementProps={{
          onClick: () => setValue()
        }}
        isDisabled={isDisabled}
        {...inputProps}
      />
      {isSuggestionListVisible && (
        <SuggestionList
          size={size}
          suggestions={filteredSuggestions}
          placement={placement}
          listHeight={listHeight}
          suggestionsLimit={suggestionsLimit}
          onListHeightChange={setListHeight}
          onSuggestionSelect={setValue}
        />
      )}
    </Box>
  );
};
