import { VStack, Text, Box } from '@chakra-ui/react';
import { Tooltip } from '@vizgen/vizgen-ui';
import { FC } from 'react';
import { WarningIcon } from './assets';

interface WarningTooltipProps {
  messages: string[];
}

export const WarningTooltip: FC<WarningTooltipProps> = ({
  messages,
  children
}) => {
  if (!messages.length) {
    return <>{children}</>;
  }

  return (
    <Box pos="relative">
      {children}
      <Box pos="absolute" right="-48px" top="50%" transform="translateY(-50%)">
        <Tooltip
          maxW="220px"
          label={
            <VStack spacing="8px">
              {messages.map((message) => (
                <Text
                  key={message}
                  variant="captionHigh"
                  color="content.tertiary"
                >
                  {message}
                </Text>
              ))}
            </VStack>
          }
        >
          <WarningIcon />
        </Tooltip>
      </Box>
    </Box>
  );
};
