import { getListPromotedPanelTranscripts } from 'api';
import { ListPromotedPanelTranscriptsQueryVariables } from 'generated/types';
import { useQuery } from 'react-query';

const LIST_PROMOTED_PANEL_TRANSCRIPTS_QUERY_KEY =
  'listPromotedPanelTranscripts';

export const useListPromotedPanelTranscriptsQuery = (
  promotedPanelId: string,
  input?: Omit<ListPromotedPanelTranscriptsQueryVariables, 'promotedPanelId'>
) => {
  return useQuery(
    [LIST_PROMOTED_PANEL_TRANSCRIPTS_QUERY_KEY, input, promotedPanelId],
    async () => {
      if (!promotedPanelId) {
        return [];
      }
      return getListPromotedPanelTranscripts({
        promotedPanelId,
        ...input
      });
    },
    { refetchOnWindowFocus: false }
  );
};
