import { FC, useState } from 'react';
import { Flex, HStack, Text, VStack, StackDivider } from '@chakra-ui/react';
import { SearchField, Scrollbar } from '@vizgen/vizgen-ui';
import { useListInstrumentsQuery } from 'api/hooks';
import { Title, InstrumentBlock } from './components';
import { useSearchInstrument } from './useSearchInstrument';
import { NotFoundMessage } from 'components';

export const Instruments: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const { data, isLoading, isRefetching, isFetched } =
    useListInstrumentsQuery();
  const filteredInstruments = useSearchInstrument(searchValue, data);
  const isNotFound = isFetched && !filteredInstruments?.length && data?.length;
  const hasNoInstruments = !data?.length && isFetched;

  return (
    <Flex w="100%" h="100%" justify="center">
      <Flex h="100%" w="100%" direction="column" maxW="1248px" p="40px 60px">
        <HStack justify="space-between" alignItems="center" mb="16px">
          <Title isLoading={isLoading || isRefetching} />
          <SearchField
            name="instrumentSearch"
            placeholder="Search instrument"
            controlProps={{
              w: '264px'
            }}
            onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
          />
        </HStack>
        <Scrollbar>
          {isLoading ? (
            <Text
              w="100%"
              textAlign="center"
              variant="accentBodyShort"
              color="content.additional"
              fontWeight="normal"
            >
              Please wait...
            </Text>
          ) : hasNoInstruments ? (
            <Text
              w="100%"
              textAlign="center"
              variant="accentBodyShort"
              color="content.additional"
              fontWeight="normal"
            >
              You don’t have permissions to see any instruments
            </Text>
          ) : isNotFound ? (
            <NotFoundMessage>
              We didn’t find any instruments matching your search request
            </NotFoundMessage>
          ) : (
            <VStack
              spacing="24px"
              w="100%"
              divider={<StackDivider borderColor="border.normal" />}
            >
              {filteredInstruments?.map((instrument) => (
                <InstrumentBlock
                  key={instrument.uniqueId}
                  instrument={instrument}
                />
              ))}
            </VStack>
          )}
        </Scrollbar>
      </Flex>
    </Flex>
  );
};
