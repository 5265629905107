import { API, Auth, graphqlOperation } from 'aws-amplify';
import {
  GraphQLResult,
  GraphQLOptions,
  GRAPHQL_AUTH_MODE
} from '@aws-amplify/api-graphql';
import mergeWith from 'lodash/mergeWith';
import awsconfig from '../aws-exports';

// Limit with AppSync works different than in relation DBMS
// It applies before data filtering, that's why it's big here.
// The maximum value is 1000 due to AppSync limitations,
// otherwise we'll skip some data
const DEFAULT_CHAINED_QUERY_LIMIT = 1000;

type Primitive =
  | string
  | Function
  | number
  | boolean
  | symbol
  | undefined
  | null;

type DeepOmitArray<T extends any[]> = {
  [P in keyof T]: DeepOmit<T[P]>;
};

export type DeepOmit<T> = T extends Primitive
  ? NonNullable<T>
  : {
      [P in Exclude<keyof T, '__typename'>]: T[P] extends infer TP
        ? TP extends Primitive
          ? NonNullable<TP> // leave primitives and functions alone
          : TP extends any[]
          ? DeepOmitArray<TP> // Array special handling
          : DeepOmit<TP>
        : never;
    };

export const executeQuery = async <T, V = {}>(query: string, input?: V) => {
  const isAuthenticated = await getIsAuthenticatedUser();
  const result = (await API.graphql(
    createGraphqlOptions(query, input, isAuthenticated)
  )) as GraphQLResult<T>;
  if (result.errors || !result.data) {
    throw new Error('GraphQL query error');
  }
  return result.data;
};

function customizer(objValue: any, srcValue: any) {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}

export const getIsAuthenticatedUser = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (e) {
    return false;
  }
};

export const executeChainQuery = async <T, V = {}>(
  query: string,
  input: V,
  nextTokenDestination: string = ''
) => {
  const output: T[] = [];

  const isAuthenticated = await getIsAuthenticatedUser();

  await (async function execute<A = V>(q: string, i?: A) {
    const result = (await API.graphql(
      createGraphqlOptions(q, i, isAuthenticated)
    )) as GraphQLResult<T>;
    if (result.errors || !result.data) {
      throw new Error('GraphQL query error');
    }
    output.push(result.data);
    const nextTokenFound = (result.data as any)[nextTokenDestination]
      ?.nextToken;
    if (nextTokenFound || (result.data as any)?.nextToken) {
      await execute(q, { ...i, nextToken: nextTokenFound });
    }
  })(query, { limit: DEFAULT_CHAINED_QUERY_LIMIT, ...input });
  return output.reduce((acc, r) => mergeWith(acc, r, customizer), {}) as T;
};

export const executeMutation = async <T, V>(
  mutation: string,
  input: V,
  isCustomInput = false,
  isApiKeyAuth = false
) => {
  const isAuthenticated = !isApiKeyAuth && (await getIsAuthenticatedUser());
  const result = (await API.graphql(
    createGraphqlOptions(
      mutation,
      isCustomInput ? input : { input },
      isAuthenticated
    )
  )) as GraphQLResult<T>;
  if (result.errors) {
    throw new Error('GraphQL mutation error');
  }
  return result.data;
};

const createGraphqlOptions = (
  query: string,
  variables?: {} | undefined,
  isAuthenticated?: boolean
): GraphQLOptions => {
  const result: GraphQLOptions = graphqlOperation(query, variables);
  if (isAuthenticated) {
    return result;
  } else {
    return {
      ...result,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
      authToken: awsconfig.aws_appsync_apiKey
    };
  }
};
