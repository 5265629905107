import { Text, VStack } from '@chakra-ui/react';
import {
  MiniModal,
  CheckboxFormField,
  useSuccessNotification
} from '@vizgen/vizgen-ui';
import { useUserInstrumentNotificationsMutation } from 'api/hooks';
import { InstrumentNotifications } from 'consts';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

interface NotificationsSettingsModalProps {
  isVisible: boolean;
  instrumentUniqueId: string;
  notifications: string[];
  onClose: () => void;
}

export interface NotificationsFormData {
  RunStarted?: boolean;
  AcquisitionCompleted?: boolean;
  AcquisitionFailed?: boolean;
  ImageProcessingComplete?: boolean;
  DataTransferCompleted?: boolean;
}

const ModalComponent: FC<
  Omit<NotificationsSettingsModalProps, 'isVisible'>
> = ({ instrumentUniqueId, notifications, onClose }) => {
  const { control, handleSubmit } = useForm<NotificationsFormData>();
  const showSuccessNotification = useSuccessNotification();
  const { mutateAsync: updateUserInstrumentNotifications, isLoading } =
    useUserInstrumentNotificationsMutation();

  const onSubmit = async (form: NotificationsFormData) => {
    const data = {
      instrumentUniqueId,
      events: Object.entries(form)
        .filter(([_, value]) => value)
        .map(([key, _]) => key)
    };
    await updateUserInstrumentNotifications(data);
    onClose();
    showSuccessNotification('Changes has been saved');
  };

  const renderCheckboxes = () => {
    return Object.entries(InstrumentNotifications).map(([key, value]) => (
      <CheckboxFormField
        key={key}
        fieldName={key}
        label={value}
        defaultValue={notifications?.includes(key) || false}
        control={control}
        isDisabled={isLoading}
      />
    ));
  };

  return (
    <MiniModal
      isInProgress={isLoading}
      contentProps={{ maxW: '454px', onSubmit: handleSubmit(onSubmit) }}
      bodyProps={{ p: '8px 24px 12px 24px' }}
      title="Experiment notifications settings"
      submitText="Save"
      onClose={onClose}
    >
      <VStack spacing="28px" w="100%">
        <Text variant="bodyShort">
          Choose experiment statuses you want to be notified of:
        </Text>
        <VStack spacing="12px" align="start" w="100%">
          {renderCheckboxes()}
        </VStack>
      </VStack>
    </MiniModal>
  );
};

export const NotificationsSettingsModal: FC<
  NotificationsSettingsModalProps
> = ({ isVisible, ...rest }) => {
  if (!isVisible) {
    return null;
  }

  return <ModalComponent {...rest} />;
};
