import { FC } from 'react';
import { Container } from '@chakra-ui/react';
import {
  HowItWorks,
  RecentQuotes,
  RecentPanels,
  Resources
} from './components';
import { ContainerLayout } from 'components';

export const Home: FC = () => {
  return (
    <ContainerLayout>
      <Container d="flex" flexDir="column" maxW="1128px" p="0">
        <HowItWorks />
        <RecentPanels />
        <RecentQuotes />
        <Resources />
      </Container>
    </ContainerLayout>
  );
};
