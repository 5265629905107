import React, { FC } from 'react';
import { HStack, Divider as ChakraDivider, Text } from '@chakra-ui/react';

export const Divider: FC = () => {
  return (
    <HStack w="100%" p="0 12px" align="center" spacing="0" m="24px 0 24px">
      <ChakraDivider />
      <Text p="0 12px" m="0" color="content.additional">
        or
      </Text>
      <ChakraDivider />
    </HStack>
  );
};
