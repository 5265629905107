import { Checkbox, CheckboxProps, forwardRef } from '@chakra-ui/react';
import { CheckboxIcon } from './components';

export const CheckboxInput = forwardRef<CheckboxProps, 'input'>(
  (props, ref) => {
    return (
      <Checkbox
        alignItems={!!props.children ? 'flex-start' : 'center'}
        icon={<CheckboxIcon />}
        {...props}
        ref={ref}
      />
    );
  }
);
