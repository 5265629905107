import { FC, useState } from 'react';
import { Box, Button, HStack, Link, Stack, Text } from '@chakra-ui/react';
import { NumberInput, ExtraContainer, WarningTooltip } from 'components';
import { Tooltip } from '@vizgen/vizgen-ui';
import { useNewOrderStore } from 'store';

interface ExtraBlockProps {
  name: string;
  extraId: string;
  details: string;
  extraAmount: number | undefined;
  onChangeAmount: (value: number) => void;
}

export const ExtraBlock: FC<ExtraBlockProps> = ({
  name,
  extraId,
  extraAmount,
  details,
  onChangeAmount
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const extrasIncompatibilities = useNewOrderStore(
    (state) => state.extrasIncompatibilities
  );

  const incompatibilities = extrasIncompatibilities
    .filter((incompatibility) => incompatibility.extraId === extraId)
    .map((incompatibility) => incompatibility.message);

  const onAddClick = () => {
    onChangeAmount(1);
  };

  return (
    <WarningTooltip messages={incompatibilities}>
      <ExtraContainer hasWarning={incompatibilities.length > 0}>
        <Stack spacing="4px">
          <Text variant="accentCaptionHigh">{name}</Text>
          {details && (
            <Text variant="captionShort">
              <Tooltip label={details} maxW="180px">
                <Link variant="underline" size="sm">
                  Product Details
                </Link>
              </Tooltip>
            </Text>
          )}
        </Stack>
        {extraAmount || isFocused ? (
          <HStack spacing="26px">
            <NumberInput
              onChangeValue={onChangeAmount}
              value={extraAmount}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </HStack>
        ) : (
          <Box>
            <Button
              variant="secondaryFilled"
              minW="132px"
              onClick={onAddClick}
              data-testid="addButton"
            >
              Add
            </Button>
          </Box>
        )}
      </ExtraContainer>
    </WarningTooltip>
  );
};
