import { Table, Tbody } from '@chakra-ui/react';
import { Instrument } from 'generated/types';
import { FC } from 'react';
import { useLastSeenTime } from './useLastSeenTime';
import { Notifications, Users } from './components';
import { SummaryRow } from '@vizgen/vizgen-ui';

interface DetailsProps {
  instrument: Instrument;
}

export const Details: FC<DetailsProps> = ({ instrument }) => {
  const { lastStatusUpdated, status, viewers, admins, whoAmI, uniqueId } =
    instrument;
  const isAdmin = whoAmI === 'InstrumentAdmin';
  const lastSeenTime = useLastSeenTime(lastStatusUpdated);
  const rowProps = {
    headerWidth: '35%',
    cellWidth: '65%'
  };
  const cellProps = {
    p: '10px 8px 10px 12px'
  };

  return (
    <Table>
      <Tbody
        css={{
          'tr:last-child': {
            'th, td': {
              borderBottom: 'none'
            }
          }
        }}
      >
        <SummaryRow
          field="Last Seen"
          value={lastSeenTime || '—'}
          {...rowProps}
        />
        <SummaryRow
          field="Current Status"
          value={status || '—'}
          {...rowProps}
        />
        <SummaryRow
          field="Experiment Notifications"
          value="—"
          cellProps={cellProps}
          {...rowProps}
        >
          <Notifications instrumentUniqueId={uniqueId} />
        </SummaryRow>
        <SummaryRow
          field="Administrators"
          cellProps={isAdmin ? cellProps : {}}
          {...rowProps}
        >
          <Users
            isEditable={isAdmin}
            isAdmin
            users={admins}
            otherUsers={viewers}
            instrumentUniqueId={uniqueId}
          >
            Add Admin
          </Users>
        </SummaryRow>
        {isAdmin && (
          <SummaryRow
            field="Viewers"
            cellProps={isAdmin ? cellProps : {}}
            {...rowProps}
          >
            <Users
              isEditable
              users={viewers}
              otherUsers={admins}
              instrumentUniqueId={uniqueId}
            >
              Add Viewer
            </Users>
          </SummaryRow>
        )}
      </Tbody>
    </Table>
  );
};
