import { Text } from '@chakra-ui/react';
import { ColumnInstance, TableToggleHideAllColumnProps } from 'react-table';
import { CheckboxInput } from '../../../../../../../CheckboxInput';

interface SelectAllProps<T extends {}> {
  isScrolled: boolean;
  columns: ColumnInstance<T>[];
  disabledColumns: string[];
  getToggleHideAllColumnsProps: (
    props?: Partial<TableToggleHideAllColumnProps>
  ) => TableToggleHideAllColumnProps;
}

export const SelectAll = <T extends {}>({
  isScrolled,
  columns,
  disabledColumns,
  getToggleHideAllColumnsProps
}: SelectAllProps<T>) => {
  const { checked, indeterminate } = getToggleHideAllColumnsProps();

  return (
    <CheckboxInput
      isChecked={checked}
      isIndeterminate={!!indeterminate}
      onChange={(e) => {
        columns.forEach(({ id, getToggleHiddenProps }) => {
          const { onChange } = getToggleHiddenProps();
          if (!disabledColumns.includes(id)) {
            onChange(e);
          }
        });
      }}
      size="sm"
      w="100%"
      p="10px 0 9px 26px"
      borderBottom="1px solid"
      borderColor={isScrolled ? 'border.divider' : 'background.primary'}
    >
      <Text lineHeight="20">All</Text>
    </CheckboxInput>
  );
};
