import { FC, useState, useRef } from 'react';
import {
  Box,
  IconButton,
  InputGroup,
  InputRightElement,
  Input,
  useOutsideClick,
  Spinner
} from '@chakra-ui/react';
import { ChevronIcon } from './assets';
import { PanelsDropdown } from './components';
import { CloseIcon } from 'assets';
import { useCustomPanelsListQuery, usePromotedPanelListQuery } from 'api/hooks';
import { Panel } from 'generated/types';

export interface ComboboxProps {
  value: Panel;
  name?: string;
  disabled?: boolean;
  onChange: (value: Panel) => void;
}

export const Combobox: FC<ComboboxProps> = ({
  name,
  value,
  onChange,
  disabled = false
}) => {
  const { data: promotedPanels = [], isFetched: isPromotedPanelsFetched } =
    usePromotedPanelListQuery();
  const { data: finalizedPanels = [], isFetched: isFinalizedPanelsFetched } =
    useCustomPanelsListQuery({
      filter: { isCompleted: { eq: true } }
    });
  const [isListVisible, setIsListVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const comboboxRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [searchValue, setSearchValue] = useState('');

  const onItemSelect = (data: any) => {
    onChange(data);
  };

  const onFocus = () => {
    setIsFocused(true);
    setIsListVisible(true);
  };

  useOutsideClick({
    ref: comboboxRef,
    handler: () => {
      setIsListVisible(false);
      setSearchValue('');
      setIsFocused(false);
    }
  });

  const clearSearch = () => {
    setSearchValue('');
    if (isListVisible) {
      inputRef?.current?.focus();
    }
  };

  const isFetching = !isPromotedPanelsFetched || !isFinalizedPanelsFetched;
  const isDisabled = isFetching || disabled;
  const showCurrentPanelName =
    !isListVisible || (isListVisible && !searchValue && !isFocused);

  return (
    <Box
      ref={comboboxRef}
      pos="relative"
      zIndex="docked"
      transition="all 250ms ease-in"
      boxShadow={isListVisible ? '0px 8px 24px rgba(215, 217, 220, 0.4)' : ''}
    >
      <InputGroup h="48px" size="xl" opacity={isDisabled ? '0.6' : '1'}>
        {/* Temporarily hidden VCD-352 */}
        {/* {value?.isProprietary && !isFocused && (
          <InputLeftElement
            pointerEvents="none"
            children={<PrivateIcon />}
            h="100%"
            w="48px"
          />
        )} */}
        <Input
          isDisabled={isDisabled}
          name={name}
          id={name}
          ref={inputRef}
          type="text"
          cursor={isDisabled ? 'not-allowed' : 'initial'}
          value={showCurrentPanelName ? value?.panelName : searchValue}
          placeholder="Search panel to duplicate and edit"
          onFocus={onFocus}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => setSearchValue(e.target.value)}
          borderColor={isListVisible ? 'border.selected' : 'border.normal'}
          borderBottomRadius={isListVisible ? '0' : '4px'}
          autoComplete="off"
          // Temporarily hidden VCD-352
          // pl={value?.isProprietary && !isFocused ? '44px' : '12px'}
        />
        <InputRightElement>
          {isListVisible && searchValue && (
            <IconButton
              onClick={clearSearch}
              size="icon"
              variant="ghost"
              aria-label="Close search panels"
              ml="4px"
              p="8px"
              icon={
                <CloseIcon width="8px" height="8px" stroke="border.selected" />
              }
            />
          )}
          <Box
            position="relative"
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
            h="48px"
            w="48px"
            d="flex"
            alignItems="center"
            justifyContent="center"
            p="8px"
            transition="all .2s ease"
            transform={isListVisible ? 'rotate(180deg)' : ''}
            opacity={isDisabled ? 0.5 : 1}
            onClick={() => {
              !isDisabled && setIsListVisible((prev) => !prev);
            }}
          >
            {isFetching ? <Spinner w="24px" h="24px" /> : <ChevronIcon />}
          </Box>
        </InputRightElement>
      </InputGroup>
      {isListVisible && (
        <PanelsDropdown
          promotedPanels={promotedPanels}
          finalizedPanels={finalizedPanels}
          setIsListVisible={setIsListVisible}
          searchValue={searchValue}
          onChange={onItemSelect}
          value={value}
        />
      )}
    </Box>
  );
};
