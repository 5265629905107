import { addInstrumentUserMutation } from 'api';
import { Instrument } from 'generated/types';
import { useMutation, useQueryClient } from 'react-query';
import { LIST_INSTRUMENTS_QUERY_KEY } from './useListInstrumentsQuery';

export const useAddInstrumentUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(addInstrumentUserMutation, {
    onSuccess: (data) => {
      const current =
        queryClient.getQueryData<Instrument[]>(LIST_INSTRUMENTS_QUERY_KEY) ||
        [];
      if (data) {
        const updated = current.map((instrument) => {
          if (instrument.uniqueId === data.uniqueId) {
            return data as Instrument;
          }
          return instrument;
        });
        queryClient.setQueryData(LIST_INSTRUMENTS_QUERY_KEY, updated);
      }
    }
  });
};
