import { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { PanelTranscriptStatus } from 'generated/types';

interface InfoTextProps {
  genesAmount: number;
  type: PanelTranscriptStatus;
}

export const InfoText: FC<InfoTextProps> = ({ genesAmount, type }) => {
  const geneText = () => {
    switch (type) {
      case PanelTranscriptStatus.insufficient_tr: {
        return genesAmount > 1
          ? 'genes have insufficient target regions and may have compromised detection efficiency:'
          : 'gene has insufficient target regions and may have compromised detection efficiency:';
      }
      case PanelTranscriptStatus.not_found: {
        return genesAmount > 1 ? 'genes were not found' : 'gene was not found:';
      }
      case PanelTranscriptStatus.untargetable: {
        return genesAmount > 1
          ? 'genes are untargetable:'
          : 'gene is untargetable:';
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Text>
      <Text as="span" mr="4px" d="inline" variant="accentBodyShort">
        {genesAmount}
      </Text>
      <Text color="content.additional" d="inline" as="span" variant="bodyShort">
        {geneText()}
      </Text>
    </Text>
  );
};
