import { create } from 'zustand';
import { NewOrderState, PanelIncompatibility } from './models';
import { createQuote, createQuotePosition } from 'api';
import { v4 as uuidv4 } from 'uuid';
import {
  PanelTranscriptSequentialAuxProbes,
  QuoteStatus
} from 'generated/types';
import { useNotificationsStore } from 'store';
import {
  getAntibodyIncompatibilityNotifications,
  getVerb,
  matchAntibodyKitsCompatibility,
  parseIncompatibilitiesFile
} from './utils';
import pluralize from 'pluralize';

const defaultState = {
  id: '',
  panels: [null],
  additionalExtras: [],
  comment: '',
  selectedAddress: '',
  panelsIncompatibilities: [],
  extrasIncompatibilities: [],
  error: null,
  isFetching: false
};

export const useNewOrderStore = create<NewOrderState>()((set, get) => ({
  ...defaultState,

  setId: () => {
    set({ id: uuidv4() });
  },

  addPanel: () => {
    const newList = get().panels.concat(null);
    set({ panels: newList });
  },

  selectPanel: (selectedPanel, index) => {
    const newList = get().panels.map((panel, i) =>
      i === index ? selectedPanel : panel
    );
    set({ panels: newList });
  },

  removePanel: (index) => {
    const newList = get().panels.filter((_, i) => i !== index);
    set({ panels: newList });
  },

  editAdditionalExtras: (extra, quantity) => {
    const newAdditionalExtra = get()
      .additionalExtras.filter((e) => e.extraId !== extra.extraId)
      .concat({ extraId: extra.extraId, extra, quantity })
      .filter((e) => e.quantity !== 0);

    set({ additionalExtras: newAdditionalExtra });
  },

  setComment: (comment) => {
    set({ comment });
  },

  setSelectedAddress: (addressId) => {
    set({ selectedAddress: addressId });
  },

  requestNewQuote: async (userId) => {
    set({ isFetching: true, error: null });
    const {
      id,
      selectedAddress,
      comment,
      panels,
      additionalExtras,
      clearState
    } = get();
    try {
      const response = await createQuote({
        quoteId: id,
        deliveryAddress: selectedAddress,
        comment: comment,
        Status: QuoteStatus.Submitted,
        userId
      });

      if (!response) {
        return;
      }

      await Promise.all([
        ...panels.map(
          (panel) =>
            panel &&
            createQuotePosition({
              quoteId: id,
              quotePositionId: uuidv4(),
              panelId: panel.panelId,
              extraId: '',
              quantity: 1,
              cartridgeId: '',
              listPricePerItem: 0,
              pricePerPosition: 0
            })
        ),
        ...additionalExtras.map(
          (extra) =>
            extra &&
            createQuotePosition({
              quoteId: id,
              quotePositionId: uuidv4(),
              panelId: '',
              extraId: extra.extraId,
              quantity: extra.quantity,
              cartridgeId: '',
              listPricePerItem: 0,
              pricePerPosition: 0
            })
        )
      ]);
      clearState();
    } catch (e: any) {
      set({ error: e });
    }

    set({ isFetching: false });
  },

  validateIncompatibilities: async () => {
    const { setNotifications } = useNotificationsStore.getState();
    const { additionalExtras, panels } = get();

    // panels and imaging kits matching
    const imagingKitsExtraIds: { [key in string]: string[] } = {};
    const imagingKitsPanels: PanelIncompatibility[] = [];
    const imagingKits = additionalExtras.filter(
      (extra) => extra.extra.extraType === 'Imaging Kit'
    );
    panels.forEach((panel) => {
      imagingKits.forEach((imagingKit) => {
        const { extraName, extraId } = imagingKit.extra;
        const panelGenes = panel?.genesSummary.primary || 0;
        const kitGenes = +extraName.split(' ')[1];
        if (panelGenes > kitGenes) {
          const hasPanel = imagingKitsPanels.find(
            (incompatiblePanel) => incompatiblePanel.panelId === panel?.panelId
          );
          if (!hasPanel) {
            imagingKitsPanels.push({
              message: `This panel includes ${panelGenes} ${pluralize(
                'gene',
                panelGenes
              )} and is incompatible with the included imaging ${pluralize(
                'kit',
                imagingKits.length
              )}`,
              panelId: panel?.panelId || ''
            });
          }
          const { panelName = '' } = panel || {};
          if (imagingKitsExtraIds[extraId]) {
            imagingKitsExtraIds[extraId].push(panelName);
          } else {
            imagingKitsExtraIds[extraId] = [panelName];
          }
        }
      });
    });
    const imagingKitsExtras = Object.keys(imagingKitsExtraIds).map(
      (extraId) => {
        const panels = imagingKitsExtraIds[extraId];
        return {
          extraId,
          message: `This imaging kit is incompatible with ${panels.join(
            ', '
          )} ${pluralize('panel', panels.length)}`
        };
      }
    );
    const imagingKitsNotification = {
      message: `The quote contains ${pluralize(
        'panel',
        imagingKitsPanels.length
      )} that ${getVerb(
        imagingKitsPanels.length
      )} incompatible with imaging ${pluralize(
        'kit',
        imagingKitsExtras.length
      )}`,
      type: 'INFO_ImagingKitIncompatibility',
      isVisible: imagingKitsPanels.length > 0
    };

    // panels with sequential genes and antibody stain kits matching
    const stainKitsExtrasPanels: { [key in string]: string[] } = {};
    const stainKitsPanelsAuxProbes: { [key in string]: string[] } = {};
    const stainKits = additionalExtras.filter(
      (extra) => extra.extra.extraType === 'Antibody MERSCOPE Kit'
    );
    panels.forEach((panel) => {
      const panelId = panel?.panelId || '';
      stainKitsPanelsAuxProbes[panelId] = [];
      const auxProbes = panel?.genes.items
        .filter((item) => item.auxProbes)
        .map((item) => item.auxProbes);
      if (auxProbes?.length) {
        stainKits.forEach((stainKit) => {
          const { auxProbesUsed, extraId } = stainKit.extra;
          const hasProbe = auxProbes.includes(
            auxProbesUsed
              .split(' ')
              .join('') as PanelTranscriptSequentialAuxProbes
          );
          if (hasProbe) {
            stainKitsPanelsAuxProbes[panelId].push(auxProbesUsed);
            const { panelName = '' } = panel || {};
            if (stainKitsExtrasPanels[extraId]) {
              stainKitsExtrasPanels[extraId].push(panelName);
            } else {
              stainKitsExtrasPanels[extraId] = [panelName];
            }
          }
        });
      }
    });
    const stainKitsPanels = Object.keys(stainKitsPanelsAuxProbes)
      .filter((panelId) => stainKitsPanelsAuxProbes[panelId].length)
      .map((panelId) => {
        const probes = stainKitsPanelsAuxProbes[panelId];
        return {
          panelId,
          message: `This panel includes ${probes.sort().join(', ')} ${pluralize(
            'probe',
            probes.length
          )} and is incompatible with the protein stain ${pluralize(
            'kit',
            stainKits.length
          )}`
        };
      });
    const stainKitsExtras = Object.keys(stainKitsExtrasPanels).map(
      (extraId) => ({
        extraId,
        message: `This protein stain kit is incompatible with ${stainKitsExtrasPanels[
          extraId
        ].join(', ')} ${pluralize(
          'panel',
          stainKitsExtrasPanels[extraId].length
        )}`
      })
    );
    const sequentialGenesNotification = {
      message: `The quote request contains secondary staining ${pluralize(
        'kit',
        stainKitsExtras.length
      )} and gene ${pluralize(
        'panel',
        stainKitsPanels.length
      )} with sequential genes that are not compatible`,
      type: 'INFO_AntibodyStainsKitsIncompatibility',
      isVisible: stainKitsPanels.length > 0
    };

    // antibody staining kits matching
    const rules = await parseIncompatibilitiesFile();
    const selectedKits = additionalExtras.map((extra) => ({
      extraName: extra.extra.extraName,
      extraId: extra.extra.extraId
    }));
    const { antibodyKitsNotifications, antibodyKitsExtras } =
      getAntibodyIncompatibilityNotifications(
        selectedKits,
        matchAntibodyKitsCompatibility(selectedKits, rules)
      );

    setNotifications([
      imagingKitsNotification,
      sequentialGenesNotification,
      ...antibodyKitsNotifications
    ]);
    set({
      panelsIncompatibilities: [...imagingKitsPanels, ...stainKitsPanels],
      extrasIncompatibilities: [
        ...imagingKitsExtras,
        ...stainKitsExtras,
        ...antibodyKitsExtras
      ]
    });
  },

  clearState: () => {
    set({ ...defaultState });
  }
}));
