import { getPanel } from 'api';
import { Panel } from 'generated/types';
import { useQuery } from 'react-query';

const PANEL_QUERY_KEY = 'panel';

export const usePanelQuery = (panelId: string, refetchOnWindowFocus = true) => {
  return useQuery(
    [PANEL_QUERY_KEY, panelId],
    async () => {
      if (!panelId) {
        return {} as Panel;
      }
      const res = await getPanel(panelId);
      return res as Panel;
    },
    { refetchOnWindowFocus }
  );
};
