/* eslint-disable no-restricted-globals */
import { shallow } from 'zustand/shallow';
import { usePanelCompositionStore } from 'store';
import { useEffect } from 'react';

export const useEventListeners = () => {
  const [setHoveredColumn, setHoveredRow, setHoveredTile, setSelectedItem] =
    usePanelCompositionStore(
      (state) => [
        state.setHoveredColumn,
        state.setHoveredRow,
        state.setHoveredTile,
        state.setSelectedItem
      ],
      shallow
    );

  useEffect(() => {
    const onMouseOver = (e: any) => {
      const { tile, rect } = (e as CustomEvent).detail;
      const { top, left, width, height } = rect;
      const { row_name = '', col_name = '' } = tile;
      setHoveredTile({
        top,
        left,
        width,
        height,
        gene: row_name.toUpperCase(),
        geneSet: col_name
      });
    };
    const onMouseOut = () => setHoveredTile();
    addEventListener('TILE_MOUSEOVER', onMouseOver);
    addEventListener('TILE_MOUSEOUT', onMouseOut);
    return () => {
      removeEventListener('TILE_MOUSEOVER', onMouseOver);
      removeEventListener('TILE_MOUSEOUT', onMouseOut);
    };
  }, [setHoveredTile]);

  useEffect(() => {
    const onClick = (e: any) => {
      const { tile } = (e as CustomEvent).detail;
      const { row_name = '', col_name = '' } = tile;
      setSelectedItem({
        gene: row_name.toUpperCase(),
        geneSet: col_name
      });
    };
    addEventListener('TILE_CLICK', onClick);
    return () => {
      removeEventListener('TILE_CLICK', onClick);
    };
  }, [setSelectedItem]);

  useEffect(() => {
    const onClick = (e: any) => {
      const { col } = (e as CustomEvent).detail;
      const { name = '' } = col;
      setSelectedItem({
        geneSet: name
      });
    };
    addEventListener('COL_CLICK', onClick);
    return () => {
      removeEventListener('COL_CLICK', onClick);
    };
  }, [setSelectedItem]);

  useEffect(() => {
    const onMouseOver = (e: any) => {
      const { rect, row } = (e as CustomEvent).detail;
      setHoveredRow({
        top: rect.bottom - rect.height / 2,
        left: rect.right + 5,
        name: row.name
      });
    };
    const onMouseOut = () => setHoveredRow();
    addEventListener('ROW_MOUSEOVER', onMouseOver);
    addEventListener('ROW_MOUSEOUT', onMouseOut);
    return () => {
      removeEventListener('ROW_MOUSEOVER', onMouseOver);
      removeEventListener('ROW_MOUSEOUT', onMouseOut);
    };
  }, [setHoveredRow]);

  useEffect(() => {
    const onMouseOver = (e: any) => {
      const { text, triangle, col } = (e as CustomEvent).detail;
      setHoveredColumn({
        top: text.bottom,
        left: triangle.right,
        name: col.name
      });
    };
    const onMouseOut = () => setHoveredColumn();
    addEventListener('COL_MOUSEOVER', onMouseOver);
    addEventListener('COL_MOUSEOUT', onMouseOut);
    return () => {
      removeEventListener('COL_MOUSEOVER', onMouseOver);
      removeEventListener('COL_MOUSEOUT', onMouseOut);
    };
  }, [setHoveredColumn]);

  useEffect(() => {
    const resizeHandler = () => {
      setHoveredColumn();
      setHoveredRow();
      setHoveredTile();
    };
    addEventListener('ON_VIZ_RESIZE', resizeHandler);
    return () => removeEventListener('ON_VIZ_RESIZE', resizeHandler);
  }, [setHoveredColumn, setHoveredRow, setHoveredTile, setSelectedItem]);
};
