import { FC } from 'react';
import { TranscriptModel } from 'store';
import { VStack } from '@chakra-ui/react';
import { Header, TranscriptsTable } from './components';
import { PanelTranscriptType } from 'generated/types';

interface GenesListProps {
  transcripts: TranscriptModel[];
}

const filterTranscripts = (
  transcripts: TranscriptModel[],
  type: PanelTranscriptType
) => transcripts.filter((t) => t.type === type);

export const GenesList: FC<GenesListProps> = ({ transcripts }) => {
  const sequentialTranscripts = filterTranscripts(
    transcripts,
    PanelTranscriptType.sequential
  );
  const primaryTranscripts = filterTranscripts(
    transcripts,
    PanelTranscriptType.primary
  );

  return (
    <VStack spacing="16px" w="100%" mb="32px">
      <Header />
      {primaryTranscripts.length && (
        <TranscriptsTable
          title={`MERFISH Panel (${primaryTranscripts.length})`}
          transcripts={primaryTranscripts}
        />
      )}
      {sequentialTranscripts.length && (
        <TranscriptsTable
          title={`Sequential Panel (${sequentialTranscripts.length})`}
          transcripts={sequentialTranscripts}
        />
      )}
    </VStack>
  );
};
