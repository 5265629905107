import { fontStyles } from '../fonts';

const defaultFieldStyles = {
  fontSize: fontStyles.fontSizes[16],
  lineHeight: fontStyles.lineHeights[24],
  p: '12px',
  color: 'content.secondary',
  bg: 'background.primary',
  border: '1px solid',
  borderRadius: '4px',
  borderColor: 'border.normal',
  _placeholder: {
    color: 'content.additional'
  },
  _focus: {
    borderColor: 'border.selected',
    boxShadow: 'none'
  },
  _invalid: {
    borderColor: 'state.error',
    boxShadow: 'none'
  },
  _disabled: {
    opacity: 0.5
  }
};

export const Input = {
  variants: {
    default: {
      field: defaultFieldStyles
    },
    search: {
      field: defaultFieldStyles
    },
    numberInput: {
      field: {
        h: '40px',
        ...defaultFieldStyles,
        border: '1px solid',
        borderTop: '0',
        borderBottom: '0',
        p: '8px 12px',
        borderColor: 'border.divider',
        textAlign: 'center',
        borderRadius: '0',
        _focus: {
          borderColor: 'border.divider',
          boxShadow: 'none'
        }
      }
    },
    table: {
      field: {
        ...defaultFieldStyles,
        border: 'none',
        bg: 'background.highlight',
        borderRadius: 'none'
      }
    },
    code: {
      field: {
        ...defaultFieldStyles,
        letterSpacing: '5px'
      }
    }
  },
  sizes: {
    mdSearch: {
      field: {
        h: '48px',
        p: '12px 12px 12px 40px',
        paddingInlineStart: '40px'
      }
    },
    smSearch: {
      field: {
        h: '40px',
        p: '8px 12px 8px 40px',
        paddingInlineStart: '40px'
      }
    },
    md: {
      field: {
        h: '48px'
      }
    },
    sm: {
      field: {
        h: '40px'
      }
    }
  },
  defaultProps: {
    size: 'md',
    variant: 'default'
  }
};
