import {
  Link as RouterLink,
  useNavigate,
  useNavigationType
} from 'react-router-dom';
import { Box, Flex, Container, Link } from '@chakra-ui/react';
import { Paths } from 'routes';
import { SmallLogo } from 'assets';
import { CloseButton } from '@vizgen/vizgen-ui';

export const Header = () => {
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const onCloseClick = () => {
    if (navigationType === 'POP') {
      navigate(Paths.Home);
    } else {
      navigate(-1);
    }
  };

  return (
    <Box bg="background.primary">
      <Container maxW="1440px" h="80px" p="0 60px">
        <Flex w="100%" h="100%" alignItems="center" justify="space-between">
          <Link as={RouterLink} to={Paths.Home}>
            <SmallLogo />
          </Link>
          <CloseButton onClick={onCloseClick} minW="132px">
            Close
          </CloseButton>
        </Flex>
      </Container>
    </Box>
  );
};
