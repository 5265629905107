import { Button, Flex, Heading, HStack, Stack } from '@chakra-ui/react';
import { Dropdown } from '@vizgen/vizgen-ui';
import { FC } from 'react';
import { useNewOrderStore } from 'store';
import { PanelIcon } from './assets';
import { PanelModelWithGenes } from 'api/types';
import { sortPanelsByName } from 'utils';
import { WarningTooltip } from 'components';
import { shallow } from 'zustand/shallow';

interface PanelProps {
  index: number;
  panelList: PanelModelWithGenes[];
  panel: PanelModelWithGenes | null;
}

interface PanelOption {
  label: string;
  value: string;
}

export const Panel: FC<PanelProps> = ({ panelList, index, panel }) => {
  const [selectPanel, removePanel, panels, panelsIncompatibilities] =
    useNewOrderStore(
      (state) => [
        state.selectPanel,
        state.removePanel,
        state.panels,
        state.panelsIncompatibilities
      ],
      shallow
    );

  const incompatibilities = panelsIncompatibilities
    .filter((incompatibility) => incompatibility.panelId === panel?.panelId)
    .map((incompatibility) => incompatibility.message);
  const panelOptions = sortPanelsByName(panelList).map(
    ({ panelName, panelId, serialNumberTag }) => ({
      label: `${panelName}${serialNumberTag ? ` (${serialNumberTag})` : ''}`,
      value: panelId
    })
  );
  const selectedPanel = panelOptions.find((p) => p.value === panel?.panelId);

  const selectChangeHandler = (event: PanelOption | null) => {
    const panel = panelList.find(
      (panel) => panel.panelId === event?.value
    ) as PanelModelWithGenes;
    if (panel) {
      selectPanel(panel, index);
    }
  };

  return (
    <>
      <Flex justify="space-between" pb="16px" alignItems="center">
        <HStack spacing="8px">
          <PanelIcon />
          <Heading variant="subtitle">Panel {index + 1}</Heading>
        </HStack>
        {panels.length > 1 && (
          <Button
            variant="tinyGhostPrimary"
            size="tiny"
            onClick={() => removePanel(index)}
            data-testid={`removePanel_${index}`}
          >
            Remove
          </Button>
        )}
      </Flex>
      <Stack spacing="32px">
        <WarningTooltip messages={incompatibilities}>
          <Dropdown
            items={panelOptions}
            value={selectedPanel}
            placeholder="Choose Panel"
            hasWarning={incompatibilities.length > 0}
            isSearchable
            name={`choosePanelDropdown_${index}`}
            onChange={selectChangeHandler}
            isOptionDisabled={(option) =>
              panels.some((p) => p?.panelId === option.value)
            }
          />
        </WarningTooltip>
      </Stack>
    </>
  );
};
