import { updateUserInstrumentNotificationsMutation } from 'api';
import { useMutation, useQueryClient } from 'react-query';
import { USER_INSTRUMENT_NOTIFICATIONS_QUERY_KEY } from './useUserInstrumentNotificationsQuery';

export const useUserInstrumentNotificationsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(updateUserInstrumentNotificationsMutation, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [USER_INSTRUMENT_NOTIFICATIONS_QUERY_KEY, variables.instrumentUniqueId],
        data
      );
    }
  });
};
