import { FC } from 'react';
import { Link as RouterLink, useMatch, useLocation } from 'react-router-dom';
import {
  Link,
  Flex,
  HStack,
  Box,
  Container,
  StackDivider,
  Button
} from '@chakra-ui/react';
import { SmallLogo } from 'assets';
import { Paths } from 'routes';
import { SecondaryButton, UserMenu } from './components';
import { EmailIcon } from './assets';
import { useUserStore } from 'store';
import { MenuButton } from '@vizgen/vizgen-ui';

const baseMenuButtonProps = {
  p: '28px 16px',
  as: RouterLink,
  h: '100%'
};

export const Header: FC = () => {
  const user = useUserStore((state) => state.user);
  const location = useLocation();

  const isCreatePanel = !!useMatch(Paths.CreatePanel + '/*');
  const isNewQuote = !!useMatch(Paths.NewQuote + '/*');
  const isPrintablePanel = !!useMatch(Paths.PrintablePanel);
  const isApproveAccount = !!useMatch(Paths.ApproveAccount);
  const isTermsOfUse = !!useMatch(Paths.TermsOfUse);
  const isGeneSetLibraries = !!useMatch(Paths.GeneSetLibraries + '/*');

  const isHeaderHidden =
    isCreatePanel ||
    isNewQuote ||
    isPrintablePanel ||
    isApproveAccount ||
    (!user && isTermsOfUse);

  const isRouteActive = (route: string): boolean => {
    // FIXME: if route contain vars placeholder — checking might return false
    return location.pathname.includes(route);
  };

  if (isHeaderHidden) {
    return null;
  }

  return (
    <Box
      zIndex="docked"
      bg="background.primary"
      filter="drop-shadow(0px 8px 24px rgba(215, 217, 220, 0.4))"
    >
      <Container maxW="1440px" p="0 60px" h="80px">
        <Flex justify="space-between" h="100%">
          <Link
            as={RouterLink}
            to={Paths.Home}
            d="flex"
            alignItems="center"
            data-testid="homeHeaderLink"
          >
            <SmallLogo />
          </Link>
          {isGeneSetLibraries || (
            <HStack
              spacing="24px"
              divider={
                <Box py="24px">
                  <StackDivider borderColor="border.divider" />
                </Box>
              }
            >
              <HStack spacing="4px" h="100%" ml="32px">
                <Button
                  as={RouterLink}
                  h="48px"
                  mr="16px"
                  to={Paths.CreatePanel}
                  variant="secondarySolid"
                  data-testid="createPanelHeaderLink"
                >
                  Create New Panel
                </Button>
                <MenuButton
                  {...baseMenuButtonProps}
                  to={Paths.Panels}
                  isActive={isRouteActive(Paths.Panels)}
                  data-testid="panelsPageHeaderLink"
                >
                  Panels
                </MenuButton>
                <MenuButton
                  {...baseMenuButtonProps}
                  to={Paths.Quotes}
                  isActive={isRouteActive(Paths.Quotes)}
                  data-testid="quotesPageHeaderLink"
                >
                  Quote Requests
                </MenuButton>
                <MenuButton
                  {...baseMenuButtonProps}
                  to={Paths.Resources}
                  isActive={isRouteActive(Paths.Resources)}
                  data-testid="resourcesPageHeaderLink"
                >
                  Resources
                </MenuButton>
              </HStack>
              <HStack spacing="4px">
                <SecondaryButton
                  to={Paths.ContactUs}
                  data-testid="contactUsLink"
                >
                  <Box mr="4px">
                    <EmailIcon />
                  </Box>
                  <Box
                    display={{
                      xs: 'none',
                      sm: 'none',
                      md: 'initial'
                    }}
                  >
                    Contact Us
                  </Box>
                </SecondaryButton>
                <UserMenu />
              </HStack>
            </HStack>
          )}
        </Flex>
      </Container>
    </Box>
  );
};
