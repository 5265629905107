import { TableState } from 'react-table';

export const saveTableState = (
  tableStateKey: string,
  { columnOrder, columnResizing, hiddenColumns }: TableState
) =>
  localStorage.setItem(
    tableStateKey,
    JSON.stringify({ columnOrder, columnResizing, hiddenColumns })
  );

export const getTableState = (tableStateKey: string): TableState =>
  JSON.parse(localStorage.getItem(tableStateKey) || '{}');
