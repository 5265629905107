import { GeneSetLibrary } from 'store';
import { useMemo } from 'react';

export const useFilteredData = (
  searchedGene: string,
  searchedGeneSet: string,
  geneSetLibrary: GeneSetLibrary
) => {
  const isSearchActive = searchedGene || searchedGeneSet;

  const geneSetsByGene = useMemo(
    () =>
      searchedGene
        ? geneSetLibrary.children.filter((geneSet) =>
            geneSet.genes.has(searchedGene)
          )
        : [],
    [geneSetLibrary, searchedGene]
  );
  const geneSetsByGeneSet = useMemo(
    () =>
      searchedGeneSet
        ? geneSetLibrary.children.filter(
            (geneSet) => geneSet.label === searchedGeneSet
          )
        : [],
    [geneSetLibrary, searchedGeneSet]
  );
  const filteredGeneSets = useMemo(
    () =>
      isSearchActive
        ? [...geneSetsByGene, ...geneSetsByGeneSet]
        : geneSetLibrary.children,
    [geneSetsByGene, geneSetsByGeneSet, isSearchActive, geneSetLibrary]
  );

  return {
    filteredGeneSets,
    geneSetsByGene,
    geneSetsByGeneSet
  };
};
