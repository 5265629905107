import fileDownload from 'js-file-download';
import { pullCodebook } from 'api';

export const downloadCodebook = async (
  panelName: string,
  panelId: string,
  serialNumberTag: string
) => {
  const codebookName = `codebook_0_${panelName}_${serialNumberTag}.csv`;
  const codebook = await pullCodebook(panelId);
  fileDownload(codebook, codebookName, 'text/csv');
};
