import { VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { usePanelCompositionStore } from 'store';
import { getTranscriptsIntersection } from 'utils/geneset/getTranscriptsIntersection';
import { Row } from './components';

export const Label: FC = () => {
  const transcripts = usePanelCompositionStore((state) => state.transcripts);
  const geneSets = usePanelCompositionStore((state) => state.geneSets);
  const tile = usePanelCompositionStore((state) => state.hoveredTile);

  if (!tile) {
    return null;
  }

  const abundance =
    transcripts.find(
      (t) => t?.geneName.toUpperCase() === tile.gene.toUpperCase()
    )?.abundance || 0;
  const totalGeneSetGenes =
    geneSets.find((set) => set[0] === tile.geneSet)?.[1].size || 0;
  const matchGeneSetGenes = getTranscriptsIntersection(
    geneSets,
    transcripts,
    tile.geneSet
  ).length;

  return (
    <VStack spacing="0" align="start">
      <Row label="Gene symbol:">
        <b>{tile.gene}</b>
      </Row>
      <Row label="Set name:">
        <b>{tile.geneSet}</b>
      </Row>
      <Row label="Gene abundance:">
        <b>{abundance}</b>
      </Row>
      <Row label="Genes in set:">
        <b>{matchGeneSetGenes}</b> of <b>{totalGeneSetGenes}</b> genes were
        found in panel
      </Row>
    </VStack>
  );
};
