import { FC, useState, useMemo } from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { ExogenousSequencesList } from './components';
import { useListExogenousSequencesQuery } from 'api/hooks';
import { SearchField, useDebounce } from '@vizgen/vizgen-ui';

export const SavedExogenousSequences: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const { data = [] } = useListExogenousSequencesQuery();
  const { value: debouncedSearchValue } = useDebounce(searchValue, 250);

  const sequences = useMemo(() => {
    return data.filter((sequence) => {
      return sequence?.name
        .toLowerCase()
        .includes(debouncedSearchValue.toLowerCase());
    });
  }, [debouncedSearchValue, data]);

  return data.length ? (
    <Stack spacing="16px">
      <SearchField
        name="search"
        placeholder="Search sequences"
        size="mdSearch"
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
      <ExogenousSequencesList data={sequences} />
    </Stack>
  ) : (
    <Text color="content.additional" textAlign="center" h="256px">
      No sequences saved
    </Text>
  );
};
