import { Button, Center, Flex, Heading } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Paths } from 'routes';
import { useGetPanels } from 'hooks';
import { GenePanelsTable } from 'components';
import { SecondaryButton, SecondaryButtonIcon } from '@vizgen/vizgen-ui';

export const RecentPanels = () => {
  const { panels = [], isLoading } = useGetPanels();

  const slicedPanels = panels.slice(0, 5);

  return (
    <Flex
      direction="column"
      mt="40px"
      pb="24px"
      borderBottom="1px solid"
      borderColor="border.divider"
    >
      <Flex justify="space-between" alignItems="center" mb="16px">
        <Heading variant="headline">Recent Gene Panels</Heading>
        <SecondaryButton
          icon={SecondaryButtonIcon.Add}
          to={Paths.CreatePanel}
          as={RouterLink}
          data-testid="createNewPanelLink"
        >
          Create New Panel
        </SecondaryButton>
      </Flex>
      <GenePanelsTable data={slicedPanels} isLoading={isLoading} isShort />
      <Center>
        <Button
          variant="primaryGhost"
          w="216px"
          h="40px"
          mt="24px"
          as={RouterLink}
          to={Paths.Panels}
          data-testid="allPanelsLink"
        >
          View All Gene Panels
        </Button>
      </Center>
    </Flex>
  );
};
