import { useState, useEffect } from 'react';
import { fetchUserViewSettings } from 'store';
import { fetchS3File } from 'utils/fetchS3File';
import { GeneSetsSource } from 'utils/geneset/types';
import { validateGeneSetsSource } from 'utils/geneset/validateGeneSetsSource';

// FIXME: Find more elegant way for empty state
const EMPTY_SOURCE: GeneSetsSource = {
  metadata: {
    species: 'abc',
    schemaVersion: '1.0',
    schemaLocation: 'abc'
  },
  categories: {}
};

export function useGeneSetsSource(
  panelId: string,
  transcriptomeId: string,
  userId: string
) {
  const [data, setData] = useState<GeneSetsSource>();
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (userId) {
      (async () => {
        try {
          const userViewSettings = await fetchUserViewSettings(
            panelId,
            userId,
            transcriptomeId
          );
          setData(await getGeneSetsSource(userViewSettings.geneSetS3Key || ''));
          setError(undefined);
        } catch (e) {
          setError(e);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [panelId, transcriptomeId, userId]);

  return { data: data || EMPTY_SOURCE, error, isLoading };
}

export async function getGeneSetsSource(s3FileKey: string) {
  const file = await fetchS3File(s3FileKey);
  if (!file) throw new Error('Gene sets file is empty');
  const data = JSON.parse(file) as GeneSetsSource;
  if (!validateGeneSetsSource(data))
    throw new Error('Gene sets file has invalid format');
  return data;
}
