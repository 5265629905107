import { FC } from 'react';
import { VStack, Heading, HStack, Box } from '@chakra-ui/react';
import { ReactComponent as PanelsIcon } from './assets/panels.svg';
import { Panel } from './components';
import { PanelModel } from 'api/types';

export interface QuotePanelsProps {
  panels: Array<PanelModel | null>;
}

export const QuotePanels: FC<QuotePanelsProps> = ({ panels }) => {
  return (
    <VStack spacing="16px" alignItems="start" w="100%">
      <Heading as="h2" variant="subtitle">
        <HStack spacing="4px">
          <HStack spacing="8px">
            <PanelsIcon />
            <Box>Panels</Box>
          </HStack>
          <Box color="content.additional">({panels.length})</Box>
        </HStack>
      </Heading>
      {panels.map((panel) => {
        if (!!panel) {
          return <Panel key={panel.panelId} {...panel} />;
        }
        return null;
      })}
    </VStack>
  );
};
