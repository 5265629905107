import { Heading, HStack, Spinner } from '@chakra-ui/react';
import { FC } from 'react';

interface TitleProps {
  isLoading: boolean;
}

export const Title: FC<TitleProps> = ({ isLoading }) => {
  return (
    <HStack spacing="8px">
      <Heading as="h1">Instruments</Heading>
      {isLoading && <Spinner size="icon" color="content.additional" />}
    </HStack>
  );
};
