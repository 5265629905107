import { GeneSetLibraryExpandButton, GeneSetLibraryDivider } from 'components';
import { FC, useState } from 'react';
import { GeneSetLibrary as GeneSetLibraryItem } from 'store';
import pluralize from 'pluralize';
import { GeneSetList, InfoMessage } from './components';
import { useFilteredData } from './useFilteredData';

interface GeneSetLibraryProps {
  expandedGeneSetLibraryName: string;
  geneSetLibrary: GeneSetLibraryItem;
  searchedGene: string;
  searchedGeneSet: string;
  onGeneSetLibraryExpand: (name: string) => void;
}

export const GeneSetLibrary: FC<GeneSetLibraryProps> = ({
  expandedGeneSetLibraryName,
  geneSetLibrary,
  searchedGene,
  searchedGeneSet,
  onGeneSetLibraryExpand
}) => {
  const [expandedGeneSetName, setExpandedGeneSetName] = useState('');
  const isCategoryExpanded =
    geneSetLibrary.label === expandedGeneSetLibraryName;
  const isSearchActive = searchedGene || searchedGeneSet;

  const { geneSetsByGene, geneSetsByGeneSet, filteredGeneSets } =
    useFilteredData(searchedGene, searchedGeneSet, geneSetLibrary);

  const subtitle = isSearchActive
    ? `${filteredGeneSets.length || 'no'} matching gene ${pluralize(
        'set',
        filteredGeneSets.length
      )}`
    : '';

  return (
    <>
      <GeneSetLibraryExpandButton
        isDark
        title={geneSetLibrary.label}
        subtitle={subtitle}
        isExpanded={isCategoryExpanded}
        onExpand={(geneSetLibrary) => {
          onGeneSetLibraryExpand(geneSetLibrary);
          setExpandedGeneSetName('');
        }}
        buttonProps={{
          zIndex: '1',
          pos: 'sticky',
          top: '0'
        }}
      />
      <GeneSetLibraryDivider zIndex="1" pos="sticky" top="48px" />
      {isCategoryExpanded &&
        (searchedGene && !geneSetsByGene.length ? (
          <InfoMessage>
            No gene sets in this library contain the gene you are looking for
          </InfoMessage>
        ) : searchedGeneSet && !geneSetsByGeneSet.length ? (
          <InfoMessage>
            This gene set library does not contain the gene set you are looking
            for
          </InfoMessage>
        ) : (
          <GeneSetList
            searchedGene={searchedGene}
            geneSets={filteredGeneSets}
            expandedGeneSetName={expandedGeneSetName}
            onGeneSetExpand={setExpandedGeneSetName}
          />
        ))}
    </>
  );
};
