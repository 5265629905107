import {
  createAddress,
  deleteAddress,
  getListAddresses,
  ListAddresses
} from 'api';
import { AddressType } from 'generated/types';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { useUserStore } from './user';

export type AddressModel = ListAddresses['listAddresses']['items'][0];

type AddressesState = {
  addresses: AddressModel[];
  getAddresses: () => void;
  addAddress: (address: string, type?: AddressType) => Promise<string>;
  removeAddress: (id: string) => void;
  clearState: () => void;
};

export const useAddressesStore = create<AddressesState>()((set) => ({
  addresses: [],

  getAddresses: async () => {
    const addresses = await getListAddresses();
    set({ addresses: addresses.listAddresses.items });
  },

  addAddress: async (address, type = AddressType.shipping) => {
    const addressId = uuidv4();
    const userId = useUserStore.getState().attributes?.userId || '';
    const response = await createAddress({
      userId,
      address,
      addressId: addressId,
      addressType: type
    });
    set((prevState) => ({
      addresses: [
        ...prevState.addresses,
        response?.createAddress as AddressModel
      ]
    }));
    return addressId;
  },

  removeAddress: async (id) => {
    const response = await deleteAddress(id);
    set((prevState) => ({
      addresses: [
        ...prevState.addresses.filter(
          (address) => address.addressId !== response?.deleteAddress.addressId
        )
      ]
    }));
  },

  clearState: () => {
    set({ addresses: [] });
  }
}));

export const addressByIdSelector = (id: string) => (state: AddressesState) =>
  state.addresses.find((address) => address.addressId === id) ||
  ({} as AddressModel);

export const addressByTypeSelector =
  (type: AddressType) => (state: AddressesState) =>
    state.addresses.filter((address) => address.addressType === type);
