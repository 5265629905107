import { Box } from '@chakra-ui/react';
import { useState, forwardRef } from 'react';
import {
  Scrollbars,
  ScrollbarProps as LibProps,
  positionValues
} from 'react-custom-scrollbars';
import { ScrollbarVariants } from './models';

const isAppleDevice =
  /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ||
  navigator.userAgent.includes('Mac');

export interface ScrollbarProps extends LibProps {
  variant?: ScrollbarVariants;
}

type Visibility = 'hidden' | 'visible';

/**
 * NEVER style here a border. It causes of infinite scroll
 */
export const Scrollbar = forwardRef<ScrollbarProps, ScrollbarProps>(
  ({ variant, children, ...rest }, scrollbarRef) => {
    const [horizontalVisibility, setHorizontalVisibility] =
      useState<Visibility>('hidden');
    const [verticalVisibility, setVerticalVisibility] =
      useState<Visibility>('hidden');
    const isSidebar = variant === ScrollbarVariants.Sidebar;
    const isContent = variant === ScrollbarVariants.Content;
    const isTable = variant === ScrollbarVariants.Table;

    const onUpdate = (values: positionValues) => {
      if (values && !isAppleDevice) {
        setHorizontalVisibility(
          values.scrollWidth > values.clientWidth ? 'visible' : 'hidden'
        );
        setVerticalVisibility(
          values.scrollHeight > values.clientHeight ? 'visible' : 'hidden'
        );
      }
    };

    return (
      <Scrollbars
        {...rest}
        ref={scrollbarRef as any}
        onUpdate={onUpdate}
        renderTrackVertical={() => (
          <Box
            pos="absolute"
            top="0"
            bottom={isTable ? '2px' : '0'}
            visibility={verticalVisibility}
            right={isTable ? '2px' : '0'}
            zIndex="sticky"
            w={isSidebar ? '2px' : '4px'}
            transition="width 0.2s ease-in-out"
            borderTopRadius="4px"
            borderBottomRadius={isSidebar ? '0' : '4px'}
            bg={isContent ? 'background.transparent' : 'background.tertiary'}
            _hover={{
              w: isSidebar ? '4px' : '8px',
              '& > div': {
                w: isSidebar ? '4px' : '8px'
              }
            }}
          />
        )}
        renderThumbVertical={() => (
          <Box
            pos="absolute"
            top="0"
            bottom="0"
            right="0"
            zIndex="sticky"
            transition="width 0.2s ease-in-out"
            w="100%"
            borderRadius="4px"
            bg="content.secondary"
          />
        )}
        renderThumbHorizontal={() => (
          <Box
            pos="absolute"
            bottom="0"
            right="0"
            left="0"
            zIndex="sticky"
            transition="height 0.2s ease-in-out"
            h="100%"
            borderRadius="4px"
            bg="content.secondary"
          />
        )}
        renderTrackHorizontal={() => (
          <Box
            {...(isTable
              ? {
                  bottom: '2px',
                  left: '2px',
                  right: '8px',
                  borderRadius: '4px'
                }
              : {
                  borderTopRadius: '4px',
                  left: '0',
                  right: '0',
                  bottom: '0'
                })}
            pos="absolute"
            zIndex="sticky"
            visibility={horizontalVisibility}
            h={isSidebar ? '2px' : '4px'}
            transition="height 0.2s ease-in-out"
            bg={isContent ? 'background.transparent' : 'background.tertiary'}
            _hover={{
              h: isSidebar ? '4px' : '8px',
              '& > div': {
                h: isSidebar ? '4px' : '8px'
              }
            }}
          />
        )}
      >
        {children}
      </Scrollbars>
    );
  }
);
