import { Text } from '@chakra-ui/react';
import { FC } from 'react';
import { PanelTranscriptStatus } from 'generated/types';

interface IssueTextProps {
  status: PanelTranscriptStatus;
}

export const IssueText: FC<IssueTextProps> = ({ status }) => {
  let text = '';
  switch (status) {
    case PanelTranscriptStatus.insufficient_tr: {
      text = 'insufficient target regions';
      break;
    }
    case PanelTranscriptStatus.untargetable: {
      text = 'untargetable';
      break;
    }
    case PanelTranscriptStatus.not_found: {
      text = 'not found';
      break;
    }
    default: {
      text = '';
    }
  }
  return (
    <Text
      color="content.additional"
      as="span"
      ml="4px"
      whiteSpace="nowrap"
      d={{ base: 'none', lg: 'block' }}
    >
      {text}
    </Text>
  );
};
