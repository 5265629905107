import { Center, Text } from '@chakra-ui/react';
import { FC } from 'react';

export const InfoMessage: FC = ({ children }) => {
  return (
    <Center w="100%" mt="24px" bg="background.secondary">
      <Text my="40px" variant="accentBase">
        {children}
      </Text>
    </Center>
  );
};
