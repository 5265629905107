import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { isAuxProbeMissingSelector, useCreatePanelStore } from 'store';

export const ProbeHeader: FC = () => {
  const isProbeMissing = useCreatePanelStore(isAuxProbeMissingSelector);
  const isSubmitted = useCreatePanelStore((state) => state.isSubmitted);
  const color =
    isSubmitted && isProbeMissing ? 'state.error' : 'content.primary';

  return (
    <Flex as="span" color={color} align="center">
      Probe
    </Flex>
  );
};
